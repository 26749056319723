export const COLUMN_CATEGORY = [
  {
    title: 'STT',
    dataIndex: 'no',
    width: '10%',
    key: 'no'
  },
  {
    title: 'Tên danh mục',
    dataIndex: 'name',
    width: '35%',
    key: 'name'
  },
  {
    title: 'Người tạo',
    dataIndex: ['user','name'],
    width: '30%',
    key: 'user.name'
  },
  {
    title: '',
    dataIndex: 'action',
    width: '10%',
    key: 'action'
  }
];

export const ACTION = {
  Add: 'ADD',
  Edit: 'EDIT',
  Del: 'DEL',
  Cancel: 'CANCEL',
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGESIZE'
};

export const PAGE_SISES = [10, 20, 30];
