<template>
  <div>
    <Loading v-if="isLoading" />
    <div class="wrap-content">
      <div class="banner-course">
        <div class="course-info">
          <h3 class="title">{{ texts.course }}: {{ titleCourse }}</h3>
          <p class="description mt-3" ref="DescCourseEl"></p>
          <div class="course-deadline mt-3">
            <span>{{ texts.deadline }}: </span>
            <span>{{ getDMY(check_deadline) }}</span>
          </div>
          <div class="list-tag">
            <div
              class="tag"
              v-for="category in currentLearningCategories"
              :key="category.id"
            >
              <span>{{ category.name }}</span>
            </div>
            <div
              class="threeDots"
              v-if="!showAllCategory"
              @click="handleClickThreeDots"
            >
              ...
            </div>
          </div>
        </div>
        <div class="course-image">
          <div class="imgContainer">
            <img :src="imagePath" :alt="texts.imagePlaceholder" />
          </div>
          <button
            @click="onStartCourse(true)"
            :disabled="check_deadline?.is_deadline ? true : false"
          >
            {{ isHaveLearned ? texts.continueCourse : texts.startCourse }}
          </button>
        </div>
      </div>
      <div class="mt-4 content-course">
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel
            key="1"
            :header="texts.lesson"
            :extra="`${countChapter} ${texts.chapterText}`"
            :collapsible="check_deadline?.is_deadline ? 'disabled' : ''"
          >
            <div
              class="content"
              v-for="(chapter, index) in chapterLesson"
              :key="chapter.id"
            >
              <div class="chapter-title">
                {{ `${texts.chapter} ` + renderRoman(index + 1) }}:
                {{ chapter.title }}
              </div>
              <template v-if="chapter?.lesson.length">
                <div
                  class="lesson"
                  v-for="(lesson, index) in chapter.lesson"
                  :key="lesson.id"
                  @click="handleAction(typeActions.LESSON, lesson.id)"
                >
                  <span>-</span>
                  <span class="lesson-title">{{ `${texts.post} ${index + 1}. ${lesson.title}` }}</span>
                </div>
              </template>
            </div>
          </a-collapse-panel>
          <a-collapse-panel
            key="2"
            :header="texts.test"
            :extra="`${countTests} ${texts.testText}`"
            :collapsible="check_deadline?.is_deadline ? 'disabled' : ''"
          >
            <div
              class="lesson"
              v-for="test in tests"
              :key="test.id"
              @click="handleAction(typeActions.TEST, test.id)"
            >
              <span>-</span>
              <span class="lesson-title">{{ test.name }}</span>
            </div>
          </a-collapse-panel>

          <a-collapse-panel
            key="3"
            :header="texts.references"
            :collapsible="check_deadline?.is_deadline ? 'disabled' : ''"
          >
            <div
              class="lesson"
              v-for="file in referencesFile"
              :key="file.id"
              @click="handleAction(typeActions.REFERENCE, file.id)"
            >
              <div
                v-if="file.type === types.filePdf"
                class="flex items-center gap-2"
              >
                <img :width="20" src="/images/pdf.svg" alt="pfd file" />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
              <div
                v-else-if="types.fileDoc.includes(file.type)"
                class="flex items-center gap-1"
              >
                <img
                  :width="26"
                  src="/images/doc.png"
                  alt="pfd file"
                  class="-ml-[3px]"
                />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
              <div v-else class="flex items-center gap-1">
                <img
                  :width="26"
                  src="/images/ptt.png"
                  alt="pfd file"
                  class="-ml-[3px]"
                />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "../../components/loading/Loading.vue";
import { DetailsCourseApi } from "./api/request";
import { texts } from "./utils/texts";
import { TYPE_SOURCE, TYPE_ACTION } from "./utils/constant";
import { h, reactive } from "vue";
import moment from "moment";
import { romanzie } from "./helper/renderRomanNumber";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { DATE_FORMAT } from "./utils/constant";
export default {
  components: { Loading },
  props: {
    title: String,
    description: String,
    listTag: [],
    course: {},
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const types = reactive({
      filePdf: "pdf",
      fileDoc: ["doc", "docx"],
    });
    const divElement = h("div", { class: "container" }, "Hello, world!");
    const renderRoman = (number) => {
      return romanzie(number);
    };
    const resetIdAll = () => {
      store.dispatch("updateIdLesson", null);
      store.dispatch("updateIdReference", null);
      store.dispatch("updateIdTest", null);
    };
    const onStartCourse = async (start) => {
      if (start) {
        resetIdAll();
      }
      const idCourse = route.params.slug;
      router.push(`${idCourse}/lesson`);
    };
    const handleAction = (action, id) => {
      resetIdAll();
      switch (action) {
        case TYPE_ACTION.LESSON:
          store.dispatch("updateIdLesson", id);
          onStartCourse();
          break;
        case TYPE_ACTION.REFERENCE:
          store.dispatch("updateIdReference", id);
          onStartCourse();
          break;
        case TYPE_ACTION.TEST:
          store.dispatch("updateIdTest", id);
          onStartCourse();
          break;
        default:
          break;
      }
    };
    return {
      divElement,
      renderRoman,
      onStartCourse,
      resetIdAll,
      handleAction,
      types,
      t,
    };
  },
  data() {
    return {
      texts: texts,
      typeActions: TYPE_ACTION,
      activeKey: [],
      isLoading: false,
      detailCourse: {},
      chapters: [],
      tests: [],
      learningFiles: [],
      chapterLesson: [],
      learningCategories: [],
      currentLearningCategories: [],
      showAllCategory: true,
      imagePath: "",
      countChapter: 0,
      countTests: "",
      titleCourse: "",
      description: "",
      referencesFile: [],
      contentDesc: "",
      isHaveLearned: "",
      paramsApi: {
        target: "home",
        step: "test_info",
      },
      imgType: TYPE_SOURCE.IMAGE_TYPE,
      check_deadline: {},
      typeFormatDate: DATE_FORMAT,
    };
  },
  methods: {
    async getDetailCourse(id) {
      this.isLoading = true;
      try {
        const response = await DetailsCourseApi.getDetailCourse(
          id,
          this.paramsApi
        );
        const {
          title,
          description,
          chapters,
          learningFiles,
          tests,
          image,
          learning_categories,
          files,
          progress,
          check_deadline,
        } = response.data;
        this.chapters = chapters;
        this.tests = tests;
        this.learningFiles = learningFiles;
        this.learningCategories = learning_categories;
        this.titleCourse = title;
        this.description = description;
        this.contentDesc = description;
        this.isHaveLearned = progress;
        if (check_deadline?.deadline_date) {
          this.check_deadline = {
            ...check_deadline,
            is_deadline: !check_deadline?.is_deadline,
          };
        } else {
          this.check_deadline = check_deadline;
        }
        this.imagePath =
          image.find((file) => {
            return this.imgType.includes(file.type);
          })?.full_path || "";
        this.referencesFile = files;
        this.chapterLesson = this.chapters.map((chapter) => {
          return {
            title: chapter.title,
            lesson: chapter.lessons,
          };
        });

        this.checkCountCategory(this.learningCategories);
        const maximumText = 500;
        this.toggleLongTest(maximumText, this.$refs.DescCourseEl);
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },

    checkCountCategory(categories) {
      const maximumQuantity = 5;
      if (categories.length > maximumQuantity) {
        this.showAllCategory = false;
        this.currentLearningCategories = categories.slice(0, maximumQuantity);
      } else {
        this.showAllCategory = true;
        this.currentLearningCategories = categories;
      }
    },
    handleClickThreeDots() {
      (this.showAllCategory = true),
        (this.currentLearningCategories = this.learningCategories);
    },
    getDMY(date) {
      if (date?.deadline_date) {
        return moment(date?.deadline_date).format(this.typeFormatDate);
      } else {
        return this.t("courseManagement.common.dateEmpty");
      }
    },
    toggleLongTest(maxLength, element) {
      const node = { element };
      element.innerHTML = this.description;
      const parentNode = node.element.children;
      const nodeText = parentNode[0].children[0];
      const originalText = nodeText.textContent;
      if (originalText.length > maxLength) {
        const shortenedText = originalText.substring(0, maxLength) + " ...";
        nodeText.textContent = shortenedText;
        nodeText.style.textAlign = "justify";
        nodeText.style.display = "block";
        nodeText.addEventListener("click", function () {
          if (nodeText.textContent === shortenedText) {
            nodeText.textContent = originalText;
          } else {
            nodeText.textContent = shortenedText;
          }
        });
      }
    },
  },
  watch: {
    chapterLesson(value) {
      value.forEach(element => {
        this.countChapter = this.countChapter + element?.lesson.length;
      });
    },
    tests(value) {
      this.countTests = value.length;
    },
    $route(to) {
      if (to.name === "DetailsCourse") {
        this.getDetailCourse(this.$route.params.slug);
      }
    },
  },
  created() {
    this.getDetailCourse(this.$route.params.slug);
  },
};
</script>
<style lang="scss" scoped>
.banner-course {
  display: flex;
  background-color: #3c57a5;
  color: white;
  margin: 0 10px;
  justify-content: space-between;
  padding: 12px 30px 30px 30px;
  h3 {
    color: white;
    font-size: 26px;
    font-weight: bold;
    line-height: 36px;
  }
  p {
    font-size: 16px;
    font-weight: bold;
  }
  .course-info {
    width: 58%;
    .title {
      text-transform: uppercase;
    }
    .description {
      font-size: 16px;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .course-deadline {
    font-size: 16px;
    font-weight: bold;
  }
  .list-tag {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 34px;
    .tag {
      border: 1px solid white;
      border-radius: 999px;
      display: inline-block;
      padding: 6px 14px;
      cursor: pointer;
      span {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .course-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38%;
    .imgContainer {
      position: relative;
      width: 100%;
      padding-top: 75.11%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    button {
      margin-top: 16px;
      background: #1280bf;
      outline: none;
      border: none;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      padding: 10px 34px;
      border-radius: 4px;
    }
  }
}

.content-course {
  margin-top: 16px;
  padding: 0 10px;
}
.ant-collapse :deep(.ant-collapse-header) {
  font-weight: bold;
  font-size: 20px;
  align-items: stretch;
  div {
    display: flex;
    align-items: center;
  }
}
.ant-collapse :deep(.ant-collapse-extra) {
  font-weight: normal;
  font-size: 14px;
}
.lesson {
  padding: 4px 0;
  cursor: pointer;

  span:hover {
    font-weight: 700;
  }
  .lesson-title {
    font-size: 16px;
    font-style: italic;
    color: #813ed3;
    margin-left: 16px;
  }
}
.threeDots {
  font-size: 20px;
  cursor: pointer;
}
.ant-collapse :deep(.content) {
  .header-content {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-bottom: 4px;
  }
  display: flex;
  flex-direction: column;
  .chapter-title {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
