<template>
  <a-modal
    :visible="visible"
    :zIndex="zIndex"
    :style="{ maxWidth: '380px' }"
    footer=""
    :closable="false"
    :class="{
      'confirm-edit': typeEdit || typeConfirm,
      'confirm-delete': typeDel,
      'confirm-edit-test': typeConfirmTest,
    }"
    centered
  >
    <div class="title">{{ texts.title }}</div>
    <div class="content">
      <p>{{ message }}</p>
      <p v-if="!typeConfirm && !typeConfirmTest">
        {{ texts.confirm }}
      </p>
    </div>

    <div class="footer">
      <a-button
        v-if="!cancelHidden"
        class="btn-button-cancel mr-2"
        @click="$emit('cancel')"
        >Hủy</a-button
      >
      <a-button
        key="submit"
        type="primary"
        class="btn-button primary"
        @click="$emit('ok')"
        >{{ typeConfirmTest ? texts.submit : texts.ok }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { TypeModalConfirm } from "./interface";
import { texts } from "./utils/texts";
export default {
  props: {
    ok: Function,
    cancel: () => {},
    visible: Boolean,
    type: String,
    message: String,
    cancelHidden: Boolean,
    zIndex: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      texts,
    };
  },
  computed: {
    typeEdit() {
      return this.type === TypeModalConfirm.Edit;
    },
    typeDel() {
      return this.type === TypeModalConfirm.Delete;
    },
    typeConfirm() {
      return this.type === TypeModalConfirm.ConFirm;
    },
    typeConfirmTest() {
      return this.type === TypeModalConfirm.ConFirmTest;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-edit {
  .ant-modal-body {
    padding: 7px 12px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 136.02%;
    color: #000000;
    margin-bottom: 4px;
  }

  .content {
    font-weight: 400;
    font-size: 15px;
    line-height: 136.02%;
    color: #515151;
    margin-bottom: 14px;
    p {
      margin-bottom: 6px;
    }
  }

  .footer {
    display: flex;
    justify-content: right;
  }
}
.confirm-delete {
  .ant-modal-body {
    padding: 5px 14px 4px 14px;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 136.02%;
    color: #e41d1d;
  }
  .content {
    font-weight: 400;
    font-size: 15px;
    line-height: 136.02%;
    color: #e41d1d;
    margin-bottom: 14px;
    p {
      margin: 6px;
    }
  }
  .footer {
    display: flex;
    justify-content: right;
  }
}
.confirm-edit-test {
  .title {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .content {
    font-weight: bold;
    font-size: 18px;
    line-height: 136.02%;
  }
  .btn-button-cancel {
    border: none;
    outline: none;
    color: #17469e;
  }
  .btn-button.primary {
    background: #17469e !important;
  }
}
</style>
