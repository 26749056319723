import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const ELearningManagementApi = {
  async getCategory(params) {
    try {
      const response = await RequestApi.get(pathUrl.category, { params });
      return response.data;
    } catch (error) {
      throw new Error();
    }
  },
  async createCategory(payload) {
    try {
      const response = await RequestApi.post(pathUrl.category, payload);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async editCategory(id, payload) {
    try {
      const res = await RequestApi.put(pathUrl.category + '/' + id, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteCategory(id) {
    const response = await RequestApi.delete(pathUrl.category + '/' + id);
  },

  async listTypeFile() {
    try {
      const res = await RequestApi.get('file/type');
      return res.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getListStudyMaterial(params) {
    try {
      const res = await RequestApi.get(pathUrl.studyMaterial, {
        params: params
      });
      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getListUser(params) {
    try {
      const res = await RequestApi.get(pathUrl.user, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getCourseware(id) {
    try {
      const res = await RequestApi.get(pathUrl.studyMaterial + `/${id}`);
      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateCourseware(id, payload) {
    try {
      const res = await RequestApi.post(pathUrl.studyMaterial + '/' + id + '?_method=PUT', payload);
      return res;
    } catch (error) {
      throw error
    }
  },
  async deleteCourseware(id){
    try {
      const res = await RequestApi.delete(pathUrl.file + '/remove/' + id);
      return res;
    } catch (error) {
      throw error
    }
  }
};
