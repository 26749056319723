import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const DetailsCourseApi = {
  async getDetailCourse(id, params) {
    try {
      const response = await RequestApi.get(`${pathUrl.course}/${id}`, {
        params
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getDetailLesson(id) {
    try {
      const response = await RequestApi.get(`${pathUrl.lesson}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getDetailELearningFile(id) {
    try {
      const response = await RequestApi.get(`${pathUrl.elearning_file}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async checkQualifyTest(id) {
    try {
      const response = await RequestApi.get(
        `${pathUrl.test}/${id}/check-before-make-test`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getDetailTest(id) {
    try {
      //comment
      const response = await RequestApi.get(`${pathUrl.test}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async startTest(id) {
    try {
      const response = await RequestApi.post(`${pathUrl.test}/${id}/start`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getQuestionByIdTest(id) {
    try {
      const params = {
        target: 'home'
      };
      const response = await RequestApi.get(`${pathUrl.test}/${id}`, {
        params
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getQuestion(page, id){
    try {
      const params = {
        page: Math.ceil(page/10),
        limit: 10,
      };
      const response = await RequestApi.get(`${pathUrl.questions}/${id}`, {
        params
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async submitTest(id, payload) {
    try {
      const response = await RequestApi.post(
        `${pathUrl.test}/${id}/finish`,
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  async requestStartCourse(id) {
    try {
      const response = await RequestApi.get(
        `${pathUrl.course}/${id}/start_learning`
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getResultTest(id) {
    try {
      const response = await RequestApi.get(`${pathUrl.test}/${id}/results`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async showResultTest(id) {
    try {
      const response = await RequestApi.get(`${pathUrl.test}/${id}/see_answer`);
      return response.data;
    } catch (error) {
      new Error(error);
    }
  },
  async completeVideo(payload){
    try {
      await RequestApi.post(`${pathUrl.lesson}/progress_video`, payload)
    } catch (error) {
      throw error
    }
  }
};
