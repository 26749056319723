
<template>
  <div class="course-item" @click="handleVisibleCourse(id)">
    <div
      class="wrap-image"
      :style="`background-image: url(${image ? image : IMG_PLACEHOLD})`"
    >
      <img
        style="opacity: 0; position: absolute; width: 100%; height: 100%"
        :src="`${image ? image : IMG_PLACEHOLD}`"
        alt=""
      />
      <svg
        width="240px"
        height="180px"
        style="width: 100%; height: auto; display: block"
      ></svg>
    </div>
    <div class="content">
      <p class="course-title">{{ title }}</p>
      <div class="course-detail">
        <p class="course-deadline">
          <span>{{ texts.deadline }}: </span
          ><span>{{
            deadline ? deadline : t("courseManagement.common.dateEmpty")
          }}</span>
        </p>
        <div class="course-progress">
          <div :style="{ width: progress + '%', height: '100%' }">
            <div class="progress-bar" ref="bar"></div>
          </div>
        </div>
        <p class="course-progress-text">
          <span>{{ progress }}% </span><span>{{ texts.complete }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { IMG_PLACEHOLD } from "./utils/constant";
import { texts } from "./utils/texts";
const router = useRouter();
const { t } = useI18n();
const props = defineProps({
  id: Number,
  image: String,
  title: String,
  deadline: String,
  progress: Number,
});
const handleVisibleCourse = (id) => {
  router.push(`course/${id}`);
};
</script>
<style lang="scss" scoped>
.course-item {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  margin: 0;
  cursor: pointer;
  background-color: white;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
  color: black;

  .wrap-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  .content {
    padding: 0 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    .course-title {
      text-align: center;
      font-size: 20px;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 10px;
      flex: 1;
    }
    .course-detail {
      .course-deadline {
        margin-bottom: 6px;
        text-align: start;
        font-style: italic;
        font-weight: bold;
      }
      .course-progress {
        width: 100%;
        height: 5px;
        background-color: rgba(217, 217, 217, 0.6);
        border-radius: 4px;
        &-text {
          font-size: 10px;
          font-style: italic;
        }
        .progress-bar {
          height: 100%;
          background-color: #62de00;
          animation: progress-bar-animation 1.5s ease-in-out;
        }
      }
    }
  }

  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 6px;
  }
  @keyframes progress-bar-animation {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
}
</style>
