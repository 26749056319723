<template>
  <div class="switch-wrapper">
    <div
      v-for="language in languages"
      :key="language.title"
      :class="{ 'language-item': true, 'is-active': language.isActive }"
      @click="() => handleChangeLanguage(language)"
    >
      <span class="title-item">{{ language.title.toUpperCase() }}</span>
      <span class="description-item">
        {{ language.description }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
defineProps({
  languages: {
    type: Array,
    default: () => [
      {
        isActive: true,
        title: 'VI',
        description: 'Vietnamese'
      },
      {
        isActive: false,
        title: 'EN',
        description: 'English'
      }
    ]
  },
  changeLanguage: Function
});

const emit = defineEmits();

const handleChangeLanguage = (language) => {
  emit('changeLanguage', language);
};
</script>

<style lang="scss" scoped>
.switch-wrapper {
  background-color: #f1f1f1;
  border-radius: 24px;
  height: 37px;
  display: flex;
  padding: 2px;
  justify-content: space-between;
  font-weight: 700;

  .language-item {
    height: 100%;
    min-width: 117px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    .title-item {
      background-color: #ababab;
      color: #f4f4f4;
      padding: 0 4px;
      border-radius: 5px;
      margin-right: 6px;
      font-size: 14px;
    }
    .description-item {
      color: #ababab;
      font-size: 12px;
    }

    &:hover {
      cursor: pointer;
    }
  }
  .is-active {
    background-color: white;
    .description-item {
      color: black;
    }
    .title-item {
      background-color: black;
      color: white;
    }
  }
}
</style>
