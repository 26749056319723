<template>
  <div class="step-wrapper">
    <div
      v-for="(step, index) in steps"
      :key="index"
      @click="$emit('click', step)"
      class="step-item"
    >
      <div
        :class="{
          'active-doing':
            step.status === CREATE_COURSE_STATUS.Doing || step.view,
          'active-success':
            step.completed &&
            step.status !== CREATE_COURSE_STATUS.Doing &&
            !isViewCourse,
          name: true,
          'step-item-view': isViewCourse
        }"
        @click="() => handleSwitchStep(step)"
      >
        <a-tooltip v-if="isViewCourse">
          <template #title>
            {{ `Click để xem bước ${step.name}` }}
          </template>
          <span
            class="icon-check"
            v-if="
              step.completed &&
              step.status !== CREATE_COURSE_STATUS.Doing &&
              !isViewCourse
            "
          >
            <IconCheck />
          </span>
          <span v-else>
            {{ step.name }}
          </span>
        </a-tooltip>
        <div v-else>
          <span
            class="icon-check"
            v-if="
              step.completed &&
              step.status !== CREATE_COURSE_STATUS.Doing &&
              !step.isViewCourse
            "
          >
            <IconCheck />
          </span>
          <span v-else>
            {{ step.name }}
          </span>
        </div>
      </div>
      <span class="title"> {{ step.title }}</span>
      <div class="line" v-if="index !== steps.length - 1" />
    </div>
  </div>
</template>

<script setup>
import { CREATE_COURSE_STATUS } from '../utils/constant';
import IconCheck from '../../../../components/icon/icon-check.vue';
import { defineComponent, toRefs, defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import store from '../../../../store';
defineComponent({
  components: {
    IconCheck
  }
});
defineProps({
  click: Function,
  steps: {
    type: Array,
    default: () => [
      {
        name: 1,
        title: 'Thông tin cơ bản',
        status: CREATE_COURSE_STATUS.Doing,
        view: false
      },
      {
        name: 2,
        title: 'Thông tin bài giảng',
        status: CREATE_COURSE_STATUS.Open,
        view: false
      },
      {
        name: 3,
        title: 'Thông tin kiểm tra',
        status: CREATE_COURSE_STATUS.Open,
        view: false
      }
    ]
  }
});
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const courseStatus = computed(() => store.state.courseManagement.courseStatus);

const handleSwitchStep = (step) => {
  if (!courseStatus.value) return;
  if (!isViewCourse.value) {
    store.dispatch('updateEditStatus', true);
    store.dispatch('goEditStep', step.name);
    store.dispatch('updateEditStatus', true);
  } else {
    store.dispatch('viewStep', step.name);
  }
};
</script>

<style lang="scss" scoped>
.step-wrapper {
  width: 74px;
}
.step-item {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    width: 30px;
    height: 30px;
    border-radius: 99px;
    background-color: #999999;
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  .name.active-success {
    background-color: #3fbf12;
  }

  .name.active-doing {
    background-color: #1890ff;
  }

  .step-item-view {
    &:hover {
      cursor: pointer;
    }
  }
  .title {
    font-size: 12px;
    color: #333;
    text-align: center;
  }
  .line {
    background-color: black;
    width: 1px;
    height: 30px;
  }

  .icon-check {
    display: flex;
    align-items: center;
  }
}
</style>
