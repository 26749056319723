<template>
  <div class="flex items-start bg-white">
    <div class="sticky top-0 bg-white min-h-[calc(100vh-98px)] max-h-[calc(100vh-98px)] overflow-auto z-50">
      <div class="text-base font-bold text-center pt-2 pb-2 sticky top-0 bg-white z-10 border-b shadow-md border-r-[1px] border-l-[#333]">Danh Mục</div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        style="width: 256px; max-height: calc(100vh - 139px); min-height: calc(100vh - 139px)"
        mode="inline"
        :open-keys="openKeys"
      >
        <a-sub-menu v-for="category in categories" :key="category.id" class="font-bold">
          <template #title>{{category?.name}}</template>
          <a-menu-item v-for="post in category?.post" :key="post?.id" class="font-normal" @click="goToDetail(post?.slug)">{{post?.title}}</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
    <section class="home flex-1 md:-ml-[50px] lg:-ml-[100px]" ref="content">
      <Loading v-if="loading" />
      <div class="relative w-full text-center mt-3">
        <img
          src="../../../../public/images/Logo-MOR-1.png"
          alt="logo-mor"
          width="269"
          class="mx-auto"
        /> 
      </div>
      <div class="search-wrapper mt-4">
        <div class="search flex">
          <img
            src="../../../../public/images/home-search.svg"
            alt=""
            class="pl-[8px]"
          />
          <div class="search-input">
            <input
              type="text"
              v-model="keywordSearch"
              placeholder="Bạn đang tìm gì?"
              @focus="toggleSuggest()"
              @blur="closeSuggest()"
              @keyup.enter="searchEnter($event)"
            />
            <div
              class="list-suggest"
              v-show="keywordSearch && flagSuggest && options?.length"
            >
              <div class="item" v-for="option in options" :key="option.id">
                <a @click="applySuggest(option.keyword)" class="pb-6 inline-block">
                  {{ option.keyword }}
                </a>
              </div>
            </div>
          </div>
          <div class="label-search">
            <!-- <div>Tất cả kết quả</div> -->
          </div>
        </div>
        <div class="quick-view mt-[6px]">
          <div class="flex justify-center gap-3">
            <p
              v-for="(item, key) in listQuickView"
              :key="key"
              @click="handleSearchClick(item.title)"
              class="text-[#17469E] cursor-pointer text-base"
            >
              {{ item.title }}
            </p>
          </div>
        </div>
        <div class="text-[12px] text-[#535353] mt-2" v-if="keywordSearch">
          <p>Khoảng {{ total }} kết quả</p>
        </div>
        <div class="mt-2" v-if="posts.length && keywordSearch?.length >= 2">
          <p class="text-sm font-bold ck-content" ref="propose"></p>
        </div>
      </div>

      <!----------------- Search list -------------------->
      <div class="search-list" v-if="posts.length">
        <div class="search-item" v-for="(item, index) in posts" :key="item.id">
          <div class="flex items-center gap-[6px] mb-1">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
              alt="user avatar"
              class="w-[30px] h-[30px] bg-cover rounded-full"
            />
            <div class="search-item-title mb-0 text-[12px] font-semibold">
              {{ item.user?.name }}
            </div>
          </div>
          <div
            class="search-item-title text-[16px] text-[#17469F] font-semibold inline-block"
            @click="goToDetail(item.slug)"
            v-html="item.title"
          ></div>
          <div
            class="ck-content block text-[14px]"
            v-if="index || keywordSearch?.length < 3"
            v-html="handleContentText(item, item.created_at, index)"
          ></div>
        </div>
      </div>

      <div class="pagination" v-if="total > per_page">
        <a-pagination
          :total="total"
          :page-size="per_page"
          v-model:current="current_page"
          class="pagination-page"
          @change="changePage($event)"
        />
      </div>
      <div
        class="title-top-views flex-col max-w-[800px]"
        v-if="!keywordSearch && !this.posts?.length"
      >
        <div class="flex items-stretch flex-1">
          <div class="flex items-center justify-center bg-[#FBF563] h-[42px]">
            <img
              src="../../../../public/images/home-tips.png"
              alt=""
              width="34"
            />
          </div>
          <div
            class="bg-[#fffdd7] bg-gradient-to-r from-[#fffdd7] to-white items-center pl-2 grow flex"
          >
            <p class="font-bold">Những điều bạn cần biết</p>
          </div>
        </div>
        <a-skeleton :loading="loadingTips" class="min-h-[214px]" active>
          <div class="summary-post mt-2 hidden">
            <div
              v-for="(chapter, key) in postRandom?.chapters"
              :key="key"
              class="truncate-tips font-normal"
            >
              <div class="content-title text-base">{{ chapter.title }}</div>
              <div
                class="content-detail ck-content"
                v-html="chapter.description"
              ></div>
            </div>
            <span
              id="show-less"
              class="inline font-bold text-[#17469F] cursor-pointer"
              @click="goToDetail(postRandom.slug)"
              >[Đọc tiếp]</span
            >
          </div>
        </a-skeleton>

        <div class="flex text-[13px] justify-end gap-1">
          <span class="font-normal">Được trích từ bài viết:</span>
          <span
            class="text-[#17469F] cursor-pointer"
            @click="goToDetail(postRandom.slug)"
            >{{ postRandom.title }}</span
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import IconDropdown from "../../components/icon/icon-dowload.vue";
import IconNext from "../../components/icon/icon-next.vue";
import IconPrev from "../../components/icon/icon-prev.vue";
import Loading from "../../components/loading/Loading.vue";
import { debounce } from "lodash";
import { postMangemenetApi } from "../../features/post/api";
import { LIST_QUICK_VIEW } from "./utils/constant";
import { category, search, searchAll } from "../../api/home";
// import {removeVietnameseTones} from '../../utils/helper'
import Mark from "mark.js";

export default {
  components: { IconDropdown, IconNext, IconPrev, Loading },
  data() {
    return {
      keywordSearch: "",
      options: [],
      flagListSearch: "",
      flagSuggest: false,
      page: 1,
      totalPageTopViews: 5,
      pageTopViews: 0,
      posts: [],
      total: 0,
      current_page: 0,
      categories: [],
      category_id: "",
      limit: 10,
      from: 0,
      to: 0,
      mostSearched: [],
      category_name: "Tất cả",
      per_page: 1,
      loading: false,
      postRandom: [],
      listQuickView: LIST_QUICK_VIEW,
      loadingTips: true,
      htmlPropose: "",
      maxLengthTruncate: 500,
      postHighLight: null,
      selectedKeys: [], 
      openKeys: ['sub1'],
    };
  },

  created() {
    this.category();
    this.getListPost();
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.keyword || params.category_id || params.page) {
      this.keywordSearch = params.keyword;
      this.category_id = params.category_id;
      this.page = params.page;
      this.searchAll();
    }
    document.title = "Công Cụ Tìm Kiếm Mor Wiki";
  },
  
  watch: {
    keywordSearch: debounce(function (e) {
      this.search();
      this.postHighLight = null
    }, 500),
    $route: function (search) {
      if (search.fullPath === "/") {
        this.loadingTips = true
        this.getListPost()
        this.keywordSearch = "";
        this.posts = [];
        this.total = 0;
        this.per_page = 1;
        let findCategory = this.categories.find((item) => item.id === "");
        this.category_name = findCategory.name;
        document.title = "Công Cụ Tìm Kiếm Mor Wiki";
      }
    },
  },

  methods: {
    customDisplayDateTime(dateString) {
      const date = dateString.split("/");
      return `${date?.[0]} tháng ${date?.[1]}, ${date?.[2]} - `;
    },
    replaceRouter() {
      this.$router.replace("/").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
      this.$router.replace({
        query: {
          keyword: this.keywordSearch,
          category_id: this.category_id,
          page: this.page,
        },
      });
    },
    handleMenuClick(e) {
      this.category_id = e.key;
      let findCategory = this.categories.find((item) => item.id === e.key);
      this.category_name = findCategory.name;
      this.page = 1
      this.searchAll();
    },
    goToDetail(slug) {
      this.$router.push({
        name: "home-detail",
        params: { slug: slug },
        query: { keyword: this.keywordSearch },
      });
    },
    handleSearchClick(e) {
      this.postHighLight = null
      this.keywordSearch = e;
      this.searchAll();
    },
    handleSearchAllClick(e) {
      this.page = 1;
      this.searchAll();
    }, 

    handleContentText(post, date = null) {
      const maxLength = 145
      const currentLength = 0
      let newContent = null
      const keyword = this.keywordSearch.toLowerCase()
      const chapters = post.chapters
      if(chapters){
        chapters.forEach((chapter)=>{
          const div = $('<div></div>');
          div.append(chapter.description)
          // find tag in content
          const listContent = div.find('p, ol, ul, h2, h3, h4, h5')
          for (let i = 0; i < listContent.length; i++) {
            const content = listContent[i].textContent
            const convertContent = listContent[i].textContent.toLowerCase()
            if(convertContent.includes(keyword) && !newContent){
              let contentRender = this.truncateContentMatch(content, (maxLength - currentLength))
              const words = contentRender.split(" ")
              const words1 = content.split(" ")
              if(words1[0] != words[0]){
                words.shift();
              }
              const result = words.join(" ");
              newContent = result
            }
          }
        })
        if(!newContent){
          newContent = this.truncateContentNotMatch(post.description, 150)
        }
      }
    
      let dateCreate = null;
      if (date) dateCreate = `${date.split("/")[0]} tháng ${date.split("/")[1]}, ${date.split("/")[2]} - `
      if (date) {
          return dateCreate + newContent + '...';
        }
      return text;
    },

    applySuggest(option) {
      this.flagSuggest = false;
      this.keywordSearch = option;
      this.searchAll();
    },
    searchEnter(event) {
      event.target.blur();
      this.searchAll();
      this.flagSuggest = false;
    },
    async search() {
      try {
        const keyword = "?keyword=" + this.keywordSearch;
        const response = await search(keyword);
        this.options = response.data;
      } catch (error) {
        throw error;
      }
    },

    async category() {
      try {
        const response = await category();
        this.categories = response.data
        // this.categories = [{ id: "", name: "Tất cả" }, ...this.categories];
        if (this.$route.query.category_id) {
          const currentCategory = this.categories.find((category) => {
            return category.id === +this.$route.query.category_id;
          });
          this.category_name = currentCategory.name;
        }
      } catch (error) {
        throw error;
      }
    },

    async searchAll() {
      try {
        this.loading = true;
        document.title = this.keywordSearch
          ? this.keywordSearch + "-Tìm kiếm trên wiki"
          : "Wiki Mor";
        document
          .querySelector("meta[property='og:description']")
          .setAttribute(
            "content",
            this.keywordSearch
              ? this.keywordSearch + "-Tìm kiếm trên wiki"
              : "Wiki Mor"
          );
        const params = {
          keyword: "?keyword=" + this.keywordSearch,
          category_id: "&category_id=" + this.category_id,
          limit: "&limit=" + this.limit,
          page: "&page=" + this.page,
        };
        const response = await searchAll(params);
        this.replaceRouter();
        this.posts = response?.data || [];
        this.total = response?.meta.total;
        this.current_page = response?.meta.current_page;
        this.per_page = parseInt(response?.meta.per_page);
        this.from = response?.meta.from;
        this.to = response?.meta.to;
      } catch (error) {
        throw error;
      } finally {
        if(!this.postHighLight){
          this.postHighLight = true
          setTimeout(()=>{
            this.mainPost(this.posts[0])
            this.initSearch()
          }, 100)
        }
        this.loading = false;
      }
    },

    mainPost(postHighLight){
      let itemMatchKeyword = true
      let currentTotalLengthText = 0
      const propose = $(this.$refs.propose);
      if (propose.children().length > 0) {
        propose.empty();
      }

      const newDiv = $('<div class="content-propose"></div>')
      postHighLight?.chapters.forEach((item)=>{
        newDiv.append(item.description)
      })
      const listParagraph = newDiv.find('p, ul, ol, figure, img')
      for(let i = 0; i < listParagraph.length; i++){
        if(listParagraph[i].textContent.toLowerCase().includes(this.keywordSearch.toLowerCase())){
          itemMatchKeyword = false
        }
      }
      for(let i = 0; i < listParagraph.length; i++){
        if(listParagraph[i].tagName === 'FIGURE' || listParagraph[i].tagName === 'IMG'){
          listParagraph[i].remove()
        }
        if(listParagraph[i].textContent.toLowerCase().includes(this.keywordSearch.toLowerCase()) || (itemMatchKeyword && currentTotalLengthText < this.maxLengthTruncate)){
          if((listParagraph[i].tagName === 'UL' || listParagraph[i].tagName === 'OL')){
            const listLi = $(listParagraph[i]).find('li')
            for(let i = 0; i < listLi.length; i++){
              let content = listLi[i].textContent
              let newContent 
              if(currentTotalLengthText < this.maxLengthTruncate && (content.toLowerCase().includes(this.keywordSearch.toLowerCase()) || itemMatchKeyword)){
                if(!itemMatchKeyword){
                  itemMatchKeyword = listLi[i]
                  newContent = this.truncateContentMatch(content, this.maxLengthTruncate - currentTotalLengthText)
                }else{
                  newContent = this.truncateContentNotMatch(content, this.maxLengthTruncate - currentTotalLengthText)
                }
                listLi[i].textContent = newContent
                currentTotalLengthText += newContent.length
             }
             else{
              listLi[i].remove()
             }
            }
          }
          else{
            const content = listParagraph[i].textContent
            let newContent
            if(!itemMatchKeyword){
              newContent = this.truncateContentMatch(content, this.maxLengthTruncate - currentTotalLengthText)
              itemMatchKeyword = listParagraph[i]
            }
            else{
              newContent = this.truncateContentNotMatch(content, this.maxLengthTruncate - currentTotalLengthText)
            }
            listParagraph[i].textContent = newContent
            currentTotalLengthText += newContent.length
          }
        }else{
          listParagraph[i].remove()
        }
      }     
      setTimeout(()=>{$(this.$refs.propose).append(newDiv)}, 0)
    },

    truncateContentMatch(content, maxLengthTruncate){
      let beforeContent = this.getTextBeforeKeyword(content, this.keywordSearch, maxLengthTruncate)
      let afterContent = ""
      if(beforeContent.length < maxLengthTruncate){
        const remainingCharacters = maxLengthTruncate - beforeContent.length
        afterContent = this.getTextAfterKeyword(content, this.keywordSearch, remainingCharacters)
      }
      return beforeContent + afterContent
    },

    truncateContentNotMatch(content, maxLengthTruncate){
      return content.slice(0, maxLengthTruncate)
    },

    getTextBeforeKeyword(text, keyword, length) {
      const convertText = text.toLowerCase()
      const convertText1 = convertText
      let keywordIndex = convertText1.indexOf(keyword.toLowerCase());
      if (keywordIndex !== -1) {
          const originText = text.slice(keywordIndex, keyword.length + keywordIndex)
          let textBeforeKeyword = text.substring(Math.max(0, keywordIndex - length), keywordIndex);
          return textBeforeKeyword + originText;
      }
    },

    getTextAfterKeyword(text, keyword, length) {
      const convertText = text.toLowerCase()
      const convertText1 = convertText
      let keywordIndex = convertText1.indexOf(keyword.toLowerCase());
      if (keywordIndex !== -1) {
          let textAfterKeyword = text.slice(keywordIndex + keyword.length, keywordIndex + keyword.length + length);
          return textAfterKeyword;
      }
    },
    
    async getListPost() {
      const params = {
        page: 1,
        limit: 10,
      };
      const { data } = await postMangemenetApi.getLisPost(params);
      this.postRandom = data[Math.floor(Math.random() * data.length)];
      setTimeout(() => {
        this.loadingTips = false
        this.truncatePost();
      }, 100);
    },

    truncatePost() {
      const showChar = 750;
      const ellipsestext = "...";
      let totalChar = 0;
      let showLess 
      setTimeout(()=>{
        showLess = document.querySelector('#show-less')
        document.querySelectorAll(".truncate-tips").forEach((element) => {
        if (totalChar >= showChar) {
          element.remove();
        } else {
          const description = element
            .querySelector(".content-detail")
            .querySelectorAll("p, ul, ol, figure, img, h1, h2, h3, h4");
          if (description.length) {
            description.forEach((element) => {
              if (totalChar < showChar && !['FIGURE', 'IMG'].includes(element.tagName)) {
                let tempTotalChar;
                const unitContent = element.textContent;
                tempTotalChar = totalChar + unitContent.length;
                if (tempTotalChar > showChar) {
                  const truncateContent = unitContent.slice(
                    0,
                    showChar - totalChar
                  );
                  // Cắt bớt nội dung trong thẻ danh sách
                  if (element.tagName === "UL" || element.tagName === "OL") {
                    const listItems = element.querySelectorAll("li");
                    let charCount = 0;
                    let truncatedItems = [];
                    listItems.forEach((li) => {
                      const liContent = li.textContent;
                      if (liContent.length <= showChar - totalChar) {
                        totalChar += liContent.length;
                        truncatedItems.push(li);
                      } else {
                        const truncatedLiContent = liContent.slice(
                          0,
                          showChar - totalChar - charCount
                        );
                        const truncatedLi = document.createElement("li");
                        truncatedLi.textContent = `${truncatedLiContent + ellipsestext}`;
                        truncatedItems.push(truncatedLi);
                        totalChar = showChar;
                      }
                    });
                    element.innerHTML = "";
                    truncatedItems.forEach((truncatedLi) => {
                      element.appendChild(truncatedLi);
                    });
                    if(totalChar === showChar){
                      const lastParagraph = element.lastElementChild;
                      if (lastParagraph.tagName === "LI") {
                        lastParagraph.appendChild(showLess);
                      }
                    }
                  } else {
                    element.innerHTML = `${truncateContent + ellipsestext}</p>`;
                   const lastParagraph = element.lastElementChild;
                    if (lastParagraph.tagName === "P") {
                      lastParagraph.before(showLess);
                    }
                  }
                  totalChar = showChar;
                } else {
                  totalChar += unitContent.length;
                }
              } else {
                element.remove();
              }
            });
            document.querySelector(".summary-post").classList.remove("hidden");
          }
        }
      });
      },0)
    },

    toggleSuggest() {
      this.flagSuggest = true;
    },
    closeSuggest() {
      setTimeout(() => (this.flagSuggest = false), 100);
    },

    changePage(page) {
      this.page = page;
      this.searchAll();
    },
    nextPageTopViews() {
      this.pageTopViews = this.pageTopViews + 1;
      this.$forceUpdate();
    },
    prevPageTopViews() {
      this.pageTopViews = this.pageTopViews - 1;
      this.$forceUpdate();
    },

    closeAndUnMark(){
      this.markInstance.unmark()
      this.isShowSearchBar = false;
    },

    searchHighlight() {
      this.markInstance.unmark();
      if (this.keywordSearch?.length > 1) {
        this.markInstance.mark(this.keywordSearch, {
          separateWordSearch: false,
          diacritics: false,
          done: () => {
            this.resultSearch = $(this.$refs.content).find("mark");
            this.lengthMark = this.resultSearch?.length
            this.currentIndexSearch = 0;
            this.jumpTo();
          },
        });
      }else{
        this.lengthMark = 0
      }
    },

    jumpTo() {
      if (this.resultSearch.length) {
        const offsetTop = 50;
        const current = this.resultSearch.eq(this.currentIndexSearch);
        this.resultSearch.removeClass("current");
        if (current.length) {
          current.addClass("current");
          this.position = current.offset().top - $(".home").offset().top - offsetTop;
          $(".layout-content").scrollTop(this.position);
        }
      }
    },

    initSearch() {
      this.searchQuery = this.$router.currentRoute.value?.query?.keyword;
      if (this.keywordSearch) {
        this.isShowSearchBar = true;
      }
      setTimeout(() => {
        this.markInstance = new Mark(this.$refs.content)
        this.searchHighlight();
        const self = this;
        $("button[data-search='next']")
          .add($("button[data-search='prev']"))
          .on("click", function () {
            if (self.resultSearch.length) {
              self.currentIndexSearch += $(this).is($("button[data-search='prev']")) ? -1 : 1;
              if (self.currentIndexSearch < 0) {
                self.currentIndexSearch = self.resultSearch.length - 1;
              }
              if (self.currentIndexSearch > self.resultSearch.length - 1) {
                self.currentIndexSearch = 0;
              }
              self.jumpTo();
            }
          });
      });
    },
  },
  // mounted() {
  //   const hamburgerElement = this.$refs.hamburger;
  //   const listCategoryElement = this.$refs.list_category;
  //   hamburgerElement.addEventListener("click", () => {
  //     hamburgerElement.classList.toggle("active");
  //     listCategoryElement.classList.toggle("active");
  //   });
  // },
};
</script>
<style lang="scss" scoped>
  :deep(.ant-menu-vertical .ant-menu-item:not(:last-child)) {
    margin-bottom: -6px;
  }
  .search-input input::placeholder {
    color: #75a4fb;
  }
  :deep(ol, li) {
    list-style-position: inside;
  }
  :deep(.content-propose){
    strong{
      font-weight: 700 !important;
    }
  }
</style>