export const tableColumns = [
  {
    title: 'STT',
    dataIndex: 'no',
    width: '10%',
    key: 'no'
  },
  {
    title: 'Tên danh mục',
    dataIndex: 'category',
    width: '35%',
    key: 'category'
  },
  {
    title: 'Người tạo',
    dataIndex: 'creator',
    width: '30%',
    key: 'creator'
  },
  {
    title: '',
    dataIndex: 'action',
    width: '10%',
    key: 'action'
  }
];
