<template>
  <div class="relative" tabindex="0" @blur="onBlur">
    <IconNotifyUnread
      v-if="isNotifyUnread"
      class="mr-[5px] cursor-pointer"
      @click="toogleNotify"
    />
    <IconNotify v-else class="mr-3 cursor-pointer z-10" @click="toogleNotify" />
    <div
      class="dropdown justify-center absolute top-[100%] right-[15px] w-[400px] opacity-0"
      :class="{ active: isNotify }"
    >
      <div
        class="text-[#fff] bg-[#0B68A2] py-[10px] px-[20px] text-[20px] font-bold text-center"
      >
        Thông báo mới
      </div>
      <div
        class="overflow-y-scroll overflow-x-hidden max-h-[370px] overflow-auto pb-[20px] bg-[#D9D9D9] scroll-notify"
      >
        <div
          class="bg-[#D9D9D9] pb-[14px] bg-bold-green py-1 rounded-sm overflow-hidden menu item cursor-pointer"
          @click="handleDetail(item?.pivot?.id, item.model_type, item)"
          v-for="(item, index) in listNotify"
          :key="index"
        >
          <div
            class="bg-[#fff] relative border-[#EBE9F1] border-solid border-1 mr-[12px] ml-[14px] mt-[12px] item-notify order-menu-none border-black"
          >
            <IconUnread
              v-if="!item?.pivot?.is_read"
              class="absolute translate-x-1/2 translate-y-1/2 inset-y-1/2"
            />
            <div
              class="flex justify-between pt-[10px] pl-[20px] pb-[8px] pr-[10px]"
            >
              <div class="text-[#000] text-[12px] font-bold">
                {{ typeTitle(item.model_type) }}
              </div>
              <div class="text-[#000] text-[12px]">
                {{ moment(item.created_at).format("DD/MM/YYYY") }}
              </div>
            </div>
            <div
              class="content pl-[20px] text-[14px] text-[#000] pb-[13px] break-words pr-[16px]"
              v-if="item.model_type === 'Course'"
            >
              <span>Khóa học mới tên</span>
              <q class="font-bold">{{ item.title }}</q>
              <span
                >đã được thêm vào trong phần E-learning. Hãy xem khóa học này
                nào!</span
              >
            </div>
            <div
              v-else
              class="content pl-[20px] text-[14px] text-[#000] pb-[13px] break-words pr-[16px]"
            >
              <span>Bài viết mới tên</span>
              <q class="font-bold">{{ item.title }}</q>
              <span
                >đã được thêm vào trong trang web wiki. Hãy xem qua bài viết này
                nhé!</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref } from "vue";

import { filter } from "lodash";
import moment from "moment";
import { useRouter } from "vue-router";

import { notify, updateStatusNotify } from "../../../api/home";
import { homeConstants } from "../../../constant";
import IconNotifyUnread from "../../icon/icon-notify-unread.vue";
import IconNotify from "../../icon/icon-notify.vue";
import IconUnread from "../../icon/icon-unread.vue";
import { getDetail } from "../../../api/homeDetail";
import { useNotify } from "../../../utils/hook";
import { DetailsCourseApi } from "../../../features/detailsCourse/api/request";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
defineComponent({
  IconNotify,
  IconNotifyUnread,
});

const router = useRouter();
const isNotify = ref(false);
const isNotifyUnread = ref(false);
const listNotify = ref([]);
const { notifyError } = useNotify();
onMounted(() => {
  getNotify();
});

const toogleNotify = () => {
  isNotify.value = !isNotify.value;
};

const onBlur = () => {
  isNotify.value = false;
};
const paramsDetailCourse = ref({
  target: "home",
  step: "test_info",
});
const handleDetail = async (id, type, item) => {
  try {
    await updateStatusNotify(id);
    getNotify();
    isNotify.value = false;
    if (type === homeConstants.POST) {
      try {
        await getDetail(item.slug);
        return router.push({
          name: homeConstants.NAME_POST,
          params: { slug: item.slug },
        });
      } catch (error) {
        notifyError(t("courseManagement.notify.noPost"));
      }
    } else {
      try {
        await DetailsCourseApi.getDetailCourse(
          item.model_id,
          paramsDetailCourse.value
        );
        return router.push({
          name: homeConstants.DETAILS_COURSE,
          params: { slug: item.model_id },
        });
      } catch (error) {
        notifyError(t("courseManagement.notify.noCourse"));
      }
    }
  } catch (error) {
    throw error;
  }
};

const typeTitle = (type) => {
  if (type === homeConstants.POST) {
    return homeConstants.TITLE_POST;
  }
  return homeConstants.TITLE_COURSE;
};

const getNotify = async () => {
  try {
    const limit = "?page=1&limit=15";
    const response = await notify(limit);
    listNotify.value = response.data;
    isNotifyUnread.value = !!filter(
      listNotify.value,
      (item) => !item?.pivot?.is_read
    )?.length;
  } catch (error) {
    throw error;
  }
};
</script>
<style lang="scss" scoped>
.dropdown {
  transition: top 0.3s ease-out, opacity 0.3s ease-out;
  visibility: hidden;
}
.active {
  opacity: 1;
  top: 44px;
  visibility: visible;
}
</style>