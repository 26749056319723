export function startTimer(duration, display, callback) {
  let timer = duration
  let hours
  let minutes
  let seconds
  let intervalId = setInterval(() => {
    hours = parseInt(timer / 3600, 10);
    minutes = parseInt((timer % 3600) / 60, 10);
    seconds = parseInt(timer % 60, 10);
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    display.textContent = hours === 0 ? '' : hours + ":" + minutes + ":" + seconds;
    if (--timer < 0) {
      callback();
      clearInterval(intervalId);
    }
  }, 1000);
  return intervalId;
}