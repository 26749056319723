export const TAGS = {
  allCourse: {
    id: 1,
    name: "list_my_course"
  },
  compulsoryCourse: {
    id: 2,
    name: "compulsory_course"
  },
  moreCourse: {
    id: 3,
    name: "additional_course"
  }
};

export const TAG_NAVIGATION = [
  { id: 1, title: 'Tất cả' },
  { id: 2, title: 'Khóa học bắt buộc' },
  { id: 3, title: 'Khóa học tham gia thêm' }
];
export const LIST_TYPE = [
  { id: 1, name: 'Tất cả', type: '' },
  { id: 2, name: 'Khóa học', type: 'course-name' },
  { id: 3, name: 'Bài giảng', type: 'lesson-name' }
];
export const IMG_PLACEHOLD = 'https://placehold.co/225x157';

export const IMAGE_TYPE = ["png", "jpg", "svg"]
