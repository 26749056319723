<template>
  <div class="sidebar-wrapper">
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="state.selectedKeys"
      mode="inline"
      :inline-collapsed="collapsed"
      @select="handleSelectMenu"
    >
      <a-menu-item key="CategoryManagement">
        <template #icon>
          <IconCategory />
        </template>
        <span>Quản lý danh mục</span>
      </a-menu-item>
      <a-menu-item
        :key="routeName === 'PostsManagement' ? 'PostsManagement' : 'ViewPost'"
        @click="handleClick"
      >
        <template #icon>
          <IconPost />
        </template>
        <span>Quản lý bài viết</span>
      </a-menu-item>
      <a-sub-menu key="e-learning">
        <template #icon>
          <IconElearning />
          quan ly
        </template>
        <template #title> Quản lý E-learning </template>
        <a-menu-item key="Course">Quản lý khóa học</a-menu-item>
        <a-menu-item key="ECategory">Quản lý thể loại khóa học</a-menu-item>
        <a-menu-item key="Courseware">Kho học liệu</a-menu-item>
      </a-sub-menu>
      <a-menu-item key="FeedbackManagement">
        <template #icon>
          <IconFeedback/>
        </template>
        <span>Quản lý phản hồi</span>
      </a-menu-item>
    </a-menu>
    <footer class="footer">
      <div class="help">
        <QuestionCircleOutlined />
        <div class="help-desc">
          <span class="text"> Help &#38; getting started </span>
          <i class="fas fa-external-link-alt"></i>
        </div>
      </div>
      <LanguageSwitcher
        :languages="languages"
        @changeLanguage="handleChangeLanguage"
      />
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch, onMounted, ref } from "vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import IconElearning from "../../icon/icon-e-learning.vue";
import IconPost from "../../icon/icon-post.vue";
import IconCategory from "../../icon/icon-category.vue";
import IconFeedback from "../../icon/icon-feedback.vue"
import { useI18n } from "vue-i18n";
import { LANGUAGES } from "../../../constant/language";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    LanguageSwitcher,
    QuestionCircleOutlined,
    IconElearning,
    IconCategory,
    IconPost,
    IconFeedback
  },
  data() {
    return {
      routeName: "",
    };
  },
  setup() {
    const { locale } = useI18n();
    const {t} = useI18n()

    const initLanguage = JSON.parse(localStorage.getItem("wikiLanguage")) || [
      {
        isActive: true,
        title: "vi",
        description: "Vietnamese",
      },
      {
        isActive: false,
        title: "en",
        description: "English",
      },
    ];

    const languages = ref(initLanguage);
    const state = reactive({
      collapsed: false,
      selectedKeys: [""],
      openKeys: ["e-learning"],
      preOpenKeys: ["sub1"],
    });

    watch(
      () => state.openKeys,
      (_val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    const switchLanguage = (cod) => {
      if (cod === LANGUAGES.En) {
        locale.value = LANGUAGES.En;
      } else {
        locale.value = LANGUAGES.Vn;
      }
    };

    const handleChangeLanguage = (value) => {
      const newLanguage = languages.value?.map((lang) => {
        if (!lang.isActive) return { ...lang, isActive: true };
        return { ...lang, isActive: false };
      });
      languages.value = newLanguage;
      localStorage.setItem("wikiLanguage", JSON.stringify(newLanguage));
      switchLanguage(value.title);
    };

    onMounted(() => {
      const cod = languages.value.find((lang) => lang.isActive).title;
      switchLanguage(cod);
    });
    return {
      ...toRefs(state),
      toggleCollapsed,
      handleChangeLanguage,
      state,
      languages,
    };
  },
  beforeMount() {
    this.state.selectedKeys = [`${this.$router.currentRoute.value.name}`];
  },
  created() {
    this.routeName = this.$route.name;
  },
  methods: {
    handleSelectMenu(item) {
      if (this.$router.currentRoute.value.name === item.key) return;
      this.$router.push({ name: item.key });
    },
    handleClick(){
      this.$router.push({ name: "PostsManagement" });
    }
  },
  watch: {
    $route(to, from) {
      this.routeName = this.$route.name;
      this.state.selectedKeys = [`${this.$router.currentRoute.value.name}`];
    },
  },
});
</script>
<style lang="scss" scoped>
.sidebar-wrapper {
  width: 288px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  :deep(.ant-menu-item) {
    transition: none;
    font-size: 16px;
    height: 48px;
    font-weight: 700;
    &:hover {
      color: #1280bf;
    }
  }
  :deep(.ant-menu-submenu-title) {
    font-weight: 700;
    font-size: 16px;
    color: #0b68a2;
    height: 48px;
    svg {
      margin-left: -5px;
    }
  }
  :deep(.ant-menu-submenu-arrow) {
    display: none;
  }
  :deep(.ant-menu-item-selected) {
    background-color: #1280bf;
    color: white;
    border-radius: 7px;
    &:hover {
      color: white;
    }
    path {
      fill: white;
    }
  }
  :deep(.ant-menu-item-selected .ant-menu-title-content) {
    color: white;
  }
  :deep(.ant-menu-item-selected::after) {
    display: none;
  }
  :deep(.ant-menu-title-content) {
    color: #0b68a2;
  }
  :deep(.ant-menu) {
    border-right: none !important;
  }
  :deep(.ant-menu-item-only-child) {
    margin-left: 36px;
    width: 224px;
    padding-left: 10px !important;
    font-size: 15px;
  }
  :deep(.ant-menu-sub) {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: transparent;
    left: 12px;
    li {
      overflow: visible;
      padding-left: 0 !important;
    }
    li::before {
      position: relative;
      top: -36px;
      left: -16px;
      width: 16px;
      height: 74px;
      border-bottom: 2px solid #e8e7e7;
      border-left: 2px solid #e8e7e7;
      content: "";
      border-bottom-left-radius: 14px;
      display: inline-block;
    }
  }
}
.footer {
  min-height: 88px;
  padding-top: 19px;
  &::before {
    content: "";
    display: block;
    background-color: #e9e9e9;
    height: 1px;
    width: 82%;
    position: relative;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.help {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  &-desc {
    cursor: pointer;
    font-size: 12px;
    color: #1976d2;
    display: flex;
    gap: 6px;
    align-items: center;
    .text {
      text-decoration: underline;
    }
  }
}
</style>
