const state = () => ({
  action: {
    name: undefined,
    id: undefined,
    slug: undefined
  }
});

const mutations = {
  updateAction(state, action) {
    state.action = action;
  }
};

const actions = {
  updateAction({ commit }, action) {
    commit('updateAction', action);
  }
};

const postManagment = {
  state,
  actions,
  mutations
};

export default postManagment;
