import { Upload, message } from "ant-design-vue";
import i18n from "../../../../i18n";
import { generateId } from "../../../../utils/helper";
const { t } = i18n.global;

export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getFileNameInResponseHeader = (headers) => {
  let fileName = "";
  if (!headers) return "";
  if ("content-disposition" in headers) {
    const data = headers["content-disposition"]
      .split(";")
      .map((item) => item.trim());

    for (let item of data) {
      const disposition = item.split("=");
      if (disposition.length > 0) {
        if (disposition[0] === "filename") return disposition[1];
      }
    }
  }
  return fileName;
};

export const getNowDate = () => {
  let date = new Date();
  let day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();
  return day + "/" + month + "/" + year;
};

export const mapDataDetailStep1 = (values) => {
  return {
    title: values.title,
    description: values.description,
    deadline: String(values.deadline),
    files: values.files.map((item, index) => ({
      name: item.name,
      id: item.id,
      url: item.path,
    })),
    image: values.image.map((item, index) => ({
      name: item.name,
      id: item.id,
      url: item.path,
    })),
  };
};

export const mapDataSubmitStep1 = (values) => {
  if (!values) return undefined;
  return {
    ...values,
    deadline: values.deadline || 0,
    elearning_categories: values.elearning_categories?.map(
      (item) => item.value
    ),
    positions: values.positions?.map((item) => item.value),
    users: values.users?.map((item) => item.value),
    files: values.files?.map((item) => item.id),
    image: values.image[0]?.id,
  };
};

export const mapDataSubmitStep2 = (chapters) => {
  if (!chapters) return undefined;
  return chapters.map((chapter) => ({
    id: chapter.id || null,
    title: chapter.name,
    lessons: chapter.paths.map((lesson) => {
      return {
        id: lesson.id || null,
        title: lesson.name,
        file_id: lesson.file_id || lesson.file[0]?.id || null,
        estimated_time:
          lesson?.documentTime.hours * 60 + lesson?.documentTime.minutes
      };
    })
  }));
};

export const mapDataDetailStep2 = (data) => {
  if (!data) return [];
  return data?.map((item) => ({
    id: item.id,
    name: item.title,
    paths: item.lessons.map((lesson) => ({
      dataEdit: {
        name: lesson.title,
        id: lesson.id,
        fileType: lesson.type,
        file: [lesson.file],
        visible: false,
        progress: 0,
        uploadingStatus: 'success',
        isErrorShow: false,
        documentTime: {
          hours: Math.floor(lesson?.estimated_time / 60),
          minutes: lesson?.estimated_time % 60
        }
      },
      name: lesson.title,
      id: lesson.id,
      fileType: lesson.type,
      file: [lesson.file],
      documentTime: {
        hours: Math.floor(lesson?.estimated_time / 60),
        minutes: lesson?.estimated_time % 60
      }
    }))
  }));
};

export const mapDataSubmitStep3 = (step) => {
  if (!step) return undefined;
  return {
    name: step.name,
    description: step.description,
    requirement: {
      tests: step.conditionTestID ? [{ id: step.conditionTestID }] : [],
    },
    number_mock_test: step.timesOfTest || 0,
    time: step.testTime.hours * 60 + step.testTime.minutes,
    minimum_score: step.limitScore || 0,
    limit_questions: +step.limitQuestion || 0,
    questions: step.questions.map((question, index) => ({
      id: question.id || null,
      test_id: step.id,
      content: question.content,
      order: index + 1,
      answers: question.answers.map((answer, _index) => ({
        id: answer.id || null,
        question_id: question.id || null,
        order: _index + 1,
        content: answer.content,
        status: answer.isCorrect
      }))
    })),
  };
};

export const mapDataTestQuestionStep3 = (step) => {
  if (!step.length) return undefined;
  return step.map((question) => ({
    id: question.id,
    test_id: step.id,
    key: generateId([], "key"),
    content: question.content,
    answers: question.answers.map((answer, _index) => ({
      id: answer.id,
      question_id: question.id,
      key: generateId([], "key"),
      content: answer.content,
      isCorrect: answer.status === 1,
    })),
  }));
};

export const mapDataTestDetailStep3 = (step) => {
  if (!step) return undefined;
  return {
    key: generateId([step], "key"),
    name: step.name,
    description: step.description,
    conditionTestID: step.requirement?.tests[0]?.id,
    testTime: {
      hours: Math.floor(step.time / 60),
      minutes: step.time % 60,
    },
    timesOfTest: step.number_mock_test,
    noLimitTimesOfTest: step.number_mock_test === 0,
    limitScore: step.minimum_score,
    limitQuestion: step.limit_questions || "",
    id: step.id,
    questions: [],
    resource: "",
    desc: "",
  };
};


export const ruleValidateField = (config) => {
  const { type, max, min } = config;
  if (type === 'input') {
    return [
      {
        required: true,
        message: t('courseManagement.validate.required')
      },
      {
        whitespace: true,
        message: t('courseManagement.validate.required')
      },
      {
        max: max,
        message: t('courseManagement.validate.maxCharacter', { num: max })
      }
    ];
  }
  if (type === 'selectTag') {
    return [
      {
        required: true,
        message: t('courseManagement.validate.required')
      }
    ];
  }
};

export const removeHtmlTagsAndNbsp = (text) => {
  let plainText = text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
  return plainText;
}