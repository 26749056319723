export const texts  = {
    title: 'Danh sách khóa học của tôi',
    emptyCourse: 'Chưa có khóa học trên hệ thống',
    complete: 'complete',
    deadline: 'Deadline',
    titleHome: 'CHÀO MỪNG ĐẾN VỚI MOR E-LEARNING',
    placeholderInput: 'Nhập tên khóa học, bài giảng, ....',
    titleListAllCourse: 'Tổng hợp các khóa học',
    titleListCompulsoryCourse: 'Các khóa học bắt buộc',
    notFoundCourse: 'Không tìm thấy khóa học',
    notFoundCourseSystem: 'Chưa có khóa học trên hệ thống',
    notFoundCourse2: 'Không có khóa học'
}