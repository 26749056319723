<template>
  <div class="sidebar-wrapper" :class="{ small_sidebar: isPlayingCourse }">
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      :inline-collapsed="collapsed"
      @select="handleSelectMenu"
    >
      <a-menu-item
        :key="
          routeName === 'HomeELearning'
            ? 'HomeELearning'
            : routeName === 'DetailsCourse'
            ? 'DetailsCourse'
            : routeName === 'LearnCourse'
            ? 'LearnCourse'
            : 'HomeELearning'
        "
        @click="handleClickHomepage"
      >
        <template #icon>
          <img
            src="../../icon/home-svg.svg"
            alt=""
            class="icon-home"
            style="margin: -4px -4px 0 -6px"
          />
        </template>
        <span v-if="!isPlayingCourse">Trang chủ</span>
      </a-menu-item>
      <a-menu-item key="MyCourse" @click="handleClickMyCourse">
        <template #icon>
          <IconPost />
        </template>
        <span v-if="!isPlayingCourse">Các khóa học của tôi</span>
      </a-menu-item>
    </a-menu>
    <footer class="footer" v-if="!isPlayingCourse">
      <div class="help">
        <QuestionCircleOutlined />
        <div class="help-desc">
          <span class="text"> Help &#38; getting started </span>
          <i class="fas fa-external-link-alt"></i>
        </div>
      </div>
      <LanguageSwitcher />
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import IconElearning from "../../icon/icon-e-learning.vue";
import IconPost from "../../icon/icon-post.vue";
import IconCategory from "../../icon/icon-category.vue";
export default defineComponent({
  components: {
    LanguageSwitcher,
    QuestionCircleOutlined,
    IconElearning,
    IconCategory,
    IconPost,
  },
  data() {
    return {
      isPlayingCourse: false,
      routeName: "",
    };
  },
  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: [""],
      openKeys: ["sub1"],
      preOpenKeys: ["sub1"],
    });
    watch(
      () => state.openKeys,
      (_val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };
    return {
      ...toRefs(state),
      toggleCollapsed,
      state,
    };
  },

  beforeMount() {
    this.state.selectedKeys = [`${this.$router.currentRoute.value.name}`];
  },
  methods: {
    handleSelectMenu(item) {
      if (this.$router.currentRoute.value.name === item.key) return;
      this.$router.push({ name: item.key });
    },
    handleClickHomepage() {
      this.$router.push({ name: "HomeELearning" });
    },
    handleClickMyCourse() {
      this.$router.push({ name: "MyCourse" });
    },
    isLesson(path) {
      const regex = /lesson|test/;
      if (regex.test(path)) {
        this.isPlayingCourse = true;
      } else {
        this.isPlayingCourse = false;
      }
    },
  },
  watch: {
    $route(to, from) {
      this.state.selectedKeys = [`${to.name}`];
      this.routeName = to.name
      const path = to.path;
      this.isLesson(path);
    },
  },
  mounted() {
    const { path } = this.$route;
    this.isLesson(path);
  },
  created() {
    this.routeName = this.$route.name;
  },
});
</script>
<style lang="scss" scoped>
.small_sidebar {
  width: fit-content !important;
  :deep(.ant-menu-item-only-child) {
    margin-left: 0 !important;
    width: unset;
    font-size: 15px;
    padding: 0 !important;
  }
  :deep(.ant-menu-item) {
    height: 40px !important;
  }
  ::after {
    border-right: 0px !important;
  }
}
.sidebar-wrapper {
  width: 288px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 5px 10px 12px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  z-index: 2;
  :deep(.ant-menu-item) {
    transition: none;
    font-size: 16px;
    height: 48px;
    font-weight: 700;
    &:hover {
      color: #1280bf;
    }
  }
  :deep(.ant-menu-item-selected) {
    background-color: #1280bf;
    color: white;
    border-radius: 7px;
    &:hover {
      color: white;
    }
    path {
      fill: white;
    }
  }
  :deep(.ant-menu-submenu-title) {
    font-weight: 700;
    font-size: 16px;
    color: black;
    svg {
      margin-left: -5px;
    }
  }

  :deep(.ant-menu) {
    border-right: none !important;
  }
  :deep(.ant-menu-item-only-child) {
    margin-left: 40px;
    width: unset;
    padding-left: 10px !important;
    font-size: 15px;
  }
  :deep(.ant-menu-sub) {
    position: relative;
    overflow: hidden;
    padding-top: 5px;
    display: inline-block;
    background-color: transparent;
    left: 12px;
    li {
      overflow: visible;
      padding-left: 0 !important;
    }
    li::before {
      position: relative;
      top: -36px;
      left: -21px;
      width: 20px;
      height: 74px;
      border-bottom: 2px solid #e8e7e7;
      border-left: 2px solid #e8e7e7;
      content: "";
      border-bottom-left-radius: 14px;
      display: inline-block;
    }
  }
}
.footer {
  min-height: 88px;
  padding-top: 19px;
  &::before {
    content: "";
    display: block;
    background-color: #e9e9e9;
    height: 1px;
    width: 82%;
    position: relative;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.help {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  &-desc {
    cursor: pointer;
    font-size: 12px;
    color: #1976d2;
    display: flex;
    gap: 6px;
    align-items: center;
    .text {
      text-decoration: underline;
    }
  }
}
</style>
