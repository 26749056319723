<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
  >
    <circle cx="11.5" cy="11.5" r="11.5" fill="#D9D9D9" />
    <path d="M17 12L8.75 19.7942L8.75 4.20577L17 12Z" fill="white" />
  </svg>
</template>
<script>
export default {};
</script>
