<template>
  <div class="add-step1-wrapper" ref="contentElement">
    <a-form
      ref="formRef"
      :model="formState"
      @finish="handleSubmit"
      layout="vertical"
      @finishFailed="handleFinishFailed"
    >
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            :label="t('courseManagement.step1.courseTitle')"
            name="title"
            :rules="ruleValidateField({ type: 'input', max: 255 })"
          >
            <a-input
              :disabled="isViewCourse"
              v-model:value="formState.title"
              :placeholder="t('courseManagement.step1.courseTitlePlaceholder')"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="t('courseManagement.step1.category')"
            name="elearning_categories"
            :rules="ruleValidateField({ type: 'selectTag' })"
          >
            <a-select
              :disabled="isViewCourse"
              v-model:value="formState.elearning_categories"
              mode="multiple"
              :placeholder="t('courseManagement.step1.categoryPlaceholder')"
              showSearch
              labelInValue
              optionFilterProp="label"
              :maxTagTextLength="40"
              :options="dataSelector.elearning_categories"
              showArrow
            >
              <template #suffixIcon><DownOutlined /> </template>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            :label="t('courseManagement.step1.positionLabel')"
            name="positions"
          >
            <a-select
              :disabled="isViewCourse"
              v-model:value="formState.positions"
              :placeholder="t('courseManagement.step1.positionPlaceholder')"
              mode="multiple"
              optionFilterProp="label"
              labelInValue
              showSearch
              :options="dataSelector.positions"
              :maxTagTextLength="40"
              showArrow
            >
              <template #suffixIcon><DownOutlined /> </template>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            :label="t('courseManagement.step1.employeeLabel')"
            name="users"
          >
            <a-select
              :disabled="isViewCourse"
              v-model:value="formState.users"
              mode="multiple"
              :placeholder="t('courseManagement.step1.employeePlaceholder')"
              optionFilterProp="label"
              labelInValue
              showSearch
              :options="dataSelector.users"
              :maxTagTextLength="40"
              showArrow
            >
              <template #suffixIcon><DownOutlined /> </template>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :span="4">
          <div class="date">
            <a-form-item
              :label="t('courseManagement.step1.dealineLabel')"
              name="deadline"
              :rules="[
                {
                  validator: (rule, value) => handleValidate(rule, value),
                },
              ]"
            >
              <div class="date-form-item">
                <a-input-number
                  :disabled="isViewCourse"
                  id="inputNumber"
                  :placeholder="t('courseManagement.step1.dealinePlaceholder')"
                  v-model:value="formState.deadline"
                  :min="0"
                  :max="999"
                />
                <span> {{ t("courseManagement.step1.day") }}</span>
              </div>
            </a-form-item>
          </div>
        </a-col>
      </a-row>

      <a-form-item
        name="description"
        :label="t('courseManagement.step1.descriptionLabel')"
        :rules="[
          {
            validator: (rule, value) => validateCountTextDesc(rule, value),
          },
          {
            required: true,
            message: t('courseManagement.validate.required'),
          },
        ]"
        style="margin-bottom: 20px"
      >
        <div class="vue-editor-wrapper">
          <ckeditor
            :disabled="isViewCourse"
            :editor="editor.ref"
            v-model="formState.description"
            :config="editor.config"
            @ready="handleOnReadyEditor"
            @input="handleChangeEditor"
          />
        </div>
      </a-form-item>

      <a-form-item
        name="files"
        :rules="[
          {
            validator: (rule, value) => validateCountFile(rule, value),
          },
        ]"
      >
        <a-upload
          :disabled="isViewCourse"
          v-model:fileList="formState.files"
          :multiple="true"
          :customRequest="customRequestUploadImage"
          :beforeUpload="beforeUploadDocs"
        >
          <a-button class="upload-btn">
            <FileTextOutlined />{{
              t("courseManagement.step1.docLabel")
            }}</a-button
          >
        </a-upload>
      </a-form-item>

      <a-form-item
        name="image"
        :rules="[
          {
            required: true,
            message: t('courseManagement.validate.required'),
          },
        ]"
      >
        <a-upload
          :disabled="isViewCourse"
          v-model:fileList="formState.image"
          :maxCount="1"
          :customRequest="customRequestUploadImage"
          :beforeUpload="beforeUploadImg"
          list-type="picture"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        >
          <a-button class="upload-btn">
            <FileImageOutlined />{{ t("courseManagement.step1.imageLabel") }}
            <span class="text-error"> *</span></a-button
          >
        </a-upload>
      </a-form-item>
      <div v-if="!isViewCourse" class="btn-wrapper">
        <a-button style="width: 131px" @click="handleBackStep">{{
          t("courseManagement.common.cancel")
        }}</a-button>
        <a-button style="width: 131px" type="primary" html-type="submit">{{
          !modeCreate
            ? t("courseManagement.common.update")
            : t("courseManagement.common.next")
        }}</a-button>
      </div>
    </a-form>
  </div>
</template>

<script setup>
import {
  DownOutlined,
  FileImageOutlined,
  FileTextOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {
  computed,
  defineComponent,
  defineEmits,
  onMounted,
  reactive,
  ref,
  onBeforeUnmount,
  h,
} from "vue";
import { useStore } from "vuex";
import iconBack from "../../../../components/icon/icon-back.vue";
import LoadingFull from "../../../../components/loading/Loading.vue";
import ModalConfirm from "../../../../components/modal/ModalConfirm.vue";
import { useLoading, useNotify, useShowConfirm } from "../../../../utils/hook";
import { step1ManagementApi } from "../api/request";
import { DOC_FILE_STEP1, IMG_FILE_STEP1, TOOL_BAR } from "../utils/constant";
import {
  mapDataSubmitStep1,
  ruleValidateField,
  removeHtmlTagsAndNbsp,
} from "../utils/helpers";
import { checkTypeAndSizeUpload } from "../../../../utils/helper";
import { useI18n } from "vue-i18n";

defineComponent({
  components: {
    iconBack,
    LoadingFull,
    ModalConfirm,
    UploadOutlined,
    FileImageOutlined,
    FileTextOutlined,
    DownOutlined,
  },
  props: {
    getListCourse: Function,
  },
});
const { t } = useI18n();
const store = useStore();
const emit = defineEmits();
const { notifySuccess, notifyError } = useNotify();
const { showConfirm } = useShowConfirm();
const { showLoading, hideLoading } = useLoading();
const formRef = ref();
const formState = reactive({
  title: "",
  elearning_categories: undefined,
  positions: undefined,
  users: undefined,
  deadline: undefined,
  description: "",
  files: [],
  image: [],
});

const dataSelector = reactive({
  elearning_categories: undefined,
  positions: undefined,
  users: undefined,
});
const editor = reactive({
  ref: DecoupledEditor,
  config: {
    placeholder: "Giới thiệu qua về  khóa học",
    toolbar: TOOL_BAR,
  },
  ready: false,
});
const isEditCourse = computed(() => store.state.courseManagement.edit_course);
const courseId = computed(() => store.state.courseManagement.course_id);
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const modeCreate = computed(() => store.state.courseManagement.modeCreate);

const handleOnReadyEditor = (_editor) => {
  _editor.ui
    .getEditableElement()
    .parentElement.insertBefore(
      _editor.ui.view.toolbar.element,
      _editor.ui.getEditableElement()
    );
  editor.ready = true;
};
const validateCountFile = (rule, value) => {
  if (value.length > 20) {
    return Promise.reject(t("courseManagement.step1.msgMaxLengthFile"));
  }
  return Promise.resolve();
};
const validateCountTextDesc = (rule, value) => {
  const text = removeHtmlTagsAndNbsp(value);
  if (text.trim().length > 1000) {
    return Promise.reject(t("courseManagement.step1.msgDescription"));
  }
  return Promise.resolve();
};
onMounted(() => {
  formRef.value.resetFields();
  if (isEditCourse.value || isViewCourse.value) {
    getStep1(courseId.value);
    fetchListCategory();
    fetchListPosition({ target: "all" });
    fetchListUser({ target: "noadmin" });
    return;
  }

  fetchListCategory();
  fetchListPosition({ target: "all" });
  fetchListUser({ target: "noadmin" });
});

const handleBackStep = (name, id) => {
  const backList = () => {
    store.dispatch("doingStep", 1);
    store.dispatch("doneStep", 1);
    store.dispatch("updateEditStatus", false);
    store.dispatch("updateModeCreate", false);
    emit("getListCourse");
  };
  if (isEditCourse.value) {
    const content = h("div", [
      h("p", `Mọi chỉnh sửa sẽ không được lưu`),
      h("p", "Bạn có chắc chắn muốn thoát?"),
    ]);
    showConfirm({
      type: "edit",
      contentBody: content,
      onOk: () => {
        backList();
      },
    });
    return;
  }
  backList();
};

const handleFinishFailed = (error) => {};

const handleValidate = (rule, value) => {
  if (value === 0) {
    return Promise.reject(t("courseManagement.validate.dealine"));
  }
  return Promise.resolve();
};

const handleSubmit = (values) => {
  if (!isEditCourse.value || modeCreate.value) {
    if (modeCreate.value && isEditCourse.value) {
      updateStep1(mapDataSubmitStep1(values), courseId.value);
      return;
    }
    addStep1(mapDataSubmitStep1(values));
    return;
  }
  showConfirm({
    type: "edit",
    suffix: "bước 1",
    onOk: () => {
      updateStep1(mapDataSubmitStep1(values), courseId.value);
    },
  });
};

const fetchListCategory = async (params) => {
  try {
    const res = await step1ManagementApi.getListCategory(params);
    dataSelector.elearning_categories = res.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (error) {
    throw error;
  }
};

const fetchListPosition = async (params) => {
  try {
    const res = await step1ManagementApi.getListPosition(params);
    dataSelector.positions = res.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (error) {
    throw error;
  }
};

const fetchListUser = async (params) => {
  try {
    const res = await step1ManagementApi.getListUser(params);
    dataSelector.users = res.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (error) {
    throw error;
  }
};

const addStep1 = async (formData) => {
  showLoading();
  try {
    const res = await step1ManagementApi.createStep1(formData);
    notifySuccess("Tạo thông tin cơ bản thành công");
    store.dispatch("doneStep", 1);
    store.dispatch("doingStep", 2);
    store.dispatch("updateCourseId", res.data.id);
    store.dispatch("updateCourseStatus", {
      course_info: 1,
    });
  } catch (error) {
    if (error?.error?.status === 422) {
      notifyError(t("courseManagement.step1.validate.courseTitleExists"));
    }
    else{
      notifyError(t("courseManagement.step1.validate.createStep1Fail"));
    }
    throw error;
  } finally {
    hideLoading();
  }
};

const updateStep1 = async (payload, id) => {
  showLoading();
  try {
    await step1ManagementApi.updateStep1(payload, id);
    if (modeCreate.value) {
      store.dispatch("doneStep", 1);
      store.dispatch("doingStep", 2);
    }

    store.dispatch("updateCourseStatus", "course_info");
    if (modeCreate.value) {
      notifySuccess(t("courseManagement.step1.textSuccess"));
    } else {
      notifySuccess(t("courseManagement.step1.textUpdateSuccess"));
    }
  } catch (error) {
    if (error?.error?.status === 422) {
      notifyError(t("courseManagement.step1.validate.courseTitleExists"));
    }
    else{
      notifyError(t("courseManagement.step1.validate.createStep1Fail"));
    }
    throw error;
  } finally {
    hideLoading();
  }
};

const getStep1 = async (id) => {
  try {
    showLoading();
    const params = {
      target: "step",
      step: "course_info",
    };
    const res = await step1ManagementApi.getStep1(id, params);

    formState.title = res.data.title;
    formState.description = res.data?.description;
    formState.deadline = String(res.data.deadline);
    res.data.files.map((item, index) => {
      const file = {
        name: item.name,
        id: item.id,
        url: item.path,
      };
      formState.files.push(file);
    });
    res.data.image.map((item, index) => {
      const file = {
        name: item.name,
        id: item.id,
        url: item.full_path,
      };
      formState.image.push(file);
    });
    formState.elearning_categories = res.data.learning_categories.map(
      (item) => ({
        value: item.id,
        label: item.name,
      })
    );
    formState.positions = res.data.positions.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    formState.users = res.data.users.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (error) {
    throw error;
  } finally {
    hideLoading();
  }
};

const customRequestUploadImage = async (options) => {
  const { onSuccess, onError, file, onProgress } = options;
  const fmData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  fmData.append("file", file);
  try {
    const res = await step1ManagementApi.uploadFile(fmData, config);
    file["id"] = res.data.id;
    onSuccess();
  } catch (err) {
    onError();
  }
};

const beforeUploadImg = (file) => {
  return checkTypeAndSizeUpload(file, IMG_FILE_STEP1);
};

const beforeUploadDocs = (file) => {
  return checkTypeAndSizeUpload(file, DOC_FILE_STEP1);
};

const handleChangeEditor = (value) => {
  if (!editor.ready) return;
  if (value.includes("<img src")) {
    formRef.value.validate(["description"]);
    return;
  }
  if (!value.length) {
    formRef.value.validate(["description"]);
    return;
  }
  formRef.value.clearValidate(["description"]);
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #f5222d;
  margin-left: 2px;
  font-weight: 400;
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  button {
    font-size: 16px;
  }
}

.upload-btn {
  font-size: 16px;
}
.date-form-item {
  display: flex;
  gap: 10px;
  span {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

:deep(label) {
  font-size: 16px;
  font-weight: 700;
}

:deep(.ant-form-item-has-error) {
  .vue-editor-wrapper {
    border: 1px solid #f5222d;
    border-radius: 5px;
  }
}
:deep(.ant-input-number) {
  min-width: 120px;
  border-radius: 6px;
  min-height: 40px;
  border-color: #c4c4c4;
  box-shadow: none;
  overflow: hidden;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
}
:deep(.ant-form-item-has-error) {
  .ant-input-number {
    border-color: #f5222d;
  }
}
:deep(.vue-editor-wrapper) {
  .ck-toolbar {
    border-radius: 5px 5px 0 0;
  }

  .ck-placeholder:before {
    color: #bfbfbf;
  }

  .ck-content {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ccc;
    min-height: calc(100vh - 750px);
    color: black;
    .table {
      max-width: 100vh;
      width: 100vh;
      display: block !important;
      overflow: auto;
      max-height: calc(100vh - 300px);
      &::-webkit-scrollbar {
        width: 8px !important;
        cursor: default;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
      }
    }
    @media only screen and (max-width: 1366px) {
      min-height: 300px;
    }
  }

  .ck-rounded-corners.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0 0 5px 5px;
  }
}
</style>
