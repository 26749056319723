import * as VueRouter from 'vue-router';
import {
  noAuth,
  getPermissionHome,
  getPermissionManageCategories,
  getPermissionPostsManagement,
  getPermissionAdmin,
  getPermissionELearning
} from '../config/middleware/roles';
import {
  getUserInfo,
  isAuthenticated,
  middlewarePipeline
} from '../utils/authenticate';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: require('../features/login/Login.vue').default,
    meta: {
      middleware: [noAuth]
    }
  },
  {
    path: '',
    component: require('../components/layouts/MainLayout').default,
    meta: {
      middleware: [getPermissionHome]
    },
    redirect: {
      name: 'Home'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: require('../features/home/Home.vue').default,
        meta: {
          middleware: [getPermissionHome]
        }
      },
      {
        path: '/post/:slug',
        name: 'home-detail',
        component: require('../features/home/HomeDetail.vue').default,
        meta: {
          middleware: [getPermissionHome]
        }
      }
    ]
  },
  {
    path: '/admin',
    component: require('../components/layouts/AdminLayout.vue').default,
    meta: {
      middleware: [getPermissionAdmin]
    },
    children: [
      {
        path: 'categories',
        name: 'CategoryManagement',
        component: require('../features/category/Category.vue').default
      },
      {
        path: 'posts',
        name: 'PostsManagement',
        component: require('../features/post/Post.vue').default
      },
      {
        path: 'posts/:id',
        name: 'ViewPost',
        component: require('../features/post/components/ViewPost.vue').default
      },
      {
        path: 'e-learning',
        children: [
          {
            path: 'category',
            name: 'ECategory',
            component:
              require('../features/e-learning/typing-course/ELearningCategoryManagement.vue')
                .default
          },
          {
            path: 'course',
            name: 'Course',
            component: require('../features/e-learning/course/Course.vue')
              .default
          },
          {
            path: 'courseware',
            name: 'Courseware',
            component:
              require('../features/e-learning/typing-course/Courseware.vue')
                .default
          }
        ]
      },
      {
        path: 'feedback',
        name: 'FeedbackManagement',
        component: require('../features/feedback/FeedbackList.vue').default
      },
      {
        path: '/admin/:catchAll(.*)',
        name: 'NotFound',
        component: require('../components/notfound/NotFound.vue').default
      }
    ]
  },
  {
    path: '/e-learning',
    component: require('../components/layouts/HomePageLayout.vue').default,
    meta: {
      middleware: [getPermissionELearning]
    },
    children: [
      {
        path: 'home',
        name: 'HomeELearning',
        component: require('../features/home-elearning/index.vue').default,
        meta: {
          middleware: [getPermissionHome]
        }
      },
      {
        path: 'course',
        meta: {
          middleware: [getPermissionHome]
        },
        children: [
          {
            path: ':slug',
            component: require('../features/detailsCourse/DetailsCourse.vue')
              .default,
            name: 'DetailsCourse',
            meta: {
              middleware: [getPermissionHome]
            }
          },
          {
            path: ':slug/lesson',
            component: require('../features/detailsCourse/LearnCourse.vue')
              .default,
            name: 'LearnCourse',
            meta: {
              middleware: [getPermissionHome]
            }
          }
        ]
      },
      {
        path: 'my-course',
        name: 'MyCourse',
        component: require('../features/home-elearning/MyCourse.vue').default,
        meta: {
          middleware: [getPermissionHome]
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: require('../components/notfound/NotFound.vue').default,
    meta: {
      middleware: [noAuth]
    }
  }
];

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHistory(), // <-- this is a new property and it is mandatory!
  routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const user = getUserInfo();
  const isLoggedIn = isAuthenticated();
  const { middleware } = to.meta;
  const context = { to, from, next, user, isLoggedIn };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
