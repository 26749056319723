const state = () => ({
  isShow: false
});

const mutations = {
  updateLoading(state, loading) {
    state.isShow = loading;
  }
};

const actions = {
  updateLoading({ commit }, loading) {
    commit('updateLoading', loading);
  }
};

const loading = {
  state,
  actions,
  mutations
};

export default loading;
