<template>
  <a-form
    ref="formRef"
    name="dynamic_form_nest_item"
    :model="formState"
    @finishFailed="onFinishFailed"
    @finish="onFinish"
  >
    <a-space
      v-for="(chapter, chapterIndex) in formState.chapters"
      :key="chapter.key"
      class="chapter-item-wrapper"
      align="baseline"
    >
      <div>
        <a-form-item
          :name="['chapters', chapterIndex, 'name']"
          :rules="[
            ...ruleValidateField({ type: 'input', max: 255 }),
            {
              validator: (rule, value) =>
                hanldleValidateDuplicateChapter(rule, value, chapter.key),
            },
          ]"
          :label="`${t('courseManagement.step2.chapterLabel')} ${
            chapterIndex + 1
          }: `"
          style="display: flex; margin-bottom: 0"
        >
          <a-input
            :disabled="isViewCourse"
            style="border: none"
            v-model:value="chapter.name"
            :placeholder="t('courseManagement.step2.chapterPlaceholder')"
          />
        </a-form-item>

        <div class="lesson-wrapper-scroll">
          <div class="lesson-wrapper">
            <template v-if="chapter.paths.length">
              <div
                v-for="(path, _pathIndex) in chapter.paths"
                :key="_pathIndex"
                :class="`lesson-item-wrapper  ${
                  path.name ? 'show-before' : ''
                }`"
              >
                <div class="item" v-if="path.name">
                  <div class="name">
                    <PlayCircleOutlined
                      v-if="path.fileType === FILE_TYPE.Video"
                    />
                    <FileTextOutlined v-else />
                    <span class="lesson-name">
                      {{ path.name }}
                    </span>
                  </div>
                  <div class="actions">
                    <span
                      v-if="!isViewCourse"
                      @click="
                        () => handleShowEditLession(chapterIndex, _pathIndex)
                      "
                    >
                      <IconEdit />
                    </span>
                    <span
                      v-if="isViewCourse"
                      @click="
                        () => handleShowEditLession(chapterIndex, _pathIndex)
                      "
                    >
                      <EyeOutlined />
                    </span>
                    <a-popconfirm
                      v-if="!isViewCourse"
                      title="Bạn có muốn bài này?"
                      ok-text="OK"
                      cancel-text="Hủy"
                      @confirm="handleDeleteLession(chapterIndex, _pathIndex)"
                      placement="topRight"
                    >
                      <span>
                        <IconDelete />
                      </span>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <a-space
          v-for="(path, pathIndex) in chapter.paths"
          :key="path.key"
          :style="{
            display: path.dataEdit.visible ? 'flex' : 'none',
            border: path.dataEdit.isErrorShow ? '1px solid red' : 'none',
          }"
          align="baseline"
          direction="vertical"
          class="lesson-form"
        >
          <a-form-item
            :name="[
              'chapters',
              chapterIndex,
              'paths',
              pathIndex,
              'dataEdit',
              'name',
            ]"
            :label="t('courseManagement.step2.lessonLabel')"
            :rules="[
              ...ruleValidateField({ type: 'input', max: 255 }),
              {
                validator: (rule, value) =>
                  hanldleValidateDuplicateLesson(
                    rule,
                    value,
                    path.key,
                    chapterIndex
                  ),
              },
            ]"
          >
            <a-input
              :disabled="isViewCourse"
              style="border: none"
              v-model:value="path.dataEdit.name"
              :placeholder="t('courseManagement.step2.lessonPlaceholder')"
            />
          </a-form-item>
          <div class="form-actions">
            <div>
              <button
                v-if="
                  !path.dataEdit.file.length ||
                  path.dataEdit.fileType === FILE_TYPE.Video
                "
                type="button"
                :disabled="isViewCourse"
                :class="{
                  'tab-active': path.dataEdit.fileType === FILE_TYPE.Video,
                  'tab-switch': true,
                }"
                @click="
                  () => {
                    if (path.dataEdit.fileType !== FILE_TYPE.Video) {
                      handleSwitchUpload(
                        chapterIndex,
                        pathIndex,
                        FILE_TYPE.Video
                      );
                    }
                  }
                "
              >
                {{ t("courseManagement.step2.videoLabel") }}
              </button>

              <a-popconfirm
                v-else
                title="Thao tác này sẽ xóa file tài liệu?"
                ok-text="OK"
                cancel-text="Hủy"
                @confirm="
                  () =>
                    handleSwitchUpload(chapterIndex, pathIndex, FILE_TYPE.Video)
                "
                placement="topRight"
              >
                <button
                  type="button"
                  :disabled="isViewCourse"
                  :class="{
                    'tab-active': path.dataEdit.fileType === FILE_TYPE.Video,
                    'tab-switch': true,
                  }"
                >
                  {{ t("courseManagement.step2.videoLabel") }}
                </button>
              </a-popconfirm>

              <button
                v-if="
                  !path.dataEdit.file.length ||
                  path.dataEdit.fileType === FILE_TYPE.Document
                "
                type="button"
                :disabled="isViewCourse"
                :class="{
                  'tab-active': path.dataEdit.fileType === FILE_TYPE.Document,
                  'tab-switch': true,
                }"
                @click="
                  () => {
                    if (path.dataEdit.fileType !== FILE_TYPE.Document) {
                      handleSwitchUpload(
                        chapterIndex,
                        pathIndex,
                        FILE_TYPE.Document,
                        path
                      );
                    }
                  }
                "
              >
                {{ t("courseManagement.step2.docLabel") }}
              </button>

              <a-popconfirm
                v-else
                title="Thao tác này sẽ xóa file video?"
                ok-text="OK"
                cancel-text="Hủy"
                @confirm="
                  () =>
                    handleSwitchUpload(
                      chapterIndex,
                      pathIndex,
                      FILE_TYPE.Document
                    )
                "
                placement="topRight"
              >
                <button
                  type="button"
                  :disabled="isViewCourse"
                  :class="{
                    'tab-active': path.fileType === FILE_TYPE.Document,
                    'tab-switch': true,
                  }"
                >
                  {{ t("courseManagement.step2.docLabel") }}
                </button>
              </a-popconfirm>
            </div>

            <a-form-item
              :name="[
                'chapters',
                chapterIndex,
                'paths',
                pathIndex,
                'dataEdit',
                'file',
              ]"
              :rules="[
                {
                  validator: handleValidateFile,
                },
              ]"
            >
              <div class="file-item">
                <span
                  class="file-name"
                  @click="() => handleDownloadFile(path)"
                  >{{ path.dataEdit.file[0]?.name }}</span
                >
                <div class="file-actions" v-if="path.dataEdit.file.length">
                  <span
                    class="file-loading"
                    v-if="path.dataEdit.uploadingStatus === STATUS_UPLOAD.Loading"
                    ><LoadingOutlined /> Tải lên
                    {{ path.dataEdit.progress }} %</span
                  >
                  <span
                    class="icon-success xy-flex-center"
                    v-if="path.dataEdit.uploadingStatus === STATUS_UPLOAD.Success"
                  >
                    <CheckCircleOutlined class="text-xl" />
                  </span>
                  <span
                    class="icon-error xy-flex-center"
                    v-if="path.dataEdit.uploadingStatus === 'error'"
                  >
                    <CloseCircleOutlined />
                  </span>

                  <span
                    v-if="!isViewCourse"
                    class="xy-flex-center cusor-pointer"
                    @click="handleDeleteFile(chapterIndex, pathIndex)"
                  >
                    <IconDelete />
                  </span>
                </div>
              </div>
            </a-form-item>
            <a-form-item
              :labelCol="{ span: 24, offset: 0 }"
              :label="t('courseManagement.step3.time')"
              :name="[
                'chapters',
                chapterIndex,
                'paths',
                pathIndex,
                'dataEdit',
                'documentTime',
              ]"
              v-if="
                path.dataEdit.file.length &&
                path.dataEdit.fileType === FILE_TYPE.Document
              "
              :rules="[
                {
                  required: true,
                  validator: handleValidateTime,
                  trigger: 'change',
                },
              ]"
            >
              <div class="flex items-center">
                <a-input-number
                  :disabled="isViewCourse"
                  v-model:value="path.dataEdit.documentTime.hours"
                >
                </a-input-number>
                <p class="font-bold mx-[15px]">
                  {{ t("courseManagement.step3.form.hours") }}
                </p>
                <a-input-number
                  :disabled="isViewCourse"
                  v-model:value="path.dataEdit.documentTime.minutes"
                >
                </a-input-number>
                <p class="font-bold mx-[15px]">
                  {{ t("courseManagement.step3.form.minute") }}
                </p>
              </div>
            </a-form-item>

            <div
              class="btn-wrapper"
              :style="{
                'justify-content': !path.dataEdit.file.length
                  ? 'space-between'
                  : 'flex-end',
              }"
            >
              <a-button
                htm-type="button"
                v-if="!path.dataEdit.file.length && !isViewCourse"
                style="height: 30px !important; color: #1890ff"
                class="mr-10"
                @click="() => handleUploadSelected(chapterIndex, pathIndex, 1)"
                size="small"
                type="text"
              >
                {{ t("courseManagement.step2.selectFile") }}</a-button
              >

              <a-button
                v-if="!isViewCourse"
                class="add-btn"
                style="height: 30px !important"
                @click="() => handleSaveLession(chapterIndex, pathIndex)"
                size="small"
                type="primary"
                >{{
                  !path.name
                    ? t("courseManagement.step2.add")
                    : t("courseManagement.step2.save")
                }}</a-button
              >
            </div>

            <div
              v-if="
                (!path.dataEdit.name?.trim().length &&
                  !path.dataEdit.file.length) ||
                isViewCourse
              "
              class="btn-close cusor-pointer"
              @click="handleCloseFormLession(chapterIndex, pathIndex)"
            >
              <CloseOutlined />
            </div>

            <a-popconfirm
              v-else
              title="Bạn có muốn đóng form?"
              ok-text="OK"
              cancel-text="Hủy"
              @confirm="handleCloseFormLession(chapterIndex, pathIndex)"
              placement="topRight"
            >
              <div class="btn-close cusor-pointer">
                <CloseOutlined />
              </div>
            </a-popconfirm>
          </div>
        </a-space>

        <div v-if="!isViewCourse" class="chapter-bottom-btn">
          <a-button
            :disabled="chapter.paths.length >= 15"
            class="add-btn"
            type="text"
            @click="() => addPath(chapterIndex)"
          >
            <PlusOutlined />
            {{ t("courseManagement.step2.createLesson") }}
          </a-button>
          <span
            v-if="
              !chapter.name?.trim().length &&
              !chapter.paths[0]?.name.trim().length &&
              !chapter.paths[0]?.file.length
            "
            class="btn-delete"
            @click="removeChapter(chapterIndex)"
          >
            <IconDelete />
            {{ t("courseManagement.step2.deleteChapter") }}
          </span>
          <a-popconfirm
            v-else
            title="Bạn có muốn xóa chương này?"
            ok-text="OK"
            cancel-text="Hủy"
            @confirm="removeChapter(chapterIndex)"
            placement="topRight"
          >
            <span class="btn-delete">
              <IconDelete />
              {{ t("courseManagement.step2.deleteChapter") }}
            </span>
          </a-popconfirm>
        </div>
      </div>
    </a-space>

    <a-form-item v-if="!isViewCourse">
      <a-button class="add-btn" type="text" @click="addChapter">
        <PlusOutlined />
        {{ t("courseManagement.step2.createChapter") }}
      </a-button>
    </a-form-item>

    <div v-if="!isViewCourse" class="btn-bottom-wrapper">
      <a-button v-if="modeCreate" style="width: 131px" @click="handleBackStep"
        >Trở lại</a-button
      >
      <div>
        <a-button
          style="width: 131px"
          type="primary"
          html-type="submit"
          :disabled="!formState.chapters && !isEditCourse"
        >
          {{
            !modeCreate
              ? t("courseManagement.common.update")
              : t("courseManagement.common.next")
          }}
        </a-button>
      </div>
    </div>
  </a-form>

  <a-modal
    v-model:visible="modalState.visible"
    title=""
    centered
    :closable="false"
    :style="{
      maxHeight: '90vh',
    }"
  >
    <template #footer>
      <a-button key="back" @click="handleCancelModalUpload">Hủy</a-button>
      <a-button
        key="submit"
        :disabled="
          !formState?.chapters?.[uploadSelected.chapterIndex]?.paths?.[
            uploadSelected?.pathIndex
          ].dataEdit?.file?.length ||
          formState?.chapters?.[uploadSelected.chapterIndex]?.paths?.[
            uploadSelected?.pathIndex
          ].dataEdit?.uploadingStatus === STATUS_UPLOAD.Loading
        "
        type="primary"
        @click="handleOkModal"
        >Áp dụng</a-button
      >
    </template>
    <p>
      <b
        v-if="
          formState.chapters[uploadSelected.chapterIndex].paths[
            uploadSelected.pathIndex
          ].dataEdit.fileType === 'document'
        "
        >{{ t("courseManagement.step2.uploadDocModalLabel") }}</b
      >
      <b v-else> {{ t("courseManagement.step2.uploadVideoModalLabel") }}</b>
    </p>
    <a-form-item v-if="modalState.visible" class="mt-2">
      <a-select v-model:value="valueSelect">
        <a-select-option
          v-for="(item, index) in UPLOAD_METHOD"
          :value="limit"
          :key="index"
          @click="handelChooseMethod(item)"
          :placeholder="t('courseManagement.step2.chooseUpload')"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-upload
        v-if="currenMethod.id === 1"
        v-model:file-list="
          formState.chapters[uploadSelected.chapterIndex].paths[
            uploadSelected.pathIndex
          ].dataEdit.file
        "
        name="file"
        @change="handleChangeUpload"
        :customRequest="customRequestUpload"
        :beforeUpload="beforeUpload"
        :max-count="1"
        class="flex mt-[10px]"
      >
        <a-button
          type="primary"
          style="
            height: 30px !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          "
        >
          {{ t("courseManagement.step2.selectFile") }}
        </a-button>
      </a-upload>
      <ListStudy
        v-else
        @chooseFile="chooseFile"
        :typeUpload="
          formState.chapters[uploadSelected.chapterIndex].paths[
            uploadSelected.pathIndex
          ].dataEdit.fileType
        "
      />
    </a-form-item>
  </a-modal>
</template>

<script setup>
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  defineComponent,
  defineEmits,
  onMounted,
  reactive,
  ref,
  computed,
  h,
} from "vue";
import { useStore } from "vuex";
import IconDelete from "../../../../components/icon/icon-delete.vue";
import IconEdit from "../../../../components/icon/icon-edit.vue";
import ListStudy from "./ListStudy.vue";
import { useNotify, useShowConfirm, useLoading } from "../../../../utils/hook";
import { SERVICE_URL_WIKI_BASE } from "../api/pathUrl";
import { step2ManagementApi } from "../api/request";
import { cloneDeep } from "lodash";
import {
  DOCS_FILE_STEP2,
  FILE_TYPE,
  VIDEOS_FILE_STEP2,
  STEP_NAME,
  UPLOAD_METHOD,
  STATUS_UPLOAD
} from "../utils/constant";
import {
  mapDataDetailStep2,
  mapDataSubmitStep2,
  ruleValidateField,
  downloadFile,
} from "../utils/helpers";
import { checkTypeAndSizeUpload } from '../../../../utils/helper'
import { useI18n } from "vue-i18n";
defineComponent({
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    IconDelete,
    IconEdit,
    CloseOutlined,
    PlayCircleOutlined,
    FileTextOutlined,
    LoadingOutlined,
    EyeOutlined,
    ListStudy,
  },
  props: {
    getListCourse: Function,
  },
});
const { t } = useI18n();
const { showLoading, hideLoading } = useLoading();
const store = useStore();
const emit = defineEmits();
const formRef = ref();
const valueSelect = ref(0);
const isEditCourse = computed(() => store.state.courseManagement.edit_course);
const courseId = computed(() => store.state.courseManagement.course_id);
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const courseStatus = computed(() => store.state.courseManagement.courseStatus);
const modeCreate = computed(() => store.state.courseManagement.modeCreate);
const modalState = reactive({
  visible: false,
});
const formState = reactive({
  chapters: [
    {
      key: uuidv4(),
      name: "",
      paths: [
        {
          dataEdit: {
            key: uuidv4(),
            name: "",
            fileType: FILE_TYPE.Video,
            file: [],
            visible: true,
            progress: 0,
            uploadingStatus: null,
            isErrorShow: false,
            documentTime: {
              hours: null,
              minutes: null,
            },
          },
          key: uuidv4(),
          name: "",
          fileType: FILE_TYPE.Video,
          file: [],
        },
      ],
    },
  ],
});

const dataDelete = reactive({
  chapter_ids: [],
  lesson_ids: [],
});

const statusStep = ref(null);
const ar = ref([]);
const currenMethod = ref(UPLOAD_METHOD[0]);

const { notifyError, notifySuccess } = useNotify();
const { showConfirm } = useShowConfirm();

const handleOkModal = () => {
  modalState.visible = false;
};

onMounted(() => {
  if (isEditCourse.value || isViewCourse.value) {
    getStep2({ course_id: courseId.value });
  }
});

const chooseFile = (payload) => {
  formState.chapters[uploadSelected.chapterIndex].paths[
    uploadSelected.pathIndex
  ].dataEdit.file = payload;
  const type =
    formState.chapters[uploadSelected.chapterIndex].paths[
      uploadSelected.pathIndex
    ].dataEdit.fileType;
};

const handleDownloadFile = (path) => {
  downloadFile(path.file[0].path, path.file[0].name);
};

const handelChooseMethod = (item) => {
  currenMethod.value = item;
};

const handleUploadSelected = (chapterIndex, pathIndex, type) => {
  modalState.visible = true;
  uploadSelected.chapterIndex = chapterIndex;
  uploadSelected.pathIndex = pathIndex;
};

const uploadSelected = reactive({
  chapterIndex: null,
  pathIndex: null,
  progress: 0,
});

const removeChapter = (chapterIndex) => {
  if (isEditCourse.value && formState.chapters[chapterIndex].id) {
    dataDelete.chapter_ids.push(formState.chapters[chapterIndex].id);
  }
  formState.chapters.splice(chapterIndex, 1);
};

const addChapter = () => {
  formState.chapters.push({
    name: "",
    key: uuidv4(),
    paths: [
      {
        dataEdit: {
          key: uuidv4(),
          name: "",
          fileType: FILE_TYPE.Video,
          file: [],
          visible: true,
          progress: 0,
          uploadingStatus: null,
          isErrorShow: false,
          documentTime: {
            hours: null,
            minutes: null,
          },
        },
        key: uuidv4(),
        name: "",
        fileType: FILE_TYPE.Video,
        file: [],
      },
    ],
  });
};

const addPath = (chapterIndex) => {
  formState.chapters[chapterIndex].paths.push({
    dataEdit: {
      key: uuidv4(),
      name: "",
      fileType: FILE_TYPE.Video,
      file: [],
      visible: true,
      progress: 0,
      uploadingStatus: null,
      isErrorShow: false,
      documentTime: {
        hours: null,
        minutes: null,
      },
    },
    key: uuidv4(),
    name: "",
    fileType: FILE_TYPE.Video,
    file: [],
  });
};

const handleChangeUpload = (info) => {
  formRef.value.validate([
    [
      "chapters",
      uploadSelected.chapterIndex,
      "paths",
      uploadSelected.pathIndex,
      "dataEdit",
      "file",
    ],
  ]);
};

const handleCancelModalUpload = () => {
  formState.chapters[uploadSelected.chapterIndex].paths[
    uploadSelected.pathIndex
  ].file = [];
  formState.chapters[uploadSelected.chapterIndex].paths[
    uploadSelected.pathIndex
  ].dataEdit.file = [];
  modalState.visible = false;
};

const handleDeleteFile = (chapterIndex, pathIndex, type) => {
  formState.chapters[chapterIndex].paths[pathIndex].dataEdit.file = [];
  formState.chapters[chapterIndex].paths[pathIndex].dataEdit.uploadingStatus =
    null;
};

const checkShowFormLesson = () => {
  formState.chapters.forEach((chapter, chapterIndex) =>
    chapter.paths.forEach((lesson, lessonIndex) => {
      if (lesson.dataEdit.visible) {
        formState.chapters[chapterIndex].paths[
          lessonIndex
        ].dataEdit.isErrorShow = true;
      }
    })
  );
  const formLessonShow = formState.chapters.find((chapter) =>
    chapter.paths.some((lesson) => lesson.dataEdit.visible)
  );
  if (formLessonShow) return true;
  return false;
};

const onFinish = (values) => {
  if (checkShowFormLesson()) return;
  if (isEditCourse.value && !modeCreate.value) {
    showConfirm({
      type: "edit",
      onOk: () => {
        if (
          formState.chapters.length <= 1 &&
          !formState.chapters[0]?.id &&
          !dataDelete.chapter_ids.length
        ) {
          if (formState.chapters.length) {
            addStep2({
              action: 1,
              course_id: courseId.value,
              data_create: mapDataSubmitStep2(formState.chapters),
            });
          }
          else{
            forceNext()
          }
        } else {
          updateStep2({
            action: 1,
            course_id: courseId.value,
            data_update: mapDataSubmitStep2(formState.chapters),
            data_delete: dataDelete,
          });
        }
      },
      suffix: "bước 2",
    });
    return;
  } else if (modeCreate.value && isEditCourse.value) {
    if (!dataDelete.chapter_ids.length && !formState.chapters[0]?.id ) {
      if (formState.chapters.length) {
        addStep2({
          action: 1,
          course_id: courseId.value,
          data_create: mapDataSubmitStep2(formState.chapters),
        });
      } else {
        forceNext();
      }
    } else {
      updateStep2({
        action: 1,
        course_id: courseId.value,
        data_update: mapDataSubmitStep2(formState.chapters),
        data_delete: dataDelete,
      });
    }
    return;
  } else {
    if (formState.chapters.length) {
      addStep2({
        action: 1,
        course_id: courseId.value,
        data_create: mapDataSubmitStep2(formState.chapters),
      });
    } else {
      forceNext();
    }
  }
};
const forceNext = () => {
  store.dispatch("doingStep", 3);
  store.dispatch("doneStep", 2);
};
const onFinishFailed = (values) => {
  if (checkShowFormLesson()) return;
};

const handleSaveLession = async (chapterIndex, pathIndex) => {
  try {
    await formRef.value.validate([
      ["chapters", chapterIndex, "paths", pathIndex, "dataEdit", "name"],
      ["chapters", chapterIndex, "paths", pathIndex, "dataEdit", "file"],
      [
        "chapters",
        chapterIndex,
        "paths",
        pathIndex,
        "dataEdit",
        "documentTime",
      ],
    ]);
    formState.chapters[chapterIndex].paths[pathIndex].dataEdit.visible = false;
    formState.chapters[chapterIndex].paths[
      pathIndex
    ].dataEdit.isErrorShow = false;
    const dataEditItem =
      formState.chapters[chapterIndex].paths[pathIndex].dataEdit;
    formState.chapters[chapterIndex].paths[pathIndex] = {
      ...dataEditItem,
      dataEdit: dataEditItem,
    };
  } catch (error) {}
};

const handleSwitchUpload = (chapterIndex, pathIndex, type) => {
  formState.chapters[chapterIndex].paths[pathIndex].dataEdit.file = [];
  formState.chapters[chapterIndex].paths[pathIndex].dataEdit.fileType = type;
};

const handleShowEditLession = (chapterIndex, pathIndex) => {
  formState.chapters[chapterIndex].paths[pathIndex].dataEdit.visible = true;
};

const handleDeleteLession = (chapterIndex, pathIndex) => {
  if (
    isEditCourse.value &&
    formState.chapters[chapterIndex].paths[pathIndex].id
  ) {
    dataDelete.lesson_ids.push(
      formState.chapters[chapterIndex].paths[pathIndex].id
    );
  }
  formState.chapters[chapterIndex].paths.splice(pathIndex, 1);
};

const handleCloseFormLession = (chapterIndex, pathIndex) => {
  if (formState.chapters[chapterIndex].paths[pathIndex].name) {
    formState.chapters[chapterIndex].paths[pathIndex].dataEdit.visible = false;
    const dataOriginItem = formState.chapters[chapterIndex].paths[pathIndex];
    formState.chapters[chapterIndex].paths[pathIndex] = {
      ...dataOriginItem,
      dataEdit: { ...dataOriginItem, visible: false },
    };
  } else {
    handleDeleteLession(chapterIndex, pathIndex);
  }
};

const beforeUpload = (file) => {
  const isVideoSelected =
    formState.chapters[uploadSelected.chapterIndex].paths[
      uploadSelected.pathIndex
    ].dataEdit.fileType === FILE_TYPE.Video;
  if (isVideoSelected) {
    return checkTypeAndSizeUpload(file, VIDEOS_FILE_STEP2);
  }
  return checkTypeAndSizeUpload(file, DOCS_FILE_STEP2);
};

const customRequestUpload = async (options) => {
  const { onSuccess, onError, file, onProgress } = options;

  const fmData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      formState.chapters[uploadSelected.chapterIndex].paths[
        uploadSelected.pathIndex
      ].dataEdit.progress = percent;
      formState.chapters[uploadSelected.chapterIndex].paths[
        uploadSelected.pathIndex
      ].dataEdit.uploadingStatus = STATUS_UPLOAD.Loading;
      if (percent === 100) {
        formState.chapters[uploadSelected.chapterIndex].paths[
          uploadSelected.pathIndex
        ].dataEdit.progress = 0;
        formState.chapters[uploadSelected.chapterIndex].paths[
          uploadSelected.pathIndex
        ].dataEdit.uploadingStatus = STATUS_UPLOAD.Success;
      }
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };
  fmData.append("file", file);
  try {
    const res = await axios.post(
      SERVICE_URL_WIKI_BASE + "file/upload",
      fmData,
      config
    );
    onSuccess("Ok");
    formState.chapters[uploadSelected.chapterIndex].paths[
      uploadSelected.pathIndex
    ].dataEdit.file_id = res.data.data.id;
  } catch (err) {
    formState.chapters[uploadSelected.chapterIndex].paths[
      uploadSelected.pathIndex
    ].dataEdit.uploadingStatus = "error";
  }
};

const handleValidateLession = (rule, value) => {
  if (!value?.trim().length) {
    return Promise.reject("Không được để trống");
  }
  if (!value?.trim().length > 255) {
    return Promise.reject("Không quá 255 ký tự");
  }
  return Promise.resolve();
};

const handleValidateFile = (rule, value, callback, path) => {
  if (!value.length) {
    return Promise.reject("Không được để trống");
  }
  return Promise.resolve();
};

const handleValidateTime = (rule, value) => {
  if (!value.hours && !value.minutes) {
    return Promise.reject(t("courseManagement.step3.validate.timeRequired"));
  } else if (value.hours && (value.hours < 0 || value.hours > 99)) {
    return Promise.reject(t("courseManagement.step2.validate.hoursInvalid"));
  } else if (value.minutes && (value.minutes < 0 || value.minutes > 59)) {
    return Promise.reject(t("courseManagement.step2.validate.minuteInvalid"));
  } else {
    return Promise.resolve();
  }
};

const hanldleValidateDuplicateChapter = (rule, value, key) => {
  const isDuplicateChapterName = formState.chapters.some((chapter) => {
    if (chapter.key === key) return false;
    return chapter.name === value;
  });
  if (isDuplicateChapterName) {
    return Promise.reject("Chương này đã bị trùng");
  }
  return Promise.resolve();
};

const hanldleValidateDuplicateLesson = (rule, value, key, chapterIndex) => {
  const isDuplicateLessonName = formState.chapters[chapterIndex].paths.some(
    (path) => {
      if (path.key === key) return false;
      return path.name === value;
    }
  );
  if (isDuplicateLessonName) {
    return Promise.reject("Bài này đã bị trùng");
  }
  return Promise.resolve();
};

const handleBackStep = () => {
  const backList = () => {
    store.dispatch("doingStep", 1);
    store.dispatch("doneStep", 2);
    store.dispatch("updateEditStatus", true);
  };

  if (isEditCourse.value) {
    const content = h("div", [
      h("p", `Mọi chỉnh sửa có thể không được lưu`),
      h("p", "Bạn có chắc chắn muốn quay lại?"),
    ]);
    showConfirm({
      type: "edit",
      contentBody: content,
      onOk: () => {
        backList();
      },
    });
    return;
  }

  backList();
};

const addStep2 = async (payload) => {
  try {
    const res = await step2ManagementApi.addStep2(payload);
    notifySuccess(t('courseManagement.step2.notification.createdSuccess'));
    store.dispatch("doingStep", 3);
    store.dispatch("doneStep", 2);
    store.dispatch("updateCourseStatus", {
      lesson_info: 1,
    });
  } catch (error) {
    notifyError(t('courseManagement.step2.notification.createdFail'));
  }
};

const updateStep2 = async (payload) => {
  showLoading();
  try {
    const res = await step2ManagementApi.updateStep2(payload);
    if (modeCreate.value) {
      notifySuccess(t('courseManagement.step2.notification.createdSuccess'));
    } else {
      notifySuccess(t('courseManagement.step2.notification.updateSuccess'));
    }
    if (modeCreate.value) {
      store.dispatch("doingStep", 3);
      store.dispatch("doneStep", 2);
    }
    dataDelete.chapter_ids = [];
    dataDelete.lesson_ids = [];

    store.dispatch("updateCourseStatus", { lesson_info: 1 });
  } catch (error) {
    notifyError(t('courseManagement.step2.notification.updateFail'));
  } finally {
    await getStep2({ course_id: courseId.value })
    hideLoading();
  }
};

const getStep2 = async (params) => {
  showLoading();
  try {
    const response = await step2ManagementApi.getStep2(params);
    formState.chapters = mapDataDetailStep2(response.data);
    if (!formState.chapters.length) {
      // store.dispatch("updateEditStatus", false);
      addChapter();
    }
    statusStep.value = response.data.data.status;
  } catch (error) {
  } finally {
    hideLoading();
  }
};
</script>

<style lang="scss" scoped>
.add-btn {
  display: flex;
  align-items: center;
  span {
    background-color: #1890ff;
    width: 23px;
    height: 23px;
    border-radius: 99px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tab-switch {
  font-weight: 700;
  color: #b4b4b4;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}
.tab-active {
  color: black;
  text-decoration: underline;
}

.chapter-item-wrapper {
  padding: 10px 20px;
  background-color: #b4b4b4;
  border-radius: 5px;
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  width: 100%;
  gap: 6px;

  :deep(> .ant-space-item) {
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
}
.lesson-wrapper-scroll {
  max-height: 120px;
  overflow-y: auto;
  padding-right: 20px;
}
.lesson-wrapper {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;

  &::before {
    content: "";
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 12px;
    border-left: 2px solid black;
  }
}

.lesson-item-wrapper {
  position: relative;
  font-weight: 700;
  &::before {
    display: none;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: #f7f9fa;
    }
    .name {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .actions {
    margin-left: 20px;
    display: flex;
    gap: 20px;
    span {
      display: flex;
      align-items: center;
    }
  }
}

.show-before {
  &::before {
    display: block;
    content: "";
    width: 15px;
    height: 28px;
    position: absolute;
    left: -18px;
    bottom: 10px;
    border-bottom: 2px solid black;
  }
}

.lesson-form {
  background-color: #f7f9fa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 145px;
  position: relative;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;

  :deep(.ant-form-item) {
    margin-bottom: 0;
  }

  :deep(.ant-space-item:last-child) {
    flex: 1;
  }

  .form-actions {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 2px;
  }

  input {
    background-color: #f7f9fa !important;
  }

  .input-wrapper {
    display: flex;
    .label {
      font-weight: 700;
      min-width: 100px;
    }
  }
}
.file-item {
  display: flex;
  gap: 20px;
  align-items: center;
  .file-name {
    color: #1280bf;
  }
  .file-name:hover {
    cursor: pointer;
  }

  .file-actions {
    display: flex;
    gap: 20px;
  }
  .icon-error {
    :deep(svg) {
      fill: red;
    }
  }

  .icon-success {
    :deep(svg) {
      fill: green;
    }
  }
}

.mr-10 {
  margin-right: 10px;
}

.none {
  display: none;
}

:deep(.ant-space-item) {
  width: 100%;
}

:deep(label) {
  font-weight: 700;
  margin-bottom: 0;
}

:deep(input) {
  height: 26px !important;
  font-weight: 700;
  padding-left: 4px;
}
:deep(button) {
  border: none;
  background-color: unset;
}
.actions {
  span {
    &:hover {
      cursor: pointer;
    }
  }
}

.chapter-bottom-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .btn-delete {
    display: flex;
    align-items: center;
    gap: 6px;
    color: red;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }
}

.btn-bottom-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  :deep(button) {
    border: 1px solid #d9d9d9;
  }

  div {
    button + button {
      margin-left: 20px;
    }
  }
}
:deep(.ant-upload-list-item) {
  margin-top: 4px;
  padding-right: 0px !important ;
  margin-right: 0px !important;
}
:deep(.ant-upload-list) {
  border: 1px solid;
  margin-left: -3px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  max-width: 82%;
  overflow: hidden;
  .ant-upload-list-text-container{
    width: 100%;
  }
  .ant-upload-span {
    margin-top: -2px;
    .ant-upload-text-icon {
      height: 26px;
    }
    .ant-upload-list-item-name{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 110%;
    }
    .ant-upload-list-item-card-actions{
      display: none;
    }
  }
}
</style>
