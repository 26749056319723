<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
  <path d="M3.69141 1.24219C3.82812 0.941406 4.12891 0.75 4.45703 0.75H7.76562C8.09375 0.75 8.39453 0.941406 8.53125 1.24219L8.75 1.625H11.375C11.8398 1.625 12.25 2.03516 12.25 2.5C12.25 2.99219 11.8398 3.375 11.375 3.375H0.875C0.382812 3.375 0 2.99219 0 2.5C0 2.03516 0.382812 1.625 0.875 1.625H3.5L3.69141 1.24219ZM10.7734 13.5195C10.7461 14.2305 10.1719 14.75 9.46094 14.75H2.76172C2.05078 14.75 1.47656 14.2305 1.44922 13.5195L0.847656 4.25H11.375L10.7734 13.5195Z" fill="#E41D1D"/>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
