import { notification, Modal } from 'ant-design-vue';
import { h, ref, computed } from 'vue';
import { texts } from './texts';
import store from '../store';
export const useNotify = () => {
  const notifyError = (description) => {
    return notification.open({
      message: 'Thất bại',
      className: 'notify-success',
      style: {
        background: '#E41D1D !important'
      },
      description: description
    });
  };
  const notifySuccess = (description) => {
    return notification.open({
      message: 'Thành công',
      className: 'notify-success',
      style: {
        background: '#00d909 !important'
      },
      description: description
    });
  };

  return { notifySuccess, notifyError };
};

export const useShowConfirm = () => {
  const showConfirm = (config) => {
    const {
      title = 'Thông báo',
      suffix = '',
      onOk,
      onCancel,
      content,
      contentBody,
      type = 'delete'
    } = config;

    const contentDelete = h('div', [
      h('p', `Bạn đang thực hiện xoá ${suffix}`),
      h('p', `${texts.notiConfirmText}`)
    ]);

    const contentEdit = h('div', [
      h('p', `${texts.notiEditText} ${suffix}`),
      h('p', `${texts.notiConfirmText}`)
    ]);
    const contentConfirmTest = h('div', [
      h('p', { style: { fontSize: '18px' } }, content)
    ]);

    let contentShow = contentBody;

    if (type === 'delete' && !contentBody) {
      contentShow = contentDelete;
    } else if (type === 'edit' && !contentBody) {
      contentShow = contentEdit;
    } else if (type === 'modalConfirmTest' && !contentBody) {
      contentShow = contentConfirmTest;
    }

    Modal.confirm({
      title: texts.notification,
      icon: null,
      content: contentShow,
      okText: type === 'modalConfirmTest' ? texts.submitText : texts.ok,
      cancelText: texts.cancelText,
      centered: true,
      bodyStyle: {
        padding: '20px'
      },
      wrapClassName:
        type === 'delete'
          ? 'ant-modal-confirm-delete'
          : 'ant-modal-confirm-edit',
      onOk() {
        onOk();
      },
      onCancel() {
        if (onCancel) {
          onCancel();
        }
      }
    });
  };
  const showModalWarning = (config) => {
    const { onOk, content, type } = config;
    const contentInfo = h('div', [
      h('p', { style: { marginBottom: '0px' } }, content)
    ]);
    Modal.warning({
      title: 'Cảnh báo',
      content: contentInfo,
      centered: true,
      okText: type === 'modalConfirmTimeOut' ? texts.confirm : texts.ok,
      onOk() {
        onOk();
      }
    });
  };

  const showModalNavigate = (config) => {
    const { onOk, content, type, onCancel } = config;
    const contentInfo = h('div', [
      h('p', { style: { marginBottom: '0px' } }, content)
    ]);

    Modal.confirm({
      title: texts.warning,
      content: contentInfo,
      centered: true,
      okText: texts.confirm,
      cancelText: texts.cancel,
      onOk() {
        onOk();
      },
      onCancel() {
        onCancel();
      }
    });
  };
  return { showConfirm, showModalWarning, showModalNavigate };
};

export const useLoading = () => {
  const showLoading = () => {
    store.dispatch('updateLoading', true);
  };
  const isShowLoading = computed(() => store.state.loading.isShow);
  const hideLoading = () => {
    store.dispatch('updateLoading', false);
  };
  return { showLoading, hideLoading, isShowLoading };
};
