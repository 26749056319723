import { createI18n } from 'vue-i18n';

import course_management_vn from './locale/vn/courseManagement.translation.json';
import course_management_en from './locale/en/courseManagement.translation.json';
import { LANGUAGES } from './constant/language';

const i18n = createI18n({
  locale: LANGUAGES.Vn,
  fallbackLocale: LANGUAGES.Vn,
  legacy: false,
  messages: {
    vn: {
      courseManagement: course_management_vn
    },
    en: {
      courseManagement: course_management_en
    }
  }
});

export default i18n;
