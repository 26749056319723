<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 6.5L0.803848 0.5L11.1962 0.5L6 6.5Z" fill="#929292" />
  </svg>
</template>
<script>
export default {};
</script>
