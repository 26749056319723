<template>
  <div class="wrap-list">
    <div class="top-header">
      <div class="header-course">
        <h2 class="title">{{ texts.title }}</h2>
        <a-tabs
          v-model:activeKey="pageState.activeKey"
          @change="handleSwitchTagOrPage"
        >
          <a-tab-pane
            v-for="item in TAG_NAVIGATION"
            :key="item.id"
            :tab="item.title"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <Loading v-if="isLoading"></Loading>
      <div v-else>
        <div class="list-course" v-if="isData">
          <MyCourseItem
            v-for="item in listAllCourse"
            :key="item.id"
            :id="item.id"
            :image="item.image"
            :title="item.title"
            :deadline="item.deadline"
            :progress="item.progress"
          />
        </div>
        <div v-else class="empty-course">
          <div>
            <img src="/images/not-found.png" alt="not found course" />
            <p>{{ texts.emptyCourse }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="isData">
      <a-button
        class="pagination-btn"
        @click="handleActions(TypeActions.Prev)"
        :disabled="disabledPrev"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <div class="page-count">
        <template v-for="(page, index) in paginationRange">
          <a-button
            class="pagination-btn-count"
            :class="{ active: page === pageState.currentPage }"
            v-if="page !== '...'"
            :key="index"
            @click="onChangePage(page)"
            >{{ page }}</a-button
          >
          <span v-else :key="index + 1">...</span>
        </template>
      </div>
      <a-button
        class="pagination-btn"
        @click="handleActions(TypeActions.Next)"
        :disabled="disabledNext"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
    <div class="flex-center" v-if="isData">
      <span class="total-page"> {{ pageRange }} </span>
    </div>
  </div>
</template>
<script setup>
import MyCourseItem from "./MyCourseItem.vue";
import { usePagination } from "./utils/heplers";
import { TypeActions } from "./utils/interface";
import { CourseELearningApi } from "./api/request";
import Loading from "../../components/loading/Loading.vue";
import {
  defineComponent,
  onBeforeMount,
  ref,
  computed,
  reactive,
  onMounted,
} from "vue";
import { texts } from "./utils/texts";
import { TAGS, TAG_NAVIGATION } from "./utils/constant";
import moment from "moment";
import { useStore } from "vuex";
const store = useStore();
defineComponent({
  components: { MyCourseItem, Loading },
});

const pageState = reactive({
  totalCount: null,
  currentPage: 1,
  pageSize: 12,
  IdCurrentTagSelect: 1,
  siblingCount: 1,
  activeKey: 1,
});
const isLoading = ref(false);
const listAllCourse = ref([]);
const currentIdTag = ref(1);
const disabledNext = ref(false);
const isData = ref(false);
const totalPageCount = computed(() => {
  return Math.ceil(pageState.totalCount / pageState.pageSize);
});

const paginationRange = computed(() => {
  return usePagination({
    currentPage: pageState.currentPage,
    totalCount: pageState.totalCount,
    siblingCount: pageState.siblingCount,
    pageSize: pageState.pageSize,
  });
});

const pageRange = computed(() => {
  const startCount =
    pageState.currentPage === 1
      ? 1
      : (pageState.currentPage - 1) * pageState.pageSize + 1;
  const endCount = Math.min(
    pageState.currentPage * pageState.pageSize,
    pageState.totalCount
  );
  return `${startCount} - ${endCount} of ${pageState.totalCount} courses`;
});
const disabledPrev = computed(() => {
  return pageState.currentPage === 1;
});

const fetchAllCourse = async (type = "") => {
  try {
    isLoading.value = true;
    const params = {
      limit: pageState.pageSize,
      page: pageState.currentPage,
      type: type,
    };
    const { data: res, total } = await CourseELearningApi.getAllCourse(params);
    if (res.length) {
      const data = res.map((item) => {
        let img =
          item?.image.find((file) => {
            return ["png", "jpg", "svg"].includes(file.type);
          })?.full_path || "";
        return {
          id: item?.id,
          image: img || "",
          title: item?.title,
          deadline: getFormattedDate(item?.check_deadline?.deadline_date),
          progress: parseFloat(item.progress) || 0,
        };
      });
      listAllCourse.value = data;
      pageState.totalCount = total || 0;

      if (Math.floor(pageState.totalCount / pageState.pageSize) === 0) {
        disabledNext.value = true;
      } else if (
        pageState.currentPage >=
        Math.ceil(pageState.totalCount / pageState.pageSize)
      ) {
        disabledNext.value = true;
      } else {
        disabledNext.value = false;
      }

      isData.value = true;
      return data;
    } else {
      listAllCourse.value = [];
      isData.value = false;
    }
    isLoading.value = false;
  } finally {
    isLoading.value = false;
  }
};
const handleSwitchTagOrPage = (idTag, isChangePage) => {
  if (idTag !== currentIdTag.value) {
    store.dispatch("updateActiveTag", idTag);
  }
  switch (idTag) {
    case TAGS.allCourse.id:
      if (currentIdTag.value !== idTag) {
        currentIdTag.value = idTag;
      }
      if (!isChangePage) {
        pageState.currentPage = 1;
      }
      fetchAllCourse(TAGS.allCourse.name);
      break;
    case TAGS.compulsoryCourse.id:
      currentIdTag.value = idTag;
      if (!isChangePage) {
        pageState.currentPage = 1;
      }
      fetchAllCourse(TAGS.compulsoryCourse.name);
      break;
    case TAGS.moreCourse.id:
      currentIdTag.value = idTag;
      if (!isChangePage) {
        pageState.currentPage = 1;
      }
      fetchAllCourse(TAGS.moreCourse.name);
      break;
    default:
      break;
  }
};
const getFormattedDate = (dateStr, outputFormat = "DD-MM-YYYY") => {
  if (dateStr) {
    let date = moment(dateStr);
    let formattedDate = date.format(outputFormat);
    return formattedDate;
  }
  return null
};

const isDisableNext = () => {
  return pageState.currentPage >= totalPageCount;
};
const onChangePage = (page) => {
  pageState.currentPage = page;
  store.dispatch("updatePage", page);
  disabledNext.value = isDisableNext();
  handleSwitchTagOrPage(currentIdTag.value, true);
};
const handleActions = (action) => {
  if (action === TypeActions.Next) {
    pageState.currentPage += 1;
    store.dispatch("updatePage", pageState.currentPage);
    disabledNext.value = isDisableNext();
    handleSwitchTagOrPage(currentIdTag.value, true);
  } else if (action === TypeActions.Prev) {
    pageState.currentPage -= 1;
    store.dispatch("updatePage", pageState.currentPage);
    disabledNext.value = isDisableNext();
    handleSwitchTagOrPage(currentIdTag.value, true);
  }
};
onMounted(() => {
  pageState.activeKey = store.state.course.tagCourse;
  if (store.state.course.tagCourse !== 1) {
    if (store.state.course.page) {
      pageState.currentPage = store.state.course.page;
    }
    handleSwitchTagOrPage(store.state.course.tagCourse);
  } else {
    if (store.state.course.page) {
      pageState.currentPage = store.state.course.page;
    }
    fetchAllCourse(TAGS.allCourse.name);
  }
});
</script>
<style lang="scss" scoped>
.wrap-list {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  .top-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-course {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      background-color: #3c57a5;
      padding: 12px 35px 0px;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: white;
      }
    }
    .list-course {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 35px;
    }
    @media (max-width: 1350px) {
      .list-course {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (max-width: 1014px) {
      .list-course {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .pagination-btn {
      border-radius: 50% !important;
    }
    .page-count {
      display: flex;
      gap: 5px;
      margin: 0 20px;
      .pagination-btn-count {
        border: none;
        width: 40px;
        height: 40px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pagination-btn-count:hover {
        color: #371783;
      }
      .active {
        border: 1px solid #371783;
        color: #371783;
      }
      span {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  :deep(.ant-tabs-top > .ant-tabs-nav) {
    margin-bottom: 0px;
    &::before {
      visibility: visible;
    }
  }
  :deep(.ant-tabs-ink-bar) {
    background: #fff;
    height: 8px;
  }
  :deep(.ant-tabs-tab) {
    .ant-tabs-tab-btn {
      color: #cfcfcf;
      font-size: 18px;
      font-weight: bold;
    }
  }
  :deep(.ant-tabs-tab-active) {
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .empty-course {
    display: flex;
    width: 100%;
    margin-top: 200px;
    align-items: center;
    justify-content: center;
    div {
      width: fit-content;
      position: relative;
      top: -10%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      img {
        width: 160px;
        height: 100%;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 26px;
        color: #000;
      }
    }
  }
  .total-page {
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>