<template>
  <svg width="23" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25.75H2.75a2.492 2.492 0 00-2.5 2.5v12.5c0 1.406 1.094 2.5 2.5 2.5h17.5c1.367 0 2.5-1.094 2.5-2.5V3.25c0-1.367-1.133-2.5-2.5-2.5zM7.125 4.5c1.367 0 2.5 1.133 2.5 2.5 0 1.406-1.133 2.5-2.5 2.5a2.468 2.468 0 01-2.5-2.5c0-1.367 1.094-2.5 2.5-2.5zm3.75 10h-7.5a.617.617 0 01-.625-.625 3.11 3.11 0 013.125-3.125h2.5a3.134 3.134 0 013.125 3.125.642.642 0 01-.625.625zm8.75-2.5h-5a.617.617 0 01-.625-.625c0-.313.273-.625.625-.625h5c.313 0 .625.313.625.625a.642.642 0 01-.625.625zm0-2.5h-5A.617.617 0 0114 8.875c0-.313.273-.625.625-.625h5c.313 0 .625.313.625.625a.642.642 0 01-.625.625zm0-2.5h-5A.617.617 0 0114 6.375c0-.313.273-.625.625-.625h5c.313 0 .625.313.625.625a.642.642 0 01-.625.625z"
      fill="#0B68A2"
    />
  </svg>
</template>
<script>
export default {};
</script>
