<template>
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="6" width="22" height="19" rx="4" fill="#0B68A2" />
    <path
      d="M9 21V10a1 1 0 011-1h12a1 1 0 011 1v11a1 1 0 01-1 1H10a1 1 0 01-1-1zM12 12h8m-8 3.5h8M12 19h8"
      stroke="#fff"
    />
  </svg>
</template>
<script>
export default {};
</script>
