<template>
  <div class="app">
    <Loading v-if="isShowLoading" />
    <RouterView />
  </div>
</template>

<script setup>
import { RouterView } from 'vue-router';
import Loading from './components/loading/Loading.vue';
import { defineComponent } from 'vue';
import { useLoading } from './utils/hook';
const { isShowLoading } = useLoading();

defineComponent({
  Loading
});
</script>

<style lang="scss" scoped></style>
