<template>
  <div
    class="rounded-[29px] border-black/50 border w-fit px-3 py-6 flex gap-3 items-center flex-1"
  >
    <div
      class="flex items-center justify-center p-2 bg-opacity-30 rounded-full"
      :class="`bg-[${color}]`"
    >
      <img :src="icon" alt="" />
    </div>
    <div>
      <p class="text-[40px] font-bold" v-if="onlyCount">{{ count }}</p>
      <p class="text-[40px] font-bold" v-else-if="onlyPercent">
        {{ percent }}%
      </p>
      <div v-else>
        <p class="text-[40px] font-bold flex items-center gap-1">
          {{ count }} <span class="text-xl">({{ percent }}%)</span>
        </p>
      </div>
      <p class="text-sm font-bold">
        {{ title }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps({
  icon: String,
  count: {
    type: Number,
    default: null,
  },
  percent: {
    type: Number,
    default: null,
  },
  name: String,
  color: String,
  title: {
    type: String,
  },
});
const onlyCount = computed(() => {
  return props.count !== undefined && props.count !== null && !props.percent && props.percent !== 0;
});
const onlyPercent = computed(() => {
  return props.percent !== undefined && props.percent !== null && !props.count && props.count !== 0;
});
</script>
<style lang="scss" scoped>
</style> 