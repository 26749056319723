<template>
  <div class="footer fixed bottom-7 right-[30px] z-10">
    <div class="flex flex-col w-[41px] gap-3 items-stretch">
      <div
        class="cursor-pointer bg-[#17469F] h-[41px] flex items-center justify-center rounded-md"
        @click="handleOpenFormFeedback"
      >
        <img src="../../../../../public/images/social/feedback.svg" alt="" />
      </div>
      <a
        target="_blank"
        :href="linkSocialNetwork.mms"
        class="cursor-pointer bg-[#17469F] h-[41px] flex items-center justify-center rounded-md"
      >
        <p class="font-bold leading-[19px] text-white">MMS</p>
      </a>
      <a
        target="_blank"
        :href="linkSocialNetwork.morHome"
        class="cursor-pointer bg-[#17469F] h-[41px] flex items-center justify-center rounded-md"
      >
        <img
          src="../../../../../public/images/social/MOR-Logo-white.svg"
          alt=""
        />
      </a>
      <a
        target="_blank"
        :href="linkSocialNetwork.facebook"
        class="cursor-pointer bg-[#17469F] h-[41px] flex items-center justify-center rounded-md"
      >
        <img
          src="../../../../../public/images/social/facebook-logo-white.svg"
          alt=""
        />
      </a>
    </div>
  </div>
  <ModalFeedback></ModalFeedback>
</template>
<script setup>
import { defineComponent, ref } from "vue";
import { socialNetwork } from "../../../constant/common";
import ModalFeedback from '../../../features/post/components/ModalFeedback.vue';
import {
  WindowsOutlined,
  FacebookOutlined,
  LaptopOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";

defineComponent({
  WindowsOutlined,
  FacebookOutlined,
  LaptopOutlined,
  ModalFeedback
});

const store = useStore();
const linkSocialNetwork = ref(socialNetwork);
const isVisibleFooter = ref(false);

const handleOpenFormFeedback = function () {
   store.dispatch("handleShowModalFeedback", true);
};
</script>
<style scoped lang="scss">
.visible {
  bottom: 16px !important;
}
</style>