<template>
  <div style="height: 100%">
    <div class="view-post-wrapper" v-if="showViewPost && !showDataNotFound">
      <div class="header-title">
        <span @click="handleActions(TypeActions.Back)"><iconBack /></span>
        <h3 class="text-[1.575rem]">Chi tiết bài viết</h3>
      </div>
      <div class="body-wrapper">
        <div class="actions-wrapper">
          <div class="flex items-center mb-[28px] gap-[6px]">
            <h2 class="bold-text text-[1.575rem]">
              {{ data.title }}
            </h2>
            <div class="cursor-pointer" @click="handleOpenModalPostHistory">
              <img src="/images/viewHistoryPost.svg" alt="icon" />
            </div>
          </div>

          <div class="btn-wrapper">
            <a-button
              type="primary"
              @click="handleActions(TypeActions.Edit, data.id)"
              >Chỉnh sửa
            </a-button>
            <a-button
              type="primary"
              class="btn-delete"
              @click="handleActions(TypeActions.Delete, data.id)"
              >Xóa
            </a-button>
          </div>
        </div>

        <div class="paragraph-wrapper" ref="contentElement">
          <div class="info">
            <div>
              <span>Ngày đăng: </span><span>{{ data.created_at }}</span>
            </div>
            <div>
              <span>Người đăng: </span
              ><span class="bold-text">{{ data.author }}</span>
            </div>
            <div>
              <span>Danh mục: </span><span>{{ data.categories }}</span>
            </div>
            <div>
              <span>Mô tả: </span><span>{{ data.description }}</span>
            </div>
          </div>
          <div class="chapter mt-[40px]" v-if="data.chapters?.length > 1">
            <div
              class="title mb-[10px] cursor-pointer"
              v-for="(chapter, index) in data.chapters"
              @click="goToScrollChapter(index)"
              :key="index"
            >
              <span
                v-if="chapter.title?.length"
                class="text-[#1280BF] font-bold text-[16px]"
              >
                {{ labelChapter(index + 1) }}
                {{ chapter.title }}
              </span>
            </div>
          </div>

          <div class="chapter mt-[30px]">
            <div
              class="info-chapter mb-[10px]"
              v-for="(chapter, index) in data.chapters"
              :key="index"
              :id="`chapter-${index}`"
            >
              <span v-if="chapter.title?.length" class="font-bold text-[20px]">
                {{ labelChapter(index + 1) }}
                {{ chapter.title }}
              </span>
              <div
                class="ck-content text-[16px]"
                v-html="chapter.description"
              ></div>
            </div>
          </div>

          <p class="bold-text mt-[46px]">
            {{ t("courseManagement.post.topView") }}
          </p>
          <div class="file-wrapper" v-if="data?.files">
            <div
              class="file-item flex items-center"
              v-for="(file, index) in data.files"
              :key="index"
            >
              <div class="icon-dowload">
                <img src="/images/pdf.svg" alt="icon-pdf" />
              </div>
              <div class="name-file !m-0 !flex gap-1 items-center pl-1">
                <div class="name-file !m-0 !flex gap-1 items-center">
                  <div @click="handleOpenPreviewDocument(file?.full_path)">
                    {{ file.name }}
                  </div>
                  <a :href="file?.full_path" download>
                    <DownloadOutlined class="text-xl cursor-pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-post" v-if="!showViewPost">
      <AddPost @toggleShowAddPost="toggleShowAddPost" />
    </div>

    <div v-if="showDataNotFound" class="not-found-wrapper">
      <a-empty :description="false" />
      <h5>Bài viết không tồn tại</h5>
    </div>

    <button
      class="btn-to-top"
      @click="handleActions(TypeActions.TopTop)"
      v-if="showToTop"
    >
      <UpOutlined />
    </button>
  </div>
  <ModalPostHistory
    :isVisible="isVisible"
    title="Lịch sử thay đổi"
    @cancel="closeModal"
  />
</template>

<script>
import NotFound from "../../../components/notfound/NotFound.vue";
import LoadingFull from "../../../components/loading/Loading.vue";
import iconBack from "../../../components/icon/icon-back.vue";
import { postMangemenetApi } from "../api/request";
import { downloadFile, romanize } from "../utils/helpers";
import { TypeActions } from "../utils/interface";
import AddPost from "./AddPost.vue";
import { UpOutlined } from "@ant-design/icons-vue";
import { useLoading, useNotify, useShowConfirm } from "../../../utils/hook";
import { ref, defineComponent } from "vue";
import ModalPostHistory from "./ModalPostHistory.vue";
import { useI18n } from "vue-i18n";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { LINK_EMBEDDED_DOC } from "../../../constant/common";

export default {
  components: {
    iconBack,
    AddPost,
    LoadingFull,
    NotFound,
    UpOutlined,
    ModalPostHistory,
    DownloadOutlined,
  },
  data() {
    return {
      data: {
        id: 1,
        title: "",
        created_at: "",
        categories: undefined,
        author: "",
        description: "",
        content: "",
        files: [],
        chapters: [],
        id: undefined,
      },
      fileList: [],
      uploading: false,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      showViewPost: true,
      showToTop: false,
      showDataNotFound: false,
      LINK_EMBEDDED_DOC: LINK_EMBEDDED_DOC,
    };
  },

  setup() {
    const { t } = useI18n();
    const { hideLoading, showLoading } = useLoading();
    const { showConfirm } = useShowConfirm();
    const { notifyError, notifySuccess } = useNotify();
    const isVisible = ref(false);
    const handleOpenModalPostHistory = () => {
      if (!isVisible.value) {
        isVisible.value = true;
      }
    };
    const closeModal = () => {
      isVisible.value = false;
    };

    return {
      isVisible,
      handleOpenModalPostHistory,
      hideLoading,
      showConfirm,
      showLoading,
      notifyError,
      notifySuccess,
      closeModal,
      t,
    };
  },

  created() {
    this.getpost(this.$router.currentRoute.value.params.id);
    if (
      this.$router.currentRoute.value.query.edit &&
      this.$store.state.postManagement.action.name === undefined
    ) {
      this.$router.push(`/admin/posts`);
    }
    if (this.$store.state.postManagement.action.name === TypeActions.Edit) {
      this.showViewPost = false;
    }
  },

  computed: {
    action() {
      return this.$store.state.postManagement.action;
    },
    TypeModalConfirm() {
      return TypeModalConfirm;
    },
    TypeActions() {
      return TypeActions;
    },
  },

  mounted() {
    const handleScroll = (e) => {
      if (e.target.scrollTop > 400) {
        this.showToTop = true;
        return;
      }

      this.showToTop = false;
    };
    if (!this.$refs.contentElement) return;
    this.$refs.contentElement.addEventListener("scroll", handleScroll);
  },

  unmounted() {
    if (!this.$refs.contentElement) return;
    this.$refs.contentElement.removeEventListener("scroll");
  },

  methods: {
    handleActions(name, id, file) {
      this.$store.dispatch("updateAction", { name, id, file });
      switch (name) {
        case TypeActions.Edit:
          this.showViewPost = false;
          this.showToTop = false;
          break;
        case TypeActions.Back:
          this.$router.push(`/admin/posts`);
          break;
        case TypeActions.Delete:
          this.showConfirm({
            type: "delete",
            suffix: "bài viết",
            onOk: () => {
              this.deletePost(id);
            },
          });
          break;
        case TypeActions.DownLoad:
          downloadFile(file.path, file.name);
          break;
        case TypeActions.TopTop:
          this.$refs.contentElement.scrollTo({ top: 0, behavior: "smooth" });
          break;
      }
    },

    toggleShowAddPost() {
      this.showViewPost = true;
    },

    convertRomanize(number) {
      return romanize(number);
    },

    labelChapter(number) {
      return `${this.t("courseManagement.post.chapter")} ${this.convertRomanize(
        number
      )}:`;
    },

    goToScrollChapter(index) {
      document.getElementById(`chapter-${index}`).scrollIntoView();
    },

    async getpost(id) {
      try {
        const res = await postMangemenetApi.getPost(id);
        this.data.title = res.data.title;
        this.data.categories = res.data.categories
          .map((item) => item.name)
          .join(", ");
        this.data.author = res.data.user.name;
        this.data.description = res.data.description;
        this.data.content = res.data.content;
        this.data.created_at = res.data.created_at;
        this.data.files = res.data.files;
        this.data.chapters = res.data.chapters;
        this.data.id = res.data.id;
      } catch (error) {
        this.showDataNotFound = true;
        throw error;
      }
    },

    async deletePost(id) {
      try {
        const res = await postMangemenetApi.deletePost(id);
        this.$router.push(`/admin/posts`);
        this.notifySuccess("Xóa bài viết thành công");
      } catch (error) {
        this.notifyError("Xóa bài viết thất bại");
        throw error;
      }
    },
    handleOpenPreviewDocument(fullPath) {
      const link = `${this.LINK_EMBEDDED_DOC}${fullPath}`;
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.view-post-wrapper {
  flex: 1;
  background: #fff;
  padding: 24px;
  height: 100%;
  border-radius: 5px;
  .actions-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .btn-wrapper {
    display: flex;
    gap: 20px;
    .btn-delete {
      background-color: red !important;
      border-color: red !important;
    }
  }
}
.header-title {
  font-weight: 700;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  h3 {
    font-weight: 700;
    margin: 0;
    margin-left: 15px;
  }
  span:hover {
    cursor: pointer;
  }
}

.add-post {
  background: #fff;
  padding: 24px;
  overflow-y: auto;
  height: calc(100vh - 94px);
}

.paragraph-wrapper {
  color: black;
  height: calc(100vh - 280px);
  overflow-y: auto;
  padding-right: 10px;
  margin-right: -24px;
  .info {
    margin-bottom: 42px;
  }
}

.not-found-wrapper {
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h5 {
    color: #ababab;
  }
}

.file-wrapper {
  display: flex;
  gap: 20px;
  .file-item {
    background: #ededed;
    border-radius: 6px;
    padding: 4px 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
.bold-text {
  font-weight: 700;
}

.btn-to-top {
  position: fixed;
  right: 70px;
  bottom: 50px;
  border-radius: 9999px;
  height: 40px;
  width: 40px;
  border: none;
  background-color: #1280bf;
  opacity: 0.5;
  color: rgb(211, 211, 211);
  font-size: 16px;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
}
:deep(.ck-content) {
  ul, ol {
    list-style-position: inside;
  };
  h3 {
    margin-left: auto !important;
  }
}
</style>
