import axios from 'axios';
import { get } from 'lodash';
import { errorContants } from '../constant';
import { revokeUser } from '../utils/authenticate';
const BASE_URL = process.env.VUE_APP_HOST_URL || '';
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
};

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = get(error, 'response.status');
    const errorData = get(error, 'response.data');
    switch (status) {
      case errorContants.StatusCode.Unauthorized: {
        revokeUser();
        window.location.reload();
        break;
      }
      case errorContants.StatusCode.ValidationFailed:
      case errorContants.StatusCode.BadRequest: {
        return Promise.reject({ ...errorData, status });
      }
      case errorContants.StatusCode.Forbidden: {
        break;
      }
      case errorContants.StatusCode.InternalServerError: {
        break;
      }
      case errorContants.StatusCode.TooManyRequests: {
        break;
      }
    }
    return Promise.reject({ ...errorData, status });
  }
);

function getApi(url, config = {}) {
  return axiosInstance.get(url, config);
}

function postApi(url, data, config = {}) {
  return axiosInstance.post(url, data, config);
}

function putApi(url, data, config = {}) {
  return axiosInstance.put(url, data, config);
}

function deleteApi(url, data = {}, config = {}) {
  return axiosInstance.delete(url, data, config);
}

const httpRequest = {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi
};

export default httpRequest;
