export const COLUMN_CATEGORY = [
  {
    title: 'STT',
    dataIndex: 'no',
    width: '5%',
    key: 'no'
  },
  {
    title: 'Thời gian phản hồi',
    dataIndex: 'created_at',
    width: '12%',
    key: 'created_at'
  },
  {
    title: 'Người gửi',
    dataIndex: ['user', 'name'],
    width: '10%',
    key: 'user.name'
  },
  {
    title: 'Danh mục',
    dataIndex: ['category', 'name'],
    width: '10%',
    key: 'category.name'
  },
  {
    title: 'Bài viết',
    dataIndex: ['post', 'title'],
    width: '10%',
    key: 'post.title'
  },
  {
    title: 'Nội dung phản hồi',
    dataIndex: 'content',
    width: '15%',
    key: 'content',
  },
  {
    title: 'Người phụ trách',
    dataIndex: 'handler',
    width: '10%',
    key: 'handler'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'action',
    width: '12%',
    key: 'action'
  }
];

export const ACTION = {
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGE_SIZE',
  HandleFeedback: "HANDLE_FEEDBACK",
  ChangeRangeTime: "CHANGE_TIME",
  ViewDetail: "VIEW_DETAIL"
};

export const USER_HANDLER = 'Phạm Hồng Nhung'

export const PAGE_SISES = [10, 20, 30];
