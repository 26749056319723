export default {
  AUTH: {
    LOGIN: '/api/login',
    LOGOUT: '/api/logout'
  },
  HOME: {
    MOST_SEARCH: "/api/most-search",
    CATEGORY: "/api/category/list",
    TOP_VIEW: "/api/top-view",
    SEARCH: "/api/autocomplete",
    SEARCH_ALL: "/api/search",
    NOTIFY: "/api/v2/notification",
    READ_NOTIFY: "/api/v2/notification/read/",
  },
  HOME_DETAIL: {
    GET_DETAIL: "/api/post/detail/"
  },
  POSTS: {
    LIST: 'api/admin/post',
    CATEGORIES: 'api/category/list',
    USER: 'api/user/list'
  },
  CATEGORY: {
    LIST_CATEGORY: "/api/category?limit=",
    CREATE_CATEGORY: "/api/category",
    EDIT_CATEGORY: "/api/category/",
    DELETE_CATEGORY: "/api/category/"
  }
};
