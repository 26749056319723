 <template>
  <div class="wrapper">
    <h2 class="title-course">{{ texts.course }}: {{ titleCourse }}</h2>
    <div class="learning-wrapper">
      <Loading v-if="isLoading" />
      <div class="playing-learning" v-if="!currentIdTest">
        <div class="video-course" v-if="isTypeVideo">
          <video ref="videoPlayer" class="video-js vjs-default-skin vjs-16-9" />
        </div>
        <div class="doc-course" v-if="isTypeDoc">
          <!-- visible file pdf -->
          <iframe
            v-if="currentType === onlyPdf"
            :src="currentDataLesson.file.full_path"
            style="width: 100%; height: calc(100vh - 150px); display: block"
          />
          <!-- visible file doc or docx -->
          <iframe
            v-else
            :key="frameKey"
            :src="renderFileSrc(currentDataLesson.file.full_path)"
            style="width: 100%; height: calc(100vh - 150px); display: block"
          />
        </div>
      </div>
      <div class="test-content" v-else-if="visibleTest">
        <TestCourse
          :idTest="currentIdTest"
          :tests="tests"
          @onCompleteTest="isPlayingTest = false"
          @redoTest="handleRedoTest"
          @onContinueTest="handleContinueTest"
          :infoTestQuestion="infoTestQuestion"
          :numberOfTest="numberOfTest"
          :resultTest="resultTest"
          :isPlayingTestProps="isPlayingTest"
        ></TestCourse>
      </div>
      <template v-else>
        <CheckBeforeTest
          :requirement="testRequirementData"
          :detailTest="detailTest"
          :testQualified="testQualified"
          @handleEmit="handleTestTimers"
        ></CheckBeforeTest>
      </template>
      <div class="detail-course">
        <div class="title">{{ texts.contentCourse }}</div>
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel key="1" :header="texts.chapter">
            <div
              class="content"
              v-for="(chapter, index) in chapterLesson"
              :key="chapter.id"
            >
              <div class="chapter-title">
                {{ `${texts.chapter} ` + renderRoman(index + 1) }}:
                {{ chapter.title }}
              </div>
              <template v-if="chapter.lesson.length">
                <div
                  class="lesson"
                  :class="{ active: lesson.id === currentLessonIdActive }"
                  v-for="lesson in chapter.lesson"
                  :key="lesson.id"
                  @click="
                    () => {
                      handleChoseLesson(lesson);
                    }
                  "
                >
                  <span>-</span>
                  <span class="lesson-title">{{ lesson.title }}</span>
                </div>
              </template>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="2" :header="texts.test">
            <div
              class="lesson"
              :class="{ active: test.id == currentIdTest }"
              v-for="test in tests"
              :key="test.id"
              @click="
                () => {
                  handleCheckStartTest(test.id);
                }
              "
            >
              <span>-</span>
              <span class="lesson-title">{{ test.name }}</span>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="3" :header="texts.references">
            <div
              class="lesson"
              :class="{ active: file.id === currentReferencesActive }"
              v-for="file in referencesFile"
              :key="file.id"
              @click="
                () => {
                  handleChoseReference(file);
                }
              "
            >
              <div
                v-if="file.type === types.filePdf"
                class="flex items-center gap-2"
              >
                <img :width="20" src="/images/pdf.svg" alt="pfd file" />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
              <div
                v-else-if="types.fileDoc.includes(file.type)"
                class="flex items-center gap-1"
              >
                <img
                  :width="26"
                  src="/images/doc.png"
                  alt="pfd file"
                  class="-ml-[3px]"
                />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
              <div v-else class="flex items-center gap-1">
                <img
                  :width="26"
                  src="/images/ptt.png"
                  alt="pfd file"
                  class="-ml-[3px]"
                />
                <span class="text-[#813ed3]">{{ file.name }}</span>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <ModalConfirm
      :message="modalConfirmExit.message"
      :visible="modalConfirmExit.visible"
      @cancel="
        () => {
          handleRejectExitTest();
        }
      "
      @ok="confirmExitTest"
      type="EDIT"
    >
    </ModalConfirm>
  </div>
</template>

<script>
import { DetailsCourseApi } from "./api/request";
import Loading from "../../components/loading/Loading.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import TestCourse from "./TestCourse.vue";
import CheckBeforeTest from "./CheckBeforeTest.vue";
import ModalConfirm from "../../components/modal/ModalConfirm.vue";
import { ONLY_PDF, TYPE_SOURCE, DRIVE_LINK } from "./utils/constant";
import { texts } from "./utils/texts";
import { defineComponent, reactive } from "vue";
import { romanzie } from "./helper/renderRomanNumber";
import { useStore } from "vuex";
import { useLoading } from "../../utils/hook";
import { useShowConfirm } from "../../utils/hook";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {
    Loading,
    TestCourse,
    CheckBeforeTest,
    ModalConfirm,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { hideLoading, showLoading } = useLoading();
    const { showModalNavigate } = useShowConfirm();
    const idLessonStore = store.state.course.idLesson;
    const idReferenceStore = store.state.course.idReference;
    const idTestStore = store.state.course.idTest;
    const types = reactive({
      filePdf: "pdf",
      fileDoc: ["doc", "docx"],
    });
    return {
      idLessonStore,
      idReferenceStore,
      idTestStore,
      hideLoading,
      showLoading,
      showModalNavigate,
      types,
      t,
    };
  },
  data() {
    return {
      driveLink: DRIVE_LINK,
      texts,
      onlyPdf: ONLY_PDF,
      activeKey: "1",
      isLoading: false,
      courseId: "",
      currentLessonId: "",
      chapters: [],
      tests: [],
      chapterLesson: [],
      titleCourse: "",
      currentDataLesson: {},
      currentType: "",
      currentIdTest: "",
      currentLessonIdActive: "",
      temp: {
        lesson: "",
        reference: "",
        test: "",
      },
      infoTestQuestion: {},
      currentReferencesActive: "",
      videoTypes: TYPE_SOURCE.VIDE0_TYPE,
      docTypes: TYPE_SOURCE.DOC_TYPE,
      isTypeVideo: "",
      isTypeDoc: "",
      detailTest: {},
      referencesFile: [],
      testRequirementData: "",
      testQualified: "",
      isPlayingTest: false,
      visibleTest: false,
      currentTypeNavigate: "",
      currentIdLessonStart: "",
      currentOrderLesson: "",
      player: null,
      modalConfirmExit: {
        visible: false,
        message: texts.textConfirmExit,
      },
      paramsApi: {
        target: "home",
        step: "test_info",
      },
      numberOfTest: 0,
      resultTest: [],
      frameKey: 0
    };
  },
  methods: {
    async getDetailCourse(id) {
      try {
        const response = await DetailsCourseApi.getDetailCourse(
          id,
          this.paramsApi
        );
        const { title, chapters, tests, files } = response.data;
        this.titleCourse = title;
        this.chapters = chapters;
        this.tests = tests;
        this.referencesFile = files;
        let countIncrease = 0;
        if (chapters.length) {
          this.chapterLesson = this.chapters.map((chapter) => {
            return {
              title: chapter.title,
              lesson: chapter.lessons.map((lesson) => {
                if (this.currentIdLessonStart === lesson.id) {
                  this.currentOrderLesson = countIncrease + 1;
                }
                return { ...lesson, order: (countIncrease += 1) };
              }),
            };
          });
        }

        if (this.idLessonStore) {
          this.currentLessonIdActive = this.idLessonStore.id;
          const dataLesson = this.chapterLesson
            .find((chapter) =>
              chapter.lesson.find((lesson) => lesson.id === this.idLessonStore)
            )
            .lesson.find((lesson) => lesson.id === this.idLessonStore);
          this.handleChoseLesson(dataLesson);
        } else if (this.idReferenceStore) {
          this.activeKey = "3";
          const data = this.referencesFile.filter((file) => {
            return file.id === this.idReferenceStore;
          });
          this.handleChoseReference(data[0]);
        } else if (this.idTestStore) {
          this.activeKey = "2";
          this.handleCheckStartTest(this.idTestStore);
        } else {
          if (this.currentIdLessonStart) {
            this.currentLessonIdActive = this.currentIdLessonStart;
            await this.fetchDetailLesson(this.currentIdLessonStart);
            if (this.isTypeVideo) {
              this.playVideo();
            }
          } else {
            this.activeKey = "2";
            this.handleCheckStartTest(this.tests[0].id);
          }
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDetailLesson(id) {
      const { data } = await DetailsCourseApi.getDetailLesson(id);
      this.currentDataLesson = data;
      this.currentType = data.file.type;
    },

    checkDoingTest() {
      if (this.isPlayingTest) {
        this.showModalNavigate({
          content: this.t("courseManagement.notification.textConfirmExit"),
          onOk: () => {
            this.confirmExitTest();
          },
          onCancel: () => {
            this.handleRejectExitTest(false);
          },
        });
        return true;
      }
    },

    renderRoman(number) {
      return romanzie(number);
    },

    async handleChoseLesson(data) {
      this.currentLessonIdActive = data.id;
      const isDoing = this.checkDoingTest();
      if (isDoing) {
        this.temp.lesson = data;
        this.currentTypeNavigate = "lesson";
        return;
      }
      this.currentIdTest = "";
      this.currentReferencesActive = "";
      await this.fetchDetailLesson(data.id);
      this.frameKey +=1
      console.log(this.frameKey)
      this.currentOrderLesson = data.order;
      if (this.videoTypes.includes(this.currentType)) {
        this.playVideo();
      }
    },

    handleChoseReference(data) {
      const isDoing = this.checkDoingTest();
      if (isDoing) {
        this.temp.reference = data;
        this.currentTypeNavigate = "reference";
        return;
      }
      this.currentIdTest = "";
      this.currentLessonIdActive = "";
      if (data) {
        this.currentReferencesActive = data.id;
      }
      this.currentDataLesson = { file: data };
      this.currentType = data.type;
    },

    async getResultTest(id) {
      this.showLoading();
      const { data: res } = await DetailsCourseApi.getResultTest(id);
      this.hideLoading();
      return res?.[0]?.user_tests;
    },
    async handleTestTimers(payload) {
      if (!payload?.numberOfTest || payload.isRedo) {
        this.showLoading();
        const res = await DetailsCourseApi.startTest(payload.id);
        this.infoTestQuestion = res?.data;
        this.isPlayingTest = true;
        this.hideLoading();
      } else {
        this.infoTestQuestion = {
          ...this.detailTest,
          isShowResult: true,
        };
        this.isPlayingTest = false;
      }
      this.numberOfTest = payload.numberOfTest;
      this.visibleTest = true;
    },

    async handleCheckStartTest(id) {
      this.testRequirementData = null;
      this.testQualified = 0;
      const isDoing = this.checkDoingTest();
      if (isDoing) {
        this.temp.test = id;
        this.currentTypeNavigate = "test";
        return;
      }
      this.currentLessonIdActive = "";
      this.currentReferencesActive = "";
      const { data } = await DetailsCourseApi.checkQualifyTest(id);
      this.detailTest = data;
      this.resultTest = await this.getResultTest(id);
      const testCount = this.resultTest.length;
      if (testCount) {
        this.handleTestTimers({
          id,
          numberOfTest: testCount,
          isRedo: false,
        });
      } else {
        this.visibleTest = false;
      }
      this.currentIdTest = id;
      this.testRequirementData = data.requirement;
      this.testQualified = data.check_requirement;
    },

    confirmExitTest() {
      this.isPlayingTest = false;
      switch (this.currentTypeNavigate) {
        case "lesson":
          this.handleChoseLesson(this.temp.lesson);
          break;
        case "reference":
          this.handleChoseReference(this.temp.reference);
          break;
        case "test":
          this.handleCheckStartTest(this.temp.test);
          break;
        default:
          break;
      }
      this.currentTypeNavigate = "";
      window.removeEventListener("beforeunload", () => {});
    },

    handleRejectExitTest() {
      this.temp.lesson = "";
    },

    handleRedoTest(payload) {
      this.handleTestTimers(payload);
    },

    handleContinueTest(test) {
      this.handleCheckStartTest(test.id);
    },
    renderFileSrc(path) {
      // return the file doc to show
      return `${this.driveLink}${path}`;
    },

    async beforeLearnCourse() {
      this.isLoading = true;
      this.courseId = this.$route.params.slug;
      const { data } = await DetailsCourseApi.requestStartCourse(this.courseId);
      this.currentIdLessonStart = data.data?.id;
      this.getDetailCourse(this.courseId);
    },

    playVideo() {
      requestAnimationFrame(() => {
        const videoPlay = this.$refs.videoPlayer;
        this.player = videojs(videoPlay, {
          autoplay: false,
          controls: true,
          preload: true,
          sources: [
            {
              type: "video/mp4",
              src: this.currentDataLesson?.file?.full_path,
            },
          ],
        });
        let apiCalled = false;
        let lessonId = this.currentDataLesson.id;
        let courseId = this.courseId;
        this.player.on("timeupdate", function () {
          if (this.duration() - this.currentTime() <= 15 && !apiCalled) {
            apiCalled = true;
            DetailsCourseApi.completeVideo({
              course_id: courseId,
              lesson_id: lessonId,
            });
          }
        });
      });
    },
  },

  watch: {
    currentType(value) {
      if (this.videoTypes.includes(value)) {
        this.isTypeVideo = true;
        this.isTypeDoc = false;
      } else if (this.docTypes.includes(value)) {
        this.isTypeVideo = false;
        this.isTypeDoc = true;
      }
    },
    currentDataLesson: {
      handler(value) {
        if (this.videoTypes.includes(value?.file?.type)) {
          this.player?.src({ type: "video/mp4", src: value.file.full_path });
          this.player?.load();
        }
      },
      deep: true,
    },
  },
  created() {
    this.beforeLearnCourse();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
});
</script>
<style scoped lang="scss">
.video-js {
  width: 100%;
}
.wrapper {
  background-color: white;
  height: 100%;
  .title-course {
    font-size: 24px;
    font-weight: bold;
    padding: 20px 15px 0px 15px;
    margin-bottom: 8px;
  }
}
.learning-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
  background-color: white;
  padding: 0px 15px 20px 15px;
  height: 100%;
  .test-content {
    flex: 1;
  }

  .playing-learning {
    flex: 1;

    .video-course {
      border: 1px solid #000;
    }
    .image-course {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .doc-course {
      width: 100%;
      height: calc(100% - 150px);
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .detail-course {
    width: 30%;
    .title {
      border: 1px solid #e7e7e7;
      margin-bottom: 3px;
      padding: 16px 8px;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .lesson {
    cursor: pointer;
    padding: 4px 0;
    .lesson-title {
      font-size: 16px;
      font-style: italic;
      color: #813ed3;
      margin-left: 16px;
    }
  }
  .active {
    font-weight: 700;
  }
  .ant-collapse {
    .content {
      .header-content {
        font-size: 18px;
        font-weight: bold;
        color: black;
        margin-bottom: 4px;
      }
      display: flex;
      flex-direction: column;
      .chapter-title {
        font-size: 18px;
        font-weight: bold;
        color: black;
      }
    }
    :deep(.ant-collapse-header) {
      font-size: 14px !important;
      font-weight: bold !important;
      .ant-collapse-arrow {
        vertical-align: 2px;
      }
    }
  }
}
</style>
