import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const CourseELearningApi = {
  async getAllCourse(params) {
    try {
      const response = await RequestApi.get(pathUrl.course,{params});
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCompulsoryCourse(params) {
    try {
      const response = await RequestApi.get(pathUrl.course, {
        params: {
          type: 'compulsory_course',
          ...params
        }
      });
      return response.data
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCategory(params) {
    try {
      const response = await RequestApi.get('elearning-category', { params });
      return response.data;
    } catch (error) {
      throw new Error(error)
    }
  },
};
