<template>
  <div class="admin-layout-wrapper">
    <MainHeader />
    <div class="body-wrapper">
      <Sidebar />
      <div class="content-wrapper">
        <router-view></router-view>
      </div>
      
    </div>
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import Sidebar from './components/Sidebar.vue';
import Footer from './components/Footer.vue'
export default {
  components: { MainHeader, Sidebar, Footer }
};
</script>

<style lang="scss" scoped>
.admin-layout-wrapper {
  height: 100%;
}

.body-wrapper {
  display: flex;
  gap: 17px;
  margin: 17px 12px 12px 0;
  height: calc(100vh - 89px);
}

.content-wrapper {
  box-shadow: 0px 4px 4px rgba(14, 13, 13, 0.25);
  flex: 1;
  border-radius: 5px;
  overflow-y: auto;
}
</style>
