import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const FeedbackListApi = {
  async getListFeedback(params) {
    try {
      const response = await RequestApi.get(pathUrl.feedback,{params});
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async handleFeedback(id){
    try {
      await RequestApi.put(pathUrl.feedback + '/' + id)
    } catch (error) {
      throw new Error(error)
    }
  },
  async getDetailFeedback(id){
    try {
      const response = await RequestApi.get(pathUrl.feedback + '/' + id)
      return response?.data
    } catch (error) {
      throw new Error(error)
    }
  }
};
