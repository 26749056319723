<template>
  <div class="animation-loading">
    <img class="loading-logo" src="../../../../public/images/logo-tab.png" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.animation-loading {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  z-index: 9999;
}
.loading-logo {
  animation: pulse 1s infinite ease-in-out alternate !important;
}
@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.2);
  }
}
</style>
