<template>
  <div class="mt-2">
    <a-table
      :columns="COLUMN_STATISTICS"
      :pagination="false"
      :scroll="scrollHeightTable"
      :data-source="dataTable"
      :loading="loadingTable"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'overage'">
          <span class="font-bold">{{ record.overage }}</span
          >/{{ record.questionCount }}<span></span>
        </template>
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <span>Rows per page:</span>
      <a-select default-value="5" v-model:value="params.limit">
        <i class="fas fa-sort-down dropdown-icon"></i>
        <a-select-option
          v-for="(pageSize, index) in PAGESIZE"
          :value="pageSize"
          :key="`pageSize_${index}`"
          @click="handleActions(ACTION.ChangeSize, null, pageSize)"
        >
          {{ pageSize }}
        </a-select-option>
      </a-select>
      <span class="total-page"
        >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
      >
      <a-button
        :disabled="meta.from === 1 || !meta.from"
        class="pagination-btn"
        @click="handleActions(ACTION.Prev)"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <a-button
        :disabled="meta.to === meta.total || !meta.total"
        class="pagination-btn"
        @click="handleActions(ACTION.Next)"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  computed,
  defineProps,
  watchEffect,
} from "vue";
import { useLoading } from "../../../../../utils/hook";
import { COLUMN_STATISTICS, ACTION, PAGESIZE } from "../../utils/constant";
import { statisticalApi } from "../../api/request";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { limitTextLength } from "../../../../../utils/helper";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  year: String,
  month: String,
});

const courseId = computed(() => store.state.courseManagement.course_id);

const params = reactive({
  course_id: courseId.value,
  month: "",
  year: "",
  limit: 5,
  page: 1,
});

watchEffect(() => {
  if (params.year !== props.year) {
    params.year = props.year;
    getListTestStatistics(params);
  }
  if (params.month !== props.month) {
    params.month = props.month;
    getListTestStatistics(params);
  }
});

const scrollHeightTable = reactive({
  y: "calc(100vh - 400px)",
});

const loadingTable = ref(false);
const { hideLoading, showLoading } = useLoading();

const meta = reactive({
  to: null,
  from: null,
  currentPage: 1,
  total: 20,
});

const dataTable = ref([]);

const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      params.page = params.page + 1;
      getListTestStatistics(params);
      break;
    case ACTION.Prev:
      params.page = params.page - 1;
      getListTestStatistics(params);
      break;
    case ACTION.ChangeSize:
      params.limit = payload;
      getListTestStatistics(params);
      break;
    default:
      break;
  }
};

const getListTestStatistics = async (payload) => {
  loadingTable.value = true;
  showLoading;
  try {
    const res = await statisticalApi.fetchListTestStatistics(payload);
    if (res.data.data) {
      dataTable.value = res.data.data.map((record, index) => {
        return {
          key: index,
          name: limitTextLength(record.name),
          time: record.time + " " + t("courseManagement.statisticalCol.minute"),
          questionCount: record.total_questions_limit,
          overage: record.medium_score,
        };
      });
    }
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } catch (error) {
  } finally {
    loadingTable.value = false;
    hideLoading;
  }
};

onMounted(() => {
  getListTestStatistics(params);
});
</script>
<style lang="scss" scoped>
:deep(.ant-table-thead) {
  .ant-table-cell {
    color: black;
    font-size: 18px;
    font-weight: 400;
    span {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}
:deep(.ant-table-cell) {
  color: black;
  font-size: 16px;
  font-weight: 400;
  span {
    font-size: inherit;
    font-weight: inherit;
  }
}
</style>