<template>
  <a-modal
    :title="
      currentId
        ? t('courseManagement.courseware.edit')
        : t('courseManagement.courseware.add')
    "
    :visible="visible"
    :style="{ maxWidth: '380px' }"
    :closable="true"
    footer=""
    centered
    @cancel="handleCancel"
  >
    <div class="">
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        layout="vertical"
        ref="form"
      >
        <a-form-item
          :label="t('courseManagement.courseware.nameCourseware')"
          name="nameCourseware"
          :rules="[
            {
              required: true,
              message: t('courseManagement.validate.nameCoursewareRequired'),
            },
            {
              max: 250,
              message:
                'Tên thể loại đã vượt quá ký tự cho phép (250 ký tự), xin hãy thay đổi tên thể loại',
            },
          ]"
          :label-col="{ span: 12 }"
          :wrapper-col="{ span: 24 }"
        >
          <a-input
            v-model:value="formState.nameCourseware"
            :placeholder="t('courseManagement.list.titlePlaceholderInput')"
          />
        </a-form-item>
        <a-form-item
          label="Thể loại học liệu"
          name="fileTypeId"
          :rules="[
            {
              required: true,
              message: t('courseManagement.validate.required'),
            },
          ]"
          :label-col="{ span: 12 }"
          :wrapper-col="{ span: 24 }"
        >
          <a-select
            v-model:value="formState.fileTypeId"
            placeholder="Please select a country"
            @change="handleChangeTypeUpload"
          >
            <template v-for="item in listType" :key="item.id">
              <a-select-option :value="item.id">{{
                item.name
              }}</a-select-option>
            </template>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-upload
            v-model:file-list="formState.file"
            name="file"
            :beforeUpload="beforeUpload"
            :customRequest="customRequestUpload"
            :max-count="1"
          >
            <a-button>
              <p class="flex items-center gap-1">
                <UploadOutlined />
                {{ t("courseManagement.common.mainDocument") }}
              </p>
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item
          :class="{ hiddenShow: !formState.file.length }"
          name="file"
          :rules="[
            {
              validator: handleValidateFile,
            },
          ]"
        >
          <div class="file-item" v-if="formState.file.length">
            <span class="file-name" @click="() => handleDownloadFile(path)">{{
              formState.file[0]?.name
            }}</span>
            <div class="file-actions" v-if="formState.file.length">
              <span
                class="file-loading min-w-[110px]"
                v-if="formState.uploadingStatus === 'loading'"
                ><LoadingOutlined /> Tải lên {{ formState.progress }} %</span
              >
              <span
                class="icon-success xy-flex-center"
                v-if="formState.uploadingStatus === 'success'"
              >
                <CheckCircleOutlined />
              </span>
              <span
                class="icon-error xy-flex-center"
                v-if="formState.uploadingStatus === 'error'"
              >
                <CloseCircleOutlined />
              </span>

              <span
                v-if="
                  formState.file.length &&
                  formState.uploadingStatus === 'success'
                "
                class="xy-flex-center cusor-pointer"
                @click="handleDeleteFile()"
              >
                <IconDelete />
              </span>
            </div>
          </div>
        </a-form-item>
        <a-form-item class="justify-end" :wrapper-col="{ span: 24 }">
          <div class="flex justify-end">
            <a-button class="btn-button-cancel mr-2 px-5" @click="handleCancel">
              {{ t("courseManagement.common.cancel") }}
            </a-button>
            <a-button
              key="submit"
              type="primary"
              html-type="submit"
              class="btn-button primary px-5"
            >
              {{
                currentId
                  ? t("courseManagement.common.update")
                  : t("courseManagement.step2.add")
              }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>
<script setup>
import {
  defineComponent,
  defineProps,
  reactive,
  onMounted,
  ref,
  watch,
  defineEmits,
} from "vue";
import { LinkOutlined, UploadOutlined } from "@ant-design/icons-vue";
import IconDelete from "../../../../components/icon/icon-delete.vue";
import { useI18n } from "vue-i18n";
import { SERVICE_URL_WIKI_BASE } from "../api/pathUrl";
import axios from "axios";
import { ELearningManagementApi } from "../api/request";
import { FILE_TYPE, DOCS_FILE, VIDEOS_FILE } from "../utils/constant";
import { checkTypeAndSizeUpload } from "../../../../utils/helper";
import { useNotify, useShowConfirm } from "../../../../utils/hook";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
const { t } = useI18n();
const { notifyError, notifySuccess } = useNotify();
const { showConfirm } = useShowConfirm();
const emit = defineEmits();
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  cancel: () => {},
  ok: Function,
  currentId: [String, Number],
  modeCreate: {
    type: Boolean,
    default: false,
  },
  isUsed: Boolean,
});
defineComponent({
  LinkOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  IconDelete,
});

const form = ref("null");
const listType = ref([
  {
    id: 1,
    name: "Video",
  },
  {
    id: 2,
    name: "Bài giảng",
  },
]);
const formState = reactive({
  nameCourseware: "",
  file: [],
  fileTypeId: 1,
  fileId: null,
  progress: null,
  uploadingStatus: null,
});

watch(
  () => props.currentId,
  async (newValue, oldValue) => {
    if (!newValue) {
      resetForm();
      return;
    } else {
      formState.fileId = props.currentId;
      fetchDetailCourseware(props.currentId);
    }
  }
);

const resetForm = () => {
  formState.nameCourseware = "";
  formState.file = [];
  formState.fileTypeId = 1;
  formState.fileId = null;
  (formState.progress = null), (formState.uploadingStatus = null);
};

const fetchDetailCourseware = async (id) => {
  const res = await ELearningManagementApi.getCourseware(id);
  const data = res.data;
  const file = { ...res.data, name: data.path };
  formState.nameCourseware = data.name;
  formState.fileTypeId = data.type_file_id;
  formState.uploadingStatus = "success";
  formState.file = [file];
};

const okModal = () => {
  resetForm();
  emit("ok");
};

const handleCancel = async () => {
  form.value.resetFields();
  if (!props.modeCreate) {
    resetForm();
    emit("cancel");
    return;
  }
  if (formState.fileId) {
    await handleDeleteFile();
    emit("cancel");
  }
  emit("cancel");
  resetForm();
};

const onFinish = async (values) => {
  const formData = new FormData();
  formData.append("name", formState.nameCourseware);
  formData.append("type_file_id", formState.fileTypeId);
  if (props.currentId) {
    if (props.isUsed) {
      showConfirm({
        type: "edit",
        onOk: async () => {
          try {
            await ELearningManagementApi.updateCourseware(
              formState.fileId,
              formData
            );
            notifySuccess(t("courseManagement.courseware.notiUpdate"));
            okModal();
          } catch (error) {
            if (error.status === 422) {
              notifyError(t("courseManagement.courseware.notiError"));
            }
          }
        },
        suffix: t('courseManagement.courseware.textUpdate'),
      });
    } else {
      showConfirm({
        type: "edit",
        onOk: async () => {
          try {
            await ELearningManagementApi.updateCourseware(
              formState.fileId,
              formData
            );
            notifySuccess(t("courseManagement.courseware.notiUpdate"));
            okModal();
          } catch (error) {
            if (error.status === 422) {
              notifyError(t("courseManagement.courseware.notiError"));
            }
          }
        },
        suffix: "học liệu",
      });
    }
  } else {
    try {
      await ELearningManagementApi.updateCourseware(formState.fileId, formData);
      notifySuccess(t("courseManagement.courseware.notiCreate"));
      okModal();
    } catch (error) {
      if (error.status === 422) {
        notifyError(t("courseManagement.courseware.notiError"));
      }
    }
  }
};

const customRequestUpload = async (options) => {
  if (formState.fileId) {
    await ELearningManagementApi.deleteCourseware(formState.fileId);
  }
  const { onSuccess, onError, file, onProgress } = options;
  const fmData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      formState.uploadingStatus = "loading";
      formState.progress = percent;
      if (percent === 100) {
        formState.uploadingStatus = "success";
      }
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };
  fmData.append("file", file);
  onSuccess();
  try {
    const res = await axios.post(
      SERVICE_URL_WIKI_BASE + "file/upload",
      fmData,
      config
    );
    formState.fileId = res.data.data.id;
    onSuccess();
  } catch (err) {
    onError();
  }
};

const onFinishFailed = (errorInfo) => {
};
const handleDeleteFile = async () => {
  if (!props.modeCreate) {
    formState.file = [];
    return;
  }
  if (formState.fileId) {
    try {
      await ELearningManagementApi.deleteCourseware(formState.fileId);
      formState.file = [];
      formState.fileId = "";
    } catch (error) {}
  }
};

const handleValidateFile = (rule, value, callback, path) => {
  if (!value.length) {
    return Promise.reject("Không được để trống");
  }
  return Promise.resolve();
};

const handleChangeTypeUpload = (type) => {
  formState.file = [];
  formState.fileUpload = "";
  formState.fileTypeId = type;
};
const beforeUpload = (file) => {
  if (formState.fileTypeId === 1) {
    return checkTypeAndSizeUpload(file, VIDEOS_FILE);
  } else {
    return checkTypeAndSizeUpload(file, DOCS_FILE);
  }
};
</script>
<style lang="scss" scoped>
:deep(.ant-upload-list-item-name) {
  word-break: break-word;
  line-height: calc(26 / 16);
}
:deep(.ant-upload-list) {
  display: none;
}
:deep(.ant-form-item-required) {
  font-size: 16px;
  font-weight: bold;
}
:deep(.ant-select-selection-item) {
  font-size: 12px;
  font-weight: bold;
}
:deep(.ant-modal) {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: green;
      }
    }
  }
}

.file-item {
  display: flex;
  align-items: center;
  gap: 20px;

  .file-name {
    color: #000;
    max-width: 265px;
    font-size: 14px;
    font-weight: bold;
  }
  .file-name:hover {
    cursor: pointer;
  }

  .file-actions {
    display: flex;
    gap: 20px;
  }
  .icon-error {
    :deep(svg) {
      fill: red;
    }
  }

  .icon-success {
    :deep(svg) {
      fill: green;
    }
  }
}
.hiddenShow {
  margin-top: -30px;
}
</style>