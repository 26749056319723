<template>
  <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.875.75c1.016 0 1.875.86 1.875 1.875v3.75c0 1.055-.86 1.875-1.875 1.875h-3.75A1.851 1.851 0 01.25 6.375v-3.75C.25 1.609 1.07.75 2.125.75h3.75zm-.625 2.5h-2.5v2.5h2.5v-2.5zm.625 7.5c1.016 0 1.875.86 1.875 1.875v3.75c0 1.055-.86 1.875-1.875 1.875h-3.75A1.851 1.851 0 01.25 16.375v-3.75c0-1.016.82-1.875 1.875-1.875h3.75zm-.625 2.5h-2.5v2.5h2.5v-2.5zm5-10.625c0-1.016.82-1.875 1.875-1.875h3.75c1.016 0 1.875.86 1.875 1.875v3.75c0 1.055-.86 1.875-1.875 1.875h-3.75a1.851 1.851 0 01-1.875-1.875v-3.75zm2.5 3.125h2.5v-2.5h-2.5v2.5zM14 17h1.25v1.25H14V17zm3.75 1.25H16.5V17h1.25v1.25zm-1.25-7.5h1.25v5H14V14.5h-1.25v3.75h-2.5v-7.5H14V12h2.5v-1.25z"
      fill="#0B68A2"
    />
  </svg>
</template>
<script>
export default {};
</script>
