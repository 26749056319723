<template>
  <div class="main-layout-wrapper">
    <MainHeader />
    <button class="btn-to-top" @click="handleScrollToTop" v-if="showToTop">
      <UpOutlined />
    </button>
    <div class="layout-content" ref="contentElement">
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import { UpOutlined } from '@ant-design/icons-vue';
import Footer from './components/Footer.vue'
export default {
  components: { MainHeader, UpOutlined, Footer },
  data() {
    return {
      showToTop: false
    };
  },
  methods: {
    handleLogout() {},
    handleScrollToTop() {
      this.$refs.contentElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  mounted() {
    const handleScroll = (e) => {
      if (e.target.scrollTop > 400) {
        this.showToTop = true;
        return;
      }

      this.showToTop = false;
    };
    if (!this.$refs.contentElement) return;
    this.$refs.contentElement.addEventListener('scroll', handleScroll);
  },

  unmounted() {
    if (!this.$refs.contentElement) return;
    this.$refs.contentElement.removeEventListener('scroll');
  }
};
</script>

<style scoped lang="scss">
.main-layout-wrapper {
  height: 100%;
  .layout-content {
    height: calc(100vh - 98px);
    margin: 17px;
    overflow-y: auto;
    position: relative;
  }
  .btn-to-top {
    position: fixed;
    right: 50px;
    bottom: 50px;
    border-radius: 9999px;
    height: 40px;
    width: 40px;
    border: none;
    background-color: #1280bf;
    opacity: 0.5;
    color: rgb(211, 211, 211);
    font-size: 16px;
    transition: all ease 0.3s;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
