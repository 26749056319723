<template>
  <div class="admin-layout-wrapper">
    <MainHeader />
    <div class="body-wrapper">
      <Sidebar />
      <div class="content-wrapper">
        <router-view></router-view>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/SidebarHome.vue";
import Footer from './components/Footer.vue'

export default {
  components: { MainHeader, Sidebar, Footer },
};
</script>

<style lang="scss" scoped>
.admin-layout-wrapper {
  height: 100%;
  background: #fff;
}

.body-wrapper {
  display: flex;
  height: calc(100vh - 87px);
  margin-top: 24px;
}

.content-wrapper {
  flex: 1;
  border-radius: 5px;
  overflow-y: auto;
  background: #fff;
}
</style>
