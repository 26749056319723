<template>
  <div class="list-item">
    <a-row :gutter="15">
      <a-col v-for="item in testItems" :key="item.key" :span="8" class="mb-15">
        <div class="test-items">
          <div class="content-items flex-center">
            <div class="flex-center">
              <img src="/images/test.png" width="49" height="64" alt="test" />
            </div>
            <p class="test-name">{{ item.name }}</p>
            <div :class="{ 'flex-between': true, 'flex-center': isViewCourse }">
              <button
                class="btn btn-edit"
                @click="
                  () => {
                    getTestDetail(item.key, item.id);
                  }
                "
              >
                {{
                  isViewCourse
                    ? t("courseManagement.step3.view")
                    : t("courseManagement.step3.edit")
                }}
              </button>
              <a-popconfirm
                v-if="!isViewCourse"
                :title="t('courseManagement.step3.confirmDelete')"
                :ok-text="t('courseManagement.step3.ok')"
                :cancel-text="t('courseManagement.step3.cancel')"
                @confirm="() => deleteTest(item.id)"
                placement="topRight"
              >
                <button class="btn btn-delete">
                  {{ t("courseManagement.step3.delete") }}
                </button>
              </a-popconfirm>
            </div>
          </div>
        </div>
      </a-col>
      <a-col v-if="!isViewCourse" :span="8">
        <div class="test-items">
          <button class="content-items flex-center" @click="showModal">
            <span class="icon-add">
              <IconAdd />
            </span>
            <p class="test-name">
              {{ t("courseManagement.step3.create") }}
            </p>
          </button>
        </div>
      </a-col>
    </a-row>

    <div v-if="!isViewCourse" class="btn-wrapper">
      <a-button class="mr-3" v-if="modeCreate" style="width: 131px" @click="handleBackStep">Trở lại</a-button>
      <div>
        <!-- <a-button
          :disabled="
            courseStatus === STEP_NAME[STEP_NAME.length - 1] ||
            !testItems.length
          "
          style="
            width: 131px;
            color: #1280bf;
            border: none;
            background-color: white;
          "
          @click="handleSaveDraft"
          >Lưu nháp</a-button
        > -->
        <a-button
          style="width: 131px"
          :disabled="!testItems.length"
          type="primary"
          @click="handleClickCompleteStep3"
          >{{
             modeCreate
              ? t("courseManagement.step3.addCourse")
              : t("courseManagement.step3.updateCourse")
          }}</a-button
        >
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    width="994px"
    :title="formTitle"
    :footer="null"
    :closable="false"
    :maskClosable="false"
  >
    <TestForm
      v-if="visible"
      :testItems="testItems"
      @onFinish="onFinish"
      @onClose="hideModal"
      :testDetail="testDetail"
      :totalQuestion="totalQuestion"
      :lastPage="lastPage"
      @scrollTable="onScrollTable"
    />
  </a-modal>
</template>
<script setup>
import { message } from "ant-design-vue";
import {
computed,
defineComponent,
defineEmits,
h,
onMounted,
reactive,
ref
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import IconAdd from '../../../../components/icon/icon-add.vue';
import { useLoading, useNotify, useShowConfirm } from '../../../../utils/hook';
import { mapDataSubmitStep3, mapDataTestDetailStep3, mapDataTestQuestionStep3 } from '.././utils/helpers';
import { step3ManagementApi } from '../api/request';
import TestForm from './TestForm.vue';

defineComponent({
  components: {
    IconAdd,
    TestForm,
  },
  props: {
    getListCourse: Function,
  },
});
const { t } = useI18n();
const emit = defineEmits();
const testDetail = ref(null);
const testDetailId = ref(null);
const visible = ref(false);
const testItems = ref([]);
const formTitle = ref(t("courseManagement.step3.createTitle"));
const { notifySuccess, notifyError } = useNotify();
const { showConfirm } = useShowConfirm();
const { showLoading, hideLoading } = useLoading();
const store = useStore();
const isEditCourse = computed(() => store.state.courseManagement.edit_course);
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const courseId = computed(() => store.state.courseManagement.course_id);
const courseStatus = computed(() => store.state.courseManagement.courseStatus);
const modeCreate = computed(() => store.state.courseManagement.modeCreate);
const limit = ref(10);
const lastPage = ref(1);
const totalQuestion = ref(0)

const paramsGetListTest = reactive({
  course_id: courseId.value,
  limit: null,
  page: 1,
});

/**
 * Hàm hiển thị modal khi click tạo bài kiểm tra
 */
const showModal = () => {
  formTitle.value = t("courseManagement.step3.createTitle");
  visible.value = true;
  testDetailId.value = null;
  testDetail.value = null;
};

/**
 * Hàm ẩn modal khi click nút hủy
 */
const hideModal = () => {
  if (!isViewCourse.value) {
    const content = h("div", [
      h("p", `Bạn đang hủy tạo mới bài kiểm tra`),
      h("p", "Bạn có muốn thực hiện thao tác này?"),
    ]);
    showConfirm({
      type: "edit",
      contentBody: content,
      onOk: () => {
        visible.value = false;
        testDetail.value = null;
      },
    });
    return;
  }
  visible.value = false;
  testDetail.value = null;
};

/**
 * Hàm xử lý dữ liệu (thêm mới hoặc sửa) bài kiểm tra
 * @param {Record<K, T>} test - Nội dung của bài kiểm tra
 * @param {string} testKey - Key của bài kiểm tra muốn cập nhật
 */
const onFinish = (test, testKey) => {
  const dataSubmit = {
    ...mapDataSubmitStep3(test),
    course_id: courseId.value,
    action: 1,
  };
  if (testDetail.value) {
    showConfirm({
      type: "edit",
      suffix: "bài kiểm tra",
      onOk: () => {
        updateStep3(dataSubmit, testDetailId.value);
      },
    });
    return;
  }
  const content = h("div", [
    h("p", `Bạn đang tạo mới bài kiểm tra`),
    h("p", "Bạn có muốn tạo mới bài kiểm tra này?"),
  ]);
  showConfirm({
    type: "edit",
    contentBody: content,
    onOk: () => {
      addStep3(dataSubmit);
    },
  });
};


/**
 * Hàm xóa bài kiểm tra sau khi confirm xóa
 * @param {string} id - Key của bài kiểm tra muốn xóa
 */
const deleteTest = (id) => {
  deleteTestItem(id);
};

/**
 * Hàm mở popup chỉnh sửa bài kiểm tra
 * @param {string} testKey - Key bài kiểm tra muốn chỉnh sửa
 */
// const showEditModal = async (testKey, id) => {
//   const test = await getTestDetail(id);
// };

const handleBackStep = () => {
  const backList = () => {
    store.dispatch("doneStep", 3);
    store.dispatch("doingStep", 2);
    store.dispatch("updateEditStatus", true);
  };

  if (isEditCourse.value) {
    const content = h("div", [
      h("p", `Mọi chỉnh sửa sẽ không được lưu`),
      h("p", "Bạn có chắc chắn muốn thoát?"),
    ]);
    showConfirm({
      type: "edit",
      contentBody: content,
      onOk: () => {
        backList();
      },
    });
    return;
  }

  backList();
};

const handleSaveDraft = () => {
  store.dispatch("doneStep", 3);
  store.dispatch("updateEditStatus", false);
  notifySuccess("Lưu nháp bài kiểm tra thành công");
  emit("getListCourse");
};

const handleClickCompleteStep3 = (id) => {
  if (isEditCourse.value && !modeCreate.value) {
    showConfirm({
      type: "edit",
      suffix: "khóa học",
      onOk: () => {
        completeStep3(courseId.value);
      },
    });
    return;
  }
  completeStep3(courseId.value);
};

const addStep3 = async (payload) => {
  showLoading();
  try {
    await step3ManagementApi.addStep3(payload);
    notifySuccess("Tạo bài kiểm tra thành công");
    visible.value = false;
    getListTest(paramsGetListTest);
  } catch (error) {
    if(error.error.status === 422){
      notifyError(t("courseManagement.step3.validate.duplicateTest"));
    }
    else{
      notifyError(t("courseManagement.step3.validate.createTestFail"));
    }
  } finally {
    hideLoading();
  }
};

const updateStep3 = async (payload, id) => {
  showLoading();
  try {
    await step3ManagementApi.updateStep3(payload, id);
    notifySuccess("Cập nhật bài kiểm tra thành công");
    visible.value = false;
    getListTest(paramsGetListTest);
  } catch (error) {
     if(error.error.status === 422){
      notifyError(t("courseManagement.step3.validate.duplicateTest"));
    }
    else{
      notifyError(t("courseManagement.step3.validate.updateTestFail"));
    }
  } finally {
    hideLoading();
  }
};

const getListTest = async (params) => {
  showLoading();
  try {
    const res = await step3ManagementApi.getListTest(params);

    testItems.value = res.data;
  } catch (error) {
  } finally {
    hideLoading();
  }
};

const getTestDetail = async (testKey, id) => {
  showLoading();
  try {
    const params = {
      page: '1',
      limit: limit.value
    };
    const [resQuestions, res] = await Promise.all([
      step3ManagementApi.getTestDetail(id, params),
      step3ManagementApi.getInfoTestDetail(id),
    ]);
    if (res.data) {
      formTitle.value = t("courseManagement.step3.editTitle");
      testDetail.value = mapDataTestDetailStep3(res.data);
      testDetail.value.questions = mapDataTestQuestionStep3(resQuestions.data);
      testDetailId.value = id;
      visible.value = true;
      lastPage.value = resQuestions?.paginate?.last_page
      totalQuestion.value = resQuestions?.paginate?.total
    } else {
      message.error(t("courseManagement.step3.validate").notFoundTest);
    }
  } catch (error) {
  } finally {
    hideLoading();
  }
};

const deleteTestItem = async (id) => {
  showLoading();
  try {
    const res = await step3ManagementApi.deleteTest(id);
    notifySuccess("Xóa bài viết thành công");
    getListTest(paramsGetListTest);
  } catch (error) {
    notifyError("Xóa bài viết thất bại");
  } finally {
    hideLoading();
  }
};

const completeStep3 = async (id) => {
  showLoading();
  try {
    const res = await step3ManagementApi.completeStep3(id);
    if (modeCreate.value) {
      notifySuccess('Tạo khóa học thành công');
    } else {
      notifySuccess("Cập nhật khóa học thành công");
    }
    if(isEditCourse.value && !modeCreate.value){
      return
    }
    if(isEditCourse.value && !modeCreate.value){
      return
    }
    if(isEditCourse.value && !modeCreate.value){
      return
    }
    store.dispatch('backListCourse');
    emit('getListCourse');
  } catch (error) {
  } finally {
    hideLoading();
  }
};

onMounted(() => {
  getListTest(paramsGetListTest);
});
</script>

<style scoped lang="scss">
.list {
  padding: 0 20px;
}
.icon-add {
  font-size: 37px;
  color: #1280bf;
}
.list-item {
  height: calc(100vh - 220px);
  overflow-y: auto;
  margin-right: -10px;
  padding: 15px;
}
.content-items {
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  min-height: 280px;
  flex-direction: column;
  min-width: 245px;
}
.mb-15 {
  margin-bottom: 15px;
}
.test-name {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 14px;
  padding-bottom: 39px;
  margin-bottom: 0;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
}

.flex-center {
  justify-content: center;
}
.btn {
  height: 32px;
  line-height: 32px;
  color: #fff;
  border-radius: 16px;
  min-width: 109px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  padding: 0 20px;
}
.btn-delete {
  background: #e41d1d;
}
.btn-edit {
  background: #1280bf;
}
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  div {
    button + button {
      margin-left: 20px;
    }
  }
}
</style>
