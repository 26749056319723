<template>
  <div class="condition-content">
  
    <div class="content">
      <div class="name-lesson">
        {{ detailTest.name }}
      </div>
      <div class="test-info">
        <div>
          {{ texts.time }}:<span>{{ timeCalculate(detailTest.time) }}</span>
        </div>
        <i class="line" />
        <div>
          {{ texts.questionCount }}:<span>{{
            detailTest?.limit_questions? detailTest.limit_questions : 0
          }}</span>
        </div>
        <i></i>
        <div>
          {{ texts.numberMockTest }}:<span>{{
            detailTest.number_mock_test === 0
              ? texts.noLimit
              : detailTest.number_mock_test
          }}</span>
        </div>
      </div>
      <div class="test-introduce">
        <div class="content-description">
          {{ texts.introduce }}:
          <article class="description" v-html="detailTest.description" />
        </div>
        <div>
          {{ texts.condition }}:
          <span v-if="!state.check_requirement">{{ texts.noCondition }}</span>
          <span v-else>
            {{ texts.textRequirement }} {{ textStringRequirement }}
            <span v-if="!props.testQualified">(Đã hoàn thành)</span>
          </span>
        </div>
        <div>
          <span
            class="test-requirement"
            :class="{ red: props.testQualified || isLimitTest }"
            >{{
              props.testQualified || isLimitTest
                ? texts.noEligible
                : texts.eligible
            }}</span
          >
        </div>
      </div>
      <div class="condition-information"></div>
      <button
        class="button"
        :disabled="props.testQualified || isLimitTest"
        @click="handleClick"
      >
        {{ texts.btnStart }}
      </button>
    </div>
  </div>
</template>
<script setup>
import {
computed,
defineEmits,
defineProps,
reactive,
watchEffect
} from "vue";
import { useLoading } from '../../utils/hook';
import { DetailsCourseApi } from "./api/request";
import { texts } from "./utils/texts";

const{hideLoading, showLoading} = useLoading()
const emit = defineEmits();

const props = defineProps({
  detailTest: Object,
  requirement: Object,
  testQualified: Number,
});

const state = reactive({
  testCount: "",
  check_requirement: null,
});

const handleClick = () => {
  emit("handleEmit", {
    id: props.detailTest.id,
    numberOfTest: state.testCount,
    isRedo: false
  });
};

const timeCalculate = (time) => {
  const minute = time % 60;
  const hour = Math.floor(time / 60);
  return `${hour} ${texts.hour} ${minute && `${minute} ${texts.minute}`}`;
};

const getResultTest = async (id) => {
  showLoading()
  const { data: res } = await DetailsCourseApi.getResultTest(id);
  state.testCount = res[0]?.user_tests.length;
  hideLoading()
};

const isLimitTest = computed(() => {
  if (props.detailTest.number_mock_test === 0) {
    return false;
  }
  return state.testCount >= props.detailTest?.number_mock_test;
});

const textStringRequirement = computed(() => {
  return state.check_requirement
    .map((requirement) => requirement.name)
    .join("và");
});

watchEffect(() => {
  if (props.detailTest?.id) {
    getResultTest(props.detailTest.id);
  }
});

watchEffect(() => {
  if (props.requirement) {
    state.check_requirement = props.requirement.tests;
  } else {
    state.check_requirement = null;
  }
});
</script>

<style lang="scss" scoped>
.condition-content {
  flex: 1;
  .content {
    position: relative;
    min-height: 545px;
    border: 1px solid #000;
    height: 70%;
    padding: 8px 24px;
    min-height: 500px;
    .name-lesson {
      font-size: 22px;
      font-weight: bold;
    }
    .test-info {
      display: flex;
      margin-left: -33px;
      margin-top: 10px;
      i {
        width: 1px;
        background-color: #000;
        height: 20px;
      }
      div {
        padding: 0 35px;
        font-size: 12px;
        font-weight: bold;
        span {
          font-weight: normal;
          margin-left: 4px;
        }
      }
    }
    .test-introduce {
      font-size: 14px;
      margin-top: 18px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      gap: 16px;
      span {
        display: inline;
        font-weight: normal;
      }
      .content-description {
        display: flex;
        gap: 3px;
        .description {
          display: inline;
          font-weight: normal;
          p {
            margin: 0;
            display: inline-block;
          }
        }
      }
    }
    .test-requirement {
      font-weight: bold !important;
      color: #62de00;
    }
    .red {
      color: #e41d1d;
    }
    .condition-information {
      margin-top: 16px;
      font-size: 14px;
      font-weight: bold;
    }
    .button {
      outline: none;
      border: none;
      position: absolute;
      right: 18px;
      bottom: 18px;
      background-color: #17469e;
      color: white;
      padding: 10px 10px;
      border-radius: 5px;
      font-weight: bold;
    }
    button[disabled] {
      cursor: not-allowed;
      background-color: #ccc;
      color: #999;
    }
  }
}
</style>