export const ONLY_PDF = 'pdf';
export const DATE_FORMAT = "DD/MM/YYYY"
export const TYPE_SOURCE = {
  VIDE0_TYPE: ['mp4', 'mov'],
  DOC_TYPE: ['pdf', 'doc', 'docx', 'ppt', 'pptx'],
  IMAGE_TYPE: ['jpg', 'png', 'svg']
};
export const TYPE_MODAL = {
  CONFIRM_TEST: 'modalConfirmTest',
  CONFIRM_TEST_WARNING: 'confirmTest',
  CONFIRM_TEST_TIMEOUT: 'modalConfirmTimeOut'
};
export const TYPE_ACTION ={
  LESSON:'lesson',
  REFERENCE: 'reference',
  TEST: 'test'
}
export const DRIVE_LINK = 'https://view.officeapps.live.com/op/view.aspx?src='

// https://view.officeapps.live.com/op/view.aspx?src=https://wiki.mor-software.com/elearning/-Vd2JeJHTvarUxO4FKm9t2SpP01fjXM750zsZJX5X.docx