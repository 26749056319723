<template>
  <div class="courses-management-wrapper">
    <div class="course-inner" v-if="showStep === 0">
      <div class="header-title">
        <h3>{{ t("courseManagement.list.headerTitle") }}</h3>
      </div>
      <div class="filter-wrapper mt-4">
        <a-form :model="formState" name="'filter'">
          <a-row :gutter="22" style="width: 100%">
            <a-col :span="4" class="name-input">
              <a-form-item label="" name="keyword">
                <a-input-search
                  :placeholder="t('courseManagement.list.titlePlaceholder')"
                  @search="handleFilter"
                  v-model:value="formState.keyword"
                >
                  <template #prefix>
                    <IconSearch />
                  </template>
                  <template
                    #enterButton
                    class="search-btn"
                    @click="handleFilter"
                  >
                    <a-button type="text">{{
                      t("courseManagement.list.search")
                    }}</a-button>
                  </template>
                </a-input-search>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item label="" name="position_id">
                <div class="relative">
                  <p class="absolute -top-6 font-base font-bold">{{t('courseManagement.list.positionApply')}}</p>
                  <a-select
                    :placeholder="t('courseManagement.list.positionPlaceholder')"
                    allowClear
                    showSearch
                    optionFilterProp="filterProps"
                    v-model:value="formState.position_id"
                    @change="handleFilter"
                  >
                    <a-select-option
                      v-for="position in positions"
                      :value="position.id"
                      :key="position.id"
                      :filterProps="position.name"
                    >
                      {{ position.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item label="" name="creator_id">
                <div class="relative">
                  <p class="absolute -top-6 font-base font-bold">{{t('courseManagement.list.creator')}}</p>
                  <a-select
                  :placeholder="t('courseManagement.list.creator')"
                  allowClear
                  showSearch
                  optionFilterProp="filterProp"
                  v-model:value="formState.creator_id"
                  @change="handleFilter"
                >
                  <a-select-option
                    v-for="author in authors"
                    :value="author.id"
                    :key="author.id"
                    :filterProp="author.name"
                  >
                    {{ author.name }}
                  </a-select-option>
                </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item>
               <div class="relative">
                <p class="absolute -top-6 font-base font-bold">{{t('courseManagement.list.createdAt')}}</p>
                <a-range-picker
                  v-model:value="formState.date"
                  size="large"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  @change="handleChangeDate"
                  :allowEmpty="[true, true]"
                  :placeholder="[t('courseManagement.statisticalCol.startDate'), t('courseManagement.statisticalCol.endDate')]"
                />
               </div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <div class="btn-wrapper gap-2">
                <a-button
                  type="primary"
                  @click="handleActions(TypeActions.Create)"
                >
                  {{ t("courseManagement.list.add") }}
                </a-button>
                <a-button
                  :class="{ btnDisable: listSelect.length }"
                  :type="listSelect.length ? 'primary' : 'secondary'"
                  :disabled="!listSelect.length"
                  @click="handleExport"
                >
                  {{ t("courseManagement.list.export") }}
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-container">
        <a-table
          :columns="COLUMNS"
          :data-source="dataTable"
          :pagination="false"
          @change="handleTableChange"
          :loading="{ indicator, spinning: loadingTable }"
          :scroll="scrollHeightTable"
          :customRow="handleCustomRow"
          :row-selection="{
            selectedRowKeys: state.selectedRowKeys,
            onChange: onSelectChange,
            onSelect: onSelectRow,
            onSelectAll: onSelectAllRow,
          }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <div class="actions-wrapper">
                <a-tooltip title="Chỉnh sửa khóa học">
                  <span
                    @click="
                      (e) =>
                        handleActions(TypeActions.Edit, record.id, e, record)
                    "
                  >
                    <IconEdit />
                  </span>
                </a-tooltip>

                <a-tooltip title="Xóa khóa học">
                  <span
                    @click.stop="
                      (e) => handleActions(TypeActions.Delete, record.id, e)
                    "
                  >
                    <IconDelete />
                  </span>
                </a-tooltip>
              </div>
            </template>
            <template v-else-if="column.key === 'positions'">
              <a-tooltip :title="record.positions">
                <div>
                  {{ truncateText(record.positions, 40) }}
                </div>
              </a-tooltip>
            </template>
            <template v-else-if="column.key === 'learning_categories'">
              <a-tooltip :title="record.learning_categories">
                <div>
                  {{ truncateText(record.learning_categories) }}
                </div>
              </a-tooltip>
            </template>
            <template v-else-if="column.key === 'title'">
              <div class="title-item">
                <a-tooltip :title="record.title"
                  ><span>{{ truncateText(record.title) }}</span></a-tooltip
                >
                <b v-if="isDraff(record.status)">(Bản nháp)</b>
              </div>
            </template>
          </template>
        </a-table>
      </div>

      <div class="pagination-wrapper">
        <span>Rows per page:</span>
        <a-select
          default-value="10"
          @change="handleChangePageSize"
          v-model:value="paramsFetchListCourse.limit"
        >
          <i slot="suffixIcon" class="fas fa-sort-down dropdown-icon"></i>
          <a-select-option
            v-for="(limit, index) in pageSizeList"
            :value="limit"
            :key="`pageSize_${index}`"
          >
            {{ limit }}
          </a-select-option>
        </a-select>
        <span v-if="showLocationPage" class="total-page"
          >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
        >
        <a-button
          class="pagination-btn"
          @click="handleActions(TypeActions.Prev)"
          :disabled="disabledPrev"
        >
          <i class="fas fa-chevron-left icon-btn"></i>
        </a-button>
        <a-button
          class="pagination-btn"
          @click="handleActions(TypeActions.Next)"
          :disabled="disabledNext"
        >
          <i class="fas fa-chevron-right icon-btn"></i>
        </a-button>
      </div>
    </div>
    <CourseLayout
      v-else-if="showStep !== 0 && !isEditCourse && !modeCreate"
      @getListCourse="handleGetListCourse"
      :title="t('courseManagement.common.detailCourse')"
    >
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="t('courseManagement.tabs.statistical')">
          <StatisticalCourse />
        </a-tab-pane>
        <a-tab-pane
          key="2"
          :tab="t('courseManagement.tabs.detailCourse')"
          force-render
        >
          <StepLayout :title="titleStep">
            <Step1 @getListCourse="handleGetListCourse" v-if="showStep === 1" />
            <Step2 v-if="showStep === 2" />
            <Step3 @getListCourse="handleGetListCourse" v-if="showStep === 3" />
          </StepLayout>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="t('courseManagement.tabs.changeHistory')">
          <CourseHistory />
        </a-tab-pane>
      </a-tabs>
    </CourseLayout>
    <StepLayout
      v-if="isEditCourse || modeCreate"
      :title="titleStep"
      @getListCourse="handleGetListCourse"
    >
      <Step1 @getListCourse="handleGetListCourse" v-if="showStep === 1" />
      <Step2 v-if="showStep === 2" />
      <Step3 @getListCourse="handleGetListCourse" v-if="showStep === 3" />
    </StepLayout>
  </div>
</template>

<script setup>
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  h,
} from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import IconDelete from "../../../components/icon/icon-delete.vue";
import IconEdit from "../../../components/icon/icon-edit.vue";
import IconNext from "../../../components/icon/icon-next.vue";
import IconPrev from "../../../components/icon/icon-prev.vue";
import IconSearch from "../../../components/icon/icon-search.vue";
import {
  default as LoadingFull,
  default as LoadingSpinner,
} from "../../../components/loading/Loading.vue";
import ModalConfirm from "../../../components/modal/ModalConfirm.vue";
import CourseLayout from "../course/components/CourseLayout.vue";
import StatisticalCourse from "./components/statistial/StatisticalCourse.vue";
import CourseHistory from "./components/statistial/CourseHistory.vue";
import { truncateText } from "../../../utils/helper";
import { useLoading, useNotify, useShowConfirm } from "../../../utils/hook";
import { courseMangemenetApi } from "./api/request";
import Step from "./components/Step.vue";
import Step1 from "./components/Step1.vue";
import Step2 from "./components/Step2.vue";
import Step3 from "./components/Step3.vue";
import StepLayout from "./components/StepLayout.vue";
import {
  COLUMNS,
  CREATE_COURSE_STATUS,
  STEP_NAME,
  DATE_FORMAT,
} from "./utils/constant";
import { TypeActions } from "./utils/interface";
import { useI18n } from "vue-i18n";
import { SERVICE_URL_WIKI_BASE } from "./api/pathUrl";
import axios from "axios";
import { truncate } from "../../../utils/helper";
import moment from "moment";
import union from "lodash/union";

defineComponent({
  components: {
    Step2,
    StepLayout,
    Step,
    Step1,
    IconDelete,
    IconEdit,
    IconNext,
    IconPrev,
    IconSearch,
    LoadingFull,
    LoadingSpinner,
    ModalConfirm,
    Step3,
    LoadingOutlined,
    CourseLayout,
    StatisticalCourse,
    CourseHistory,
  },
});

const { t } = useI18n();
const store = useStore();
const dataTable = ref([]);
const positions = ref([]);
const authors = ref([]);
const loadingTable = ref(false);
const showLocationPage = ref(false);
const shinkHeader = ref(false);
const disabledNext = ref(false);
const disabledPrev = ref(false);
const pageSizeList = [10, 20, 25, 50, 100];
const activeKey = ref("1");
const isSelectAll = ref(false);
const isSelectOneRow = ref(false);
const currentIdSelect = ref(null);
const listSelect = ref([]);
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
  },
});
const isEditCourse = computed(() => store.state.courseManagement.edit_course);
const modeCreate = computed(() => store.state.courseManagement.modeCreate);

const paramsFetchListCourse = reactive({
  limit: 10,
  keyword: undefined,
  position_id: undefined,
  creator_id: undefined,
  page: 1,
  start_date: null,
  end_date: null,
});
const meta = reactive({
  total: null,
  from: 1,
  to: null,
  currentPage: 1,
});
const scrollHeightTable = reactive({
  y: "calc(100vh - 400px)",
});
const formState = reactive({
  keyword: "",
  position_id: undefined,
  creator_id: undefined,
  date: null,
});
const { notifyError, notifySuccess } = useNotify();
const { showConfirm } = useShowConfirm();
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const handleLeavePage = (e) => {
  if (isEditCourse.value || modeCreate.value) {
    e.returnValue = "You have some unsaved changes";
  }
};

onMounted(() => {
  window.addEventListener("resize", onWidthChange);
  window.addEventListener("beforeunload", handleLeavePage);
  onWidthChange();
  fetchListCourse(paramsFetchListCourse);
  fetchListPosition({ target: "all" });
  fetchListAuthor({ roles: [1, 14] });
});

onBeforeUnmount(() => {
  store.dispatch("backListCourse");
  window.removeEventListener("resize", onWidthChange);
  window.removeEventListener("beforeunload", handleLeavePage);
});

const handleGetListCourse = () => {
  paramsFetchListCourse.limit = 10
  formState.keyword = null
  formState.position_id = null
  formState.creator_id = null
  formState.date = null
  paramsFetchListCourse.page = 1
  paramsFetchListCourse.start_date = null
  paramsFetchListCourse.end_date = null
  fetchListCourse(paramsFetchListCourse);
};

const state = reactive({
  selectedRowKeys: [],
  // Check here to configure the default column
  loading: false,
});


const onSelectChange = (selectedRowKeys) => {
  if (isSelectAll.value) {
    if (!listSelect.value.length) {
      listSelect.value = selectedRowKeys;
    } else {
      listSelect.value = union(listSelect.value, selectedRowKeys)
    }
  } else if (isSelectOneRow.value) {
    const isDuplicate = listSelect.value.includes(currentIdSelect.value);
    if (isDuplicate) {
      listSelect.value.splice(listSelect.value.indexOf(currentIdSelect.value), 1);
    } else {
      listSelect.value.push(currentIdSelect.value);
    }
  } 
  // destroy all listSelect.value
  else {
    dataTable.value.forEach(element => {
      if(listSelect.value.includes(element.id)){
        listSelect.value.splice(listSelect.value.indexOf(element.id), 1)
      }
    });
  }
  state.selectedRowKeys = [...listSelect.value];
};

// select one row
const onSelectRow = (record, selected) => {
  isSelectOneRow.value = true;
  isSelectAll.value = false;
  currentIdSelect.value = record.id;
};

// select all row in table
const onSelectAllRow = (selected, selectedRows) => {
  isSelectAll.value = selected;
  isSelectOneRow.value = false;
};

const handleExport = () => {
  axios
    .get(SERVICE_URL_WIKI_BASE + `course/`, {
      method: "GET",
      responseType: "blob", // important
      params: {
        export: true,
        ids_course_export: listSelect.value,
        ...paramsFetchListCourse,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${truncate(response.headers["content-disposition"])}.csv`
      );
      document.body.appendChild(link);
      link.click();
    });
};

const showStep = computed(() => {
  if (!isViewCourse.value) {
    return (
      store.getters.steps.findIndex(
        (step) => step.status === CREATE_COURSE_STATUS.Doing
      ) + 1
    );
  }
  return store.getters.steps.findIndex((step) => step.view) + 1;
});

const titleStep = computed(() => {
  if (!isViewCourse.value) {
    return store.getters.steps.find(
      (step) => step.status === CREATE_COURSE_STATUS.Doing
    )?.title;
  }
  return store.getters.steps.find((step) => step.view)?.title;
});

const onWidthChange = () => {
  if (window.innerWidth <= 1366) {
    shinkHeader.value = true;
    scrollHeightTable.value = {
      y: "calc(100vh - 350px)",
    };
  } else {
    shinkHeader.value = false;
  }
};

const handleCustomRow = (record, index) => {
  return {
    onClick: () => {
      listSelect.value = [];
      state.selectedRowKeys = []
      activeKey.value = "1";
      store.dispatch("updateCourseId", record.id);
      store.dispatch("updateViewStatus", true);
      store.dispatch("viewStep", 1);
      store.dispatch("updateCourseStatus", record.status);
      const doingStepIndex = STEP_NAME.indexOf(record.status);
      if (doingStepIndex == -1) return;
      const stepsShow = store.getters.steps.map((step, index) => {
        if (index <= doingStepIndex)
          return {
            ...step,
            status: CREATE_COURSE_STATUS.Done,
          };
        return { ...step, status: CREATE_COURSE_STATUS.Open };
      });
      store.dispatch("updateStep", stepsShow);
    },
  };
};
const isDraff = (status) => {
  let count = 0;
  for (const key in status) {
    const value = status[key];
    if (value === 1) {
      count += 1;
    }
  }
  return count <= 1;
};
const handleFilter = (props, fields) => {
  paramsFetchListCourse.page = 1;
  fetchListCourse({ ...paramsFetchListCourse, ...formState });
};

const handleActions = (name, id, e, record) => {
  store.dispatch("updateAction", { name, id });

  switch (name) {
    case TypeActions.Create:
      store.dispatch("updateModeCreate", true);
      store.dispatch("resetStepStatus");
      store.dispatch("doingStep", 1);
      break;
    case TypeActions.Delete:
      e.stopPropagation();
      showConfirm({
        onOk: () => {
          deleteCourse(id);
        },
        suffix: "",
      });
      break;
    case TypeActions.Edit:
      e.stopPropagation();
      store.dispatch("updateCourseId", id);
      store.dispatch("doingStep", 1);
      store.dispatch("updateEditStatus", true);
      store.dispatch("updateCourseStatus", record.status);
      store.dispatch("updateModeCreate", false);
      break;
    case TypeActions.Cancel:
      break;
    case TypeActions.Prev:
      paramsFetchListCourse.page = paramsFetchListCourse.page - 1;
      fetchListCourse({ ...paramsFetchListCourse, ...formState });
      break;
    case TypeActions.Next:
      paramsFetchListCourse.page = paramsFetchListCourse.page + 1;
      fetchListCourse({ ...paramsFetchListCourse, ...formState });
      break;
  }
};

const handleChangePageSize = (limit) => {
  paramsFetchListCourse.limit = limit;
  paramsFetchListCourse.page = 1;
  fetchListCourse({ ...paramsFetchListCourse, ...formState });
};

const handleTableChange = (pagination, filters, sorter) => {
  paramsFetchListCourse.sort_by = sorter.field;
  paramsFetchListCourse.sort_type = sorter.order == "ascend" ? "asc" : "desc";
  fetchListCourse({ ...paramsFetchListCourse, ...formState });
};

const handleChangeDate = (dates) => {
  paramsFetchListCourse.page = 1;
  if (!formState.date) {
    paramsFetchListCourse.start_date = null;
    paramsFetchListCourse.end_date = null;
  } else {
    paramsFetchListCourse.start_date = formState.date[0];
    paramsFetchListCourse.end_date = formState.date[1];
  }
  fetchListCourse({ ...paramsFetchListCourse, ...formState });
};

const fetchListCourse = async (params = paramsFetchListCourse) => {
  try {
    loadingTable.value = true;
    const res = await courseMangemenetApi.getListCourse(params);
    if (res.data.length > 0) {
      dataTable.value = res.data?.map((item, index) => ({
        ...item,
        created_at: moment(item.created_at).format(DATE_FORMAT),
        creator: item.creator?.name || item.creator?.email,
        learning_categories: item.learning_categories
          ?.map((_item) => _item?.name)
          .join(", "),
        positions: item.positions?.map((_item) => _item?.name).join(", "),
        key: item.id,
        no: (res.current_page - 1) * res.per_page + index + 1,
      }));
      showLocationPage.value = true;
    }

    if (res.data.length === 0) {
      dataTable.value = [];
      showLocationPage.value = false;
    }
    meta.total = res.total;
    meta.currentPage = res.current_page;
    meta.from = res.from;
    meta.to = res.to;
    disabledNext.value = !res.to || res.to === res.total;
    disabledPrev.value = !res.from || res.from === 1;
  } catch (error) {
    throw error;
  } finally {
    loadingTable.value = false;
  }
};

const fetchListPosition = async (params) => {
  try {
    const res = await courseMangemenetApi.getListPosition(params);
    positions.value = res.data;
  } catch (error) {
    throw error;
  }
};

const fetchListAuthor = async (params) => {
  try {
    const res = await courseMangemenetApi.getListUser(params);
    authors.value = res.data;
  } catch (error) {
    throw error;
  }
};

const deleteCourse = async (id) => {
  try {
    const res = await courseMangemenetApi.deleteCourse(id);
    notifySuccess("Xóa khóa học thành công");
    fetchListCourse({ ...paramsFetchListCourse, ...formState });
  } catch (error) {
    throw error;
  }
};
</script>

<style lang="scss" scoped>
.courses-management-wrapper {
  background-color: #fff;
  height: 100%;
  border-radius: 5px;
  padding: 24px;
  overflow-y: auto;

  :deep(.ant-input-affix-wrapper) {
    padding: 0 11px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }

  :deep(.ant-input-group-addon) {
    border-top: 1px solid #d9d9d9 !important;
    border-right: 1px solid #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  :deep(.ant-input-affix-wrapper:focus) {
    border-color: #d9d9d9 !important;
  }

  :deep(.ant-input-affix-wrapper-focused) {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  :deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
    border-color: #d9d9d9;
  }
}

.course-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: black;
}

.header-title {
  font-weight: 700;
  margin-bottom: 8px;
  @media only screen and (max-width: 1366px) {
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
  }
}

.title-item {
  display: flex;
  flex-direction: column;
  b {
    width: fit-content;
  }
}

.content-wrapper {
  display: flex;
  gap: 20px;
  .step-wrapper {
    width: 74px;
  }

  .form-wrapper {
    flex: 1;
  }
}

:deep(.filter-wrapper) {
  .name-input {
    .ant-input {
      border-radius: 36px !important;
      padding-left: 10px !important;
    }
  }
  .ant-input-suffix {
    i {
      display: none;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-right: 4px;
      color: black;
    }
  }

  .search-btn {
    &hover {
      cursor: pointer;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .btn-wrapper {
    height: 100%;
    display: flex;
    gap: 20px;
    font-weight: 700px;
    justify-content: flex-end;
    button {
      font-size: 16px;
    }
  }
}

:deep(.table-container) {
  flex: 1;
  .actions-wrapper {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-right: 10px;
    span:hover {
      cursor: pointer;
    }
  }

  .ant-table-content {
    overflow-x: auto;
    min-width: 800px;
  }

  .ant-table-thead {
    color: black;
    font-size: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }
  .ant-table-body {
    color: black;
    font-size: 16px;
  }

  .ant-table-thead {
    tr > th {
      padding: 16px 9px;
    }
  }

  .ant-table-tbody {
    tr > td {
      padding: 16px 16px;
    }
    @media only screen and (max-width: 1366px) {
      tr > td {
        padding: 12px 12px;
      }
    }

    tr:hover {
      cursor: pointer;
    }
  }
  .ant-table-placeholder {
    height: calc(100vh - 420px);
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}

.step-layout-wrapper {
  .header-title {
    font-weight: 700;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    h3 {
      font-weight: 700;
      margin: 0;
      margin-left: 15px;
    }
    span:hover {
      cursor: pointer;
    }
  }
  .content-wrapper {
    display: flex;
    gap: 20px;

    .step {
      width: 74px;
    }
    .content {
      flex: 1;
    }
  }
}

:deep(.ant-tabs-tab) {
  .ant-tabs-tab-btn {
    font-size: 18px;
  }
}
:deep(.ant-tabs-tab.ant-tabs-tab-active) {
  .ant-tabs-tab-btn {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}
:deep(.ant-checkbox) {
  input[type="checkbox"] {
    width: 50px;
    height: 50px;
  }
}
:deep(.ant-picker) {
  border-radius: 6px;
  .ant-picker-input input {
    font-size: 14px;
  }
  .ant-picker-input input::placeholder {
    font-size: 14px;
  }
  .ant-picker-range-separator{
    position: relative;
    top: -2px;
  }
}
:deep(.ant-picker-large){
    padding: 8.5px 11px 8.5px;
}
</style>
