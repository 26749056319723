export const StatusCode = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  TooManyRequests: 429,
  ValidationFailed: 422,
  InternalServerError: 500,
};

export default {
  StatusCode
}
