<template>
  <div class="mt-4">
    <p class="text-base font-bold" v-if="typeUpload === 'document'">
      {{ t("courseManagement.list.labelDocument")}}
    </p>
    <p class="text-base font-bold" v-else>{{ t("courseManagement.list.labelVideo")}}</p>
    <div class="filter-wrapper mt-[6px]">
      <a-input-search
        :placeholder="t('courseManagement.list.titlePlaceholder')"
        @search="handleFilter"
        v-model:value="params.keyword"
      >
        <template #prefix>
          <IconSearch />
        </template>
        <template #enterButton>
          <a-button type="text">{{
            t("courseManagement.list.search")
          }}</a-button>
        </template>
      </a-input-search>
    </div>
    <div class="mt-2">
      <a-table
        :columns="STUDY_TABLE"
        :pagination="false"
        :scroll="scrollHeightTable"
        :data-source="dataTable"
        :loading="loadingTable"
        :row-selection="{
          ...rowSelection,
        }"
      >
      </a-table>
      <div class="pagination-wrapper mt-3">
        <span>Rows per page:</span>
        <a-select v-model:value="valueSelect" default-value="10">
          <i class="fas fa-sort-down dropdown-icon"></i>
          <a-select-option
            v-for="(pageSize, index) in PAGESIZE1"
            :value="pageSize"
            :key="index"
            @click="handleActions(ACTION.ChangeSize, null, pageSize)"
          >
            {{ pageSize }}
          </a-select-option>
        </a-select>
        <span class="total-page"
          >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
        >
        <a-button
          :disabled="meta.from === 1 || !meta.from"
          class="pagination-btn"
          @click="handleActions(ACTION.Prev)"
        >
          <i class="fas fa-chevron-left icon-btn"></i>
        </a-button>
        <a-button
          :disabled="meta.to === meta.total || !meta.total"
          class="pagination-btn"
          @click="handleActions(ACTION.Next)"
        >
          <i class="fas fa-chevron-right icon-btn"></i>
        </a-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import IconSearch from "../../../../components/icon/icon-search.vue";
import { courseMangemenetApi } from "../api/request";
import { STUDY_TABLE, ACTION, PAGESIZE1 } from "../utils/constant";
import { limitTextLength } from "../../../../utils/helper";
const { t } = useI18n();
const emit = defineEmits();
const props = defineProps({
  chooseFile: Function,
  typeUpload: String,
});

const scrollHeightTable = reactive({
  y: 'calc(100vh - 522px)'
});

const valueSelect = ref([10]);
const params = reactive({
  limit: 10,
  page: 1,
  keyword: "",
  type_file_id: 1,
});
const meta = reactive({
  to: null,
  from: null,
  currentPage: 1,
  total: 20,
});

const selectionId = ref(null);

const handleSelectionChange = (checkedId) => {
  // Xử lý bản ghi được chọn ở đây
};

const handleFilter = () => {
  params.page = 1 
  getListStudy(params);
};

const loadingTable = ref(false);
const dataTable = ref([]);
const selectedRowId = ref([]);
const getListStudy = async (params) => {
  try {
    loadingTable.value = true;
    const res = await courseMangemenetApi.getListStudyMaterial(params);

    dataTable.value = res.data.data
      .map((item, index) => {
        return {
          key: index,
          no:  (res.data.current_page - 1) * res.data.per_page + index + 1,
          name: limitTextLength(item.name),
          id: item.id,
        };
      })
      .filter((item) => item !== null);
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } finally {
    loadingTable.value = false;
  }
};
const listSelect = ref([]);
const rowSelection = reactive({
  columnTitle: "Lựa chọn",
  type: "radio",
  fixed: false,
  selectedRowKeys: selectedRowId.value,
  onChange: (selectedRowKeys, selectedRows) => {
    emit("chooseFile", selectedRows);
    changeRowKey(selectedRowKeys);
    selectedRowId.value = [selectedRowKeys];
  },
  // getCheckboxProps: (record) => ({
  //   disabled: record.name === "Disabled User",
  //   // Column configuration not to be checked
  //   name: record.name,
  // }),
});
const changeRowKey = (selectedRowKeys) => {
  rowSelection.selectedRowKeys = selectedRowKeys;
};
const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      params.page = params.page + 1;
      getListStudy(params);
      break;
    case ACTION.Prev:
      params.page = params.page - 1;
      getListStudy(params);
      break;
    case ACTION.ChangeSize:
      params.page = 1
      params.limit = payload;
      getListStudy(params);
      break;
    default:
      break;
  }
};

onMounted(() => {
  if (props.typeUpload === "document") {
    params.type_file_id = 2;
  } else {
    params.type_file_id = 1;
  }
  getListStudy(params);
});
</script>
<style lang="scss" scoped>
:deep(.filter-wrapper) {
  .name-input {
    .ant-input {
      border-radius: 36px !important;
      padding-left: 10px !important;
    }
  }
  .ant-input-suffix {
    i {
      display: none;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-right: 4px;
      color: black;
    }
  }

  .search-btn {
    &hover {
      cursor: pointer;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .btn-wrapper {
    height: 100%;
    display: flex;
    gap: 20px;
    font-weight: 700px;
    justify-content: flex-end;
    button {
      font-size: 16px;
    }
  }
}
:deep(.ant-input-affix-wrapper) {
  padding: 8px 11px;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

:deep(.ant-input-group-addon) {
  border-top: 1px solid #d9d9d9 !important;
  border-right: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

:deep(.ant-input-affix-wrapper:focus) {
  border-color: #d9d9d9 !important;
}

:deep(.ant-input-affix-wrapper-focused) {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
:deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-color: #d9d9d9;
}

:deep(.ant-table-column-title) {
  color: black;
  font-size: 20px;
  @media only screen and (max-width: 1366px) {
    font-size: 16px;
  }
}
:deep(.ant-table-cell) {
  color: black;
  font-size: 16px;
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
:deep(.ant-select) {
  width: 60px !important;
}
</style>