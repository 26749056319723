const SERVICE_URL_WIKI_BASE = '/api/';

const pathUrl = {
  post: 'post',
  category: 'category/list',
  user: 'user',
  feedback: 'v2/feedback'
};

export { pathUrl, SERVICE_URL_WIKI_BASE };
