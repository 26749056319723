export const TypeActions = {
  Create: "CREATE",
  Delete: "DELETE",
  Edit: "EDIT",
  Update: "UPDATE",
  AcceptDel: "ACCEPT_DEL",
  Cancel: "CANCEL",
  AcceptEdit: "ACCEP_EDIT",
  Back: "BACK",
  DownLoad: "DOWNLOAD",
  View: "VIEW",
  Next: "NEXT",
  Prev: "PREV",
  TopTop: "TO_TOP",
  ChangeOptionTime:'CHANGE_OPTION_TIME',
  ChangeRangeTime: 'CHANGE_RANGE_TIME'
};

export const optionPickerTime = {
  Week:{
    format: '',
    name: 'week'
  },
  Month:{
    format: 'MM-YYYY',
    name: 'month'
  },
  Year: {
    format: 'YYYY',
    name: 'year'
  }
}