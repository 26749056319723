<template>
  <div class="mt-2">
    <a-table
      :columns="COLUMN_COURSE_HISTORY"
      :pagination="false"
      :scroll="scrollHeightTable"
      :data-source="dataTable"
      :loading="loadingTable"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'created_at'">
          <span>{{ record.created_at }}&nbsp;</span>
          <span>{{ record.time }}</span>
        </template>
        <template v-if="column.key === 'affected_field'">
          <span>{{ record.action }}</span>
          <span class="font-bold">{{ record.affected_field }}</span>
        </template>
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <span>Rows per page:</span>
      <a-select v-model:value="valueSelect">
        <i class="fas fa-sort-down dropdown-icon"></i>
        <a-select-option
          v-for="(pageSize, index) in PAGESIZE1"
          :value="pageSize"
          :key="index"
          @click="handleActions(ACTION.ChangeSize, null, pageSize)"
        >
          {{ pageSize }}
        </a-select-option>
      </a-select>
      <span class="total-page"
        >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
      >
      <a-button
        :disabled="meta.from === 1 || !meta.from"
        class="pagination-btn"
        @click="handleActions(ACTION.Prev)"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <a-button
        :disabled="meta.to === meta.total || !meta.total"
        class="pagination-btn"
        @click="handleActions(ACTION.Next)"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  computed,
  defineProps,
  defineComponent,
  h,
} from "vue";
import { statisticalApi } from "../../api/request";
import { useStore } from "vuex";
import moment from "moment";
import { ACTION, PAGESIZE1, COLUMN_COURSE_HISTORY } from "../../utils/constant";
import { useI18n } from "vue-i18n";
import Loading from "../../../../../components/loading/Loading.vue";

const { t } = useI18n();
const store = useStore();
defineComponent({
  Loading,
});
const props = defineProps({
  year: String,
  month: String,
});

const courseId = computed(() => store.state.courseManagement.course_id);
const params = reactive({
  course_id: courseId.value,
  month: "",
  year: "",
  limit: 10,
  page: 1,
});

const scrollHeightTable = reactive({
  y: "calc(100vh - 400px)",
});

const loadingTable = ref(false);

const meta = reactive({
  to: null,
  from: null,
  currentPage: 1,
  total: 20,
});
const dataTable = ref([]);
const valueSelect = ref(10);
const nameStep = ref([
  {
    id: 1,
    label: t("courseManagement.history.nameStep1"),
    labelNew: t("courseManagement.history.newStep1"),
    name: "course_info",
  },
  {
    id: 2,
    label: t("courseManagement.history.nameStep2"),
    labelNew: t("courseManagement.history.newStep2"),
    name: "lesson_info",
  },
  {
    id: 3,
    label: t("courseManagement.history.nameStep3"),
    labelNew: t("courseManagement.history.newStep3"),
    name: "test_info",
  },
]);
const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      params.page = params.page + 1;
      getListCourseHistory(params);
      break;
    case ACTION.Prev:
      params.page = params.page - 1;
      getListCourseHistory(params);
      break;
    case ACTION.ChangeSize:
      params.limit = payload;
      getListCourseHistory(params);
      break;
    default:
      break;
  }
};

const getListCourseHistory = async (params) => {
  loadingTable.value = true;
  try {
    const res = await statisticalApi.getListCourseHistory(params);
    if (res.data.data) {
      dataTable.value = res.data.data.map((record, index) => {
        return {
          key: index,
          created_at: moment(record.created_at).format("DD/MM/YYYY"),
          user: record.user.name || "Admin",
          history: getText(record.action, record.affected_field),
          time: moment(record.created_at).format("HH:mm:ss"),
        };
      });
    }
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } catch (error) {
  } finally {
    loadingTable.value = false;
  }
};
const getText = (action, step) => {
  if (step === "created") {
    return h("div", t("courseManagement.history.newStep1"))
  } else {
    return h("div", [
      h(
        "span",
        `${
          action === "create"
            ? step === nameStep.value[0].name
              ? nameStep.value[0].labelNew
              : step === nameStep.value[1].name
              ? nameStep.value[1].labelNew
              : nameStep.value[2].labelNew
            : t("courseManagement.history.changeInfo")
        }`
      ),
      h(
        "span",
        { style: { fontWeight: "bold", marginLeft: "4px" } },
        `${
          step === nameStep.value[0].name
            ? nameStep.value[0].label
            : step === nameStep.value[1].name
            ? nameStep.value[1].label
            : nameStep.value[2].label
        }`
      ),
    ]);
  }
};
onMounted(() => {
  getListCourseHistory(params);
});
</script>
<style lang="scss" scoped>
:deep(.ant-table-column-title) {
  color: black;
  font-size: 18px;
  @media only screen and (max-width: 1366px) {
    font-size: 16px;
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
</style>