import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const courseMangemenetApi = {
  getListCourse: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.course, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getCourse: async (id) => {
    try {
      const res = await RequestApi.get(pathUrl.course + '/' + id);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListCategory: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.category, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getPosition: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.position, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListUser: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.user, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  createCourse: async (payload) => {
    try {
      const res = await RequestApi.post(pathUrl.course, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  updateCourse: async (payload, slug, params) => {
    try {
      const res = await RequestApi.post(
        pathUrl.course + '/' + slug + '?_method=PUT',
        payload,
        {
          params
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  editCourse: async (payload) => {
    try {
      const res = await RequestApi.put(pathUrl.course, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  deleteCourse: async (slug) => {
    try {
      const res = await RequestApi.delete(pathUrl.course + '/' + slug);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListPosition: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.position, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getListStudyMaterial: async(params) => {
    try {
      const res = await RequestApi.get('file/study_material', {
        params: params
      });
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },

};

export const step1ManagementApi = {
  getListPosition: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.position, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListCategory: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.category, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListUser: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.user, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getStep1: async (id, params = {}) => {
    try {
      const res = await RequestApi.get(pathUrl.course + '/' + id, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  createStep1: async (payload) => {
    try {
      const res = await RequestApi.post(pathUrl.course, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  updateStep1: async (payload, id, params) => {
    try {
      const res = await RequestApi.put(pathUrl.course + '/' + id, payload, {
        params
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  uploadFile: async (payload, config) => {
    try {
      const res = await RequestApi.post(pathUrl.upload, payload, config);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
};

export const step2ManagementApi = {
  addStep2: async (payload) => {
    try {
      const res = await RequestApi.post(pathUrl.step2, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateStep2: async (payload) => {
    try {
      const res = await RequestApi.put(pathUrl.step2, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getStep2: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.step2, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
};

export const step3ManagementApi = {
  addStep3: async (payload) => {
    try {
      const res = await RequestApi.post(pathUrl.test, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateStep3: async (payload, id) => {
    try {
      const res = await RequestApi.put(pathUrl.test + '/' + id, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getListTest: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.test, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getTestDetail: async (id, params) => {
    try {
      const res = await RequestApi.get(`${pathUrl.test}/${id}/${pathUrl.step3}`, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getInfoTestDetail: async (id, params) => {
    try {
      const res = await RequestApi.get(`${pathUrl.test}/${id}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },


  
  deleteTest: async (id) => {
    try {
      const res = await RequestApi.delete(pathUrl.test + '/' + id);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  completeStep3: async (id) => {
    try {
      const res = await RequestApi.put(pathUrl.course + '/complete/' + id);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
};

export const statisticalApi = {
  fetchListTestStatistics: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.statistical + '/general/test', {
        params: params
      });
      return res;
    } catch (error) {
      throw error;
    }
  },
  getDetailTestStatistics: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.statistical + '/detail/test', {
        params
      });
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDetailCourseStatistics: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.statistical + '/detail/course', {
        params
      });
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },
  getListUserCompulsory: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.user + '/compulsory', {
        params
      });
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },
  getGeneralStatistics: async (params) => {
    try {
      const res = await RequestApi.get(
        pathUrl.statistical + '/general/course',
        {
          params
        }
      );
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },
  getListCourseHistory: async (params) =>{
    try {
      const res = await RequestApi.get(
        pathUrl.listHistory + '/course', {
          params
        }
      )
      return res
    } catch (error) {
      
    }
  },

};
