<template>
  <div class="wrap-test" v-if="isTesting">
    <div class="test">
      <div class="header-test">
        <div class="chart-result" v-if="data.disabled">
          <a-progress
            type="circle"
            :strokeWidth="12"
            stroke-linecap="square"
            :width="40"
            :percent="data.score"
            strokeColor="#62DE00"
            trailColor="#E41D1D"
          />
        </div>
        <p class="title-test">
          {{ data.detailTest.name }}
          {{ (data.disabled && ` - ${texts.result}`) || "" }}
        </p>
        <div class="countdown-timer" v-if="!data.disabled">
          <img src="/images/clock.svg" alt="" />
          <div class="timer" ref="time"></div>
        </div>
      </div>
      <div class="question" :class="{ pointerNone: data.disabled }">
        <p class="question-count">
          {{ texts.question }} {{ data.currentPage }}:
        </p>
        <p class="question-name">
          {{ data.currentQuestion.content }}
        </p>
        <div class="answers">
          <a-checkbox-group
            v-if="data.currentQuestion.multiple_choice == true"
            @change="handleChooseAnswer"
            v-model:value="data.valueCheckbox[`${data.currentQuestion.id}`]"
          >
            <a-checkbox
              v-for="answer in data.currentAnswers"
              :class="{
                bgGreen: answer.selected_answer === 0 && answer.status === 1,
                bgRed: answer.selected_answer === 1 && answer.status === 0,
              }"
              :key="answer.value"
              :value="answer.value"
            >
              <span>{{ answer.label }}</span>
              <span
                v-if="
                  (answer.selected_answer === 0 && answer.status === 1) ||
                  (answer.selected_answer === 1 && answer.status === 0)
                "
                >(<span>{{
                  answer.selected_answer === 0 && answer.status === 1
                    ? "Đúng"
                    : "Sai"
                }}</span
                >)
              </span>
            </a-checkbox>
          </a-checkbox-group>

          <a-radio-group
            v-else
            v-model:value="data.valueRadio[`${data.currentQuestion.id}`]"
            name="radioGroup"
            @change="handleChooseAnswer"
          >
            <a-radio
              v-for="answer in data.currentAnswers"
              :class="{
                bgGreen: answer.selected_answer === 0 && answer.status === 1,
                bgRed: answer.selected_answer === 1 && answer.status === 0,
              }"
              :value="answer.value"
              :key="answer.value"
            >
              <span>{{ answer.label }}</span>
              <span
                v-if="
                  (answer.selected_answer === 0 && answer.status === 1) ||
                  (answer.selected_answer === 1 && answer.status === 0)
                "
                >(<span>{{
                  answer.selected_answer === 0 && answer.status === 1
                    ? "Đúng"
                    : "Sai"
                }}</span
                >)
              </span>
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="button-group" v-if="!data.isShowAnswers">
        <button
          v-if="data.isShowPevBtn"
          style="border: none; color: #17469e; background-color: #fff"
          type="button"
          class="button"
          @click="handleAction('PREV')"
        >
          {{ texts.btnBack }}
        </button>
        <button
          v-if="data.currentQuestionIsSelectAnswer && !data.isShowCompeteBtn"
          type="button"
          class="button"
          @click="handleAction('NEXT')"
        >
          {{ texts.btnNext }}
        </button>
        <button
          v-else-if="
            !data.currentQuestionIsSelectAnswer && !data.isShowCompeteBtn
          "
          type="button"
          class="button button-disable"
          @click="handleAction('SKIP')"
        >
          {{ texts.btnIgnore }}
        </button>
        <button
          v-if="data.isShowCompeteBtn"
          type="button"
          class="button"
          @click="handleAction('DONE')"
        >
          {{ texts.btnSubmit }}
        </button>
      </div>
      <div v-else class="button-group">
        <button
          style="
            border: none;
            color: #17469e;
            background-color: #fff;
            font-style: bold;
          "
          type="button"
          class="button"
          @click="handleBackDisplayResult"
        >
          {{ texts.btnBack }}
        </button>
      </div>
    </div>
    <div class="pagination">
      <a-pagination
        v-model:current="data.currentPage"
        :total="data.totalQuestion"
        @change="handleChangeQuestion"
        show-size-changer
        :itemRender="renderItem"
        :defaultPageSize="1"
      />
    </div>
  </div>
  <template v-else>
    <ResultsTest
      :tests="tests"
      :dataResult="data.resultsTest"
      :detailTest="data.detailTest"
      :currentIdTest="data.currentIdTest"
      @onRedoTest="handleRedoTest"
      @onNextTest="handleContinueTest"
      @showAnswerTest="handleShowAnswerTest"
      @cancelBeforeEach="cancelBeforeEach"
    ></ResultsTest>
  </template>
</template>
<script>
import { Modal } from "ant-design-vue";
import { cloneDeep, map } from "lodash";
import moment from "moment";
import {
  defineComponent,
  h,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLoading, useShowConfirm } from "../../utils/hook";
import ResultsTest from "./ResultTest.vue";
import { DetailsCourseApi } from "./api/request";
import Wrapper from "./components/Wrapper.vue";
import { startTimer } from "./helper/countdown";
import { typeActions } from "./helper/interface";
import { TYPE_MODAL } from "./utils/constant";
import { texts } from "./utils/texts";
export default defineComponent({
  props: {
    idTest: Number,
    tests: Array,
    infoTestQuestion: Object,
    numberOfTest: Number,
    resultTest: Array,
    isPlayingTestProps: Boolean,
  },
  components: {
    Wrapper,
    ResultsTest,
  },
  data() {
    return {
      texts,
    };
  },
  setup(props, context) {
    const router = useRouter();
    const { t } = useI18n();
    const { showConfirm, showModalWarning, showModalNavigate } =
      useShowConfirm();
    const { hideLoading, showLoading } = useLoading();

    const { idTest, infoTestQuestion, isPlayingTestProps } = toRefs(props);
    const isTesting = ref(true);
    const time = ref("");
    const pageMapIdResult = ref([]);
    const pageMapIdResultAnswers = ref([]);
    const timeOut = ref(false);
    const answersPayload = ref([]);
    const actionBeforeMount = ref(null);
    const interval = ref(null);
    const data = reactive({
      tempTime: time,
      detailTest: {},
      valueCheckbox: {},
      valueRadio: {},
      questions: [],
      currentQuestionId: 1,
      currentQuestion: {},
      currentAnswers: [],
      currentPage: 1,
      currentIdTest: "",
      totalQuestion: "",
      result: {},
      resultShowAnswers: {},
      isShowPevBtn: false,
      isShowCompeteBtn: false,
      currentQuestionIsSelectAnswer: false,
      totalTime: "",
      resultsTest: [],
      onlyOneQuestion: false,
      isShowAnswers: false,
      disabled: false,
      score: null,
    });

    const getAllQuestion = async (id) => {
      showLoading();
      data.detailTest = infoTestQuestion.value;
      data.totalTime = infoTestQuestion.value.time;
      data.currentIdTest = infoTestQuestion.value.id;
      // all question
      data.questions = cloneDeep(infoTestQuestion.value?.questions);
      data.questions.forEach((question) => {
        answersPayload.value.push({
          question_id: question.id,
          answers: map(question?.answers, "id"),
          selections: [],
        });
      });
      data.totalQuestion = data.questions?.length;
      if (infoTestQuestion.value?.total_questions === 1) {
        data.isShowCompeteBtn = true;
      }
      // select question 1
      data.currentQuestion = data.questions[0];
      data.currentQuestionId = data.currentQuestion.id;

      if (time.value && isPlayingTestProps.value && !interval.value) {
        interval.value = startTimer(
          data.totalTime * 60,
          time.value,
          callBackFn
        );
      }
      infoTestQuestion.value?.questions.forEach((question) => {
        data.result[question.id] = [];
      });
      pageMapIdResult.value = map(infoTestQuestion.value?.questions, "id");
      data.currentAnswers = getAnswers(data.currentQuestion.answers);
      hideLoading();
      handleChangeQuestion(data.currentPage);
    };

    const handleChooseAnswer = (value) => {
      answersPayload.value.forEach((item) => {
        if (item.question_id === data.currentQuestionId) {
          if (value.target) {
            item.selections = [value.target.value];
          } else {
            item.selections = value;
          }
        }
      });
      if (value.target) {
        data.result[data.currentQuestionId] = [value.target.value];
      } else {
        data.result[data.currentQuestionId] = value;
      }

      const isSelect = questionIsSelectAnswer(data.currentQuestionId);
      data.currentQuestionIsSelectAnswer = isSelect;
    };

    const getAnswers = (answers) => {
      const newListAnswer = answers.map((answer) => {
        return {
          label: answer.content,
          value: answer.id,
          status: answer.status || 0,
          selected_answer: answer.selected_answer || 0,
        };
      });
      return newListAnswer;
    };

    const questionIsSelectAnswer = (questionID) => {
      if (!data.result[questionID]) {
        return false;
      } else {
        return data.result[questionID].length ? true : false;
      }
    };

    const calTestTime = (startTime, endTime) => {
      return moment(endTime).diff(moment(startTime), "minutes");
    };

    const getDMY = (date) => {
      return moment(date).format("DD/MM/YYYY");
    };

    const handleChangeQuestion = async (page) => {
      setTimeout(() => {
        const nextMore = document.querySelector(".ant-pagination-jump-next");
        const prevMore = document.querySelector(".ant-pagination-jump-prev");
        const element = document.createElement("span");
        element.innerHTML = "•••";
        element.classList.add("ant-pagination-item-ellipsis");
        if (nextMore) {
          if (!nextMore.children.length) {
            nextMore.appendChild(element);
          }
        }
        if (prevMore) {
          if (!prevMore.children.length) {
            prevMore.appendChild(element);
          }
        }
      });

      data.currentQuestion = data.questions[page - 1];
      data.isShowCompeteBtn = page === data.questions.length ? true : false;
      data.isShowPevBtn = page > 1 ? true : false;
      data.currentQuestionId = data.currentQuestion.id;
      data.currentAnswers = getAnswers(data.currentQuestion.answers);
      const isSelect = questionIsSelectAnswer(data.currentQuestionId);
      data.currentQuestionIsSelectAnswer = isSelect;
    };

    const checkBeforeSubmit = () => {
      let isPass = true;
      let isLeastOneCheck = false;
      for (const property in data.result) {
        if (!data.result[property].length) {
          isPass = false;
        } else {
          isLeastOneCheck = true;
        }
      }
      if (!isLeastOneCheck && !timeOut.value) {
        showModalWarning({
          type: TYPE_MODAL.CONFIRM_TEST_WARNING,
          content: texts.messageEmptyCourse,
          onOk: () => {},
        });
        return;
      }
      if (isPass) {
        handleSubmitAllQuestion();
      } else {
        showConfirm({
          type: TYPE_MODAL.CONFIRM_TEST,
          content: texts.messageUnfinishedQuestion,
          onOk: () => {
            handleSubmitAllQuestion();
          },
        });
      }
    };

    const resetInterval = () => {
      if (interval.value) {
        clearInterval(interval.value);
        interval.value = null;
      }
    };

    const handleSubmitAllQuestion = async () => {
      const payload = {
        answers: answersPayload.value,
      };
      try {
        const response = await DetailsCourseApi.submitTest(
          idTest.value,
          payload
        );
        resetInterval();
        if (!timeOut.value) {
                    isTesting.value = false;
        }
        if (response) {
          await getResultTest();
          isTesting.value = false;
        }
        context.emit("onCompleteTest");
      } catch (error) {}
    };

    const getResultTest = async () => {
      showLoading();
      try {
        const { data: res } = await DetailsCourseApi.getResultTest(
          idTest.value
        );
        data.resultsTest = res[0].user_tests;
      } finally {
        hideLoading();
      }
    };

    const renderItem = (page, type, originalElement) => {
      if (page.type === "page") {
        let current;
        if (pageMapIdResult.value && !data.isShowAnswers) {
          current = pageMapIdResult.value[page.page - 1];
        } else current = pageMapIdResultAnswers.value[page.page - 1];
        if (data.isShowAnswers) {
          return h("a", {
            class: `${
              data.resultShowAnswers[current] ? "answered" : "inCorrect"
            }`,
            innerHTML: `${page.page}`,
          });
        }
        return h("a", {
          class: `${data.result[current]?.length ? "answered" : "noneColor"}`,
          innerHTML: `${page.page}`,
        });
      }
      return originalElement;
    };

    const callBackFn = () => {
      timeOut.value = true;
      Modal.destroyAll();
      showModalWarning({
        type: TYPE_MODAL.CONFIRM_TEST_TIMEOUT,
        content: t("courseManagement.notification.messageTimeout"),
        onOk: () => {
          isTesting.value = false;
        },
      });
      resetInterval();
      handleSubmitAllQuestion();
    };

    const confirmExit = (event) => {
      event.preventDefault();
      event.returnValue = "";
      alert(texts.messageConfirmExitPage);
    };

    const handleAction = (type) => {
      switch (type) {
        case typeActions.Next:
          data.currentPage = data.currentPage + 1;
          handleChangeQuestion(data.currentPage);
          break;
        case typeActions.Skip:
          data.currentPage = data.currentPage + 1;
          handleChangeQuestion(data.currentPage);
          break;
        case typeActions.Prev:
          data.currentPage = data.currentPage - 1;
          handleChangeQuestion(data.currentPage);
          break;
        case typeActions.Done:
          checkBeforeSubmit();
          break;
        default:
          break;
      }
    };

    const resetFormTest = () => {
      data.valueCheckbox = {};
      data.valueRadio = {};
      data.result = {};
      data.currentPage = 1;
    };

    const handleRedoTest = (id) => {
      isTesting.value = true;
      timeOut.value = false;
      answersPayload.value = [];
      resetFormTest();
      // gắn lại sự kiện hiển thị popup cảnh báo thoát
      eventLeaveTheTest();
      context.emit("redoTest", {
        id: id,
        numberOfTest: data.resultsTest.length,
        isRedo: true,
      });
      setTimeout(() => {
        if (interval.value) {
          clearInterval(interval.value);
        }
        time.value.textContent = "00:00:00";
      });
    };

    const handleContinueTest = (id) => {
      context.emit("onContinueTest", id);
    };

    const handleShowAnswerTest = (result, score = 0) => {
      isTesting.value = true;
      data.totalQuestion = result?.answers?.length;
      data.isShowAnswers = true;
      data.score = score;
      data.disabled = true;
      resetFormTest();
      data.questions = cloneDeep(result.answers);
      pageMapIdResultAnswers.value = map(data.questions, "id");
      data.currentQuestion = data.questions[data.currentPage - 1];
      data.currentAnswers = getAnswers(data.currentQuestion.answers);
      result.answers.forEach((question) => {
        if (question.multiple_choice) {
          data.resultShowAnswers[question.id] = true;
          question.answers.forEach((answer) => {
            //đáp án được chọn
            if (answer.selected_answer === 1) {
              if (!data.valueCheckbox[question.id]) {
                data.valueCheckbox[question.id] = [];
              }
              if (answer.status === 0) {
                data.resultShowAnswers[question.id] = false;
              }
              data.valueCheckbox[question.id].push(answer.id);
            }
            // đáp án không được chọn
            else {
              if (answer.status === 1) {
                data.resultShowAnswers[question.id] = false;
              }
            }
          });
        } else {
          let answer = question.answers.find((answer) => {
            return answer.selected_answer === 1;
          });
          data.valueRadio[question.id] = answer?.id ? answer.id : null;
          data.resultShowAnswers[question.id] =
            answer?.status === 1 ? true : false;
        }
      });
    };

    const handleBackDisplayResult = () => {
      isTesting.value = false;
      data.isShowAnswers = false;
      data.disabled = false;
      data.resultShowAnswers = {};
    };

    // sự kiện popup cảnh báo thoát
    const eventLeaveTheTest = () => {
      actionBeforeMount.value = router.beforeEach((to, from, next) => {
        showModalNavigate({
          content: t("courseManagement.notification.textConfirmExit"),
          onOk: () => {
            resetInterval();
            next();
            actionBeforeMount.value();
          },
          onCancel: () => {
            next(false);
          },
        });
      });
    };

    const cancelBeforeEach = () => {
      if (actionBeforeMount.value) {
        actionBeforeMount.value();
      }
    };
    watch(isTesting, () => {
      if (isTesting.value) {
        window.addEventListener("beforeunload", confirmExit);
      } else {
        window.removeEventListener("beforeunload", confirmExit);
      }
    }),
      watch(infoTestQuestion, async (val) => {
                resetFormTest();
        infoTestQuestion.value = val;
        answersPayload.value = [];
        if (val?.isShowResult) {
          isTesting.value = false;
          data.resultsTest = props.resultTest;
          data.detailTest = infoTestQuestion;
          data.currentIdTest = infoTestQuestion.value?.id;
        } else {
          if (isPlayingTestProps.value) {
            getAllQuestion(idTest);
            isTesting.value = true;
          }
        }
        timeOut.value = false;
      });
    watch(isPlayingTestProps, (val) => {
      if (!val) {
        isTesting.value = false;
        actionBeforeMount.value();
        resetInterval();
      }
    });

    onMounted(() => {
      window.addEventListener("beforeunload", confirmExit);
      setTimeout(() => {
        const nextMore = document.querySelector(".ant-pagination-jump-next");
        const element = document.createElement("span");
        element.innerHTML = "•••";
        element.classList.add("ant-pagination-item-ellipsis");
        if (nextMore) {
          if (!nextMore.children.length) {
            nextMore.appendChild(element);
          }
        }
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", confirmExit);
      resetInterval();
    });

    return {
      data,
      getAllQuestion,
      handleChangeQuestion,
      getAnswers,
      handleChooseAnswer,
      callBackFn,
      handleAction,
      handleSubmitAllQuestion,
      checkBeforeSubmit,
      time,
      calTestTime,
      confirmExit,
      getDMY,
      isTesting,
      handleRedoTest,
      texts,
      renderItem,
      handleContinueTest,
      handleShowAnswerTest,
      handleBackDisplayResult,
      resetFormTest,
      cancelBeforeEach,
      getResultTest,
      eventLeaveTheTest,
      resetInterval,
    };
  },

  mounted() {
    if (!this.numberOfTest) {
      this.eventLeaveTheTest();
      if (this.$refs.time) {
        this.$refs.time.textContent = "00:00:00";
      }
      this.getAllQuestion(this.idTest);
    } else {
      this.data.detailTest = this.infoTestQuestion;
      this.data.totalTime = this.infoTestQuestion.time;
      this.data.currentIdTest = this.infoTestQuestion.id;
      this.isTesting = false;
      this.data.resultsTest = this.resultTest;
    }
  },
});
</script>
<style lang="scss" scoped>
.pointerNone {
  pointer-events: none;
}
.test {
  padding: 12px 27px;
  border: 1px solid #000;
  min-height: 350px;
  position: relative;
  p {
    margin: 0;
  }
  .countdown-timer {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    width: 100px;
    align-items: center;
    gap: 8px;
    .timer {
      font-size: 18px;
    }
  }
  .header-test {
    display: flex;
    gap: 10px;
    align-items: center;
    .title-test {
      font-size: 22px;
      width: 85%;
      font-weight: bold;
    }
    @media screen and (max-width: 1024px) {
      .title-test {
        width: 70%;
      }
    }
  }

  .question {
    &-count {
      font-size: 20px;
    }
    &-name {
      font-size: 18px;
      font-weight: bold;
    }
    .answers {
      margin-top: 14px;
    }
  }
  :deep(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span:last-child {
      font-size: 18px;
      font-weight: bold;
    }
    .ant-checkbox-checked {
      &::after {
        border-color: #000;
      }
      .ant-checkbox-inner {
        border-color: #000;
        background-color: #000;
      }
    }
  }
  :deep(.ant-checkbox-wrapper) {
    width: 100%;
    border: 1px solid #000;
    padding: 10px 14px;
    + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  :deep(.ant-radio) {
    top: 2px;
    .ant-radio-inner {
      border-color: #000;
      &::after {
        background-color: black;
      }
    }
  }
}

:deep(.ant-pagination) {
  .answered {
    background-color: #62de00 !important;
  }
  .inCorrect {
    background-color: #e41d1d !important;
  }
}
:deep(.ant-pagination-item-active) {
  border-color: #000;
  a {
    font-weight: bold;
    color: #000;
  }
}
:deep(.ant-pagination-item:hover) {
  border-color: #62de00;
}
.button-group {
  text-align: right;
  margin-top: 26px;
  .button {
    outline: none;
    border: none;
    background-color: #17469e;
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
    font-weight: bold;
    min-width: 70px;
  }
  .button-disable {
    border: 1px solid #17469e;
    color: #17469e;
    background: #fff;
  }
}
.pagination {
  margin-top: 20px;
}

:deep(.ant-pagination-prev) {
  display: none;
}
:deep(.ant-pagination-next) {
  display: none;
}
:deep(.ant-radio-group) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ant-radio-wrapper {
    width: 100%;
    border: 1px solid #000;
    padding: 10px 14px;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.result-test {
  .detail-result {
    display: flex;
    gap: 44px;
  }
  .text-medium {
    font-size: 14px;
  }
  .text-small {
    font-size: 12px;
  }
  :deep(.ant-collapse) {
    width: 100%;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    .ant-collapse-extra {
      width: 100%;
      margin-left: 0;
      display: flex;
      gap: 40px;
      align-items: center;
      padding: 0 15px;
    }
    .header-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .ant-collapse-arrow {
      font-size: 20px;
      top: 32px;
    }
  }
  :deep(.ant-collapse-item-active) {
    .ant-collapse-extra {
      display: none;
      visibility: hidden;
      transition: 0.2s;
    }
  }
  :deep(.ant-checkbox-checked) {
    .ant-checkbox-inner {
      background-color: #000;
      border-color: #000;
    }
  }

  // :deep(.ant-checkbox-inner) {

  // }
}
.bgGreen {
  background-color: rgba(97, 224, 0, 0.5);
}
.bgRed {
  background-color: rgba(228, 27, 27, 0.35);
}
:deep(.ant-progress-text) {
  opacity: 0;
}
:deep(.ant-pagination-options) {
  opacity: 0;
}
p {
  margin: 0;
}
</style>
