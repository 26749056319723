<template>
  <div class="step-layout-wrapper">
    <div class="header-title" v-if="isEditCourse || modeCreate">
      <div class="button-wrapper">
        <a-button @click="handleBackListCourse" class="btn-back">
          <LeftOutlined />
          Trở về danh sách
        </a-button>
        <a-button
          type="primary"
          class="btn-edit"
          @click="handleSwitchEditCourse"
          v-if="isViewCourse"
        >
          Chỉnh sửa
        </a-button>
      </div>
      <h3>{{ title }}</h3>
    </div>
    <div class="content-wrapper">
      <div class="step">
        <Step :steps="steps" />
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import Step from "./Step.vue";
import { defineComponent, computed, defineProps, h, defineEmits } from "vue";
import { useStore } from "vuex";
import { LeftOutlined } from "@ant-design/icons-vue";
import { useShowConfirm } from "../../../../utils/hook";
import IconEdit from "../../../../components/icon/icon-edit.vue";

defineComponent({
  Step,
  LeftOutlined,
  IconEdit,
});
defineProps({
  title: {
    require: true,
    type: String,
  },
  getListCourse: Function,
  isEdit: Boolean,
});
const store = useStore();
const emit = defineEmits();
const { showConfirm } = useShowConfirm();
const steps = computed(() => store.state.courseManagement.steps);
const isViewCourse = computed(() => store.state.courseManagement.view_course);
const isEditCourse = computed(() => store.state.courseManagement.edit_course);
const modeCreate = computed(() => store.state.courseManagement.modeCreate);
const handleBackListCourse = () => {
  emit('getListCourse');
  if (isEditCourse.value && !modeCreate.value) {
    const content = h('div', [
      h('p', `Bạn đang thực hiện thoát chỉnh sửa khóa học`),
      h('p', 'Bạn có chắc chắn muốn thoát?')
    ]);
    showConfirm({
      type: "edit",
      contentBody: content,
      onOk: () => {
        store.dispatch("backListCourse");
        store.dispatch("updateViewStatus", false);
      },
    });
    return;
  }
  store.dispatch("backListCourse");
  store.dispatch("updateViewStatus", false);
};

const handleSwitchEditCourse = () => {
  store.dispatch('updateEditStatus', true);
  store.dispatch('updateViewStatus', false);
  store.dispatch('doingStep', 1);
  store.dispatch("updateModeCreate", false);
};
</script>

<style lang="scss" scoped>
.step-layout-wrapper {
  display: flex;
  flex-direction: column;
  .header-title {
    font-weight: 700;
    margin-bottom: 36px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    h3 {
      font-weight: 700;
      margin: 0;
      margin-left: 15px;
      font-size: 24px;
    }
    span:hover {
      cursor: pointer;
    }
    button {
      display: flex;
      align-items: center;
      color: #1890ff;
    }
  }
  .content-wrapper {
    flex: 1;
    display: flex;
    gap: 20px;

    .step {
      width: 74px;
    }
    .content {
      flex: 1;
    }
  }
  .button-wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
    .btn-back {
      border: none;
      font-size: 16px;
    }
    .btn-edit {
      color: white;
      height: 34px !important;
      svg {
        margin-right: 8px;
      }
    }
  }
}
</style>
