import apiConstant from './apiConstant';
import httpRequest from './http-request';

export const getMostSearch = async () =>
    httpRequest.get(apiConstant.HOME.MOST_SEARCH);
export const category = async () => 
    httpRequest.get(apiConstant.HOME.CATEGORY);
export const topView = async (params) => 
    httpRequest.get(apiConstant.HOME.TOP_VIEW+params);
export const search = async (params) => 
    httpRequest.get(apiConstant.HOME.SEARCH+params);
export const searchAll = async (params) => 
    httpRequest.get(apiConstant.HOME.SEARCH_ALL+params.keyword+params.category_id+params.limit+params.page);
export const notify = async (params) =>
    httpRequest.get(apiConstant.HOME.NOTIFY+params);
export const updateStatusNotify = async (id) =>
    httpRequest.put(apiConstant.HOME.READ_NOTIFY + id);