<template>
  <a-modal
    :title="title"
    :visible="isVisible"
    width="1124px"
    :closable="true"
    centered
    footer=""
  >
    <div class="mt-2">
      <a-table
        :columns="COLUMN_COURSE_HISTORY"
        :pagination="false"
        :scroll="scrollHeightTable"
        :data-source="dataTable"
        :loading="loadingTable"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'created_at'">
            <span>{{ record.created_at }}&nbsp;</span>
            <span>{{ record.time }}</span>
          </template>
          <template v-if="column.key === 'affected_field'">
            <span>{{ record.action }}</span>
            <span class="font-bold">{{ record.affected_field }}</span>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <span>Rows per page:</span>
        <a-select v-model:value="valueSelect">
          <i class="fas fa-sort-down dropdown-icon"></i>
          <a-select-option
            v-for="(pageSize, index) in PAGESIZE1"
            :value="pageSize"
            :key="index"
            @click="handleActions(ACTION.ChangeSize, null, pageSize)"
          >
            {{ pageSize }}
          </a-select-option>
        </a-select>
        <span class="total-page"
          >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
        >
        <a-button
          :disabled="meta.from === 1 || !meta.from"
          class="pagination-btn"
          @click="handleActions(ACTION.Prev)"
        >
          <i class="fas fa-chevron-left icon-btn"></i>
        </a-button>
        <a-button
          :disabled="meta.to === meta.total || !meta.total"
          class="pagination-btn"
          @click="handleActions(ACTION.Next)"
        >
          <i class="fas fa-chevron-right icon-btn"></i>
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import {
  ACTION,
  PAGESIZE1,
  COLUMN_COURSE_HISTORY,
} from "../../e-learning/course/utils/constant";
import { defineProps, reactive, onMounted, ref, h } from "vue";
import { postMangemenetApi } from "../api/request";
import { useRoute } from "vue-router";
import moment from "moment";
import { useI18n } from "vue-i18n";

const route = useRoute();
const { t } = useI18n();

const props = defineProps({
  title: String,
  isVisible: Boolean,
  cancel: () => {},
});
const meta = reactive({
  to: null,
  from: null,
  currentPage: 1,
  total: 20,
});
const params = reactive({
  post_id: route.params.id,
  month: "",
  year: "",
  limit: 10,
  page: 1,
});
const listTitleChange = ref([
  {
    id: 1,
    label: t("courseManagement.history.title"),
    name: "title",
  },
  {
    id: 2,
    label: t("courseManagement.history.description"),
    name: "description",
  },
  {
    id: 3,
    label: t("courseManagement.history.category"),
    name: "categories",
  },
  {
    id: 4,
    label: t("courseManagement.history.files"),
    name: "files",
  },
  {
    id: 5,
    label: t("courseManagement.history.postChapter"),
    name: "post_chapters",
  },
]);

const scrollHeightTable = reactive({
  y: "calc(100vh - 400px)",
});

const dataTable = ref([]);
const valueSelect = ref(10);
const loadingTable = ref(false);
const fetchHistoryPost = async (params) => {
  try {
    loadingTable.value = true;
    const res = await postMangemenetApi.getHistoryPost(params);
    if (res.data.data) {
      dataTable.value = res.data.data.map((record, index) => {
        return {
          key: index,
          created_at: moment(record.created_at).format("DD/MM/YYYY"),
          user: record.user.name || "Admin",
          history: getText(record.action, record.affected_field),
          time: moment(record.created_at).format("HH:mm:ss"),
        };
      });
    }
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } finally {
    loadingTable.value = false;
  }
};

const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      params.page = params.page + 1;
      fetchHistoryPost(params);
      break;
    case ACTION.Prev:
      params.page = params.page - 1;
      fetchHistoryPost(params);
      break;
    case ACTION.ChangeSize:
      params.limit = payload;
      fetchHistoryPost(params);
      break;
    default:
      break;
  }
};

const render = (listAffected) => {
  const listText = [];
  listAffected.forEach((name) => {
    listTitleChange.value.forEach((nameList) => {
      if (name === nameList.name) {
        listText.push(nameList.label);
      }
    });
  });

  return listText.join(", ");
};

const getText = (action, affected) => {
  const listAffected = String(affected).split(",");
  return h("div", [
    h(
      "span",
      `${action === "create" ? "Tạo mới bài post" : "Thay đổi thông tin"}`
    ),
    h(
      "span",
      { style: { fontWeight: "bold", marginLeft: "4px" } },
      render(listAffected)
    ),
  ]);
};

onMounted(() => {
  fetchHistoryPost(params);
});
</script>
<style lang="scss" scope>
</style>