<template>
  <div class="container-fluid content-wrapper">
    <Loading v-if="isLoading" />
    <h2 class="title mb-2">{{ texts.titleHome }}</h2>
    <div class="search-wrapper">
      <div class="search d-flex">
        <a-dropdown class="btn-search">
          <template #overlay>
            <a-menu @click="handleChoseType" class="dropdown-option">
              <a-menu-item v-for="type in listType" :key="type.id">
                {{ type.name }}
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            <span @click.prevent> {{ type_name }}</span>
            <IconDropdown />
          </a-button>
        </a-dropdown>
        <div class="search-input">
          <input v-model="keyword" :placeholder="texts.placeholderInput" />
        </div>
      </div>
      <a-dropdown class="btn-search">
        <template #overlay>
          <a-menu @click="handleMenuClick" class="dropdown-option">
            <a-menu-item v-for="category in categories" :key="category.id">
              {{ category.name }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button class="category-button">
          <span @click.prevent class="category-name"> {{ category_name }}</span>
          <IconDropdown />
        </a-button>
      </a-dropdown>
    </div>
    <div class="courses" v-if="isData">
      <ListCourseWrapper
        :title="texts.titleListAllCourse"
        :data="listAllCourse"
      ></ListCourseWrapper>
      <span class="line"></span>
      <ListCourseWrapper
        :title="texts.titleListCompulsoryCourse"
        :data="listCompulsoryCourse"
      ></ListCourseWrapper>
    </div>
    <div v-else-if="!isData && isInitData" class="empty-course">
      <div class="">
        <img src="/images/not-found.png" alt="not found course" />
        <p>{{ texts.notFoundCourse }}</p>
      </div>
    </div>
    <div v-else class="empty-course">
      <div class="">
        <img src="/images/empty.png" alt="empty course" />
        <p>{{ texts.notFoundCourseSystem }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading/Loading.vue";
import IconDropdown from "../../components/icon/icon-dowload.vue";
import { CourseELearningApi } from "./api/request";
import ListCourseWrapper from "./ListCourseWrapper.vue";
import { texts } from "./utils/texts";
import { debounce } from "lodash";
import { LIST_TYPE, IMAGE_TYPE } from "./utils/constant";
export default {
  components: {
    Loading,
    IconDropdown,
    ListCourseWrapper,
  },
  data() {
    return {
      keyword: "",
      texts,
      isInitData: false,
      listAllCourse: [],
      listCompulsoryCourse: [],
      categories: [],
      category_name: "Thể loại",
      type_name: "Tất cả",
      isLoading: false,
      category_id: "",
      type_id: "",
      search_type: "",
      listType: LIST_TYPE,
      imgType: IMAGE_TYPE,
    };
  },
  computed: {
    isData() {
      if (
        this.listAllCourse.length === 0 &&
        this.listCompulsoryCourse.length === 0
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    keyword: debounce(function (e) {
      this.search();
    }, 500),
  },
  methods: {
    async getAllCourse() {
      this.isLoading = true;
      try {
        const params = {
          keyword: this.keyword,
          type: "course_list_elearning",
          elearning_category_id: this.category_id,
          search_type: this.search_type,
          limit: 1000,
        };
        const { data: res } = await CourseELearningApi.getAllCourse(params);
        if (res.length) {
          const data = res.map((item) => {
            let img =
              item?.image.find((file) => {
                return this.imgType.includes(file.type);
              })?.full_path || "";
            return {
              id: item?.id,
              image: img || "",
              title: item?.title,
            };
          });
          this.listAllCourse = data;
          return data;
        } else {
          this.listAllCourse = [];
        }
        this.isLoading = false;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async getCompulsoryCourse() {
      try {
        const params = {
          keyword: this.keyword,
          type: "compulsory_course",
          elearning_category_id: this.category_id,
          search_type: this.search_type,
          limit: 1000,
        };
        const { data: res } = await CourseELearningApi.getCompulsoryCourse(
          params
        );
        if (res.length) {
          const data = res.map((item) => {
            let img =
              item?.image.find((file) => {
                return this.imgType.includes(file.type);
              })?.full_path || "";
            return {
              id: item?.id,
              image: img || "",
              title: item?.title,
            };
          });
          this.listCompulsoryCourse = data;
          return data;
        } else {
          this.listCompulsoryCourse = [];
        }
      } catch (error) {
        this.listCompulsoryCourse = [];
      }
    },

    async getCategory() {
      try {
        const { data: res } = await CourseELearningApi.getCategory();
        const listCategory = [{ name: "Tất cả", id: null }, ...res];
        this.categories = listCategory;
      } catch (error) {}
    },
    handleMenuClick(e) {
      let findCategory = this.categories.find((item) => item.id === e.key);
      if (this.category_id !== e.key) {
        this.category_id = e.key;
        this.search(e.key);
      }
      this.category_name = findCategory.name;
    },
    handleChoseType(e) {
      this.type_id = e.key;
      let findType = this.listType.find((item) => item.id === e.key);
      this.type_name = findType.name;
      if (this.search_type !== findType.type) {
        this.search_type = findType.type;
        this.search(e.key);
      }
    },
    search(key) {
      Promise.all([this.getAllCourse(), this.getCompulsoryCourse()]).then(
        () => {
          this.isLoading = false;
        }
      );
    },
  },

  created() {
    Promise.all([this.getAllCourse(), this.getCompulsoryCourse()]).then(
      (data) => {
        if (data[0]?.length > 0 || data[1]?.length > 0) {
          this.isInitData = true;
        }
        this.isLoading = false;
      }
    );
    this.getCategory();
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
}
.line {
  display: block;
  background-color: gray;
  height: 1px;
  width: 95%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 16px;
}
.category-button {
  max-width: 135px;
  display: flex;
  gap: 10px;
  align-items: center;
  .category-name {
    font-size: 16px;
    max-width: 90px;
    overflow: hidden;
  }
}
.empty-course {
  display: flex;
  width: 100%;
  margin-top: 200px;
  align-items: center;
  justify-content: center;
  div {
    width: fit-content;
    position: relative;
    top: -10%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    img {
      width: 160px;
      height: 100%;
      text-align: center;
    }
    p {
      font-weight: 400;
      font-size: 26px;
      color: #000;
    }
  }
}
.content-wrapper {
  height: 100%;
  width: 100%;
  background: white;
  padding: 28px 40px;
}
.search-wrapper {
  background-color: white;
  position: relative !important;
  display: flex;
  gap: 20px;
  align-items: center;
  top: 0px;
  width: 100% !important;
  padding-bottom: 29px;
  .search {
    padding-top: 0 !important;
    max-width: 800px;
    width: 100%;
    input:focus-visible {
      outline: none;
    }
    .btn-search {
      border: 2px solid #000000;
      border-right: none;
      border-top-left-radius: 47px !important;
      border-bottom-left-radius: 47px !important;
      height: 51px !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 193%;
      color: #929292;
      padding-left: 31px;
      padding-right: 23px;
      span {
        margin-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 160px;
        margin-top: 6px;
        display: inline-block;
      }
      svg {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
    &-input {
      width: 100% !important;
      @media (max-width: 700px) {
        width: calc(73% - 115px);
      }
      position: relative;
      margin-left: -2px;
      input {
        display: block;
        width: 100%;
        font-size: 16px;
        padding: 4px;
        border-right: 2px solid #000 !important;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
        padding-left: 20px;
        border: 2px solid #000000;
        border-left: 1px solid #a6a6a6;
        height: 51px !important;
        font-weight: 400;
        line-height: 136%;
        color: #000000;
        :hover {
          padding-left: 6px;
          border: none;
        }
      }
    }
  }
}
</style>
