import i18n from '../../../i18n';
const { t } = i18n.global;

export const LIST_QUICK_VIEW = [
  {
    id: 1,
    title: t('courseManagement.post.qw-1'),
  },
  {
    id: 2,
    title: t('courseManagement.post.qw-2'),
  },
  {
    id: 3,
    title: t('courseManagement.post.qw-3'),
  },
  {
    id: 4,
    title: t('courseManagement.post.qw-4'),
  },
  {
    id: 5,
    title: t('courseManagement.post.qw-5'),
  },
]