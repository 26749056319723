import { createStore } from 'vuex';
import postManagement from './postManagement';
import courseManagement from './courseManagement';
import course from './course';
import loading from './loading';
import common from './common'
const store = createStore({
  modules: {
    postManagement,
    courseManagement,
    loading,
    course,
    common
  }
});

export default store;
