<template>
  <a-modal
    width="1224px"
    :closable="true"
    centered
    footer=""
    :visible="visible"
  >
    <div>
      <h2 class="font-bold mb-3 text-[30px]">
        {{ t("courseManagement.post.postPreview") }}
      </h2>
      <a-row>
        <a-col :span="16">
          <div>
            <div class="text-black font-bold text-[26px] pb-[10px]">
              {{ dataInfo.title }}
            </div>
            <div class="pb-[10px]">
              {{ t("courseManagement.post.dateSubmit") }}:
              {{ dataInfo.created_at }}
            </div>
            <div class="pb-[10px]">
              {{ t("courseManagement.post.poster") }}:
              <strong>{{ dataInfo.author }}</strong>
            </div>
            <div class="pb-[10px]">
              {{ t("courseManagement.post.category") }}:
              <span v-for="(category, index) in listCategory" :key="index">
                <span class="-mr-[1px]">{{ category }}</span>
                <span v-if="index !== listCategory?.length - 1">, </span>
              </span>
            </div>
            <div class="pb-[10px]">
              {{ t("courseManagement.post.description") }}:
              <span>{{ dataInfo.description }}</span>
            </div>
            <div class="chapter mt-[40px]" v-if="chapters?.length > 1">
              <div
                class="title mb-[10px] cursor-pointer"
                v-for="(chapter, index) in chapters"
                @click="goToScrollChapter(index)"
                :key="index"
              >
                <span
                  v-if="chapter?.description?.length"
                  class="text-[#1280BF] font-bold text-base"
                  >{{
                    `${t("courseManagement.post.chapter")} ${convertRomanize(
                      index + 1
                    )}: `
                  }}
                  {{ chapter.description }}</span
                >
              </div>
            </div>
            <div class="chapter mt-[30px]">
              <div
                class="info-chapter mb-[10px]"
                v-for="(chapter, index) in chapters"
                :key="index"
                :id="`chapter-${index}`"
              >
                <span
                  v-if="chapter?.description?.length"
                  class="font-bold text-xl"
                  >{{
                    `${t("courseManagement.post.chapter")} ${convertRomanize(
                      index + 1
                    )}: `
                  }}
                  {{ chapter.description }}</span
                >
                <div
                  class="ck-content text-base"
                  v-html="chapter.editor.content"
                ></div>
              </div>
            </div>
            <p class="mt-3 text-lg font-bold">{{t("courseManagement.post.attachments")}}</p>
            <div class="mt-2" v-if="dataInfo?.files">
              <div
                class="flex items-center gap-2 mt-1"
                v-for="(file, key) in dataInfo.files"
                :key="key"
              >
                <div class="icon-dowload">
                  <img src="/images/pdf.svg" alt="icon-pdf" />
                </div>
                <div class="name-file !m-0 !flex gap-1 items-center">
                  <div class="name-file !m-0 !flex gap-1 items-center">
                    <a :href="domain + file.url" download>
                      <DownloadOutlined class="text-xl cursor-pointer"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8" class="p-8">
          <div class="text-[26px] font-bold text-[#00C62B]">
            {{ t("courseManagement.post.topView") }}
          </div>
          <div class="view-list">
            <div class="mb-7" v-for="item in topViews" :key="item.id">
              <div class="font-bold text-xl mb-3">
                <a @click="goToDetail(item.slug)">{{ item.title }}</a>
              </div>
              <div class="text-base">
                {{ !!item.description ? item.description : "" }}
              </div>
            </div>
          </div>
          <div class="pagination">
            <a-pagination
              :total="total"
              :page-size="pageSize"
              :default-current="1"
              class="pagination-page"
              @change="changePage($event)"
            />
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script setup>
import {
  defineProps,
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
} from "vue";
import IconBack from "../../../components/icon/icon-back.vue";
import { romanize } from "../utils/helpers";
import { getTopView } from "../../../api/homeDetail";
import { useI18n } from "vue-i18n";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { LINK_EMBEDDED_DOC } from '../../../constant/common'
const { t } = useI18n();
defineComponent({
  IconBack,
  DownloadOutlined,
});
const props = defineProps({
  info: Object,
  visible: Boolean,
  cancel: () => {},
});
const pageSize = ref(5);
const dataInfo = ref(props.info);
const chapters = ref(props.info.chapters);
const topViews = ref([]);
const total = ref();
const loading = ref();
const listCategory = ref([]);
const domain = ref(null)
const convertRomanize = (number) => {
  return romanize(number);
};

const fetchTopView = async (page = 1) => {
  try {
    loading.value = true;
    const params = {
      limit: "?limit=" + pageSize.value,
      page: "&page=" + page,
    };
    const response = await getTopView(params);
    topViews.value = response.data;
    total.value = response.meta.total;
  } catch (error) {
    throw error;
  } finally {
    loading.value = false;
  }
};
const changePage = (e) => {
  fetchTopView(e);
};

const checkExist = (value) => {
  if (listCategory.value.length) {
    return listCategory.value.includes(value);
  }
};

watch(dataInfo.value, () => {
  listCategory.value = [];
  dataInfo.value.categories.map((category) => {
    if (category?.option) {
      if (checkExist(category.option.filterProps)) return;
      listCategory.value.push(category.option.filterProps);
    } else {
      if (checkExist(category.label)) return;
      listCategory.value.push(category.label);
    }
  });
});

const goToScrollChapter = (index) => {
  document.getElementById(`chapter-${index}`).scrollIntoView();
};
const mappingCategories = (payload) => {
  if (payload.length) {
    listCategory.value = payload.map((item) => {
      return item.label;
    });
  }
};

onMounted(() => {
  fetchTopView();
  domain.value = window.location.hostname
});
</script>
