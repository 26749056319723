<template>
  <div class="statistical">
    <div class="flex gap-4">
      <a-dropdown class="btn-search">
        <template #overlay>
          <a-menu @click="handleChoseYear" class="dropdown-option">
            <a-menu-item v-for="type in YEAR" :key="type.id">
              {{ type.name }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button>
          <span @click.prevent> {{ yearFilter }}</span>
          <IconDropdown />
        </a-button>
      </a-dropdown>
      <a-dropdown class="btn-search">
        <template #overlay>
          <a-menu @click="handleChoseMonth" class="dropdown-option">
            <a-menu-item v-for="type in MONTH" :key="type.id">
              {{ type.name }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button :disabled="yearValue === ''">
          <span @click.prevent> {{ monthFilter }}</span>
          <IconDropdown />
        </a-button>
      </a-dropdown>
    </div>
    <div class="mt-10">
      <div class="flex justify-between">
        <p class="font-bold text-xl">
          {{ t("courseManagement.common.statistical") }}
        </p>
        <p>
          <a
            class="text-blue1 text-sm font-bold"
            @click="
              () => {
                handleOpenModal(TYPE_MODAL.course);
              }
            "
            ><span>>> </span> <i class="underline">Chi tiết </i>
          </a>
        </p>
      </div>
      <div class="mt-4 flex gap-5 max-w-7xl">
        <BoxStatistical
          :title="t('courseManagement.statistical.staffJoin')"
          name="study"
          icon="/images/courseManagement/study.svg"
          :count="generalStatistics.participation"
          color="#813ED3"
        >
        </BoxStatistical>
        <BoxStatistical
          :title="t('courseManagement.statistical.compulsoryStaff')"
          name="staff"
          icon="/images/courseManagement/staff.svg"
          :count="generalStatistics.required"
          :percent="generalStatistics.percent"
          color="#62DE00"
        >
        </BoxStatistical>
        <BoxStatistical
          :title="t('courseManagement.statistical.evarageTime')"
          name="clock"
          icon="/images/courseManagement/clock.svg"
          :count="generalStatistics.learning_time"
          color="#3ED3AF"
        >
        </BoxStatistical>
        <BoxStatistical
          :title="t('courseManagement.statistical.evarageProgress')"
          name="stonk"
          icon="/images/courseManagement/stonk.svg"
          :percent="generalStatistics.progress"
          color="#3E83D3"
        >
        </BoxStatistical>
      </div>
    </div>
    <div class="mt-10">
      <div class="flex justify-between">
        <p class="font-bold text-xl">
          {{ t("courseManagement.statistical.testStatistics") }}
        </p>
        <p>
          <a
            class="text-blue1 text-sm font-bold"
            @click="
              () => {
                handleOpenModal(TYPE_MODAL.score);
              }
            "
            ><span>>> </span> <i class="underline">Chi tiết </i>
          </a>
        </p>
      </div>
      <TableStatistical :month="monthValue" :year="yearValue" />
    </div>
    <ModalTable
      :title="t('courseManagement.statistical.scoreDetail')"
      :subTitle="t('courseManagement.statistical.sub1')"
      :isVisible="isOpenDetailScore"
      :column="COLUMN_STATISTICS_DETAIL"
      :dataTable="dataDetailTest"
      :listUser="listUser"
      :listTest="listTest"
      @cancel="closeModal"
      @export="handleExport"
    >
    </ModalTable>
    <ModalTable
      :title="t('courseManagement.statistical.statisticalDetail')"
      :subTitle="t('courseManagement.statistical.sub2')"
      :isVisible="isOpenModalCourse"
      :column="COLUMN_DETAIL_COURSE"
      :listUser="listUser"
      :isDetailCourse="true"
      :isCourse="true"
      @cancel="closeModal"
      @export="handleExport"
    >
    </ModalTable>
  </div>
</template>
<script setup>
import { ref, defineComponent, onMounted, reactive, computed } from "vue";
import BoxStatistical from "./BoxStatistical.vue";
import TableStatistical from "./TableStatistical.vue";
import IconDropdown from "../../../../../components/icon/icon-dowload.vue";
import { TypeFilters } from "../../utils/interface";
import { formatDate } from "../../../../../utils/helper";
import { useI18n } from "vue-i18n";
import { truncate } from '../../../../../utils/helper'
import {
  YEAR,
  MONTH,
  COLUMN_STATISTICS_DETAIL,
  COLUMN_DETAIL_COURSE,
} from "../../utils/constant";
import ModalTable from "../../../../../components/modal/ModalTable.vue";
import {
  statisticalApi,
  courseMangemenetApi,
  step3ManagementApi,
} from "../../api/request";
import { useStore } from "vuex";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";
import axios from "axios";
import { SERVICE_URL_WIKI_BASE } from "../../api/pathUrl";

const TYPE_MODAL = {
  course: "COURSE",
  score: "SCORE",
};

const { t } = useI18n();
const store = useStore();
const yearFilter = ref("Năm");
const monthFilter = ref("Tháng");
const yearValue = ref("");
const monthValue = ref("");
const dataDetailTest = ref([]);
const dataDetailCourse = ref([]);
const isOpenModalCourse = ref(false);
const isOpenDetailScore = ref(false);
const listUser = ref([]);
const listTest = ref([]);
const courseId = computed(() => store.state.courseManagement.course_id);
const generalStatistics = reactive({
  learning_time: 0,
  participation: 0,
  progress: 0,
  required: 0,
  percent: 0,
});
const paramsGeneralStatistics = reactive({
  course_id: courseId.value,
  year: "",
  month: "",
});
const paramsDetailTest = reactive({
  course_id: courseId.value,
  limit: 20,
  page: 1,
});

const handleChoseYear = (e) => {
  let findYear = YEAR.find((item) => item.id === e.key);
  yearFilter.value = findYear.name;
  yearValue.value = findYear.type;
  if (yearValue.value === "") {
    (monthFilter.value = MONTH[0].name), (monthValue.value = MONTH[0].type);
    paramsGeneralStatistics.year = "";
    paramsGeneralStatistics.month = "";
  }
  paramsGeneralStatistics.year = yearValue.value;
  fetchGeneralStatistics(paramsGeneralStatistics);
};

const handleChoseMonth = (e) => {
  let findYear = MONTH.find((item) => item.id === e.key);
  monthFilter.value = findYear.name;
  monthValue.value = findYear.type;
  paramsGeneralStatistics.month = monthValue.value;
  fetchGeneralStatistics(paramsGeneralStatistics);
};

const closeModal = () => {
  isOpenDetailScore.value = false;
  isOpenModalCourse.value = false;
};

defineComponent({
  components: {
    BoxStatistical,
    ModalTable,
  },
});

const handleOpenModal = (type) => {
  switch (type) {
    case TYPE_MODAL.course:
      isOpenModalCourse.value = true;
      break;
    case TYPE_MODAL.score:
      isOpenDetailScore.value = true;
      break;
    default:
      break;
  }
};

const handleExport = (payload) => {
  const {type , ...params} = {
    ...payload
  }
  axios
    .get(
      SERVICE_URL_WIKI_BASE +
        `statistical/detail/${payload.type}?export=true`,
      {
        method: "GET",
        responseType: "blob", // important
        params: params
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${truncate(response.headers['content-disposition'])}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

const fetchListUser = async () => {
  const res = await courseMangemenetApi.getListUser();
  listUser.value = [...res.data];
};

const fetchListTest = async () => {
  const res = await step3ManagementApi.getListTest(paramsDetailTest);
  listTest.value = [...res.data];
};

const fetchGeneralStatistics = async (params) => {
  const { data } = await statisticalApi.getGeneralStatistics(params);
  generalStatistics.participation = data.data.participation;
  generalStatistics.learning_time = Math.round(
    parseFloat(data.data.learning_time)
  );
  generalStatistics.progress = Math.round(parseFloat(data.data.progress));
  generalStatistics.required = data.data.required;
  generalStatistics.percent = Math.round(
    parseFloat(generalStatistics.required / generalStatistics.participation) *
      100
  );
};

onMounted(() => {
  fetchGeneralStatistics(paramsGeneralStatistics);
  fetchListUser();
  fetchListTest();
  const allTag = document.querySelectorAll(".ant-tabs-tab-btn");
  for (var i = 0; i < allTag.length; i++) {
    allTag[i].title = allTag[i].innerHTML;

    allTag[i].style.position = "relative";
    var beforeElement = document.createElement("div");
    beforeElement.classList.add("tab-item-before");
    beforeElement.innerText = allTag[i].getAttribute("title");
    beforeElement.style.fontWeight = "bold"
    beforeElement.style.height = "0px"
    beforeElement.style.overflow = "hidden"
    beforeElement.style.paddingRight="2px"
    allTag[i].appendChild(beforeElement);
  }
});

</script>
<style lang="scss" scoped>
.btn-search {
  font-weight: 600;
  font-size: 16px;
  line-height: 193%;
  color: #929292;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    display: inline-block;
    margin-right: 6px;
  }
  svg {
    display: inline-block;
    margin-bottom: 20px;
  }
}
.tab-item-before {
  font-weight: bold;
  height: 0;
  overflow: hidden;
}
</style>