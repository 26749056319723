<template>
  <div class="login-container">
    <img class="mor-logo" src="/images/logo.png" alt="MOR logo" />
    <a-form
      :model="formState"
      layout="vertical"
      id="form-container"
      @finish="handleSubmit"
    >
      <h1 class="title">Chào mừng đến với MorWiki</h1>
      <a-form-item
        name="email"
        :rules="[
          {
            type: 'email',
            message: 'Địa chỉ email không đúng!'
          },
          {
            required: true,
            message: 'Nhập địa chỉ email!'
          }
        ]"
      >
        <a-input v-model:value="formState.email" placeholder="Email address">
          <i slot="prefix" style="color: rgba(28, 28, 30, 0.72)">
            <IconEnvelope />
          </i>
        </a-input>
      </a-form-item>
      <a-form-item
        name="password"
        class="form-item"
        :rules="[{ required: true, message: 'Nhập mật khẩu!' }]"
      >
        <a-input
          v-model:value="formState.password"
          :type="inputType"
          placeholder="Password"
        >
          <i slot="prefix" style="color: rgba(28, 28, 30, 0.72)">
            <IconLock />
          </i>
        </a-input>
      </a-form-item>
      <span class="incorrect">{{ errorMessage }}</span>
      <a-form-item>
        <a-checkbox @change="onToggleShowPassword" class="login-checkbox">
          Hiển thị mật khẩu
        </a-checkbox>
      </a-form-item>
      <a-button type="primary" html-type="submit" class="login-form-button">
        Đăng nhập
      </a-button>
    </a-form>
  </div>
</template>

<script>
import { setUserInfo } from '../../utils/authenticate';
import { login } from '../../api/auth';
import IconEnvelope from '../../components/icon/icon-envelope.vue';
import IconLock from '../../components/icon/icon-lock.vue';

import { defineComponent, reactive } from 'vue';

export default defineComponent({
  components: { IconEnvelope, IconLock },
  setup() {
    const formState = reactive({
      email: '',
      password: ''
    });

    return {
      formState
    };
  },
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      errorMessage: '',
      isShowPassword: false
    };
  },
  methods: {
    async handleSubmit(data) {
      try {
        const response = await login(data);
        setUserInfo(JSON.stringify(response.data.user));
        this.$router.push({ name: 'Home' });
      } catch (error) {
        this.errorMessage = error.message.errors[0].message;
      }
    },
    onToggleShowPassword() {
      this.isShowPassword = !this.isShowPassword;
    }
  },
  computed: {
    inputType() {
      return this.isShowPassword ? 'text' : 'password';
    }
  }
});
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  background: url(/images/login-background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mor-logo {
  width: 185px;
  height: 63px;
  position: absolute;
  left: 9%;
  top: 3%;
}
#form-container {
  width: 528px;
  border-radius: 24px;
  border: 0.5px solid #c4c4c4;
  background-color: #fff;
  box-shadow: 0px 10px 1px rgb(63 48 37 / 25%);
  padding: 40px 72px;
  position: absolute;
  right: 10%;
  top: 25%;
}
.login-form-button:hover {
  background: #fc9c56 !important;
  border-color: #fc9c56 !important;
}
.incorrect {
  color: #f5222d;
}
:deep(.ant-form-item) {
  margin-bottom: 16px;
}
:deep(.ant-input) {
  height: 60px;
  border-radius: 8px;
}
.title {
  color: #333;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
:deep(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}
:deep(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #1280bf;
  border-color: #1280bf;
}
:deep(.ant-btn) {
  width: 100%;
  margin-top: 7px;
  height: 48px !important;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
}
:deep(.ant-input:not(:first-child)) {
  padding-left: 46px;
}
:deep(.ant-btn[disabled]:hover) {
  background: #1280bf !important;
  border-color: #1280bf !important;
}
</style>
