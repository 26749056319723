<template>
  <div class="container-fluid category-content">
    <div>
      <h1 class="title">Danh sách danh mục bài viết</h1>
      <div class="btn-wrapper">
        <a-button class="add" type="primary" @click="handleActions(ACTION.Add)">
          <PlusOutlined />
          Thêm danh mục
        </a-button>
      </div>

      <a-table
        :columns="COLUMN_CATEGORY"
        :pagination="false"
        :scroll="scrollHeightTable"
        :data-source="dataTable"
        :loading="loadingTable"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <div class="actions-wrapper">
              <span
                class="icon"
                @click="handleActions(ACTION.Edit, record.id, record)"
              >
                <IconEdit />
              </span>
              <span class="icon" @click="handleActions(ACTION.Del, record.id)">
                <IconDelete />
              </span>
            </div>
          </template>
        </template>
      </a-table>
    </div>

    <div class="pagination-wrapper">
      <span>Rows per page:</span>
      <a-select default-value="10">
        <i class="fas fa-sort-down dropdown-icon"></i>
        <a-select-option
          v-for="(pageSize, index) in PAGE_SISES"
          :value="pageSize"
          :key="`pageSize_${index}`"
          @click="handleActions(ACTION.ChangeSize, null, pageSize)"
        >
          {{ pageSize }}
        </a-select-option>
      </a-select>
      <span class="total-page"
        >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
      >
      <a-button
        :disabled="meta.from === 1 || !meta.from"
        class="pagination-btn"
        @click="handleActions(ACTION.Prev)"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <a-button
        :disabled="meta.to === meta.total || !meta.total"
        class="pagination-btn"
        @click="handleActions(ACTION.Next)"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
    <a-modal
      :visible="modalAdd.visible"
      :footer="null"
      :title="modalAdd.title"
      @cancel="handleActions(ACTION.Cancel)"
      centered
    >
      <a-form
        layout="vertical"
        :model="formState"
        @finish="handleSubmit"
        ref="formRef"
      >
        <div v-if="!!editId" class="category-value">
          <span class="category-title">Tên danh mục:</span>
          <span class="category-name">{{ editName }}</span>
        </div>
        <a-form-item
          label="Tên danh mục"
          name="name"
          :rules="[
            { required: true, message: 'Không được để trống' },
            { whitespace: true, message: 'Không được để trống' }
          ]"
        >
          <a-input v-model:value="formState.name" />
        </a-form-item>

        <div class="btn-wrapper-modal">
          <a-button @click="handleActions(ACTION.Cancel)"> Hủy </a-button>
          <a-button type="primary" html-type="submit">
            {{ editId ? 'Cập nhật' : 'Thêm' }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive } from 'vue';
import { categoryManagement } from '../../api/category';
import Loading from '../../components/loading/Loading.vue';
import ModalConfirm from '../../components/modal/ModalConfirm.vue';
import IconDelete from '../../components/icon/icon-delete.vue';
import IconEdit from '../../components/icon/icon-edit.vue';
import { useLoading, useNotify, useShowConfirm } from '../../utils/hook';
import { computed, onMounted, ref, onBeforeUnmount } from 'vue';
import { COLUMN_CATEGORY, ACTION, PAGE_SISES } from './utils/constant';

defineComponent({
  components: {
    IconDelete,
    IconEdit,
    Loading,
    ModalConfirm,
    PlusOutlined
  }
});
const { hideLoading, showLoading } = useLoading();
const { notifySuccess, notifyError } = useNotify();
const { showConfirm } = useShowConfirm();
const formRef = ref();
const formState = reactive({
  name: ''
});
const meta = reactive({
  to: null,
  from: null,
  currentPage: null,
  total: null
});
const modalAdd = reactive({
  visible: false,
  title: ''
});
const editId = ref(null);
const editName = ref('');
const dataTable = ref([]);
const loadingTable = ref(false);
const showLocationPage = ref(false);
const paramsFetchListCategory = reactive({
  limit: 10,
  page: 1
});
const scrollHeightTable = reactive({
  y: 'calc(100vh - 400px)'
});

onMounted(() => {
  window.addEventListener('resize', onWidthChange);
  getListCategory(paramsFetchListCategory);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onWidthChange);
});

const onWidthChange = (e) => {
  if (window.innerWidth <= 1366) {
    shinkHeader.value = true;
    scrollHeightTable.value = {
      y: 'calc(100vh - 350px)'
    };
  }
};

const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Add:
      editId.value = null;
      modalAdd.title = 'Thêm mới danh mục';
      modalAdd.visible = true;
      break;
    case ACTION.Edit:
      modalAdd.title = 'Chỉnh sửa danh mục';
      modalAdd.visible = true;
      editId.value = id;
      editName.value = payload.name;
      break;
    case ACTION.Cancel:
      formRef.value.resetFields();
      modalAdd.visible = false;
      break;
    case ACTION.Del:
      showConfirm({
        type: 'delete',
        suffix: 'danh mục',
        onOk: () => {
          deleteCategory(id);
        }
      });
      break;
    case ACTION.ChangeSize:
      paramsFetchListCategory.limit = payload;
      getListCategory(paramsFetchListCategory);
      break;
    case ACTION.Prev:
      paramsFetchListCategory.page = paramsFetchListCategory.page - 1;
      getListCategory(paramsFetchListCategory);
      break;
    case ACTION.Next:
      paramsFetchListCategory.page = paramsFetchListCategory.page + 1;
      getListCategory(paramsFetchListCategory);
      break;
  }
};

const handleSubmit = (data) => {
  if (!editId.value) {
    addCategory(data);
    return;
  }
  showConfirm({
    type: 'edit',
    suffix: 'danh mục',
    onOk: () => {
      editCategory(editId.value, data);
    }
  });
};

const getListCategory = async (params) => {
  try {
    loadingTable.value = true;
    const res = await categoryManagement.getListCategory(params);
    if (res.data.data.length > 0) {
      dataTable.value = res.data.data.map((item, index) => ({
        ...item,
        key: index,
        no: (res.data.current_page - 1) * res.data.per_page + index + 1
      }));
      showLocationPage.value = true;
    }
    if (res.data.data.length === 0) {
      dataTable.value = [];
      showLocationPage.value = false;
    }
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } catch (error) {
  } finally {
    loadingTable.value = false;
  }
};

const addCategory = async (payload) => {
  try {
    showLoading();
    const response = await categoryManagement.createCategory(payload);
    formRef.value.resetFields();
    modalAdd.visible = false;
    getListCategory(paramsFetchListCategory);
    notifySuccess('Tạo danh mục thành công');
  } catch (error) {
    notifyError('Tên đanh mục đã tồn tại');
    throw error;
  } finally {
    hideLoading();
  }
};

const deleteCategory = async (id) => {
  try {
    showLoading();
    const response = await categoryManagement.deleteCategory(id);
    getListCategory(paramsFetchListCategory);
    notifySuccess('Xóa danh mục thành công');
  } catch (error) {
    notifyError('Xóa danh mục thất bại');
  } finally {
    hideLoading();
  }
};
const editCategory = async (id, payload) => {
  try {
    showLoading();
    const response = await categoryManagement.editCategory(id, payload);
    formRef.value.resetFields();
    modalAdd.visible = false;
    getListCategory(paramsFetchListCategory);
    notifySuccess('Cập nhật danh mục thành công');
  } catch (error) {
    notifyError('Tên đanh mục đã tồn tại');
    throw error;
  } finally {
    hideLoading();
  }
};
</script>

<style lang="scss" scoped>
.actions-wrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-right: 10px;
  span:hover {
    cursor: pointer;
  }
}

:deep(.ant-modal-title) {
  text-align: center;
  color: #000000;
  font-size: 24px;
  line-height: calc(28.97 / 24);
  font-weight: 600;
}

:deep(label) {
  color: #333333;
  font-size: 16px;
  line-height: 162.02%;
  font-weight: 600;
}
:deep(.ant-modal-header) {
  border-bottom: none !important;
}
:deep(.ant-modal-body) {
  padding: 8px 24px;
}

.category-value {
  display: flex;
  margin-bottom: 10px;
  .category-title {
    color: #333333;
    font-size: 16px;
    line-height: 162.02%;
    font-weight: 600;
    margin-right: 5px;
  }
  .category-name {
    color: #0047ff;
    font-size: 16px;
    line-height: calc(26 / 16);
    font-weight: 600;
  }
}

.category-content {
  padding: 20px 24px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icon-add {
    position: relative;
    top: -1px;
  }
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    width: 160px;
    padding: 6px;
  }
  .ant-btn-primary {
    background-color: #1280bf;
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 18px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.pagination-wrapper {
  display: flex;
  margin-top: 30px;
  justify-content: right;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    padding: 14px;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
:deep(.ant-table) {
  color: #000;
  font-size: 16px;
  th {
    font-size: 20px;
  }

  tr > td {
    padding: 16px 16px;
  }
  @media only screen and (max-width: 1366px) {
    tr > td {
      padding: 12px 12px;
    }
  }
}

.btn-wrapper-modal {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
</style>
