import { rolesConstants, routesConstants } from '../../constant';

export const noAuth = (context) => {
  const { next, isLoggedIn } = context;
  if (isLoggedIn) {
    return next({ name: 'Home' });
  }
  return next();
};

export const getPermissionHome = (context) => {
  const { next, isLoggedIn } = context;
  if (isLoggedIn) {
    return next();
  }
  return next({ name: 'Login' });
};
export const getPermissionELearning = (context) => {
  const { next, isLoggedIn } = context;
  if (isLoggedIn) {
    return next({ name: routesConstants.homeELearning });
  }
  return next({ name: 'Login' });
};

const roleAdminSite = [rolesConstants.ADMIN, rolesConstants.HRM];
export const isRoleAdminSite = (roles) => {
  return roles?.some((role) => roleAdminSite.includes(role.name));
};

export const getPermissionAdmin = (context) => {
  const { next, user, isLoggedIn } = context;
  if (!isLoggedIn) {
    return next({ name: 'Login' });
  }
  const roles = JSON.parse(user).roles;
  if (!isRoleAdminSite(roles)) return next({ name: 'Home' });
  return next();
};
