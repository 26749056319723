/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { createApp } from 'vue';
import router from './config/routes';
import store from './store';
import { Form, HasError, AlertError } from 'vform';
import moment from 'moment';
import Swal from 'sweetalert2';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import Vuex from 'vuex';
import App from './App.vue';
import i18n from './i18n';
import './index.css'
require('./bootstrap');
window.Vue = require('vue');
window.Form = Form;

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
window.Swal = Swal;
window.Toast = Toast;

const options = {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  thickness: '3px',
  autoRevert: true,
  location: 'left',
  inverse: false
};

const app = createApp(App)
  .use(Vuex)
  .use(CKEditor)
  .use(Antd)
  .use(VueProgressBar, options)
  .use(router)
  .use(store)
  .use(i18n);

app.component(HasError.name, HasError);
app.component(AlertError.name, AlertError);

app.config.globalProperties.$filters = {
  myDate(created) {
    return moment(created).format('MMMM Do YYYY');
  },
  yesno(value) {
    return value
      ? '<i class="fas fa-check green"></i>'
      : '<i class="fas fa-times red"></i>';
  }
};

app.mount('#app');
