<template>
  <a-modal
    :visible="isShow"
    title="Thắc mắc và phản hồi!"
    class="modal-feedback"
    @cancel="hiddenModalFeedback"
    :footer="null"
    :width="750"
    centered
  >
    <div class="flex flex-row gap-10">
      <div>
        <p class="text-sm leading-[16px] text-[#9B9A9A]">Your Email</p>
        <p class="mt-2 font-bold leading-[16px]">{{ userInfo.email }}</p>
      </div>
      <div>
        <p class="text-sm leading-[16px] text-[#9B9A9A]">Your Name</p>
        <p class="mt-2 font-bold leading-[16px]">{{ userInfo.name }}</p>
      </div>
    </div>
    <a-form :model="formState" autocomplete="off" @finish="handleSubmit">
      <a-row :gutter="12" class="mt-3">
        <a-col :span="formState?.category_id && isNotWiki ? 12 : 24">
          <p class="font-bold">Danh mục</p>
          <a-form-item
            name="category_id"
            class="mt-2"
            :rules="[{ required: true, message: 'Không được để trống!' }]"
          >
            <a-select
              class="text-xs"
              placeholder="Lựa chọn danh mục"
              allowClear
              showSearch
              optionFilterProp="filterProps"
              v-model:value="formState.category_id"
              @change="handleChangeCategory"
            >
              <a-select-option
                class="text-xs"
                v-for="category in categories"
                :value="category.id"
                :key="category.id"
                :name="category.name"
                :filterProps="category.name"
              >
                {{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="formState.category_id && isNotWiki">
          <p class="font-bold">Bài viết</p>
          <a-form-item
            label=""
            name="post_id"
            class="mt-2"
            :rules="[{ required: true, message: 'Không được để trống!' }]"
          >
            <a-select
              class="text-xs"
              placeholder="Lựa chọn bài viết"
              allowClear
              showSearch
              optionFilterProp="filterProps"
              v-model:value="formState.post_id"
              @popupScroll="handleScroll"
              :loading="loadingLoadMore"
            >
              <a-select-option
                class="text-xs"
                v-for="post in listPost"
                :value="post.id"
                :key="post.id"
                :filterProps="post.title"
              >
                {{ post.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="12" class="flex flex-col px-[6px]">
        <p class="font-bold">Nội dung</p>
        <a-form-item
          name="content"
          class="mt-2"
          :rules="[
            { required: true, message: 'Không được để trống!' },
            { max: 1000, message: 'Tối đã 1000 ký tự' },
            { whitespace: true, message: 'Không được để trống', trigger: 'blur'},
          ]"
        >
          <a-textarea
            class="text-xs"
            placeholder="Nhập nội dung cần giải đáp"
            :rows="6"
            v-model:value="formState.content"
          ></a-textarea>
        </a-form-item>
      </a-row>
      <div class="flex justify-end mt-3">
        <button
          htm-type="submit"
          class="flex items-center gap-1 bg-gradient-to-r from-[#E4B52A] to-[#EC2A24] text-base font-bold py-2 px-4 rounded-[5px] text-white"
        >
          Send
          <span
            class="loader"
            :class="{ 'inline-block': isSending, hidden: !isSending }"
          ></span>
        </button>
      </div>
    </a-form>
  </a-modal>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watchEffect, h } from 'vue'
import { useStore } from 'vuex'
import { getUserInfo } from '../../../utils/authenticate'
import { postMangemenetApi as postApi } from '../api/request'
import { USER_HANDLER, SYSTEM_WIKI } from '../utils/constant'
import { notification } from 'ant-design-vue'
import {LIST_CATEGORY} from '../../../constant/common'

const store = useStore()
const isShow = computed(() => store.state.common.isShowModalFeedback)

const formState = reactive({
  content: null,
  category_id: null,
  post_id: null,
  handler: USER_HANDLER,
})

const isNotWiki = ref(true)

watchEffect(function () {
  if (formState.category_id && isNotWiki) {
    formState.post_id = null
    paramsGetListPost.category_id = formState.category_id
    listPost.value = []
    mountListPost(paramsGetListPost)
  }
})

const limitOptions = ref(10)
const paramsGetListPost = reactive({
  limit: limitOptions.value,
  page: 1,
  category_id: null,
})

const categories = ref([])
const listPost = ref([])
const loadingLoadMore = ref(false)
const isFullPostOptions = ref(false)
const isSending = ref(false)

const userInfo = computed(() => JSON.parse(getUserInfo()))
const hiddenModalFeedback = function () {
  store.dispatch('handleShowModalFeedback', false)
}
const fetchListCategory = async function () {
  const res = await postApi.getLisCategory()
  categories.value = res?.data
    .map(item => {
      if (LIST_CATEGORY.includes(item.name)) {
        return item
      }
    })
    .filter(item => item?.id)
}
const mountListPost = async function (params) {
  const resList = await fetchListPost(params)
  if (!resList || resList.length < limitOptions.value) {
    isFullPostOptions.value = true
  }
  listPost.value = listPost.value.concat(resList)
  loadingLoadMore.value = false
}
const fetchListPost = async function (params) {
  const res = await postApi.getLisPost(params)
  return res?.data
}

const handleScroll = async function (e) {
  const { target } = e
  if (isFullPostOptions.value) return
  if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
    loadingLoadMore.value = true
    paramsGetListPost.page = paramsGetListPost.page + 1
    mountListPost(paramsGetListPost)
  }
}

const handleSubmit = async function () {
  isSending.value = true
  await postApi.createFeedBack({
    ...formState,
    content: formState.content.trim(),
  })
  isSending.value = false
  formState.content = null
  formState.category_id = null
  formState.post_id = null
  notification.open({
    style: {
      background: '#00d909 !important',
      color: '#ffffff !important',
    },
    description: () => {
      return h('div', [
        h('p', 'Cảm ơn bạn đã gửi phản hồi!'),
        h(
          'p',
          'Chúng tôi sẽ giải đáp thắc mắc của bạn trong thời gian sớm nhất!',
        ),
      ])
    },
  })
  store.dispatch('handleShowModalFeedback', false)
}

const handleChangeCategory = (_, e) => {
  if (e.name === SYSTEM_WIKI) {
    isNotWiki.value = false
  } else {
    isNotWiki.value = true
  }
}

onMounted(() => {
  fetchListCategory()
  // mountListPost(paramsGetListPost);
})
</script>
<style lang="scss" scoped>
.loader {
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:deep(.ant-form-item-control-input-content) {
  textarea {
    ::placeholder {
      color: red;
    }
  }
}
</style>