<template>
  <a-form
    ref="form"
    :model="formState"
    autocomplete="off"
    @finish="() => submit(formState)"
  >
    <a-row class="mb-15">
      <a-col :span="24">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.name')"
          name="name"
          :rules="[
            {
              required: true,
              message: t('courseManagement.step3.validate.nameRequired')
            }
          ]"
        >
          <a-input
            :disabled="isViewCourse"
            v-model:value="formState.name"
            :placeholder="t('courseManagement.step3.form.namePlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="mb-15">
      <a-col :span="24">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.description')"
          name="description"
          :rules="[
            {
              required: true,
              message: t('courseManagement.step3.validate.descriptionRequired')
            }
          ]"
        >
          <div class="vue-editor-wrapper">
            <ckeditor
              :disabled="isViewCourse"
              :editor="editor.ref"
              v-model="formState.description"
              :config="editor.config"
              @ready="handleOnReadyEditor"
              @input="
                (value) => {
                  handleChangeEditor(value);
                  clearValidate('description');
                }
              "
            ></ckeditor>
          </div>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="mb-15">
      <a-col :span="10">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.condition')"
          name="conditionTestID"
        >
          <a-select
            v-model:value="formState.conditionTestID"
            class="form-input"
            :placeholder="t('courseManagement.step3.form.conditionPlaceholder')"
          >
            <a-select-option v-for="item in listCondition" :value="item.id" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4"></a-col>
      <a-col :span="10">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.time')"
          name="testTime"
          :rules="[{ required: true, validator: checkTime, trigger: 'change' }]"
        >
          <a-row>
            <a-col :span="12">
              <div class="flex-box">
                <a-input-number
                  :disabled="isViewCourse"
                  v-model:value="formState.testTime.hours"
                  :placeholder="
                    t('courseManagement.step3.form.hoursPlaceholder')
                  "
                />
                <p class="mb-0 form-label pl-15">
                  {{ t('courseManagement.step3.form.hours') }}
                </p>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="flex-box">
                <a-input-number
                  :disabled="isViewCourse"
                  v-model:value="formState.testTime.minutes"
                  :placeholder="
                    t('courseManagement.step3.form.minutePlaceholder')
                  "
                />
                <p class="mb-0 form-label pl-15">
                  {{ t('courseManagement.step3.form.minute') }}
                </p>
              </div>
            </a-col>
          </a-row>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="mb-15">
      <a-col :span="10">
        <a-form-item
          class="form-label"
          :label=" t('courseManagement.step3.form.times')"
        >
          <a-row>
            <a-col :span="12">
              <a-input-number
                :disabled="formState.noLimitTimesOfTest || isViewCourse"
                v-model:value="formState.timesOfTest"
                min="0"
                :placeholder="t('courseManagement.step3.form.timesPlaceholder')"
              />
            </a-col>
            <a-col :span="12">
              <a-checkbox
                :disabled="isViewCourse"
                @change="handleChangeCheckLimitTimesTest"
                v-model:checked="formState.noLimitTimesOfTest"
                class="form-label flex-box h-full"
              >
                {{ t('courseManagement.step3.form.noLimited') }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-form-item>
      </a-col>
      <a-col :span="4"></a-col>
      <a-col :span="10">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.limitPoint')"
          name="limitScore"
        >
          <a-input-number
            v-model:value="formState.limitScore"
            :disabled="isViewCourse"
            max="100"
            min="0"
            :placeholder="
              t('courseManagement.step3.form.limitPointPlaceholder')
            "
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="mb-15">
      <a-col :span="5">
        <a-form-item
          class="form-label"
          :label="t('courseManagement.step3.form.randomQuestion')"
          name="limitQuestion"
          :rules="[{  validator: checkLimitQuest, trigger: 'change' }]"
        >
          <a-input
            :disabled="isViewCourse"
            v-model:value="formState.limitQuestion"
            :placeholder="
              t('courseManagement.step3.form.randomQuestionText')
            "
          />
        </a-form-item>
      </a-col>
    </a-row>
    <draggable v-model="formState.questions" group="people" item-key="id">
      <template #item="{ element }">
        <div class="mb-15 question-wrap">
          <div>
            <div class="flex-between">
              <p class="form-label flex-center mb-10">
                <a-tooltip placement="top">
                  <template #title>
                    <span>Kéo thả để thay đối thứ tự câu hỏi</span>
                  </template>
                  <IconDrag class="drag-icon icon-16" />
                </a-tooltip>
                {{ t('courseManagement.step3.form.questionPrefix') }}
                {{ getQuestionIndex(element.key) + 1 }}
              </p>
              <a-tooltip v-if="!isViewCourse" placement="top">
                <template #title>
                  <span>{{
                    t('courseManagement.step3.toolTip.deleteQuestion')
                  }}</span>
                </template>
                <IconTrash
                  class="icon-23"
                  @click="deleteQuestion(element.key)"
                />
              </a-tooltip>
            </div>
            <div class="pr-60">
              <a-form-item
                class="form-label mb-15"
                :name="['questions', getQuestionIndex(element.key), 'content']"
                :rules="[
                  {
                    required: true,
                    message: t(
                      'courseManagement.step3.validate.questionRequired'
                    )
                  }
                ]"
              >
                <a-input
                  :disabled="isViewCourse"
                  v-model:value="element.content"
                  :placeholder="
                    t('courseManagement.step3.form.questionPlaceholder')
                  "
                />
              </a-form-item>
            </div>
          </div>
          <a-form-item
            class="form-label correct-answer-checker"
            :name="['questions']"
            :rules="[
              {
                validator: (_rule, value) =>
                  checkAnswer(_rule, value, getQuestionIndex(element.key))
              }
            ]"
          >
            <div
              v-for="(answer, idx) in element.answers"
              :key="answer.key"
              class="pl-15 mb-15"
            >
              <a-form-item
                class="form-label"
                :name="[
                  'questions',
                  getQuestionIndex(element.key),
                  'answers',
                  idx,
                  'content'
                ]"
                :rules="[
                  {
                    required: true,
                    message: t('courseManagement.step3.validate.answerRequired')
                  }
                ]"
              >
                <div class="flex-box">
                  <p class="form-label w-20">{{ listAnswerNames[idx] }}</p>
                  <a-input
                    :disabled="isViewCourse"
                    v-model:value="answer.content"
                    :placeholder="
                      t('courseManagement.step3.form.answerPlaceholder')
                    "
                  />
                  <div class="w-60 flex-between pl-15">
                    <a-tooltip placement="top">
                      <template #title>
                        <span>{{
                          t('courseManagement.step3.toolTip.correctAnswer')
                        }}</span>
                      </template>
                      <div
                        :class="{
                          'flex-box': true,
                          'pointer-events-none': isViewCourse
                        }"
                      >
                        <span
                          :class="
                            answer.isCorrect
                              ? 'ant-checkbox ant-checkbox-checked'
                              : 'ant-checkbox'
                          "
                          @click="
                            {
                              clearValidate('questions');
                              selectCorrectAnswer(
                                getQuestionIndex(element.key),
                                answer.key
                              );
                            }
                          "
                        >
                          <span class="ant-checkbox-inner"></span>
                        </span>
                      </div>
                    </a-tooltip>
                    <a-tooltip v-if="!isViewCourse" placement="top">
                      <template #title>
                        <span>{{
                          t('courseManagement.step3.toolTip.deleteAnswer')
                        }}</span>
                      </template>
                      <IconTrash
                        class="icon-16"
                        @click="
                          deleteAnswer(
                            getQuestionIndex(element.key),
                            answer.key
                          )
                        "
                      />
                    </a-tooltip>
                  </div>
                </div>
              </a-form-item>
            </div>
          </a-form-item>

          <div v-if="element.answers.length < 11" class="pl-15">
            <button
              v-if="!isViewCourse"
              type="button"
              @click="addMoreAnswer(getQuestionIndex(element.key))"
              class="btn-add"
            >
              <span class="icon-add"><IconAdd /></span
              >{{ t('courseManagement.step3.form.addAnswer') }}
            </button>
          </div>
        </div>
      </template>
    </draggable>
    <button
      v-if="(!testDetail && !isViewCourse) || (!isViewCourse && testDetail && (currentPage === lastPage))"
      type="button"
      @click="addMoreQuestion()"
      class="btn-add mb-15"
    >
      <span class="icon-add"><IconAdd /></span
      >{{ t('courseManagement.step3.form.addQuestion') }}
    </button>

    <div class="ant-modal-footer">
      <a-button type="secondary" html-type="button" @click="close">{{
        t('courseManagement.step3.form.cancel')
      }}</a-button>
      <a-button
        v-if="testDetail && !isViewCourse && (currentPage === lastPage)"
        type="primary"
        html-type="submit"
        >{{ t('courseManagement.step3.form.save') }}</a-button
      >
      <a-button
        v-if="!testDetail && !isViewCourse"
        type="primary"
        html-type="submit"
        >{{ t('courseManagement.step3.form.add') }}</a-button
      >
    </div>
  </a-form>
</template>
<script>
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { cloneDeep, filter, find, findIndex, map } from 'lodash';
import { computed, defineComponent, onMounted, reactive, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import draggable from 'vuedraggable';
import { useStore } from 'vuex';
import IconAdd from '../../../../components/icon/icon-add.vue';
import IconDrag from '../../../../components/icon/icon-drag.vue';
import IconTrash from '../../../../components/icon/icon-trash.vue';
import { generateId } from '../../../../utils/helper';
import { useLoading } from '../../../../utils/hook';
import { mapDataTestQuestionStep3 } from '.././utils/helpers';
import { step3ManagementApi } from '../api/request';
import { TOOL_BAR } from '../utils/constant';

export default defineComponent({
  components: {
    draggable,
    IconAdd,
    IconTrash,
    IconDrag
  },
  props: ['testItems', 'testDetail', 'lastPage', 'totalQuestion'],
  emits: ['scrollTable'],
  setup(props, emit) {
    const { t } = useI18n();
    const lastPage = ref(0);
    const currentPage = ref(1);
    const store = useStore();
    const listCondition = ref()
    const { showLoading, hideLoading } = useLoading();
    const handleChangeCheckLimitTimesTest = (value) => {
      if (value.target.checked) {
        formState.timesOfTest = '';
      }
    };
    /**
     * Hàm thêm mới câu hỏi
     */
    const addMoreQuestion = () => {
      formState.questions.push({
        key: generateId(formState.questions, 'key'),
        content: '',
        answers: [
          {
            key: generateId([], 'key'),
            content: '',
            isCorrect: true
          }
        ]
      });
    };
    
    watch(props, (val) => {
      lastPage.value = props.lastPage;
    });

    onMounted(()=>{
      listCondition.value = [...props.testItems]
      lastPage.value = props.lastPage;
      if(props.testDetail){
        const currentIdTest = props.testDetail.id
        const index = props.testItems.findIndex((item) => item.id == currentIdTest)
        if(index || index === 0){
          listCondition.value.splice(index, 1)
        }
      }
      const tableElement = document.querySelector(".ant-modal-wrap")
      tableElement.addEventListener("scroll", function(event) {
        if (currentPage.value === lastPage.value) return;

        const currentScroll = tableElement.scrollTop;
        const maxScroll = tableElement.scrollHeight - tableElement.clientHeight;
        if (currentScroll >= maxScroll - 3) {
          onScrollTable()
        }
      });
    })

    const onScrollTable = async () => {
      currentPage.value += 1;
      showLoading();
      try {
        const params = {
          page: currentPage.value,
          limit: 10
        };
        const res  = await step3ManagementApi.getTestDetail(props.testDetail.id, params);
        formState.questions = [...formState.questions, ...mapDataTestQuestionStep3(res.data)];
      } catch (error) {
      } finally {
        hideLoading();
      }
    };

    /**
     * Hàm thêm mới câu trả lời
     * @param {number} index - Index của câu hỏi muốn thêm câu trả lời
     */
    const addMoreAnswer = (index) => {
      if (index === -1) return;
      formState.questions[index].answers.push({
        key: generateId(formState.questions[index].answers, 'key'),
        content: '',
        isCorrect: !formState.questions[index].answers.length
      });
    };

    /**
     * Hàm trả ra index hiện tại của câu hỏi, vì sử dụng drag drop
     * nên không get trực tiếp từ list được mà phải dùng hàm này
     * @param {string} key - key của câu hỏi
     */
    const getQuestionIndex = (key) => {
      return findIndex(formState.questions, (item) => item.key === key);
    };

    /**
     * Hàm xóa câu trả lời
     * @param {number} index - index của câu hỏi
     * @param {string} answerKey - key của câu trả lời muốn xóa
     */
    const deleteAnswer = (index, answerKey) => {
      if (index === -1 || !answerKey) return;
      const newAnswers = filter(
        formState.questions[index].answers,
        (item) => item.key !== answerKey
      );
      formState.questions[index].answers = newAnswers;
    };

    /**
     * Hàm xóa câu hỏi
     * @param {string} questionKey - key của câu hỏi muốn xóa
     */
    const deleteQuestion = (questionKey) => {
      if (!questionKey) return;
      const newQuestions = filter(
        formState.questions,
        (item) => item.key !== questionKey
      );
      formState.questions = newQuestions;
    };

    /**
     * Hàm chọn câu trả lời chính xác, chỉ chọn 1 trong các câu trả lời
     * @param {number} index - index của câu hỏi
     * @param {string} answerKey - key của câu trả lời chính xác
     */
    const selectCorrectAnswer = (index, answerKey) => {
      if (index === -1 || !answerKey) return;
      const newAnswers = map(formState.questions[index].answers, (item) => {
        if (item.key === answerKey) {
          return { ...item, isCorrect: !item.isCorrect };
        }
        return item;
        // return { ...item, isCorrect: item.key === answerKey };
      });
      formState.questions[index].answers = newAnswers;
    };

    /**
     * Hàm thay đổi nội dung giới thiệu bài kiểm tra
     * @param {string} value - nội dung giới thiệu trigger từ ckeditor
     */
    const handleChangeEditor = (value) => {
      formState.description = value;
    };

    /**
     * Hàm kiểm tra ckeditor đã load xong, sau đó thêm toolbar cho ckeditor
     * @param {Editor} editor - editor muốn kiểm tra
     */
    const handleOnReadyEditor = (editor) => {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    };
    /**
     * Định nghĩa state của form là khởi tạo
     * hay là từ props detail của component cha truyền vào
     */
    const formState = reactive(
      cloneDeep(props.testDetail) || {
        key: generateId(props.testItems || [], 'key'),
        name: '',
        description: '',
        conditionTestID: null,
        testTime: {
          hours: null,
          minutes: null
        },
        timesOfTest: null,
        noLimitTimesOfTest: false,
        limitScore: null,
        limitQuestion: null,
        questions: [
          {
            key: generateId([], 'key'),
            content: '',
            answers: [
              {
                key: generateId([], 'key'),
                content: '',
                isCorrect: true
              }
            ]
          }
        ],
        resource: '',
        desc: ''
      }
    );

    const isViewCourse = computed(
      () => store.state.courseManagement.view_course
    );

    return {
      t,
      formState,
      editor: {
        ref: DecoupledEditor,
        config: {
          placeholder: t('courseManagement.step3.form.descriptionPlaceholder'),
          toolbar: TOOL_BAR
        }
      },
      isViewCourse,
      addMoreQuestion,
      addMoreAnswer,
      getQuestionIndex,
      deleteAnswer,
      deleteQuestion,
      handleChangeEditor,
      handleOnReadyEditor,
      selectCorrectAnswer,
      handleChangeCheckLimitTimesTest,
      listCondition,
      currentPage,
      submit(value) {
        this.$emit('onFinish', toRaw(value), props?.testDetail?.key);
      }
    };
  },
  data() {
    return {
      /**
       * Danh sách tên các câu trả lời, tối đa 1 câu hỏi có 11 câu trả lời từ A đến K
       */
      listAnswerNames: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
    };
  },
  methods: {
    /**
     * Hàm validate thời gian làm bài
     */
    async checkTime(_rule, value) {
      if (!value.hours && !value.minutes) {
        return Promise.reject(
          this.$t('courseManagement.step3.validate.timeRequired')
        );
      } else if (value.hours && (value.hours < 0 || value.hours > 99)) {
        return Promise.reject(
          this.$t('courseManagement.step3.validate.hoursInvalid')
        );
      } else if (value.minutes && (value.minutes < 0 || value.minutes > 59)) {
        return Promise.reject(
          this.$t('courseManagement.step3.validate.minuteInvalid')
        );
      } else {
        return Promise.resolve();
      }
    },
    
    // Hàm validate question limit
    async checkLimitQuest(_rule, value){
      if(value > this.formState.questions.length){
        return Promise.reject(
         this.$t('courseManagement.step3.validate.limitQuestion')
        )
      }
    },

    /**
     * Hàm validate câu hỏi đã có câu trả lời chưa hoặc phải có ít nhất 1 câu trả lời đúng
     */
    async checkAnswer(_rule, value, index) {
      if (!value?.[index]?.answers?.length) {
        return Promise.reject(
          this.$t('courseManagement.step3.validate.answerRequired')
        );
      } else if (
        !find(value?.[index]?.answers || [], (item) => item.isCorrect)
      ) {
        return Promise.reject(
          this.$t('courseManagement.step3.validate.correctAnswerRequired')
        );
      } else {
        return Promise.resolve();
      }
    },

    /**
     * Hàm clear validate những fields không được control bằng form input
     * @param {string} name - tên field muốn xóa validate
     */
    clearValidate(name) {
      this.$refs['form'].clearValidate([name]);
    },

    /**
     * Hàm đóng modal khi click nút hủy
     */
    close() {
      this.$emit('onClose');
    }
  },

});
</script>

<style scoped>
.form-label,
.form-input {
  width: 100%;
  display: block;
}
.icon-16 {
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
}
.icon-23 {
  font-size: 23px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
}
:deep(.ant-form-item-label label),
.form-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
:deep(.ant-form-item-explain-error) {
  font-weight: 400;
}
.form-input {
  font-weight: 400;
}
.form-label :deep(.ck-content) {
  font-weight: normal;
}
:deep(.ck-content) {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  position: relative;
  top: -1px;
  box-shadow: none !important;
}
.ant-modal-footer {
  margin-left: -24px;
  margin-right: -24px;
  padding: 24px;
  padding-bottom: 0;
}
.input-hidden :deep(.ant-form-item-control-input) {
  display: none;
}
:deep(.ant-checkbox) {
  top: 0;
}
:deep(.ant-select-selection-item) {
  line-height: 40px !important;
}
:deep(.ant-select-selection-item::after) {
  display: none !important;
}
:deep(.ant-form-item-label) {
  width: auto !important;
  text-align: left;
}
:deep(.ant-form-item-label label::after) {
  display: none;
}
:deep(.ant-form-item-control) {
  max-width: 100%;
}
:deep(.ant-input-number) {
  min-width: 120px;
  border-radius: 6px;
  min-height: 40px;
  border-color: #c4c4c4;
  box-shadow: none;
  overflow: hidden;
}
:deep(.ant-form-item-has-error .ant-input-number),
:deep(.ant-form-item-has-error) .ck-content {
  border-color: #ff4d4f !important;
}
:deep(.ant-input-number input) {
  min-height: 40px !important;
}
:deep(.ant-form-item-has-error.correct-answer-checker input) {
  border-color: #c4c4c4 !important;
}
:deep(.ant-form-item-has-error.correct-answer-checker .ant-checkbox-inner),
:deep(
    .ant-form-item-has-error.correct-answer-checker
      .ant-form-item-has-error
      input
  ) {
  border-color: #ff4d4f !important;
}
.w-20 {
  width: 20px;
  text-align: left;
  white-space: nowrap;
}
.w-60 {
  width: 60px;
  min-width: 60px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.drag-icon {
  margin-right: 10px;
  cursor: grab;
}
.mr-15 {
  margin-right: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-15 {
  padding-right: 15px;
}
.h-full {
  height: 100%;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-add {
  font-size: 23px;
  line-height: 23px;
  color: #1280bf;
  margin-right: 7px;
  display: flex;
}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  font-size: 12px;
  line-height: 19px;
  font-weight: 700;
}
.question-wrap {
  width: 100%;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  border-radius: 4px;
}
.ant-checkbox {
  top: 0;
}
.pointer-events-none {
  pointer-events: none;
}
</style>
