export const TypeModalConfirm = {
  Edit: 'EDIT',
  Delete: 'DELETE',
  ConFirm: 'CONFIRM',
  ConFirmTest: 'CONFIRM-TEST'
};

export const TypeActions = {
  Staff: 'STAFF',
  StaffCompulsory: 'STAFFC',
  Test: 'TEST',
  Year: 'YEAR',
  Month: 'MONTH'
};

export const TypeSearch = {
  User: 'user_id',
  Test: 'test_id',
  Year: 'year',
  Month: 'month'
};

export const TypeFilters = {
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGESIZE',
  Sort: 'SORT'
};
