<template>
  <div>
    <h3 class="title mb-2">{{ props.title }}</h3>
    <a-carousel
      arrows
      :slidesToShow="4"
      :dots="false"
      centerPadding="50px"
      v-if="!isEmptyData && isVisibleSlice"
    >
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div
        class="course-item"
        v-for="item in props.data"
        :key="item.id"
        @click="
          () => {
            handleVisibleCourse(item.id);
          }
        "
      >
        <div
          class="wrap-image"
          :style="`background-image: url(${
            item.image ? item.image : imgPlacehold
          })`"
        >
          <img
            style="opacity: 0; position: absolute; width: 100%; height: 100%"
            :src="`${item.image ? item.image : imgPlacehold}`"
            alt=""
          />
          <svg
            width="240px"
            height="180px"
            style="width: 100%; height: auto; display: block"
          ></svg>
        </div>
        <p class="title-course">{{ item.title }}</p>
      </div>
    </a-carousel>
    <div v-else-if="!isVisibleSlice && !isEmptyData" class="list-course-none">
      <div
        class="course-item"
        v-for="item in props.data"
        :key="item.id"
        @click="
          () => {
            handleVisibleCourse(item.id);
          }
        "
      >
        <div
          class="wrap-image"
          :style="`background-image: url(${
            item.image ? item.image : imgPlacehold
          })`"
        >
          <img
            style="opacity: 0; position: absolute; width: 100%; height: 100%"
            :src="`${item.image ? item.image : imgPlacehold}`"
            alt=""
          />
          <svg
            width="240px"
            height="180px"
            style="width: 100%; height: auto; display: block"
          ></svg>
        </div>
        <p class="title-course">{{ item.title }}</p>
      </div>
    </div>
    <div v-else class="empty-course">
      <img src="/images/not-found.png" alt="" />
      <p>{{ texts.notFoundCourse2 }}</p>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import { IMG_PLACEHOLD } from "./utils/constant";
import { texts } from "./utils/texts";
import { defineComponent, defineProps, reactive, computed } from "vue";
defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
});
const router = useRouter();

const props = defineProps({
  title: String,
  data: Array,
});

const isEmptyData = computed(() => {
  return props.data.length === 0;
});

const isVisibleSlice = computed(() => {
  return props.data.length >= 4;
});

const handleVisibleCourse = (id) => {
  router.push(`course/${id}`);
};
</script>

<style lang="scss" scoped>
.course-item {
  padding: 0 15px;
  height: inherit;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  p {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 0px !important;
    text-align: center;
  }
  .wrap-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    transition: transform 0.3s ease;
    overflow: hidden;
  }
  .wrap-image:hover {
    transform: scale(1.05);
  }

  .title-course {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
  }
}
.list-course-none {
  display: flex;
  .course-item {
    width: 25%;
  }
}
.empty-course {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 150px;
  }
  p {
    font-weight: bold;
    font-style: italic;
    font-weight: 20px;
  }
}
.title {
  font-weight: bold;
  color: #000;
  font-size: 22px;
}
.ant-carousel {
  padding: 0 12px;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
  div {
    height: 100%;
  }
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: #fff;
  background: #000;
  z-index: 1;
  border-radius: 999px;
  span {
    svg {
      path:last-child {
        display: none;
      }
    }
  }
}
.ant-carousel :deep(.slick-arrow.slick-next) {
  right: -32px !important;
  top: 36%;
}
.ant-carousel :deep(.slick-arrow.slick-prev) {
  left: -32px !important;
  top: 36%;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.8;
}

.ant-carousel :deep(.slick-slide) {
  height: 100%;
  background-color: white;
}
.ant-carousel :deep(.slick-slide div) {
  height: 100%;
}
.ant-carousel :deep(.slick-track) {
  display: flex;
}
</style>