<template>
  <a-modal
    :visible="props.isShow"
    class="modal-detail-feedback"
    @cancel="hiddenModal"
    :width="750"
    :bodyStyle="{ minHeight: '402px' }"
    centered
    title="Chi tiết phản hồi"
  >
    <a-skeleton :loading="isLoading" class="h-full" :rows="7">
      <!-- <template #title>
        <div class="float-left" style="color: #17469e; font-size: 25px">
          Chi tiết phản hồi
        </div>
      </template> -->
      <div class="flex flex-row gap-10 pt-3">
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">
            Thời gian gửi phản hồi
          </p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ convertDateTime(feedbackInfo.created_at) }}
          </p>
        </div>
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Người gửi</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.user_name }}
          </p>
        </div>
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Email người gửi</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.user_email }}
          </p>
        </div>
      </div>

      <div class="flex flex-row gap-10 pt-3">
        <div class="flex-1" style="flex: 0 0 29.5%">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Danh mục</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.category_name }}
          </p>
        </div>
        <div class="flex-1" v-if="feedbackInfo.post_name">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Bài viết</p>
          <a
            @click="handleRedirectPost(feedbackInfo.post_id)"
            class="mt-2 font-bold leading-[22px]"
            style="color: #17469e; text-decoration: underline"
            >{{ feedbackInfo.post_name }}</a
          >
        </div>
      </div>

      <div class="flex flex-row gap-10 pt-3" v-if="feedbackInfo.status === 0">
        <div class="flex-1" style="flex: 0 0 29.5%">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Người phụ trách</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.handler }}
          </p>
        </div>
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Trạng thái</p>
          <a-button class="btn btn-outline-danger">Chưa xử lý</a-button>
        </div>
      </div>

      <div class="flex flex-row gap-10 pt-3" v-if="feedbackInfo.status === 1">
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Người phụ trách</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.handler }}
          </p>
        </div>
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Trạng thái</p>
          <a-button class="btn btn-outline-success">Đã xử lý</a-button>
        </div>
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Ngày xử lý</p>
          <p class="mt-2 font-bold leading-[22px]">
            {{ feedbackInfo.processing_date }}
          </p>
        </div>
      </div>

      <div class="flex flex-row gap-10 pt-3">
        <div class="flex-1">
          <p class="text-sm leading-[22px] text-[#9B9A9A]">Nội dung phản hồi</p>
          <p class="mt-2 leading-[22px] text-[13px]">{{ feedbackInfo.content }}</p>
        </div>
      </div>
    </a-skeleton>
    <template #footer>
      <div class="flex justify-end gap-4">
        <a-button class="text-[#5E5E5E]" key="cancel" @click="hiddenModal">Đóng</a-button>
        <a-button
          class="!bg-[#17469F]"
          key="submit"
          v-if="feedbackInfo.status === 0"
          type="primary"
          @click="handleSubmit"
          >Xử lý</a-button
        >
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import { reactive, ref, defineProps, defineEmits, watchEffect } from 'vue'
import { FeedbackListApi } from '../api'
import { USER_HANDLER } from '../utils/constant'
import { convertDateTime } from '../utils/helper'
import { useRouter } from 'vue-router'
import { ACTION } from '../utils/constant'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const emit = defineEmits()

const router = useRouter()

const props = defineProps({
  isShow: {
    default: false,
    type: Boolean,
  },
  id: String || Number,
})

const feedbackInfo = reactive({
  id: null,
  created_at: null,
  user_name: null,
  user_email: null,
  category_name: null,
  post_name: null,
  post_slug: null,
  status: null,
  content: null,
  handler: USER_HANDLER,
  processing_date: null,
})

const isLoading = ref(false)

const hiddenModal = function () {
  emit('hiddenModal')
}

const handleSubmit = async function () {
  await emit('handleFeedback', ACTION.HandleFeedback, feedbackInfo.id)
  // emit('hiddenModal')
}

const feedbackDetail = async function (id) {
  isLoading.value = true
  try {
    const res = await FeedbackListApi.getDetailFeedback(id)
    if (res && res.data) {
      feedbackInfo.created_at = res.data.created_at
      feedbackInfo.user_name = res.data.user.name
      feedbackInfo.user_email = res.data.user.email
      feedbackInfo.category_name = res.data.category.name
      feedbackInfo.content = res.data.content
      feedbackInfo.post_name = res.data?.post?.title
      feedbackInfo.post_slug = res.data?.post?.slug
      feedbackInfo.handler = 'Phạm Hồng Nhung'
      feedbackInfo.status = res.data.status
      feedbackInfo.processing_date = res.data.processing_date
      feedbackInfo.post_id = res.data?.post?.id
      feedbackInfo.id = res.data.id
    }
  } catch (error) {
    message.error(t('courseManagement.feedback.msgNotFoundFeedback'))
    emit('hiddenModal')
  } finally {
    isLoading.value = false
  }
}

const handleRedirectPost = function (post_slug) {
  router.push('/admin/posts/' + post_slug)
}

watchEffect(() => {
  if (props.isShow) {
    feedbackInfo.id = null
    feedbackInfo.created_at = null
    feedbackInfo.user_name = null
    feedbackInfo.user_email = null
    feedbackInfo.category_name = null
    feedbackInfo.content = null
    feedbackInfo.post_name = null
    feedbackInfo.post_slug = null
    feedbackInfo.status = null
    feedbackInfo.processing_date = null
    feedbackInfo.post_id = null
    feedbackInfo.handler = 'Phạm Hồng Nhung'
    feedbackDetail(props.id)
  }
})
</script>
<style lang="scss" scoped>
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  font-size: 16px;
  border-color: #28a745;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  font-size: 16px;
}
:deep(.ant-btn) {
  font-weight: 400px;
  line-height: normal;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 32px !important;
  span {
    line-height: normal;
  }
}
</style>