import { step1ManagementApi } from '../../e-learning/course/api/request';

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getFileNameInResponseHeader = (headers) => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const getNowDate = () => {
  let date = new Date();
  let day = date.getDate() < 9 ? '0' + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();
  return day + '/' + month + '/' + year;
};
export const romanize = (num) => {
  var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export const uploadImage = (loader) => {
  return {
    upload: () => {
      return new Promise((resolve, reject)=>{
        loader.file.then(async (file)=>{
          try {
            if(!file) return
            const fmData = new FormData();
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            fmData.append("file", file);
            const res = await step1ManagementApi.uploadFile(fmData, config);
            if(res){
              resolve({default: res?.data?.full_path})
            }
          } catch (error) {
            reject(error)
          }
        })
      })
    }
  }
}

