<template>
  <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.5" cy="11.5" rx="10.5" ry="11.5" fill="#1280BF"/>
    <path d="M5.8335 11.871H14.7779M10.5002 6.5V17" stroke="white" stroke-width="2"/>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
