const SERVICE_URL_WIKI_BASE = '/api/v2/';

const pathUrl = {
  course: 'course',
  lesson: 'lesson',
  elearning_file: 'elearning-file',
  test: 'test',
  questions: 'user-test'
};

export { pathUrl, SERVICE_URL_WIKI_BASE };
