<template>
<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
<rect y="0.525391" width="44.0042" height="44" rx="22" fill="#fff"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0024 28.373C27.6427 28.373 30.2519 27.6496 30.5039 24.7459C30.5039 21.8442 28.6848 22.0308 28.6848 18.4705C28.6848 15.6895 26.0484 12.5254 22.0024 12.5254C17.9565 12.5254 15.3201 15.6895 15.3201 18.4705C15.3201 22.0308 13.501 21.8442 13.501 24.7459C13.754 27.6606 16.3632 28.373 22.0024 28.373Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.3917 31.3828C23.0273 32.8975 20.8989 32.9155 19.5215 31.3828" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="29" cy="14" r="5" fill="#E41D1D" stroke="white" stroke-width="2"/>
</svg>


</template>
<script>
export default {};
</script>
