const SERVICE_URL_WIKI_BASE = '/api/v2/';

const pathUrl = {
  course: 'course',
  category: 'elearning-category',
  position: 'position',
  user: 'user',
  upload: 'file/upload',
  step2: 'chapter',
  test: 'test',
  step3: 'questions',
  statistical: 'statistical',
  listHistory: 'list_history'
};

export { pathUrl, SERVICE_URL_WIKI_BASE };
