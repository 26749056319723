const state = () => ({
  isShowModalFeedback: false,
});

const mutations = {
  updateShowModalFeedback(state, showFeedback) {
    state.isShowModalFeedback = showFeedback;
  },
};

const actions = {
  handleShowModalFeedback({ commit }, showFeedback) {
    commit('updateShowModalFeedback', showFeedback);
  },
};

const showFeedback = {
  state,
  actions,
  mutations
};

export default showFeedback;
