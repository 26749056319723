<template>
  <div class="posts-management-wrapper">
    <div class="post-inner" v-if="!showAddPost">
      <div class="header-title">
        <h3>Danh sách bài viết</h3>
      </div>
      <div class="filter-wrapper mt-2">
        <a-form :model="formState" name="'filter'">
          <a-row :gutter="20" style="width: 100%">
            <a-col :span="6" class="name-input">
              <a-form-item label="" name="keyword">
                <a-input-search
                  placeholder="Nhập tên bài viết"
                  @search="handleFilter"
                  v-model:value="formState.keyword"
                >
                  <template #prefix>
                    <IconSearch />
                  </template>
                  <template
                    #enterButton
                  >
                    <a-button class="search-btn" @click="handleFilter" type="text">Tìm</a-button>
                  </template>
                </a-input-search>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="" name="category_id">
                <a-select
                  placeholder="Danh mục"
                  allowClear
                  showSearch
                  optionFilterProp="filterProps"
                  v-model:value="formState.category_id"
                  @change="handleFilter"
                >
                  <a-select-option
                    v-for="category in categories"
                    :value="category.id"
                    :key="category.id"
                    :filterProps="category.name"
                  >
                    {{ category.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="" name="user_id">
                <a-select
                  placeholder="Người đăng"
                  allowClear
                  showSearch
                  optionFilterProp="filterProp"
                  v-model:value="formState.user_id"
                  @change="handleFilter"
                >
                  <a-select-option
                    v-for="author in authors"
                    :value="author.id"
                    :key="author.id"
                    :filterProp="author.name ? author.name : '-'"
                  >
                    {{ author.name ? author.name : "-" }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="3">
              <a-form-item label="" name="active">
                <a-select
                  :placeholder="t('courseManagement.post.status')"
                  allowClear
                  showSearch
                  optionFilterProp="filterProp"
                  v-model:value="formState.active"
                  @change="handleFilter"
                >
                  <a-select-option
                    v-for="status in listStatus"
                    :value="status.value"
                    :key="status.value"
                    :filterProp="status.value"
                  >
                    {{ status.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="7">
              <div class="btn-wrapper">
                <a-button
                  type="primary"
                  @click="handleActions(TypeActions.Create)"
                >
                  + Thêm bài viết
                </a-button>
              </div>
            </a-col>
            <a-col :span="3">
              <a-button
                type="outline-primary"
                class="custom-button custom-outline-primary !text-base"
                @click="handleExportExcel"
              >
                {{ t("courseManagement.courseware.exportExcel") }}
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- Filter by visits -->
      <a-row :gutter="18" class="flex items-center mt-[14px]">
        <a-col :span="6">
          <p class="font-bold text-sm leading-[22px]">
            Lượt truy cập bài viết theo
          </p>
        </a-col>
        <a-col :span="6" class="ml-[20px]">
          <a-radio-group
            v-model:value="formState.type"
            name="type"
            class="flex gap-2"
            @change="handleActions(TypeActions.ChangeOptionTime)"
          >
            <a-radio :value="optionPickerTime.Week.name">Tuần</a-radio>
            <a-radio :value="optionPickerTime.Month.name">Tháng</a-radio>
            <a-radio :value="optionPickerTime.Year.name">Năm</a-radio>
          </a-radio-group>
        </a-col>
        <a-col :span="7">
          <a-range-picker
            v-model:value="rangeDate.date"
            size="large"
            format="DD-MM-YYYY"
            value-format="YYYY-MM-DD"
            :picker="typeTime"
            @change="handleActions(TypeActions.ChangeRangeTime)"
            :placeholder="['Từ', 'Đến']"
            :bordered="false"
          >
            <template #suffixIcon>
              <CloseCircleOutlined />
            </template>
          </a-range-picker>
        </a-col>
        <div></div>
      </a-row>
      <div class="table-container mt-3">
        <a-table
          :columns="columns"
          :data-source="dataTable"
          :pagination="false"
          @change="handleTableChange"
          :customRow="customRow"
          :loading="loadingTable"
          :scroll="scrollHeightTable"
          bordered
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'no2'">
              <div class="actions-wrapper gap-1 flex flex-col items-center">
                <p>{{ column.week }}</p>
                <p class="font-normal">({{ column.timeStamp }})</p>
              </div>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <div class="actions-wrapper gap-4 flex items-center mr-[10px]">
                <span
                  class="cursor-pointer"
                  @click="
                    (e) => handleActions(TypeActions.Edit, record.id, e, record)
                  "
                >
                  <IconEdit />
                </span>
                <span
                  class="cursor-pointer"
                  @click.stop="
                    (e) =>
                      handleActions(TypeActions.Delete, record.id, e, record)
                  "
                >
                  <IconDelete />
                </span>
              </div>
            </template>
            <template v-if="column.key === 'active'">
              <div class="font-bold text-[10px]">
                <span v-if="record.active" class="text-[#62DE00]">{{
                  t("courseManagement.post.active")
                }}</span>
                <span v-else class="text-[#E41D1D]">{{
                  t("courseManagement.post.inactive")
                }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'categories'">
              <div>
                {{ record.categories.map((e) => e.name).join(", ") }}
              </div>
            </template>
            <template v-if="column.key === 'statistical'">
              <p class="font-bold">
                {{ record.statistical }}
              </p>
            </template>
          </template>
        </a-table>
      </div>

      <div class="pagination-wrapper">
        <span>Rows per page:</span>
        <a-select default-value="10" @change="handleChangePageSize">
          <i class="fas fa-sort-down dropdown-icon"></i>
          <a-select-option
            v-for="(limit, index) in pageSizeList"
            :value="limit"
            :key="`pageSize_${index}`"
          >
            {{ limit }}
          </a-select-option>
        </a-select>
        <span v-if="showLocationPage" class="total-page"
          >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
        >
        <a-button
          class="pagination-btn"
          @click="handleActions(TypeActions.Prev)"
          :disabled="disabledPrev"
        >
          <i class="fas fa-chevron-left icon-btn"></i>
        </a-button>
        <a-button
          class="pagination-btn mr-[47px]"
          @click="handleActions(TypeActions.Next)"
          :disabled="disabledNext"
        >
          <i class="fas fa-chevron-right icon-btn"></i>
        </a-button>
      </div>
    </div>
    <AddPost
      v-if="showAddPost"
      :showAddPost="showAddPost"
      @getListPost="getListPost"
    />
  </div>
</template>

<script setup>
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import AddPost from "./components/AddPost.vue";
import ViewPost from "./components/ViewPost.vue";
import IconEdit from "../../components/icon/icon-edit.vue";
import IconDelete from "../../components/icon/icon-delete.vue";
import IconPrev from "../../components/icon/icon-prev.vue";
import IconNext from "../../components/icon/icon-next.vue";
import IconSearch from "../../components/icon/icon-search.vue";
import { postMangemenetApi } from "./api/request";
import { getUserInfo } from "../../utils/authenticate";
import { TypeActions, optionPickerTime } from "./utils/interface";
import { useLoading, useShowConfirm, useNotify } from "../../utils/hook";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { COLUMNS } from "./utils/constant";
import { cloneDeep } from "lodash";
import axios from "axios";
import { SERVICE_URL_WIKI_BASE } from "./api/pathUrl";
import { truncate } from "../../utils/helper";
import {
  defineComponent,
  reactive,
  onBeforeUnmount,
  onMounted,
  ref,
  h,
} from "vue";

defineComponent({
  components: {
    AddPost,
    IconDelete,
    IconEdit,
    ViewPost,
    IconNext,
    IconPrev,
    IconSearch,
    LoadingOutlined,
    CloseCircleOutlined,
  },
});
const { t } = useI18n();
const { showConfirm } = useShowConfirm();
const { notifySuccess, notifyError } = useNotify();
const { showLoading, hideLoading } = useLoading();
const store = useStore();
const router = useRouter();
const dataTable = ref([]);
const categories = ref([]);
const authors = ref([]);
const loadingTable = ref(false);
const showLocationPage = ref(false);
const shinkHeader = ref(false);
const disabledNext = ref(false);
const disabledPrev = ref(false);
const showAddPost = ref(false);
const isFistChange = ref(false)
const typeTime = ref(optionPickerTime.Month.name);
const columns = ref(cloneDeep(COLUMNS));
const pageSizeList = [10, 20, 25, 50, 100];
const listStatus = [
  {
    label: t("courseManagement.post.inactive"),
    value: 0,
  },
  {
    label: t("courseManagement.post.active"),
    value: 1,
  },
];
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
  },
});
const paramsFetchListPost = reactive({
  limit: 10,
  page: 1,
});
const meta = reactive({
  total: null,
  from: 1,
  to: null,
  currentPage: 1,
});
const rangeDate = reactive({
  date: ["", ""],
});
const scrollHeightTable = reactive({
  y: "calc(100vh - 450px)",
  x: "calc(100vw - 400px)",
});
const formState = reactive({
  keyword: "",
  category_id: null,
  user_id: null,
  active: null,
  type: optionPickerTime.Month.name,
  start_date: null,
  end_date: null,
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onWidthChange);
});
const onWidthChange = (e) => {
  if (window.innerWidth <= 1366) {
    shinkHeader.value = true;
    scrollHeightTable.value = {
      y: "calc(100vh - 350px)",
    };
  }
};

const handleFilter = (props, fields) => {
  paramsFetchListPost.page = 1;
  fetchListPost({
    ...paramsFetchListPost,
    ...formState,
  });
};

const getListPost = () => {
  formState.keyword = null;
  formState.category_id = null;
  formState.user_id = null;
  formState.active = null;
  paramsFetchListPost.page = 1;
  paramsFetchListPost.limit = 10;
  toggleShowAddPost();
  fetchListPost({
    ...paramsFetchListPost,
    start_date: formState.start_date,
    end_date: formState.end_date,
    type: optionPickerTime.Month.name,
  });
};

const handleActions = (name, id, e) => {
  store.dispatch("updateAction", { name, id });
  switch (name) {
    case TypeActions.Edit:
      e.stopPropagation();
      router.push(`/admin/posts/${id}?edit=true`);
      break;
    case TypeActions.Create:
      toggleShowAddPost();
      break;
    case TypeActions.Delete:
      showConfirm({
        type: "delete",
        suffix: "bài viết",
        onOk: () => {
          deletePost(id);
        },
      });
      break;
    case TypeActions.Prev:
      paramsFetchListPost.page = paramsFetchListPost.page - 1;
      fetchListPost({ ...paramsFetchListPost, ...formState });
      break;
    case TypeActions.Next:
      paramsFetchListPost.page = paramsFetchListPost.page + 1;
      fetchListPost({ ...paramsFetchListPost, ...formState });
      break;
    case TypeActions.ChangeOptionTime:
      if (formState.type === optionPickerTime.Week.name) {
        typeTime.value = optionPickerTime.Week.name;
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
      } 
      else if (formState.type === optionPickerTime.Month.name) {
        typeTime.value = optionPickerTime.Month.name;
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
        const date = new Date(rangeDate.date[1]);
        const dateFirst = new Date(rangeDate.date[0])
        const fistDayOfMonth = new Date(dateFirst.getFullYear(), dateFirst.getMonth() , 2)
        const nextMonthFirstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        const lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1);
        rangeDate.date[0] = fistDayOfMonth.toISOString().split("T")[0];
        if(isFistChange.value){
          rangeDate.date[1] = lastDayOfMonth.toISOString().split("T")[0];
        }
      } 
      else {
        typeTime.value = optionPickerTime.Year.name;
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
        const yearStart = rangeDate.date[0].slice(0, 4);
        const yearEnd = rangeDate.date[1].slice(0, 4)
        const firstDayOfYear = new Date(yearStart, 0, 2);
        const endDayOfYear = new Date(yearEnd, 11, 32)
        rangeDate.date[0] = firstDayOfYear.toISOString().split("T")[0];
        if(isFistChange.value){
          rangeDate.date[1] = endDayOfYear.toISOString().split("T")[0];
        }
      }
      fetchListPost({ ...paramsFetchListPost, ...formState });
      break;
    case TypeActions.ChangeRangeTime:
      if (typeTime.value === optionPickerTime.Year.name) {
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
        const yearStart = rangeDate.date[0].slice(0, 4);
        const yearEnd = rangeDate.date[1].slice(0, 4)
        const firstDayOfYear = new Date(yearStart, 0, 2);
        const endDayOfYear = new Date(yearEnd, 11, 32)
        rangeDate.date[0] = firstDayOfYear.toISOString().split("T")[0];
        rangeDate.date[1] = endDayOfYear.toISOString().split("T")[0];
      } 
      else if (typeTime.value === optionPickerTime.Month.name) {
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
        const date = new Date(rangeDate.date[1]);
        const year = date.getFullYear();
        const month = date.getMonth();
        const nextMonthFirstDay = new Date(year, month + 1, 1);
        const lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1);
        rangeDate.date[1] = lastDayOfMonth.toISOString().split("T")[0];
        if(rangeDate.date[1]){
          isFistChange.value = true
        }
      } 
      else {
        formState.start_date = rangeDate.date[0];
        formState.end_date = rangeDate.date[1];
        if(rangeDate.date[1]){
          isFistChange.value = true
        }
      }

      fetchListPost({ ...paramsFetchListPost, ...formState });
      break;
  }
};

const customRow = (record) => {
  return {
    onClick: () => {
      handleActions(TypeActions.View, record.id);
      const action = {
        name: TypeActions.View,
        id: record.id,
      };
      store.dispatch("updateAction", action);
      router.push(`/admin/posts/${record.id}`);
    },
  };
};

const toggleShowAddPost = () => {
  showAddPost.value = !showAddPost.value;
};

const handleChangePageSize = (limit) => {
  paramsFetchListPost.limit = limit;
  paramsFetchListPost.page = 1;
  fetchListPost({ ...paramsFetchListPost, ...formState });
};

const handleTableChange = (pagination, filters, sorter) => {
  paramsFetchListPost.field = sorter.field;
  paramsFetchListPost.sortType = sorter.order;
  fetchListPost({ ...paramsFetchListPost, ...formState });
};

const fetchListPost = async (params = paramsFetchListPost) => {
  try {
    loadingTable.value = true;
    const res = await postMangemenetApi.getLisPost(params);
    if (res?.data?.length > 0) {
      let isPushColStatisticDetails = false;
      dataTable.value = res?.data.map((item, index) => {
        let objColDetail = {};
        let totalCount = 0;
        if (item?.statistic) {
          const indexColStatistic = columns.value.findIndex((item) => {
            return item.title === t("courseManagement.post.totalAccess");
          });
          if (indexColStatistic !== -1 && !isPushColStatisticDetails) {
            const createStatisticColumn = (key1, key2) => ({
              title: key1,
              dataIndex: key2,
              width: 120,
              key: "no2",
              align: "center",
              week: key1,
              timeStamp: key2,
            });

            const listColDetail =
              formState.type === optionPickerTime.Week.name
                ? Object.entries(item?.statistic || {}).flatMap(
                    ([key1, key2]) =>
                      Object.entries(key2).map(([key3, _]) =>
                        createStatisticColumn(key1, key3)
                      )
                  )
                : Object.entries(item?.statistic || {}).map(([key1, _]) => ({
                    title: key1,
                    dataIndex: key1,
                    width: 80,
                    key: key1,
                    align: "center",
                  }));

            columns.value[indexColStatistic].children = [
              {
                title: t("courseManagement.post.total"),
                width: 60,
                fixed: "left",
                key: "statistical",
                dataIndex: "statistical",
                align: "center",
              },
              ...listColDetail,
            ];
          }
          formState.type === optionPickerTime.Week.name
            ? Object.entries(item?.statistic || {}).flatMap(([key1, key2]) =>
                Object.entries(key2).forEach(([key3, value]) => {
                  objColDetail[key3] = value;
                  totalCount = totalCount + value;
                })
              )
            : Object.entries(item?.statistic || {}).forEach(([key1, value]) => {
                objColDetail[key1] = value;
                totalCount = totalCount + value;
              });
        }

        return {
          ...item,
          key: index,
          no: (res?.meta?.current_page - 1) * res?.meta?.per_page + index + 1,
          statistical: totalCount,
          ...objColDetail,
        };
      });
       $(document).ready(function() {
          setTimeout(()=>{
           
            const totalTh = $("th").filter(function() {
            return $(this).text().trim() === "Total";
          });
          const accessTh = $("th").filter(function() {
            return $(this).text().trim() === "Tổng lượt truy cập";
          });
          $(totalTh[0]).addClass('custom-cell');
           if ($('.custom-cell').find('.class-access')) {
              $('.class-access').remove()
            }
          const totalWidth = $('.ant-table-header').width()
          var thElements = $('.ant-table-thead tr:first th');
          let widthOther = 0
          thElements.each((index ,element)=>{
            if(!($(element).text().trim() === "Tổng lượt truy cập")){
              widthOther += $(element).outerWidth()
            }
          })

          const widthColumnTotalAccess = totalWidth - widthOther
          $('.custom-cell').append('<div class="class-access">Tổng lượt truy cập</div>')
          $('.class-access').css({
            "position": "absolute",
            "top": "0",
            "color": "#17469f",
            "left": `${(widthColumnTotalAccess - 112) / 2}px`,
            "top": "-38px",
            "width": "112px"
          })
          $(accessTh[0]).css('color', 'transparent');
          }, 100)
        });

      showLocationPage.value = true;
    }
    if (res?.data?.length === 0) {
      dataTable.value = [];
      showLocationPage.value = false;
    }
    meta.total = res?.meta?.total;
    meta.currentPage = res?.meta?.current_page;
    meta.from = res?.meta?.from;
    meta.to = res?.meta?.to;
    disabledNext.value = !res?.meta?.to || res?.meta?.to === res?.meta?.total;
    disabledPrev.value = !res?.meta?.from || res?.meta?.from === 1;
  } catch (error) {
    throw error;
  } finally {
    loadingTable.value = false;
  }
};

const fetchListCategory = async (params) => {
  try {
    const res = await postMangemenetApi.getLisCategory(params);
    categories.value = res?.data;
  } catch (error) {
    throw error;
  }
};

const fetchListAuthor = async (params) => {
  try {
    const res = await postMangemenetApi.getLisUser(params);
    authors.value = res?.data;
  } catch (error) {
    throw error;
  }
};

const deletePost = async (id) => {
  try {
    showLoading();
    await postMangemenetApi.deletePost(id);
    notifySuccess(t("courseManagement.post.validate.msgDeleteSuccess"));
    hideLoading();
    fetchListPost({ ...paramsFetchListPost, ...formState });
  } catch (error) {
    notifyError(t("courseManagement.post.validate.msgDeleteError"));
    throw error;
  } finally {
    hideLoading();
  }
};

function getCurrentYearDates() {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  formState.start_date = formatDate(new Date(currentYear, 0, 1));
  formState.end_date = formatDate(currentDate);
  rangeDate.date[0] = formState.start_date;
  rangeDate.date[1] = formState.end_date;
}

function customDatePicker() {
  $(".ant-picker-range-separator").html(
    "<span class='inline-block mt-1 font-bold text-sm'>đến</span>"
  );
  $(".ant-picker.ant-picker-range").prepend(
    "<span class='font-bold text-sm inline-block mr-[6px]'>từ</span>"
  );

  $(".ant-picker-input")
    .append(`<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0391 11.9766H0.960938C0.444 11.9766 0.0234375 11.556 0.0234375 11.0391V1.89844C0.0234375 1.3815 0.444 0.960938 0.960938 0.960938H1.66406C1.79348 0.960938 1.89844 1.06589 1.89844 1.19531V1.65288C1.89844 1.78383 1.99969 1.89391 2.12419 1.89827C2.25417 1.9039 2.36719 1.7993 2.36719 1.66406V1.19531C2.36719 1.06589 2.47212 0.960938 2.60156 0.960938H4.24219C4.37161 0.960938 4.47656 1.06589 4.47656 1.19531V1.65288C4.47656 1.78383 4.57781 1.89391 4.70231 1.89827C4.83319 1.9039 4.94531 1.79836 4.94531 1.66406V1.19531C4.94531 1.06589 5.05024 0.960938 5.17969 0.960938H6.82031C6.94973 0.960938 7.05469 1.06589 7.05469 1.19531V1.65288C7.05469 1.78383 7.15594 1.89391 7.28044 1.89827C7.41005 1.90387 7.52344 1.79958 7.52344 1.66406V1.19531C7.52344 1.06589 7.62839 0.960938 7.75781 0.960938H9.39844C9.52786 0.960938 9.63281 1.06589 9.63281 1.19531V1.65288C9.63281 1.78383 9.73406 1.89391 9.85856 1.89827C9.98899 1.9039 10.1016 1.79887 10.1016 1.66406V1.19531C10.1016 1.06589 10.2065 0.960938 10.3359 0.960938H11.0391C11.556 0.960938 11.9766 1.3815 11.9766 1.89844V11.0391C11.9766 11.556 11.556 11.9766 11.0391 11.9766ZM0.960938 1.42969C0.702469 1.42969 0.492188 1.63997 0.492188 1.89844V11.0391C0.492188 11.2975 0.702469 11.5078 0.960938 11.5078H11.0391C11.2975 11.5078 11.5078 11.2975 11.5078 11.0391V1.89844C11.5078 1.63997 11.2975 1.42969 11.0391 1.42969H10.5703V1.66406C10.5703 2.06166 10.2421 2.38052 9.84216 2.36674C9.46191 2.35343 9.16406 2.03986 9.16406 1.65288V1.42969H7.99219V1.66406C7.99219 2.06187 7.66373 2.38052 7.26403 2.36674C6.88378 2.35343 6.58594 2.03986 6.58594 1.65288V1.42969H5.41406V1.66406C5.41406 2.06135 5.0861 2.38055 4.68591 2.36674C4.30566 2.35343 4.00781 2.03986 4.00781 1.65288V1.42969H2.83594V1.66406C2.83594 2.06062 2.50877 2.38055 2.10778 2.36674C1.72753 2.35343 1.42969 2.03986 1.42969 1.65288V1.42969H0.960938Z" fill="black"/>
<path d="M11.7422 3.77344H0.257812C0.128367 3.77344 0.0234375 3.66851 0.0234375 3.53906C0.0234375 3.40962 0.128367 3.30469 0.257812 3.30469H11.7422C11.8716 3.30469 11.9766 3.40962 11.9766 3.53906C11.9766 3.66851 11.8716 3.77344 11.7422 3.77344Z" fill="black"/>
<path d="M9.86719 2.36719C9.47948 2.36719 9.16406 2.05177 9.16406 1.66406V0.726562C9.16406 0.338859 9.47948 0.0234375 9.86719 0.0234375C10.2557 0.0234375 10.5703 0.338086 10.5703 0.726562V1.66406C10.5703 2.05177 10.2549 2.36719 9.86719 2.36719ZM9.86719 0.492188C9.73795 0.492188 9.63281 0.597328 9.63281 0.726562V1.66406C9.63281 1.7933 9.73795 1.89844 9.86719 1.89844C9.99642 1.89844 10.1016 1.7933 10.1016 1.66406V0.726562C10.1016 0.597211 9.99659 0.492188 9.86719 0.492188Z" fill="black"/>
<path d="M7.28906 2.36719C6.90136 2.36719 6.58594 2.05177 6.58594 1.66406V0.726562C6.58594 0.338859 6.90136 0.0234375 7.28906 0.0234375C7.67756 0.0234375 7.99219 0.338086 7.99219 0.726562V1.66406C7.99219 2.05177 7.67677 2.36719 7.28906 2.36719ZM7.28906 0.492188C7.15983 0.492188 7.05469 0.597328 7.05469 0.726562V1.66406C7.05469 1.7933 7.15983 1.89844 7.28906 1.89844C7.4183 1.89844 7.52344 1.7933 7.52344 1.66406V0.726562C7.52344 0.597211 7.41846 0.492188 7.28906 0.492188Z" fill="black"/>
<path d="M4.71094 2.36719C4.32323 2.36719 4.00781 2.05177 4.00781 1.66406V0.726562C4.00781 0.338859 4.32323 0.0234375 4.71094 0.0234375C5.09944 0.0234375 5.41406 0.338086 5.41406 0.726562V1.66406C5.41406 2.05177 5.09864 2.36719 4.71094 2.36719ZM4.71094 0.492188C4.5817 0.492188 4.47656 0.597328 4.47656 0.726562V1.66406C4.47656 1.7933 4.5817 1.89844 4.71094 1.89844C4.84017 1.89844 4.94531 1.7933 4.94531 1.66406V0.726562C4.94531 0.597211 4.84034 0.492188 4.71094 0.492188Z" fill="black"/>
<path d="M2.13281 2.36719C1.74511 2.36719 1.42969 2.05177 1.42969 1.66406V0.726562C1.42969 0.338859 1.74511 0.0234375 2.13281 0.0234375C2.52129 0.0234375 2.83594 0.338086 2.83594 0.726562V1.66406C2.83594 2.05177 2.52052 2.36719 2.13281 2.36719ZM2.13281 0.492188C2.00358 0.492188 1.89844 0.597328 1.89844 0.726562V1.66406C1.89844 1.7933 2.00358 1.89844 2.13281 1.89844C2.26205 1.89844 2.36719 1.7933 2.36719 1.66406V0.726562C2.36719 0.597211 2.26221 0.492188 2.13281 0.492188Z" fill="black"/>
<path d="M3.07031 6.11719H1.89844C1.76899 6.11719 1.66406 6.01223 1.66406 5.88281V4.71094C1.66406 4.58149 1.76899 4.47656 1.89844 4.47656H3.07031C3.19973 4.47656 3.30469 4.58149 3.30469 4.71094V5.88281C3.30469 6.01223 3.19973 6.11719 3.07031 6.11719ZM2.13281 5.64844H2.83594V4.94531H2.13281V5.64844Z" fill="black"/>
<path d="M5.41406 6.11719H4.24219C4.11274 6.11719 4.00781 6.01223 4.00781 5.88281V4.71094C4.00781 4.58149 4.11274 4.47656 4.24219 4.47656H5.41406C5.54351 4.47656 5.64844 4.58149 5.64844 4.71094V5.88281C5.64844 6.01223 5.54351 6.11719 5.41406 6.11719ZM4.47656 5.64844H5.17969V4.94531H4.47656V5.64844Z" fill="black"/>
<path d="M7.75781 6.11719H6.58594C6.45652 6.11719 6.35156 6.01223 6.35156 5.88281V4.71094C6.35156 4.58149 6.45652 4.47656 6.58594 4.47656H7.75781C7.88723 4.47656 7.99219 4.58149 7.99219 4.71094V5.88281C7.99219 6.01223 7.88723 6.11719 7.75781 6.11719ZM6.82031 5.64844H7.52344V4.94531H6.82031V5.64844Z" fill="black"/>
<path d="M10.1016 6.11719H8.92969C8.80027 6.11719 8.69531 6.01223 8.69531 5.88281V4.71094C8.69531 4.58149 8.80027 4.47656 8.92969 4.47656H10.1016C10.231 4.47656 10.3359 4.58149 10.3359 4.71094V5.88281C10.3359 6.01223 10.231 6.11719 10.1016 6.11719ZM9.16406 5.64844H9.86719V4.94531H9.16406V5.64844Z" fill="black"/>
<path d="M7.75781 8.46094H6.58594C6.45652 8.46094 6.35156 8.35598 6.35156 8.22656V7.05469C6.35156 6.92527 6.45652 6.82031 6.58594 6.82031H7.75781C7.88723 6.82031 7.99219 6.92527 7.99219 7.05469V8.22656C7.99219 8.35598 7.88723 8.46094 7.75781 8.46094ZM6.82031 7.99219H7.52344V7.28906H6.82031V7.99219Z" fill="black"/>
<path d="M10.1016 8.46094H8.92969C8.80027 8.46094 8.69531 8.35598 8.69531 8.22656V7.05469C8.69531 6.92527 8.80027 6.82031 8.92969 6.82031H10.1016C10.231 6.82031 10.3359 6.92527 10.3359 7.05469V8.22656C10.3359 8.35598 10.231 8.46094 10.1016 8.46094ZM9.16406 7.99219H9.86719V7.28906H9.16406V7.99219Z" fill="black"/>
<path d="M7.75781 10.8047H6.58594C6.45652 10.8047 6.35156 10.6997 6.35156 10.5703V9.39844C6.35156 9.26902 6.45652 9.16406 6.58594 9.16406H7.75781C7.88723 9.16406 7.99219 9.26902 7.99219 9.39844V10.5703C7.99219 10.6997 7.88723 10.8047 7.75781 10.8047ZM6.82031 10.3359H7.52344V9.63281H6.82031V10.3359Z" fill="black"/>
<path d="M10.1016 10.8047H8.92969C8.80027 10.8047 8.69531 10.6997 8.69531 10.5703V9.39844C8.69531 9.26902 8.80027 9.16406 8.92969 9.16406H10.1016C10.231 9.16406 10.3359 9.26902 10.3359 9.39844V10.5703C10.3359 10.6997 10.231 10.8047 10.1016 10.8047ZM9.16406 10.3359H9.86719V9.63281H9.16406V10.3359Z" fill="black"/>
<path d="M3.07031 8.46094H1.89844C1.76899 8.46094 1.66406 8.35598 1.66406 8.22656V7.05469C1.66406 6.92527 1.76899 6.82031 1.89844 6.82031H3.07031C3.19973 6.82031 3.30469 6.92527 3.30469 7.05469V8.22656C3.30469 8.35598 3.19973 8.46094 3.07031 8.46094ZM2.13281 7.99219H2.83594V7.28906H2.13281V7.99219Z" fill="black"/>
<path d="M5.41406 8.46094H4.24219C4.11274 8.46094 4.00781 8.35598 4.00781 8.22656V7.05469C4.00781 6.92527 4.11274 6.82031 4.24219 6.82031H5.41406C5.54351 6.82031 5.64844 6.92527 5.64844 7.05469V8.22656C5.64844 8.35598 5.54351 8.46094 5.41406 8.46094ZM4.47656 7.99219H5.17969V7.28906H4.47656V7.99219Z" fill="black"/>
<path d="M3.07031 10.8047H1.89844C1.76899 10.8047 1.66406 10.6997 1.66406 10.5703V9.39844C1.66406 9.26902 1.76899 9.16406 1.89844 9.16406H3.07031C3.19973 9.16406 3.30469 9.26902 3.30469 9.39844V10.5703C3.30469 10.6997 3.19973 10.8047 3.07031 10.8047ZM2.13281 10.3359H2.83594V9.63281H2.13281V10.3359Z" fill="black"/>
<path d="M5.41406 10.8047H4.24219C4.11274 10.8047 4.00781 10.6997 4.00781 10.5703V9.39844C4.00781 9.26902 4.11274 9.16406 4.24219 9.16406H5.41406C5.54351 9.16406 5.64844 9.26902 5.64844 9.39844V10.5703C5.64844 10.6997 5.54351 10.8047 5.41406 10.8047ZM4.47656 10.3359H5.17969V9.63281H4.47656V10.3359Z" fill="black"/>
</svg>
`);
}

onMounted(() => {
  getCurrentYearDates();
  columns.value = cloneDeep(COLUMNS);
  window.addEventListener("resize", onWidthChange);
  onWidthChange();
  fetchListPost({
    ...paramsFetchListPost,
    start_date: formState.start_date,
    end_date: formState.end_date,
    type: optionPickerTime.Month.name,
  });
  fetchListCategory();
  fetchListAuthor({ roles: [1, 14] });
  customDatePicker();
});

const handleExportExcel = async () => {
  if (formState.type === optionPickerTime.Week.name) {
    typeTime.value = optionPickerTime.Week.name;
    formState.start_date = rangeDate.date[0];
    formState.end_date = rangeDate.date[1];
  } else if (formState.type === optionPickerTime.Month.name) {
    typeTime.value = optionPickerTime.Month.name;
    formState.start_date = rangeDate.date[0];
    formState.end_date = rangeDate.date[1];
  } else {
    typeTime.value = optionPickerTime.Year.name;
    formState.start_date = rangeDate.date[0];
    formState.end_date = rangeDate.date[1];
  }

  axios
    .get(SERVICE_URL_WIKI_BASE + `post/export`, {
      method: "GET",
      responseType: "blob",
      params: {
        ...formState,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${truncate(response.headers["content-disposition"])}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    });
};
</script>

<style lang="scss" scoped>
.posts-management-wrapper {
  background-color: #fff;
  height: 100%;
  border-radius: 5px;
  padding: 20px 24px;
  overflow-y: auto;

  :deep(.ant-input-affix-wrapper) {
    padding: 0 11px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }

  :deep(.ant-input-group-addon) {
    border-top: 1px solid #d9d9d9 !important;
    border-right: 1px solid #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  :deep(.ant-input-affix-wrapper:focus) {
    border-color: #d9d9d9 !important;
  }

  :deep(.ant-input-affix-wrapper-focused) {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  :deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
    border-color: #d9d9d9;
  }
}

.post-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: black;
}

.header-title {
  font-weight: 700;
  @media only screen and (max-width: 1366px) {
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
  }
}

:deep(.filter-wrapper) {
  .name-input {
    .ant-input {
      border-radius: 36px !important;
      padding-left: 10px !important;
    }
  }
  .ant-input-suffix {
    i {
      display: none;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-right: 4px;
      color: black;
    }
  }

  .search-btn {
    &hover {
      cursor: pointer;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .btn-wrapper {
    height: 100%;
    display: flex;
    gap: 20px;
    font-weight: 700px;
    justify-content: flex-end;
    button {
      width: 160px;
      font-size: 16px;
    }
  }
}

:deep(.table-container) {
  flex: 1;
  .ant-table-content {
    overflow-x: auto;
    min-width: 800px;
  }

  .ant-table-thead {
    color: black;
    font-size: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }
  .ant-table-body {
    color: black;
    font-size: 12px;
  }

  .ant-table-thead {
    tr > th {
      padding: 16px 9px;
    }
  }

  .ant-table-tbody {
    tr > td {
      padding: 16px 16px;
    }
    @media only screen and (max-width: 1366px) {
      tr > td {
        padding: 12px 12px;
      }
    }

    tr:hover {
      cursor: pointer;
    }
  }
  .ant-table-placeholder {
    height: calc(100vh - 420px);
  }
}

.custom-button {
  transition: color 0.3s, background-color 0.3s;
}

.custom-button:hover {
  color: #fff;
  background-color: #1280bf;
  border-color: #1280bf;
}

.custom-outline-primary {
  color: #1890ff;
  border-color: #1890ff;
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
:deep(.ant-picker) {
  border-radius: 6px;
  .ant-picker-input input {
    font-size: 14px;
  }
  .ant-picker-input input::placeholder {
    font-size: 14px;
  }
  .ant-picker-range-separator {
    position: relative;
    top: -2px;
  }
}
:deep(.ant-picker-large) {
  padding: 4px 11px;
}
:deep(th.ant-table-cell) {
  font-size: 12px;
  color: #17469f;
  background-color: #e7ecf5 !important;
}
:deep(.ant-table-cell-fix-left) {
  z-index: 100;
}
:deep(.ant-table-cell-fix-left-last) {
  z-index: 1;
}
:deep(.ant-table-thead tr:last-child > th) {
  background-color: #f3f6fa !important;
}
:deep(.ant-picker-input) {
  border: 1px solid #b9c7e2;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 5px;
  height: 28px;
}
</style>
