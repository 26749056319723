export default {
  ADMIN: 'Admin',
  HR: 'HR',
  CEO: 'CEO',
  COO: 'COO',
  BRANCH_DIRECTOR: 'Branch Director',
  DIVISION_DIRECTOR: 'Division Director',
  ACCOUNTANT: 'Accountant',
  PROJECT_MANAGER: 'Project Manager',
  SALES: 'Sales',
  DEV_TEAM: 'Dev Team',
  BACK_OFFICE: 'Back Office',
  SOFTWARE_ENGINEER: 'Software Engineer',
  HRM: 'HRM'
};
