const state = () => ({
  idLesson: null,
  idReference: null,
  idTest: null,
  page: null,
  tagCourse: 1,
  listCourse: null,
  totalPage: null
});

const mutations = {
  updateIdLesson(state, id) {
    state.idLesson = id;
  },
  updateIdReference(state, id) {
    state.idReference = id;
  },
  updateIdTest(state, id) {
    state.idTest = id;
  },
  updatePage(state, page) {
    state.page = page;
  },
  updateActiveTag(state, tag) {
    state.tagCourse = tag;
  },
  updateListCourse(state, data) {
    state.listCourse = data;
  },
  updateTotalPage(state, total) {
    state.totalPage = total;
  }
};

const actions = {
  updateIdLesson({ commit }, id) {
    commit('updateIdLesson', id);
  },
  updateIdReference({ commit }, id) {
    commit('updateIdReference', id);
  },
  updateIdTest({ commit }, id) {
    commit('updateIdTest', id);
  },
  updatePage({ commit }, page) {
    commit('updatePage', page);
  },
  updateActiveTag({ commit }, tag) {
    commit('updateActiveTag', tag);
  },
  updateListCourse({ commit }, data) {
    commit('updateListCourse', data);
  },
  updateTotalPage({ commit }, total) {
    commit('updateTotalPage', total);
  }
};
const course = {
  state,
  actions,
  mutations
};
export default course;
