import { RequestApi } from './axios';
import { pathUrl } from './pathUrl';

export const postMangemenetApi = {
  getLisPost: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.post, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getPost: async (id) => {
    try {
      const res = await RequestApi.get(pathUrl.post + '/' + id);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getLisCategory: async (params) => {
    try {
      const res = await RequestApi.get(pathUrl.category, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getLisUser: async (params) => {
    try {
      const res = await RequestApi.get('/v2/' + pathUrl.user, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  createPost: async (payload) => {
    try {
      const res = await RequestApi.post(pathUrl.post, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  updatePost: async (payload, slug, params) => {
    try {
      const res = await RequestApi.post(
        pathUrl.post + '/' + slug + '?_method=PUT',
        payload,
        {
          params
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  editPost: async (payload) => {
    try {
      const res = await RequestApi.put(pathUrl.post, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  deletePost: async (slug) => {
    try {
      const res = await RequestApi.delete(pathUrl.post + '/' + slug);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getHistoryPost: async(params) =>{
    try {
      const res = await RequestApi.get('v2/list_history/post',{
        params
      })
      return res
    } catch (error) {
      throw error
    }
  },

  createFeedBack: async(payload)=>{
    try {
      const res = await RequestApi.post(pathUrl.feedback, payload);
      return res.data;
    } catch (error) {
      throw error
    }
  }
};
