import { SERVICE_URL_WIKI_BASE } from './pathUrl';
import axios from 'axios';
import {revokeUser} from '../../../utils/authenticate'
import {errorContants} from '../../../constant'
import get from 'lodash/get'

export const RequestApi = axios.create({
  withCredentials: true,
  baseURL: SERVICE_URL_WIKI_BASE,
  headers: {
    'Content-Type': 'application/json'
  }
});

RequestApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = get(error, 'response.status');
    const errorData = get(error, 'response.data');
    switch (status) {
      case errorContants.StatusCode.Unauthorized: {
        revokeUser();
        window.location.reload();
        break;
      }
      case errorContants.StatusCode.ValidationFailed:
      case errorContants.StatusCode.BadRequest: {
        return Promise.reject({ ...errorData, status });
      }
      case errorContants.StatusCode.Forbidden: {
        break;
      }
      case errorContants.StatusCode.InternalServerError: {
        break;
      }
      case errorContants.StatusCode.TooManyRequests: {
        break;
      }
    }
    return Promise.reject({ ...errorData, status });
  }
);
