<template>
  <a-modal
    :visible="isShow"
    :title="t('courseManagement.post.viewDocument')"
    class="modal-preview"
    :style="{ maxWidth: '1135px', width: '1135px', maxHeight: '98vh' }"
    @cancel="closeModal"
    :footer="null"
    centered
  >
    <div class="border border-mainColor rounded-[10px] p-[16px]" v-if="isShow">
      <div v-if="typeFile.isDocument">
        <div v-if="props.path.slice(-3) === 'pdf'">
          <iframe :src="props.path"  style="width: 100%; min-height: 75vh; display: block"></iframe>
        </div>
        <div v-else>
           <iframe
          :src="LINK_EMBEDDED_DOC + props.path"
          style="width: 100%; min-height: 75vh; display: block"
        />
        </div>
      </div>
      <div v-else-if="typeFile.isVideo">
        <video ref="videoPlayer" class="video-js vjs-default-skin vjs-16-9" />
      </div>
      <div v-else-if="typeFile.isImage">
        <img :src="props.path" alt="">
      </div>
      <div class="flex items-center gap-2 justify-end mt-4">
        <a-button @click="closeModal">
          {{ t('courseManagement.common.close') }}
        </a-button>
        <a :href="props.originPath" download>
          <a-button type="primary">
            <div class="flex items-center gap-[6px]">
              <p>{{ t('courseManagement.common.download') }}</p>
              <i
                ><svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6864_296)">
                    <path
                      d="M10.8889 14H3.1111C2.28015 14 1.49889 13.6764 0.911239 13.0887C0.323616 12.5012 0 11.7199 0 10.8889V10.1111C0 9.68148 0.348205 9.33325 0.777782 9.33325C1.20736 9.33325 1.55556 9.68148 1.55556 10.1111V10.8889C1.55556 11.3044 1.71739 11.695 2.01114 11.9888C2.30498 12.2826 2.6956 12.4444 3.1111 12.4444H10.8889C11.3043 12.4444 11.695 12.2826 11.9888 11.9888C12.2826 11.6949 12.4444 11.3043 12.4444 10.8889V10.1111C12.4444 9.68148 12.7926 9.33325 13.2222 9.33325C13.6517 9.33325 14 9.68148 14 10.1111V10.8889C14 11.7198 13.6764 12.5011 13.0887 13.0887C12.5011 13.6764 11.7198 14 10.8889 14ZM6.99999 10.8888C6.89239 10.8888 6.78997 10.867 6.69677 10.8275C6.60983 10.7908 6.52813 10.7374 6.45665 10.6676C6.45662 10.6676 6.45662 10.6676 6.4566 10.6676C6.45608 10.6671 6.45556 10.6666 6.45505 10.6661C6.45491 10.6659 6.45475 10.6658 6.45461 10.6656C6.45418 10.6652 6.4538 10.6648 6.45339 10.6644C6.45312 10.6642 6.45287 10.6639 6.4526 10.6636C6.45233 10.6634 6.45198 10.663 6.45173 10.6628C6.45119 10.6622 6.45059 10.6617 6.45005 10.6611L3.33892 7.54995C3.03519 7.24622 3.03519 6.75375 3.33892 6.44999C3.64265 6.14627 4.13515 6.14624 4.43888 6.44999L6.22223 8.23335V0.777782C6.2222 0.348205 6.57041 0 6.99999 0C7.42956 0 7.7778 0.348205 7.7778 0.777782V8.23332L9.56112 6.44999C9.86482 6.14627 10.3574 6.14627 10.6611 6.44999C10.9648 6.75372 10.9648 7.24622 10.6611 7.54995L7.54995 10.6611C7.54941 10.6616 7.54881 10.6622 7.54827 10.6627C7.54797 10.663 7.54764 10.6634 7.5474 10.6636C7.54713 10.6639 7.54688 10.6641 7.54661 10.6644C7.54623 10.6648 7.54579 10.6652 7.54541 10.6656C7.54528 10.6657 7.54509 10.6659 7.54495 10.666C7.54446 10.6665 7.54395 10.667 7.54343 10.6675C7.5434 10.6675 7.5434 10.6675 7.54338 10.6676C7.53482 10.6759 7.52615 10.684 7.51729 10.6919C7.45217 10.75 7.37963 10.7953 7.30293 10.8276C7.30265 10.8277 7.30244 10.8278 7.30217 10.8279C7.30187 10.828 7.30162 10.8282 7.30132 10.8283C7.20862 10.8673 7.10684 10.8888 6.99999 10.8888Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6864_296">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
            </div> </a-button
        ></a>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { defineEmits, onMounted, ref, watchEffect, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import videojs from "video.js";
import { LINK_EMBEDDED_DOC } from "../../constant/common";

const props = defineProps({
  isShow: Boolean,
  path: String,
  originPath: String,
})
const { t } = useI18n()
const emit = defineEmits()

const videoRegex = /\.mp4$/i // Video có đuôi .mp4
const documentRegex = /\.(pdf|doc|docx|ppt|pptx|xlsx|xls)$/i // Tài liệu có đuôi .pdf, .doc, .docx, .ppt, .xlxs
const imageRegex = /\.(jpg|jpeg|png|gif|svg)$/i // Ảnh có đuôi .jpg, .jpeg, .png, .gif, .svg

const player = ref(null)
const videoPlayer = ref(null)


const isShowModal = ref(props.isShow)
const closeModal = function () {
  if(player.value && videoPlayer.value){
    videoPlayer.value = null
    player.value.dispose()
  }
  emit('onCloseModal')
}

const typeFile = computed(() => ({
  isVideo: videoRegex.test(props.path),
  isDocument: documentRegex.test(props.path),
  isImage: imageRegex.test(props.path),
}));

const playVideo = () => {
  requestAnimationFrame(() => {
    if(!videoPlayer?.value) return
    const videoPlay = videoPlayer.value
    player.value = videojs(videoPlay, {
      autoplay: false,
      controls: true,
      preload: true,
      sources: [
        {
          type: 'video/mp4',
          src: props.path,
        },
      ],
    })
  })
}

watchEffect(()=>{
  if(props.isShow){
      playVideo()
  }
})
</script>
<style lang="scss" scoped>
</style>