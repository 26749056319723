<template>
  <nav class="navbar navbar-expand-lg navbar-light header">
    <router-link to="/">
      <IconLogo />
    </router-link>
    <button
      class="navbar-toggler ml-auto"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li
          v-for="item in headerItems"
          :key="item.id"
          class="nav-item flex gap-2"
          :class="{ active: item.isActive }"
        >
          <div v-if="checkPermissionsUser(item.path)" class="flex items-center gap-2">
            <img :src="item.linkImg" alt="icon-header" width="20" class="object-contain">
            <router-link
              :to="item.path + item.nextPath"
              class="nav-link"
              :class="{ active: item.isActive }"
            >
              {{ item.title }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
    <Notify/>
    <div class="d-flex text-black profile">
      <div class="flex-shrink-0">
        <img
          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
          alt="Generic placeholder image"
          class="avatar"
        />
      </div>
      <div class="info">
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <div class="ant-dropdown-link">
            <div class="name">{{ userInfo?.name || "Admin" }}</div>
            <div class="position">{{ userInfo.roles[0]?.name }}</div>
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item
                class="menu-item menu-item-last"
                @click="handleLogout"
              >
                <IconLogout class="inline" />
                <span class="logout"> Đăng xuất </span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed, defineComponent, defineProps, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { logout } from "../../../api/auth";
import { isRoleAdminSite } from "../../../config/middleware/roles";
import { getUserInfo, revokeUser } from "../../../utils/authenticate";
import IconLogo from "../../icon/icon-logo.vue";
import Notify from "./Notify.vue";
import IconLogout from "../../icon/icon-logout.vue";
import IconProfile from "../../icon/icon-profile.vue";
import IconSettings from "../../icon/icon-setting.vue";

defineComponent({
  IconProfile,
  IconSettings,
  IconLogout,
  IconLogo,
  Notify
});

const initHeaderItems = [
  { 
    id: 1,
    path: "/",
    key: "Home",
    title: "Wiki",
    linkImg: "/images/image-35.png",
    isActive: true 
  },
  {
    id: 2,
    path: "/e-learning",
    title: "E-Learning",
    nextPath: "/",
    linkImg: "/images/image-36.png",
    isActive: false,
  },
  {
    id: 3,
    path: "/admin",
    nextPath: "/categories",
    title: "Admin site",
    linkImg: "/images/image-37.png",
    isActive: false,
  },
];
const headerItems = ref(initHeaderItems);
const router = useRouter();
const route = useRoute();

const handleChangeHeaderItem = () => {
  headerItems.value = headerItems.value.map((item) => {
    if (route.matched.some((_item) => item.path === _item.path)) {
      return { ...item, isActive: true };
    }
    return { ...item, isActive: false };
  });
};

onMounted(() => {
  handleChangeHeaderItem();
});

const checkPermissionsUser = (path) => {
  const userInfo = JSON.parse(getUserInfo());
  if (path === "/admin") {
    return isRoleAdminSite(userInfo.roles);
  }
  return true;
};

const userInfo = computed(() => JSON.parse(getUserInfo()));

const handleLogout = async () => {
  try {
    await logout();
    revokeUser();
    router.push({ name: "Login" });
  } catch (error) {
    throw error;
  }
};

</script>

<style lang="scss" scoped>
.collapse {
  visibility: visible !important  ;
}
.menu-item {
  padding: 8px 35px 10px 34px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(16 / 12);
  a {
    margin: unset !important;
    padding: unset !important;
  }
  &-last {
    padding: 12px 35px 14px 34px;
    border-top: 0.5px solid #efefef;
  }
}
.navbar {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
}
.ant-dropdown-link {
  cursor: pointer;
}
</style>
