<template>
  <div class="add-post-wrapper" ref="contentElement">
    <div class="header-title">
      <span @click="handleActions(TypeActions.Back)">
        <iconBack />
      </span>
      <h3 class="text-2xl">{{ title }}</h3>
    </div>
    <a-form
      :model="formState"
      @finish="handleSubmit"
      layout="vertical"
      @finishFailed="handleFinishFailed"
    >
     <h3 class="font-bold text-[20px] mb-[24px]">Thông tin chung</h3>
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="Tiêu đề bài viết"
            name="title"
            :rules="[
              {
                required: true,
                message: 'Không được để trống!'
              },
              {
                whitespace: true,
                message: 'Không được để trống!'
              },
              {
                max: 191,
                message: 'Không được quá 191 ký tự'
              }
            ]"
          >
            <a-input
              v-model:value="formState.title"
              placeholder="Nhập tiêu đề  bài viết"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Danh mục"
            name="categories"
            :rules="[{ required: true, message: 'Không được để trống!' }]"
          >
            <a-select
              v-model:value="formState.categories"
              mode="multiple"
              placeholder="Chọn danh mục"
              labelInValue
              optionFilterProp="filterProps"
              showSearch
            >
              <a-select-option
                v-for="(category) in categories"
                :value="category.id"
                :key="category.id"
                :filterProps="category.name"
              >
                {{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item
            label="Người đăng"
            :rules="[{ required: true, message: 'Không được để trống!' }]"
            name="author"
          >
            <a-input
              :disabled="true"
              v-model:value="formState.author"
              placeholder="Người đăng"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="Ngày đăng"
            :rules="[{ required: true, message: 'Không được để trống!' }]"
            name="created_at"
          >
            <a-input
              :disabled="true"
              placeholder="Trống"
              v-model:value="formState.created_at"
            />
          </a-form-item>
        </a-col>
      </a-row>
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item
            label="Mô tả bài viết"
            name="description"
            :rules="[
              {
                required: true,
                message: 'Không được để trống!'
              },
              {
                whitespace: true,
                message: 'Không được để trống!'
              },
              {
                max: 200,
                message: 'Không được quá 200 ký tự'
              }
            ]"
          >
            <div class="description">
              <a-input
                v-model:value="formState.description"
                v-decorator="[
                  'description',
                  {
                    initialValue: initialValue.description
                  }
                ]"
                placeholder="Nhập mô tả bài viết"
                @change="handleChangeDes"
              />
              <span class="count"
                >{{ descriptionText.input }}/{{ descriptionText.max }}</span
              >
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="20">
         <a-form-item name="active">
            <a-checkbox v-model:checked="formState.active">
              <label class="font-bold text-xl">{{t('courseManagement.post.textCheckbox')}}</label>
            </a-checkbox>
         </a-form-item>
        </a-col>
      </a-row>
      <h3 class="font-bold text-[20px] mb-[24px]">Thông tin chương</h3>
      <a-card  v-for="(chapter, index) in formState.chapters"  :key="index" class="mb-[24px] pd-[15px]">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item
              class="font-bold text-base"
              :label="`Chương ${convertRomanize(index + 1)}`"
              :name="['chapters', index, 'description']"
              :rules="[
                {
                  max: 250,
                  message: 'Không được quá 250 ký tự'
                }
              ]"
            >
              <div class="description">
                <a-input
                  class="font-bold"
                  v-model:value="chapter.description"
                  placeholder="Nhập tiêu đề chương"
                />
                <span class="count"
                  >{{ chapter.description ? chapter.description.trim().length : 0 }}/{{ descriptionText.chapterMax }}</span
                >
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="ant-col ant-form-item-label"><label  class="ant-form-item-required font-bold text-base" >Nội dung chương </label></div>
        <div class="vue-editor-wrapper">
          <div :class="{ error: chapter.editor.isError && chapter.editor.isFocus, content: true }">
            <ckeditor
              :editor="editor.ref"
              v-model="chapter.editor.content"
              :config="editor.config"
              @ready="handleOnReadyEditor"
              @input="handleChangeEditor(index, chapter.editor.content)"
              @focus="handleFocusEditor(index)"
              style="max-height: 60vh; overflow: auto;"
            ></ckeditor>
          </div>
          <p class="msg-error" v-if="chapter.editor.isError  && chapter.editor.isFocus">
            <span class="msg-error-prefix">*</span>{{ chapter.editor.msgError }}
          </p>
        </div>
        <div class="icon-chapter-remove" @click="removeDomain(chapter)">
        <span class="icon">
          <IconRemoveChapter />
        </span>
        <p class="title">
          Xóa chương
        </p>
      </div>
      </a-card>
      <div class="icon-chapter-add mb-[24px]" @click="addChapter">
        <span class="icon">
          <IconAddChapter />
        </span>
        <p class="title">
          Tạo chương
        </p>
      </div>
      <a-form-item name="files">
        <a-upload
          v-model:fileList="formState.files"
          v-decorator="['files']"
          name="file"
          :multiple="true"
          :headers="headers"
          :supportServerRender="false"
          :beforeUpload="() => false"
        >
          <a-button> <UploadOutlined />Tài liệu đính kèm</a-button>
        </a-upload>
      </a-form-item>
      <div class="btn-wrapper">
        <a-button @click="handleOpenModal">
          {{t('courseManagement.post.preview')}}
        </a-button>
        <a-button style="width: 131px" @click="handleActions(TypeActions.Back)"
          >{{t('courseManagement.post.cancel')}}</a-button
        >
        <a-button style="width: 131px" type="primary" html-type="submit">{{
          submitName
        }}</a-button>
      </div>
    </a-form>
    <ModalPreviewPost :visible="isOpenModal" @cancel="closeModal" :info="formState"/>
  </div>
</template>

<script>
import iconBack from '../../../components/icon/icon-back.vue';
import { postMangemenetApi } from '../api/request';
import { getNowDate, romanize } from '../utils/helpers';
import { getUserInfo } from '../../../utils/authenticate';
import { TypeActions } from '../utils/interface';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { Image, ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
import { defineComponent, reactive, ref } from 'vue';
import { filter, map } from "lodash"
import { TOOL_BAR } from '../utils/constant';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useLoading, useNotify, useShowConfirm } from '../../../utils/hook';
import IconAddChapter from "../../../components/icon/icon-add-chapter.vue";
import IconRemoveChapter from "../../../components/icon/icon-remove-chapter.vue";
import ModalPreviewPost from './ModalPreviewPost.vue';
import { useI18n } from 'vue-i18n'
import { uploadImage } from '../utils/helpers'

export default defineComponent({
  components: { iconBack, UploadOutlined, IconAddChapter, IconRemoveChapter, ModalPreviewPost },
  props: {
    toggleShowAddPost: Function,
    getListPost: Function
  },

  data() {
    return {
      dataShowPost: [],
      categories: [],
      fileList: [],
      dataSubmit: {
        title: undefined,
        content: undefined,
        categories: [],
        files: []
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      descriptionText: {
        input: 0,
        max: 200,
        chapterMax: 250
      },
      initialValue: {
        title: '',
        categories: undefined,
        description: '',
        content: '',
        fileList: [],
        author: undefined,
        created_at: getNowDate(),
        active: false
      },
      title: 'Thêm mới bài viết',
      submitName: 'Đăng',
      editor: {
        content: '',
        placeholder: 'Nhập nội dung',
        ref: DecoupledEditor,
        config: {
          resizeOptions: [
            {
                name: 'resizeImage:original',
                value: null,
                label: 'Original'
            },
            {
                name: 'resizeImage:40',
                value: '40',
                label: '40%'
            },
            {
                name: ' :60',
                value: '60',
                label: '60%'
              }
          ],
          toolbar: TOOL_BAR,
          extraPlugins: [this.uploader],
          placeholder: "Nhập nội dung chương",
          fontFamily: {
          options: [
              'Arial, sans-serif',
            ]
          },
        },
        ready: false
      },
      editId: this.$store.state.postManagement.action.id,
    };
  },

  setup() {
    const { notifyError, notifySuccess } = useNotify();
    const { hideLoading, showLoading } = useLoading();
    const { showConfirm } = useShowConfirm();
    const isOpenModal = ref(false)
    const { t } = useI18n()
    const formState = reactive({
      title: '',
      categories: [],
      created_at: getNowDate(),
      author:
        JSON.parse(getUserInfo())?.name || JSON.parse(getUserInfo())?.email,
      description: '',
      files: [],
      chapters: [],
      active: false
    });
    const closeModal = () => {
      isOpenModal.value = false;
    };
    const handleOpenModal = () =>{
      isOpenModal.value = true
    }
    return {
      formState,
      notifySuccess,
      notifyError,
      showConfirm,
      showLoading,
      hideLoading,
      isOpenModal,
      closeModal,
      handleOpenModal,
      t
    };
  },

  created() {
    if (this.$store.state.postManagement.action.name === TypeActions.Edit) {
      this.title = 'Chỉnh sửa bài viết';
      this.submitName = 'Cập nhật';
      this.getPost(this.$store.state.postManagement.action.id);
      this.fetchListCategory();
    }
    if (this.$store.state.postManagement.action.name === TypeActions.Create) {
      this.addChapter();
      this.title = 'Thêm mới bài viết';
      this.fetchListCategory();
    }
    const userName = JSON.parse(localStorage.getItem('userInfo'));
    this.initialValue.author = userName.name ? userName.name : 'DUONG MD';
  },

  computed: {
    action() {
      return this.$store.state.postManagement.action;
    },
    userInfo() {
      const userInfo = JSON.parse(getUserInfo());
      return userInfo;
    },
    TypeActions() {
      return TypeActions;
    }
  },

  methods: {
     uploader(editor)
      {
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            return uploadImage( loader );
        };
      },
    handleChangeDes(e) {
      this.descriptionText.input = e.target.value.trim().length;
    },

    convertRomanize(number){
      return romanize(number)
    },

    handleActions(name, id) {
      this.$store.dispatch('updateAction', { name, id });
      switch (name) {
        case TypeActions.Back:
          this.$emit('getListPost');
          const params = this.$router.currentRoute.value.params;
          if (Object.keys(params).length !== 0) {
            this.$router.push(`/admin/posts`);
          }
          break;
      }
    },
    addChapter(){
      this.formState.chapters.push({description: '', editor: { content: '', isError: false, msgError: '', id: '' }});
    },

    removeDomain(item){
      let index = this.formState.chapters.indexOf(item);
      if (index !== -1) {
        this.formState.chapters.splice(index, 1);
      }
    },

    handleOnReadyEditor(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
      this.editor.ready = true;
    },

    handleChangeEditor(index) {
      if (!this.editor.ready ) return;
      if (this.formState.chapters[index].editor.content.trim().length === 0) {
        this.formState.chapters[index].editor.isError = true;
        this.formState.chapters[index].editor.msgError = 'Không được để trống!';
        return;
      }
      this.formState.chapters[index].editor.msgError = '';
      this.formState.chapters[index].editor.isError = false;
    },

    handleFocusEditor(index){
      this.formState.chapters[index].editor.isFocus = true;
    },

    handleFinishFailed() {
      this.formState.chapters.forEach((chapter, index) => {
        this.formState.chapters[index].editor.isFocus = true;
        this.handleChangeEditor(index)
      })
      const isError =  filter(this.formState.chapters, (chapter)=> !chapter.editor.content)
      if (!isError.length){
        return;
      }
    },

    handleSubmit(values) {
      this.formState.chapters.forEach((chapter, index) => {
        this.formState.chapters[index].editor.isFocus = true;
        this.handleChangeEditor(index)
      })

      const isError =  filter(this.formState.chapters, (chapter)=> !chapter.editor.content)
      if (isError.length){
        return;
      }
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('created_at', values.created_at);
      formData.append('active', values.active ? 1 : 0)
      values.categories.forEach((item) => {
        formData.append('categories[]', item.key);
      });
      this.formState.chapters.forEach((chapter, index) => {
        formData.append(`chapters[${index}][title]`,  chapter.description ? chapter.description : '');
        formData.append(`chapters[${index}][description]`, chapter.editor.content ? chapter.editor.content : '');
        formData.append(`chapters[${index}][id]`, chapter.editor.id);
      });

      
      const listFileUpload = values.files.filter((e) => e.originFileObj);
      const listIdFileNotUpload = values.files.filter(
        (e) => !e.originFileObj
      );
      const fileDuplicate = this.initialValue.fileList.filter((item) => {
        return listIdFileNotUpload.some((e) => e.uid === item.uid);
      });
      const fileRemoved = this.initialValue.fileList.filter((item) => {
        return fileDuplicate.every((e) => e.uid !== item.uid);
      });
      fileRemoved.forEach((item, index) => {
        formData.append('removeFiles[]', item.uid);
      });
      listFileUpload.forEach((item, index) => {
        formData.append('files[]', item.originFileObj);
      });
      

      if (this.$store.state.postManagement.action.name === TypeActions.Create) {
        this.post(formData);
        return;
      }
      this.showConfirm({
        type: 'edit',
        suffix: 'bài viết',
        onOk: () => {
          this.updatePost(formData, this.$store.state.postManagement.action.id);
        }
      });
    },

    async fetchListCategory(params) {
      try {
        const res = await postMangemenetApi.getLisCategory(params);
        this.categories = res.data;
      } catch (error) {
        throw error;
      }
    },

    async post(formData) {
      try {
        const res = await postMangemenetApi.createPost(formData);
        this.$emit('toggleShowAddPost');
        this.$emit('getListPost');
        this.notifySuccess('Thêm bài viết thành công');
      } catch (error) {
        if (error.error.status === 422) {
          if(Object.keys(error.error.bags).includes('title')){
            return this.notifyError('Tên bài viết đã tồn tại');
          }
          this.notifyError('Tên chương đã tồn tại');
        }
        throw error;
      }
    },

    async updatePost(payload, id, params) {
      try {
        await postMangemenetApi.updatePost(payload, id, {
          params
        });
        this.$emit('getListPost');
        this.$emit('toggleShowAddPost');
        this.$router.push(`/admin/posts/`);
        this.notifySuccess('Cập nhật bài viết thành công');
      } catch (error) {
        if (error.error.status === 422) {
          if(Object.keys(error.error.bags).includes('title')){
            return this.notifyError('Tên bài viết đã tồn tại');
          }
          this.notifyError('Tên chương đã tồn tại');
        }
        throw error;
      }
    },

    async getPost(id) {
      this.showLoading()
      try {
        const res = await postMangemenetApi.getPost(id);
        this.formState.title = res.data.title;
        this.formState.description = res.data?.description;
        this.formState.created_at = res.data.created_at;
        this.formState.active = res.data.active
        res.data.files.map((item, index) => {
          const file = {
            name: item.name,
            uid: item.id,
            url: item.path
          };
          this.initialValue.fileList.push(file);
          this.formState.files.push(file);
        });

        res.data.chapters.map((item) => {
          const chapter = {
            description: item.title, 
            editor: 
              { content: item.description, 
                isError: false, 
                msgError: '',
                id: item.id
              }
          };
          this.formState.chapters.push(chapter);
        });
        this.formState.categories = res.data.categories.map((item) => {
          return {
            key: item.id,
            label: item.name
          };
        });
      } catch (error) {
        throw error;
      }finally{
        this.hideLoading()
      }
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(.ant-card-body) {
  padding: 15px !important;
}
.header-title {
  font-weight: 700;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  h3 {
    font-weight: 700;
    margin: 0;
    margin-left: 15px;
  }
  span:hover {
    cursor: pointer;
  }
}
.icon-chapter-add {
  cursor: pointer;
  margin-top: 20px;
  display: inline-flex;
  .icon {
    margin-right: 10px;
  }
}

.icon-chapter-remove {
  cursor: pointer;
    display: inline-flex;
    color: red;
    float: right;
  .icon {
    margin-right: 10px;
    margin-top: 2px;
  }
}
.description {
  position: relative;
  input {
    padding-right: 80px;
  }

  .count {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  button {
    margin-left: 18px;
  }
}

.msg-error-prefix {
  margin-right: 4px;
  color: #f5222d;
}

.msg-error {
  color: #f5222d;
  font-size: 12px;
  margin-top: 6px;
}

:deep(.vue-editor-wrapper) {
  margin-bottom: 24px;

  .ck-toolbar {
    border-radius: 5px 5px 0 0;
  }

  .ck-content {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ccc;
    min-height: calc(100vh - 630px);
    color: black;
    .table {
      max-width: 100vh;
      width: 100vh;
      display: block !important;
      overflow: auto;
      max-height: calc(100vh - 300px);
      &::-webkit-scrollbar {
        width: 8px !important;
        cursor: default;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
      }
    }
    @media only screen and (max-width: 1366px) {
      min-height: 300px;
    }
  }

  .ck-rounded-corners.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0 0 5px 5px;
  }

  .content.error {
    border: 1px solid #f5222d;
    border-radius: 5px;
  }
}
:deep(.ck-widget){
  max-width: none !important;
}
:deep(.ck-content) {
  ul, ol {
    list-style-position: inside;
  }
}
:deep(.ck-balloon-panel){
  z-index: -1;
}
</style>
