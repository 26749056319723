<template>
  <div class="result-test">
    <Wrapper :detailTest="detailTest">
      <div class="result-item">
        <a-collapse
          v-for="(result, index) in dataResult"
          :key="result.id"
          :bordered="false"
          expand-icon-position="right"
        >
          <a-collapse-panel key="1">
            <template #extra>
              <a-progress
                type="circle"
                :strokeWidth="12"
                stroke-linecap="square"
                :width="40"
                :percent="result.score || 0"
                strokeColor="#62DE00"
                trailColor="#E41D1D"
              />
              <div class="header-info">
                <p>
                  <span>{{ texts.numberTry }} {{ index + 1 }}</span
                  >:
                  <span
                    style="font-weight: bold"
                    class="green"
                    :class="{ red: !isPass(result.score) }"
                    >{{
                      isPass(result.score) ? texts.achieved : texts.notAchieved
                    }}</span
                  >
                </p>
                <div>
                  <p class="info_percent">{{ result.score || 0 }}%</p>
                  <p>
                    {{ calTestTime(result.started_at, result.submitted_at) }}
                    {{ texts.minute }}
                  </p>
                  <p>{{ getDMY(result.started_at) }}</p>
                </div>
              </div>
            </template>
            <div class="detail-result">
              <div>
                <a-progress
                  type="circle"
                  :strokeWidth="12"
                  stroke-linecap="square"
                  :width="150"
                  :percent="result.score || 0"
                  strokeColor="#62DE00"
                  trailColor="#E41D1D"
                />
                <div class="wrap-box">
                  <div class="box-item">
                    <span style="background-color: #62de00" class="box"></span>
                    <span style="color: #62de00">{{ texts.correct }}</span>
                  </div>
                  <div class="box-item">
                    <span style="background-color: #e41d1d" class="box"></span>
                    <span class="red">{{ texts.inCorrect }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="toggle-detail">
                  <p>
                    <span>{{ texts.numberTry }} {{ index + 1 }}</span
                    >:
                    <span
                      style="font-weight: bold"
                      class="green"
                      :class="{ red: !isPass(result.score) }"
                    >
                      {{
                        isPass(result.score)
                          ? texts.achieved
                          : texts.notAchieved
                      }}
                    </span>
                  </p>
                  <p>
                    <span>{{ texts.satisfactoryScore }}</span
                    >:
                    <span style="font-weight: bold"
                      >{{ detailTest.minimum_score }}%</span
                    >
                  </p>
                  <p>
                    <span>{{ texts.scores }}: </span>
                    <span class="info_percent">{{ result.score || 0
                       }}% </span>
                    <span>
                      ({{ result.corrects || 0 }}/{{ result.total || 0 }}
                      {{ texts.rightSentence }})
                    </span>
                  </p>
                  <p>
                    <span>{{ texts.timeTest }}: </span>
                    <span style="font-weight: bold">
                      {{ calTestTime(result.started_at, result.submitted_at) }}
                      {{ texts.minute }}
                    </span>
                  </p>
                  <p>
                    <span>{{ texts.finishDay }}: </span>
                    <span style="font-weight: bold">{{
                      getDMY(result.started_at)
                    }}</span>
                  </p>
                  <button
                    type="button"
                    :disabled="result.score === null"
                    class="btn-show"
                    @click="showAnswerTest(result.id)"
                  >
                    {{ texts.viewResult }}
                  </button>
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
      <div class="button-group">
        <button
          class="button button-disable"
          @click="handleRedoTest"
          v-if="isAllowRedo"
        >
          {{ texts.redo }}
        </button>
        <button
          v-if="tests.length > 1 && !isLastTest"
          class="button"
          @click="handleContinueTest"
        >
          {{ texts.continue }}
        </button>
      </div>
    </Wrapper>
    <div class="line"></div>
    <div class="description-course">
      <p class="head">{{ texts.infoCourse }}</p>
      <p class="text" v-html="detailTest.description" />
    </div>
  </div>
</template>
<script>
import { DetailsCourseApi } from "./api/request";
// import { interval } from "./helper/countdown";
import Wrapper from "./components/Wrapper.vue";
import moment from "moment";
import { texts } from "./utils/texts";
import { cloneDeep } from "lodash";
export default {
  props: {
    dataResult: Array,
    detailTest: Object,
    tests: Array,
    currentIdTest: Number,
  },
  data() {
    return {
      description: "",
      isAllowRedo: "",
      texts: texts,
    };
  },

  components: {
    Wrapper,
  },

  methods: {
    calTestTime(startTime, endTime) {
      if (endTime) {
        return moment(endTime).diff(moment(startTime), "minutes");
      } else {
        return 0;
      }
    },
    getDMY(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleRedoTest() {
      this.$emit("onRedoTest", this.detailTest.id);
    },

    handleContinueTest() {
      const locationTestInArray = this.tests.findIndex((test) => {
        return test.id === this.currentIdTest;
      });
      const nextIdText = this.tests[locationTestInArray + 1];
      this.$emit("onNextTest", nextIdText);
    },
    async showAnswerTest(idUserTest) {
      const { data } = await DetailsCourseApi.showResultTest(idUserTest);
      this.$emit("showAnswerTest", data.results, data.score);
    },
    isPass(userScore) {
      return userScore >= this.detailTest.minimum_score;
    },
    handleAllowRedo() {
      if (this.detailTest.number_mock_test === 0) {
        this.isAllowRedo = true;
        return;
      }
      this.isAllowRedo =
        this.detailTest.number_mock_test > this.dataResult.length;
    },
  },
  computed: {
    isLastTest() {
      const cloneArray = cloneDeep(this.tests);
      const lastTest = cloneArray.reverse()[0];
      return lastTest.id === this.currentIdTest;
    },
  },
  watch: {
    dataResult(value) {
      this.handleAllowRedo();
    },
  },
  mounted() {
    this.handleAllowRedo();
    window.history.pushState(null, null, window.location.href);
    this.$emit("cancelBeforeEach");
  },
};
</script>
<style lang="scss" scoped>
.button-group {
  margin-top: 26px;
  justify-content: flex-end;
  display: flex;
  gap: 18px;
  margin-bottom: 20px;
  .button {
    outline: none;
    border: none;
    background-color: #17469e;
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
    font-weight: bold;
    min-width: 70px;
  }
  .button-disable {
    border: 1px solid #17469e;
    color: #17469e;
    background: #fff;
  }
}
.green {
  color: #62de00;
}
.red {
  color: #e41d1d;
}
.result-test {
  .result-item {
    display: flex;
    flex-direction: column;
  }
  .info_percent {
    font-weight: 700;
  }
  .detail-result {
    display: flex;
    gap: 44px;
    .wrap-box {
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;
        span {
          font-size: 10px;
        }
        .box {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .text-medium {
    font-size: 14px;
  }
  .text-small {
    font-size: 12px;
  }

  :deep(.ant-collapse) {
    width: 100%;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    margin-top: 30px;
    .ant-collapse-extra {
      width: 100%;
      margin-left: 0;
      display: flex;
      gap: 40px;
      align-items: center;
      padding: 0 15px;
    }
    .header-info {
      flex: 1;
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      p {
        width: 30%;
      }
      div {
        flex: 1;
        display: flex;
        justify-content: space-between;
        gap: 30px;
      }
    }
    .toggle-detail {
      font-size: 14px;
      .btn-show {
        outline: none;
        border: 0.3px solid #000;
        font-size: 12px;
        padding: 3px 8px;
        margin-top: 16px;
        background-color: #fff;
        font-weight: bold;
      }
    }
    .toggle-detail {
      font-size: 14px;
      .btn-show {
        outline: none;
        border: 0.3px solid #000;
        font-size: 12px;
        padding: 3px 8px;
        margin-top: 16px;
        background-color: #fff;
        font-weight: bold;
      }
    }
    .ant-collapse-arrow {
      font-size: 20px;
      top: 32px;
    }
  }
  :deep(.ant-collapse-item-active) {
    .ant-collapse-extra {
      display: none;
      visibility: hidden;
      transition: 0.2s;
    }
  }
  .line {
    margin-top: 33px;
    padding: 0 8px;
    background-color: #e7e7e7;
    width: 100%;
    height: 1px;
  }
  .description-course {
    margin-top: 10px;
    margin-bottom: 20px;
    .head {
      font-size: 22px;
      font-weight: bold;
    }
    .text {
      margin-top: 10px;
      font-size: 16px;
      line-height: 22px;
      max-width: 500px;
    }
  }
}
:deep(.ant-progress-text) {
  opacity: 0;
}
p {
  margin: 0;
}
</style>