export const TypeActions = {
  Create: "CREATE",
  Delete: "DELETE",
  Edit: "EDIT",
  Update: "UPDATE",
  AcceptDel: "ACCEPT_DEL",
  Cancel: "CANCEL",
  AcceptEdit: "ACCEP_EDIT",
  Back: "BACK",
  DownLoad: "DOWNLOAD",
  View: "VIEW",
  Next: "NEXT",
  Prev: "PREV",
  TopTop: "TO_TOP"
};
export const TypeFilters = {
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGESIZE',
  Sort: 'SORT'
};
