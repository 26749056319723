<template>
  <div class="category-wrapper">
    <div class="category-content">
      <Loading v-if="isLoading" />
      <div>
        <h1 class="title">Danh sách thể loại</h1>
        <div class="btn-wrapper">
          <a-button class="add" type="primary" @click="toggleShowModalAdd">
            <PlusOutlined />
            Thêm thể loại
          </a-button>
        </div>

        <a-table
          :columns="columns"
          :pagination="false"
          :scroll="scrollHeightTable"
          :data-source="dataTable"
          :loading="isLoadingTable"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <div class="actions-wrapper">
                <span
                  class="icon"
                  @click="showModalEdit(record.fullTextCategory, record.id)"
                >
                  <IconEdit />
                </span>
                <span class="icon" @click="showModalDelete(record.id)">
                  <IconDelete />
                </span>
              </div>
            </template>
          </template>
        </a-table>
      </div>

      <div class="pagination-wrapper">
        <span>Rows per page:</span>
        <a-select default-value="10">
          <i class="fas fa-sort-down dropdown-icon"></i>
          <a-select-option
            v-for="(pageSize, index) in pageSizeList"
            :value="pageSize"
            :key="`pageSize_${index}`"
            @click="rowPerPage(pageSize)"
          >
            {{ pageSize }}
          </a-select-option>
        </a-select>
        <span class="total-page"
          >{{ this.meta.from }}-{{ this.meta.to }} of {{ totalPage }}</span
        >
        <a-button
          :disabled="meta.from === 1"
          class="pagination-btn"
          @click="handlePrevPage"
        >
          <i class="fas fa-chevron-left icon-btn"></i>
        </a-button>
        <a-button
          :disabled="meta.to === totalPage"
          class="pagination-btn"
          @click="handleNextPage"
        >
          <i class="fas fa-chevron-right icon-btn"></i>
        </a-button>
      </div>
      <a-modal
        :visible="add.visible"
        :footer="null"
        :title="editId ? add.titleEdit : add.titleAdd"
        @cancel="toggleShowModalAdd"
        centered
      >
        <a-form
          layout="vertical"
          ref="formRef"
          :model="formState"
          @finish="
            (data) => {
              if (editId) {
                modalConfirmEdit.visible = true;
              } else {
                addCategory();
              }
            }
          "
        >
          <div v-if="!!editId" class="category-value">
            <span class="category-title">Tên thể loại:</span>
            <span class="category-name">{{ editName }}</span>
          </div>
          <a-form-item
            :label="!!editId ? 'Tên thể loại mới' : 'Tên thể loại'"
            name="name"
            whitespace
            :rules="[
              { required: true, message: 'Vui lòng nhập tên thể loại' },
              {
                max: 255,
                message:
                  'Tên thể loại đã vượt quá ký tự cho phép (255 ký tự), xin hãy thay đổi tên thể loại'
              },
              { whitespace: true, message: 'Vui lòng nhập tên thể loại' }
            ]"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Nhập tên thể loại"
            />
          </a-form-item>

          <div class="btn-wrapper-modal">
            <a-button @click="toggleShowModalAdd"> Hủy </a-button>
            <a-button type="primary" html-type="submit">
              {{ !!editId ? 'Sửa' : 'Thêm' }}
            </a-button>
          </div>
        </a-form>
      </a-modal>

      <ModalConfirm
        :message="modalConfirmDel.message"
        :visible="modalConfirmDel.visible"
        @cancel="modalConfirmDel.visible = false"
        @ok="deleteCategory"
        type="DELETE"
      />

      <ModalConfirm
        :message="modalConfirmEdit.message"
        :visible="modalConfirmEdit.visible"
        @cancel="modalConfirmEdit.visible = false"
        @ok="editCategory"
        type="EDIT"
      />
      <ModalConfirm
        cancelHidden
        :message="modalConfirmEditError.message"
        :visible="modalConfirmEditError.visible"
        :zIndex="1001"
        @cancel="modalConfirmEditError.visible = false"
        @ok="hiddenModalConfirm"
        type="CONFIRM"
      />
    </div>
  </div>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive } from 'vue';
import { ELearningManagementApi } from './api/request';
import Loading from '../../../components/loading/Loading.vue';
import ModalConfirm from '../../../components/modal/ModalConfirm.vue';
import IconDelete from '../../../components/icon/icon-delete.vue';
import IconEdit from '../../../components/icon/icon-edit.vue';
import { tableColumns } from '../../../constant/categoryTable';

export default defineComponent({
  components: {
    IconDelete,
    IconEdit,
    Loading,
    ModalConfirm,
    PlusOutlined
  },
  data() {
    return {
      categories: {},
      name: '',
      totalPage: '',
      pageSize: 10,
      page: 1,
      meta: {
        from: 1,
        to: 10
      },
      scrollHeightTable: {
        y: 'calc(100vh - 400px)'
      },
      limit: 10,
      deleteId: '',
      editName: '',
      editId: '',
      pageSizeList: [10, 20, 25, 50, 100],
      lastPage: '',
      textError: '',
      columns: tableColumns,
      dataTable: [],
      isLoading: false,
      isLoadingTable: false,
      add: {
        visible: false,
        titleAdd: 'Thêm mới thể loại',
        titleEdit: 'Sửa thể loại'
      },
      modalConfirmDel: {
        visible: false,
        message: 'Bạn đang thực hiện xoá thể loại'
      },
      modalConfirmEdit: {
        visible: false,
        message: 'Bạn đang thực hiện sửa thể loại'
      },
      modalConfirmEditError: {
        visible: false,
        message: 'Tên thể loại đã tồn tại. Bạn vui lòng kiểm tra lại!'
      }
    };
  },

  setup() {
    const formState = reactive({
      name: ''
    });

    return {
      formState
    };
  },
  methods: {
    async getResult(limit, page) {
      try {
        this.isLoadingTable = true;
        const response = await ELearningManagementApi.getCategory({
          limit,
          page
        });
        this.categories = response;
        this.totalPage = response.total;
        this.lastPage = response.last_page;
        this.checkRow();
        this.dataTable = this.categories.data.map((item, index) => ({
          key: item.id,
          category: this.limitTextLength(item.name),
          creator: item.user?.name || '',
          no:
            (this.categories.current_page - 1) * this.categories.per_page +
            1 +
            index,
          id: item.id,
          fullTextCategory: item.name
        }));
      } catch (error) {
      } finally {
        this.isLoadingTable = false;
      }
    },
    limitTextLength(text) {
      if (text.length >= 50) {
        return `${text.substring(0, 50)}...`;
      } else return text;
    },
    checkRow() {
      this.meta.from = this.page * this.pageSize - this.pageSize + 1;
      this.meta.to = this.meta.from + this.pageSize - 1;
      if (this.meta.to >= this.totalPage) {
        this.meta.to = this.totalPage;
      }
    },
    handlePrevPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
      this.getResult(this.limit, this.page);
    },
    handleNextPage() {
      this.btn = false;
      if (this.page < this.lastPage) {
        this.page = this.page + 1;
      }
      this.getResult(this.limit, this.page);
    },
    rowPerPage(pageSize) {
      this.pageSize = pageSize;
      this.limit = pageSize;
      if (this.totalPage % pageSize != 0) {
        this.lastPage = Math.floor(this.totalPage / pageSize + 1);
      } else {
        this.lastPage = Math.floor(this.totalPage / pageSize);
      }
      this.page = 1;
      this.getResult(this.limit, 1);
    },
    toggleShowModalAdd() {
      this.editId = null;
      this.add.visible = !this.add.visible;
      this.formState.name = '';
      this.$refs.formRef.resetFields();
    },
    async addCategory() {
      try {
        this.isLoading = true;
        const response = await ELearningManagementApi.createCategory(
          this.formState
        );
        if (response.data) {
          this.getResult(this.limit, this.page);
          this.notifyEditSuccess();
          this.add.visible = false;
        } else if (response.error) {
          this.modalConfirmEditError.visible = true;
        }
      } catch (error) {
        this.modalConfirmEditError.visible = true;
      } finally {
        this.isLoading = false;
      }
    },
    async editCategory() {
      try {
        this.isLoading = true;
        const response = await ELearningManagementApi.editCategory(
          this.editId,
          this.formState
        );
        if (response.data) {
          this.getResult(this.limit, this.page);
          this.notifyEditSuccess();
          this.modalConfirmEdit.visible = false;
          this.add.visible = false;
          this.editId = null;
        }
      } catch (error) {
        if (error) {
          this.modalConfirmEdit.visible = false;
          this.modalConfirmEditError.visible = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCategory() {
      try {
        this.isLoading = true;
        const response = await ELearningManagementApi.deleteCategory(
          this.deleteId
        );
        if ((this.totalPage - 1) % this.pageSize == 0) {
          if (this.page != 1) {
            this.page = this.page - 1;
          }
          this.getResult(this.limit, this.page);
        } else {
          this.getResult(this.limit, this.page);
        }
        this.notifyEditSuccess();
        this.modalConfirmDel.visible = false;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    showModalEdit(name, id) {
      this.editName = name;
      this.editId = id;
      this.add.visible = true;
      this.formState.name = '';
    },
    showModalDelete(id) {
      this.deleteId = id;
      this.modalConfirmDel.visible = true;
    },
    editCategories() {},
    cancelModalAdd() {
      this.textError = '';
      this.form.resetFields();
    },
    cancelModalEdit(e) {
      this.form.resetFields();
      this.textError = '';
    },
    cancelModalConfirm() {},
    cancelModalDelete() {},

    hiddenModalConfirm() {
      this.modalConfirmEditError.visible = false;
    },
    notifyEditSuccess() {
      this.$notification.open({
        message: 'Thành công',
        className: 'notify-success',
        style: {
          background: '#00d909 !important',
          width: '340px',
          borderRadius: '5px'
        },
        closeIcon: ' ',
        description: 'Thay đổi được thực hiện thành công! '
      });
    }
  },
  created() {
    this.getResult(this.limit, this.page);
  }
});
</script>

<style lang="scss" scoped>
.category-wrapper {
  background-color: #fff;
  height: 100%;
  border-radius: 5px;
  padding: 20px 24px;
  overflow-y: auto;
}
.actions-wrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-right: 10px;
  span:hover {
    cursor: pointer;
  }
}
.error-message {
  color: #f5222d;
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  font-size: 14px;
  line-height: 1.5;
}
:deep(.notify-success) {
  color: red !important;
}
:deep(.anticon-question-circle) {
  display: none !important;
}
:deep(.ant-modal .ant-modal-title) {
  text-align: center;
  color: #000000;
  font-size: 24px !important;
  line-height: calc(28.97 / 24);
  font-weight: 600;
}
:deep(.ant-table-cell) {
  text-overflow: ellipsis;
}
:deep(label) {
  color: #333333;
  font-size: 16px;
  line-height: 162.02%;
  font-weight: 600;
}
:deep(.ant-modal-header) {
  border-bottom: none !important;
}
:deep(.ant-modal-body) {
  padding: 8px 24px;
}

.category-value {
  display: flex;
  margin-bottom: 10px;
  .category-title {
    color: #333333;
    font-size: 16px;
    line-height: 162.02%;
    font-weight: 600;
    margin-right: 5px;
    white-space: nowrap;
  }
  .category-name {
    color: #0047ff;
    font-size: 16px;
    line-height: calc(26 / 16);
    font-weight: 600;
    word-break: break-word;
  }
}
.primary {
  background: #1280bf;
  span {
    color: #fff;
  }
}
.category-content {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icon-add {
    position: relative;
    top: -1px;
  }
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    width: 160px;
    padding: 6px;
  }
  .ant-btn-primary {
    background-color: #1280bf;
  }
}

.title {
  margin-top: 4px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 18px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.action-wrapper {
  display: flex;
  gap: 16px;
  .icon {
    cursor: pointer;
  }
}

.pagination-wrapper {
  display: flex;
  margin-top: 30px;
  justify-content: right;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    padding: 14px;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
:deep(.ant-table) {
  color: #000;
  font-size: 16px;
  th {
    font-size: 20px;
  }

  tr > td {
    padding: 16px 16px;
  }
  @media only screen and (max-width: 1366px) {
    tr > td {
      padding: 12px 12px;
    }
  }
}

.btn-wrapper-modal {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
</style>
