<template>
  <div class="step-layout-wrapper">
    <div class="header-title">
      <div class="button-wrapper">
        <a-button @click="handleBackListCourse" class="btn-back">
          <LeftOutlined />
          {{t("courseManagement.common.backList")}}
        </a-button>
        <a-button
          type="primary"
          class="btn-edit"
          @click="handleSwitchEditCourse"
          v-if="isViewCourse"
        >
          {{t("courseManagement.common.edit")}}
        </a-button>
      </div>
      <h3 class="text-2xl font-bold">{{ title }}</h3>
    </div>
    <slot/>
  </div>
</template>

<script setup>
import { defineComponent, computed, defineProps, h, defineEmits } from "vue";
import { useStore } from "vuex";
import { LeftOutlined } from "@ant-design/icons-vue";
import { useShowConfirm } from "../../../../utils/hook";
import IconEdit from "../../../../components/icon/icon-edit.vue";
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
defineComponent({
  LeftOutlined,
  IconEdit,
});
defineProps({
  title: {
    require: true,
    type: String,
  },
  getListCourse: Function,
});
const store = useStore();
const emit = defineEmits();
const isViewCourse = computed(() => store.state.courseManagement.view_course);

const handleBackListCourse = () => {
  emit('getListCourse');
  store.dispatch('backListCourse');
  store.dispatch('updateViewStatus', false);
};
const handleSwitchEditCourse = () => {
  store.dispatch("updateEditStatus", true);
  store.dispatch("updateViewStatus", false);
  store.dispatch("doingStep", 1);
  store.dispatch("updateModeCreate", false);
};

</script>

<style lang="scss" scoped>
.step-layout-wrapper {
  display: flex;
  flex-direction: column;
  .header-title {
    font-weight: 700;
    margin-bottom: 10px;
    h3 {
      font-weight: 700;
      margin: 0;
      margin-left: 15px;
    }
    span:hover {
      cursor: pointer;
    }
    button {
      display: flex;
      align-items: center;
      color: #1890ff;
    }
  }
  .content-wrapper {
    flex: 1;
    display: flex;
    gap: 20px;

    .step {
      width: 74px;
    }
    .content {
      flex: 1;
    }
  }
  .button-wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
    .btn-back {
      border: none;
      font-size: 16px;
    }
    .btn-edit {
      color: white;
      height: 34px !important;
      svg {
        margin-right: 8px;
      }
    }
  }
}
</style>
