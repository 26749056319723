<template>
  <a-modal
    :title="title"
    :visible="isVisible"
    :style="{ minHeight: '550px', width: '1270px' }"
    :closable="true"
    :onCancel="onCancel"
    centered
    footer=""
  >
    <p class="text-xl font-bold mb-4">{{ subTitle }}</p>
    <div class="flex justify-between mt-2">
      <a-form>
        <a-row :gutter="22" class="flex gap-4 items-start">
          <a-select
            v-if="isCourse"
            allowClear
            showSearch
            optionFilterProp="filterProps"
            v-model:value="params.user_ids"
            mode="multiple"
            style="width: 200px"
            :placeholder="t('courseManagement.courseware.staff')"
            :max-tag-count="maxTagCount"
            @change="handleFilter"
            :listHeight="156"
          >
            <a-select-option
              v-for="user in listUser"
              :value="user.id"
              :key="user.id"
              :filterProps="user.name"
            >
              {{ user.name }}
            </a-select-option>
          </a-select>
          <a-select
            v-if="isCourse"
            allowClear
            showSearch
            style="width: 200px"
            :placeholder="t('courseManagement.statisticalCol.mandatoryStaff')"
            v-model:value="params.is_compulsory"
            @change="handleFilter"
          >
            <a-select-option
              v-for="type in listCompulsoryUser"
              :value="type.id"
              :key="type.id"
              :filterProps="type.name"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
          <div v-else class="flex gap-4 items-start">
            <a-select
              :placeholder="t('courseManagement.courseware.test')"
              allowClear
              showSearch
              optionFilterProp="filterProp"
              v-model:value="params.test_id"
              @change="handleFilter"
            >
              <a-select-option
                v-for="test in listTest"
                :value="test.id"
                :key="test.id"
                :filterProp="test.name"
              >
                {{ test.name }}
              </a-select-option>
            </a-select>
            <a-select
              allowClear
              showSearch
              optionFilterProp="filterProps"
              v-model:value="params.user_ids"
              mode="multiple"
              style="width: 200px"
              :placeholder="t('courseManagement.courseware.staff')"
              :max-tag-count="maxTagCount"
              @change="handleFilter"
              :listHeight="156"
            >
              <a-select-option
                v-for="user in listUser"
                :value="user.id"
                :key="user.id"
                :filterProps="user.name"
              >
                {{ user.name }}
              </a-select-option>
            </a-select>
          </div>
          <a-select
            :placeholder="t('courseManagement.courseware.branch')"
            allowClear
            showSearch
            optionFilterProp="filterProp"
            v-model:value="params.branch"
            @change="handleFilter"
          >
            <a-select-option
              v-for="branch in LIST_BRANCH"
              :value="branch.name"
              :key="branch.id"
              :filterProp="branch.name"
            >
              {{ branch.name }}
            </a-select-option>
          </a-select>
          <div class="relative">
            <p v-if="isCourse" class="absolute -top-6 font-base font-bold">{{t('courseManagement.statisticalCol.dateStartCourse')}}</p>
            <p v-else class="absolute -top-6 font-base font-bold">{{t('courseManagement.statisticalCol.filterDate')}}</p>
            <a-range-picker
              v-model:value="date"
              size="large"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              @change="handleChangeDate"
              :allowEmpty="[true, true]"
              :placeholder="[t('courseManagement.statisticalCol.startDate'), t('courseManagement.statisticalCol.endDate')]"
            />
          </div>
          <div v-if="!isCourse" class="flex gap-1 items-start max-w-[220px] relative">
            <p class="absolute -top-6 font-base font-bold">{{t('courseManagement.statisticalCol.filterScore')}}</p>
            <a-form-item
              class="flex items-center gap-[2px] flex-nowrap"
              name="highest_score"
              :rules="[{ validator: validateNumbers, trigger: 'change' }]"
            >
              <a-input-number
                v-model:value="params.minium_score"
                :min="0"
                :max="100"
                :formatter="(value) => `${value}%`"
                :parser="(value) => value.replace('%', '')"
                :defaultValue="null"
                size="large"
                @change="handleFilter"
              />
              <MinusOutlined class="text-xs relative -top-4 px-1" />
              <a-input-number
                v-model:value="params.highest_score"
                :min="0"
                :max="100"
                :formatter="(value) => `${value}%`"
                :parser="(value) => value.replace('%', '')"
                :defaultValue="null"
                size="large"
                @change="handleFilter"
              />
            </a-form-item>
          </div>
        </a-row>
      </a-form>

      <a-button type="primary" @click="handleExportExcel">
        {{ t("courseManagement.courseware.exportExcel") }}
      </a-button>
    </div>
    <a-table
      :columns="column"
      :pagination="false"
      :scroll="scrollHeightTable"
      :data-source="isCourse ? dataDetailCourse : dataDetailTest"
      :loading="loadingTable"
      @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'is_compulsory'">
          <div class="flex justify-center" v-if="record.is_compulsory">
            <CheckOutlined></CheckOutlined>
          </div>
          <span v-else />
        </template>
        <template v-if="column.key === 'score'">
          <div class="flex items-center">
            <span>{{ record.correct_questions }}/</span>
            <span>{{ record.total_questions }}&nbsp;</span>
            <span>({{ record.score }}%)</span>
          </div>
        </template>
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <span>Rows per page:</span>
      <a-select default-value="10" v-model:value="params.limit">
        <i class="fas fa-sort-down dropdown-icon"></i>
        <a-select-option
          v-for="(limit, index) in pageSizeList"
          :value="limit"
          :key="`pageSize_${index}`"
          @click="handleActions(ACTION.ChangeSize, null, limit)"
        >
          {{ limit }}
        </a-select-option>
      </a-select>
      <span class="total-page"
        >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
      >
      <a-button
        class="pagination-btn"
        @click="handleActions(ACTION.Prev)"
        :disabled="disabledPrev"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <a-button
        class="pagination-btn"
        @click="handleActions(ACTION.Next)"
        :disabled="disabledNext"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
  </a-modal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineComponent,
  reactive,
  defineEmits,
  watchEffect,
  watch,
  onMounted,
  computed,
  onBeforeMount,
} from "vue";
import {
  LIST_BRANCH,
  listCompulsoryUser,
} from "../../features/e-learning/course/utils/constant";
import { formatDate } from "../../utils/helper";
import { useI18n } from "vue-i18n";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { ACTION } from "../../features/e-learning/course/utils/constant";
import { statisticalApi } from "../../features/e-learning/course/api/request";
import { useStore } from "vuex";
import { limitTextLength } from "../../utils/helper";
import { message } from "ant-design-vue";

const emit = defineEmits();
const { t } = useI18n();
const store = useStore();
const pageSizeList = [10, 20, 25, 50, 100];
const props = defineProps({
  isVisible: {
    required: true,
    type: Boolean,
  },
  isCourse: {
    default: false,
    type: Boolean,
  },
  column: {
    required: true,
  },
  title: String,
  listUser: Array,
  listTest: Array,
  changePage: Function,
  meta: {
    default: {
      total: null,
      from: 1,
      to: null,
      currentPage: 1,
    },
    type: Object,
  },
  isDetailCourse: {
    default: false,
    type: Boolean,
  },
  subTitle: String,
});
defineComponent({
  CheckOutlined,
  MinusOutlined,
});
const courseId = computed(() => store.state.courseManagement.course_id);
const loadingTable = ref(false);

const params = reactive({
  course_id: courseId.value,
  user_ids: [],
  limit: 10,
  page: 1,
  is_compulsory: null,
  test_id: null,
  branch: null,
  start_date: null,
  end_date: null,
  minium_score: null,
  highest_score: null,
});

const meta = reactive({
  total: null,
  from: 1,
  to: null,
  currentPage: 1,
});

const dataDetailCourse = ref([]);
const dataDetailTest = ref([]);
const disabledNext = ref(false);
const disabledPrev = ref(false);
const maxTagCount = ref(3);
const date = ref(null)
const scrollHeightTable = reactive({
  y: "calc(50vh - 22px)",
});

const handleExportExcel = () => {
  if (props.isDetailCourse) {
    emit("export", {
      type: "course",
      ...params,
    });
  } else {
    emit("export", {
      type: "test",
      ...params,
    });
  }
};

const handleFilter = (value) => {
  params.page = 1;
  if (Array.isArray(value)) {
    if (value.length >= 10) {
      message.info(t("courseManagement.statisticalCol.msgLimitChooseStaff"));
      params.user_ids = value.slice(0, 10);
    }
  }
};

const fetchDetailCourseStatistics = async (params) => {
  try {
    loadingTable.value = true;
    const res = await statisticalApi.getDetailCourseStatistics(params);
    dataDetailCourse.value = res.data.data.map((item) => {
      return {
        ...item,
        started_at: formatDate(item.started_at),
        study_time: `${item.study_time} ${t(
          "courseManagement.statisticalCol.minute"
        )}`,
        percentage_progress: `${Math.floor(item.percentage_progress)} %`,
      };
    });
    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } finally {
    loadingTable.value = false;
  }
};

const fetchDetailTestStatistics = async (params) => {
  try {
    loadingTable.value = true;
    const res = await statisticalApi.getDetailTestStatistics(params);
    dataDetailTest.value = res.data.data.map((item) => {
      return {
        ...item,
        started_at: formatDate(item.started_at),
        test_name: limitTextLength(item.test_name),
        time_taken: `${item.time_taken} phút`,
      };
    });

    meta.total = res.data.total;
    meta.currentPage = res.data.current_page;
    meta.from = res.data.from;
    meta.to = res.data.to;
  } finally {
    loadingTable.value = false;
  }
};

const validateNumbers = (_rule, value) => {
  if (
    params.minium_score > params.highest_score &&
    params.highest_score !== null
  ) {
    return Promise.reject(t("courseManagement.courseware.msgRangeNumber"));
  }
};

const handleChangeDate = (dates) => {
  params.page = 1;
  params.start_date = dates?.[0];
  params.end_date = dates?.[1];
};

const onCancel = () => {
  params.user_ids = [];
  params.start_date = null;
  params.end_date = null;
  params.minium_score = null;
  params.highest_score = null;
  params.page = 1;
  params.limit = 10;
  params.sort_by = null;
  params.sort_type = null;
  params.test_id = null;
  params.user_id = null;
  params.is_compulsory = null;
  params.branch = null;
  date.value = null
};

watch(meta, (value) => {
  disabledNext.value = !value.to || value.to === value.total;
  disabledPrev.value = !value.from || value.from === 1;
});

const handleTableChange = (pagination, filters, sorter) => {
  params.sort_by = sorter.field;
  params.sort_type = sorter.order == "ascend" ? "asc" : "desc";
};

watchEffect(() => {
  if (props.isVisible) {
    if (props.isCourse) {
      fetchDetailCourseStatistics(params);
    } else {
      params.highest_score >= params.minium_score ||
      params.highest_score === null
        ? fetchDetailTestStatistics(params)
        : null;
    }
  } else {
    document.querySelector(".anticon.active")?.classList.remove("active");
  }
});

const handleActions = (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      params.page = params.page + 1;
      break;
    case ACTION.Prev:
      params.page = params.page - 1;
      break;
    case ACTION.ChangeSize:
      params.page = 1;
      params.limit = payload;
      break;
    default:
      break;
  }
};
</script>
<style lang="scss" scoped>
:deep(.ant-modal) {
  :deep(.ant-modal-title) {
    text-align: left !important;
    font-size: 22px;
  }
}
.btn-search {
  font-weight: 600;
  font-size: 16px;
  line-height: 193%;
  color: #929292;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    display: inline-block;
    margin-right: 6px;
  }
  svg {
    display: inline-block;
    margin-bottom: 20px;
  }
}

:deep(.ant-table-column-title) {
  color: black;
  font-size: 18px;
  @media only screen and (max-width: 1366px) {
    font-size: 16px;
  }
}
:deep(.ant-table-cell) {
  color: black;
  font-size: 16px;
  font-weight: 400;
  span {
    font-size: inherit;
    font-weight: inherit;
  }
}
:deep(.ant-select-selection-item) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
:deep(.ant-picker) {
  border-radius: 6px;
  max-width: 250px;
  input::placeholder{
    font-size: 14px;
  }
  .ant-picker-range-separator{
    position: relative;
    top: -3px;
  }
}
:deep(.ant-input-number) {
  border-radius: 6px;
  overflow: hidden;
}
</style>
