import i18n from '../../../../i18n';
const { t } = i18n.global;

export const FILE_TYPE = {
  Video: 'video',
  Document: 'document'
};

export const VIDEOS_FILE = {
  type: ['video/mp4', 'video/quicktime'],
  message: ['MP4', 'MOV'],
  maxSize: 500 * 1024 * 1024
};

export const DOCS_FILE = {
  type: [
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  message: ['WORD', 'MSWORD', 'POWERPOINT', 'PDF'],
  maxSize: 100 * 1024 * 1024
};
export const COLUMN = [
  {
    title: 'STT',
    dataIndex: 'stt',
    width: '1.5%',
    key: 'stt'
  },
  {
    title: t('courseManagement.courseware.nameCourseware'),
    dataIndex: 'name',
    width: '5%',
    key: 'name',
    sorter: true
  },
  {
    title: t('courseManagement.courseware.categoryCourseware'),
    dataIndex: 'type_file',
    width: '5%',
    key: 'type_file',
    sorter: true
  },
  {
    title: t('courseManagement.courseware.createAt'),
    dataIndex: 'created_at',
    width: '5%',
    key: 'created_at',
    sorter: true
  },
  {
    title: t('courseManagement.courseware.poster'),
    dataIndex: 'creator',
    width: '5%',
    key: 'creator',
    sorter: true
  },
  {
    title: '',
    dataIndex: 'action',
    width: '1%',
    key: 'action'
  }
];
export const ACTION = {
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGESIZE',
  Edit: 'EDIT',
  Delete: 'DELETE'
};
export const TYPE_FILE = [
  {
    id: 1,
    name: 'Video'
  },
  {
    id: 2,
    name: 'Bài giảng'
  }
];
