<template>
  <div class="condition-content">
    <div class="content">
      <div class="name-lesson">{{ detailTest?.name }} - {{ texts.result }}</div>
      <div class="test-info">
        <div style="border-right: 2px solid #000">
          <span style="font-weight: bold">{{ texts.time }}: </span
          ><span>{{ timeCalculate(detailTest.time) }}</span>
        </div>
        <div style="border-right: 2px solid #000">
          <span style="font-weight: bold">{{ texts.questionCount }}: </span>
          <span>{{
            detailTest?.limit_questions ? detailTest?.limit_questions : detailTest?.total_questions
          }}</span>
        </div>
        <div>
          {{ texts.numberMockTest }}:<span>{{
            detailTest.number_mock_test === 0
              ? texts.noLimit
              : detailTest.number_mock_test
          }}</span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { texts } from "../utils/texts";
export default {
  props: {
    detailTest: Object,
  },
  data() {
    return {
      texts: texts,
    };
  },
  methods: {
    handleClick() {
      this.$emit("handleEmit", this.detailTest.id);
    },
    timeCalculate(time) {
      const minute = time % 60;
      const hour = Math.floor(time / 60);
      return `${hour} giờ ${minute ? `${minute} phút` : ""}`;
    },
  },

  created() {
  },
};
</script>
<style lang="scss" scoped>
.condition-content {
  flex: 1;
  .content {
    position: relative;
    border: 1px solid #000;
    height: 70%;
    padding: 8px 24px;
    .name-lesson {
      font-size: 22px;
      font-weight: bold;
    }
    .test-info {
      display: flex;
      font-size: 12px;
      margin-left: -22px;
      margin-top: 10px;
      div {
        padding: 0 37px;
        font-size: 12px;
        font-weight: bold;
        height: 25px;
        display: flex;
        align-items: center;
        span {
          font-weight: normal;
          margin-left: 4px;
        }
      }
    }
    .test-introduce {
      font-size: 14px;
      margin-top: 30px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      gap: 16px;
      span {
        margin-left: 5px;
        display: inline;
        font-weight: normal;
      }
    }
    .test-requirement {
      font-weight: bold;
      color: #62de00;
    }
    .red {
      color: #e41d1d;
    }
  }
}
</style>