import i18n from '../../../i18n';
const { t } = i18n.global;

const TOOL_BAR = [
  'undo',
  'redo',
  'heading',
  '|',
  'fontfamily',
  'fontsize',
  '|',
  'alignment',
  '|',
  'fontColor',
  'fontBackgroundColor',
  '|',
  '|',
  'italic',
  'strikethrough',
  'underline',
  '|',
  'link',
  'imageUpload',
  '|',
  'outdent',
  'indent',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'insertTable',
  '|',
  'blockQuote',
  '|'
];

export { TOOL_BAR };
export const COLUMNS = [
  {
    title: t('courseManagement.post.no'),
    dataIndex: 'no',
    sorter: false,
    width: 60,
    key: 'no',
    fixed: 'left',
    align: 'center',
  },
  {
    title: t('courseManagement.post.title'),
    dataIndex: 'title',
    sorter: true,
    width: 150,
    key: 'title',
    fixed: 'left'
  },
  {
    title: t('courseManagement.post.category'),
    dataIndex: 'categories',
    sorter: false,
    width: 150,
    key: 'categories',
    fixed: 'left'
  },
  {
    title: t('courseManagement.post.dateSubmit'),
    dataIndex: 'created_at',
    sorter: true,
    width: 150,
    key: 'created_at',
    fixed: 'left'
  },
  {
    title: t('courseManagement.post.poster'),
    dataIndex: ['user', 'name'],
    sorter: false,
    width: 150,
    key: 'user.name',
    fixed: 'left'
  },
  {
    title: t('courseManagement.post.status'),
    dataIndex: 'active',
    width: 100,
    key: 'active',
    fixed: 'left',
    align: 'center',
  },
  {
    title: t('courseManagement.post.totalAccess'),
    key: 'totalAccess',
    align: 'center',
    width: 200,
    children: [
      {
        title: t('courseManagement.post.total'),
        width: 60,
        fixed: 'left',
        key: 'statistical',
        dataIndex: 'statistical',
        align: 'center'
      },
    ]
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: 80,
    key: 'action',
    fixed: 'right',
    align: 'center',
  }
];
export const USER_HANDLER = 'Phạm Hồng Nhung'

export const SYSTEM_WIKI = "Hệ thống Wiki"