export const texts = {
  time: 'Thời gian',
  questionCount: 'Số lượng câu hỏi',
  numberMockTest: 'Số lần thử',
  introduce: 'Giới thiệu',
  condition: 'Điều kiện',
  noCondition: 'Không có điều kiện',
  eligible: 'Bạn đã đủ điều kiện tham gia bài kiểm tra',
  noEligible: 'Bạn không đủ điều kiện tham gia bài kiểm tra',
  btnStart: 'Bắt đầu',
  noLimit: 'Không giới hạn',
  course: 'Khóa học',
  textConfirmExit: 'Bạn vẫn chưa nộp bài. Bạn có thật sự muốn thoát',
  messageTimeout:
    'Thời gian làm bài đã hết. Vui lòng hãy nhấn nộp bài để hoàn thành',
  messageUnfinishedQuestion:
    'Bạn vẫn còn câu hỏi chưa hoàn thành. Bạn có muốn nộp bài kiểm tra?',
  messageEmptyCourse:
    'Bài kiểm tra này hiện tại không có câu trả lời, xin hãy bổ sung thêm câu trả lời vào bài',
  messageIsBack:
    'Không được quay lại trang trước đó khi đang làm bài kiểm tra.',
  messageConfirmExitPage: 'Bạn có chắc chắn muốn thoát khỏi trang web?',
  contentCourse: 'Nội dung khóa học',
  chapter: 'Bài giảng',
  chapterText: 'bài giảng',
  test: 'Bài kiểm tra',
  testText: 'bài kiểm tra',
  references: 'Tài liệu tham khảo',
  btnNext: 'Tiếp',
  btnBack: 'Quay lại',
  btnIgnore: 'Bỏ qua',
  btnSubmit: 'Hoàn thành',
  startCourse: 'Bắt đầu khóa học',
  continueCourse: 'Tiếp tục khóa học',
  imagePlaceholder: 'Anh bia khoa hoc',
  question: 'Câu hỏi',
  deadline: 'Deadline',
  correct: 'Đúng',
  inCorrect: 'Sai',
  numberTry: 'Lần thử số',
  achieved: 'Đạt',
  notAchieved: 'Không đạt',
  satisfactoryScore: 'Điểm đạt yêu cầu',
  scores: 'Điểm số',
  timeTest: 'Thời gian làm bài',
  rightSentence: 'Câu đúng',
  minute: 'Phút',
  hour: 'Giờ',
  finishDay: 'Ngày hoàn thành',
  viewResult: 'Xem đáp án',
  redo: 'Làm lại',
  continue: 'Tiếp tục',
  infoCourse: 'Thông tin khóa học',
  result: 'Kết quả',
  textRequirement: 'Bạn phải hoàn thành bài',
  chapter: 'Chương',
  confirm: 'Xác nhận',
  warning: 'Cảnh báo',
  ok: 'Ok',
  cancel: 'Hủy',
  lesson: 'Bài giảng',
  post: 'Bài'
};
