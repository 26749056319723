import moment from 'moment';
import { Upload, message } from 'ant-design-vue';

export const formatString = (str) => {
  str = str.replace(/<br>/gi, ' ');
  str = str.replace(/<p.*>/gi, '');
  str = str.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, '');
  str = str.replace(/<(?:.|\s)*?>/g, '');
  return str;
};

/**
 * Hàm tạo hash id unique cho item trong list
 * @param {Record<K, T>[]} list - list check unique id
 * @param {string} fieldName - field check unique
 * @returns
 */
export const generateId = (list, fieldName = 'id') => {
  const uniqueId = (Math.random() + 1).toString(36).substring(7);
  if (find(list, (item) => item[fieldName] === uniqueId)) {
    return generateId(list);
  }
  return uniqueId;
};

export const truncateText = (string, length = 20, end = '...') => {
  return string.length < length ? string : string.substring(0, length) + end;
};
export const formatDate = (dateStr) => {
  const date = moment(dateStr).format('DD/MM/YYYY');
  if (date === 'Invalid date') {
    return '';
  } else {
    return date;
  }
};
export const limitTextLength = (text) => {
  if (text.length >= 50) {
    return `${text.substring(0, 50)}...`;
  } else return text;
};

export const truncate = (string) => {
  const text = string.split('=');
  return text[1];
};

const invalidFileSize = (name, size) => `File ${name} phải nhỏ hơn ${size / 1024 / 1024}MB`;
export const checkTypeAndSizeUpload = (file, constant) => {
  const config = {
    class: 'custom-class',
    style: {
      marginTop: '90vh',
      color: 'red'
    },
    duration: 3
  };
  const isTrueType = constant.type.includes(file.type);
  const isOverSize = file.size > constant.maxSize;
  if (!isTrueType) {
    message.error({
      content: () => `File ${file.name} phải là ${constant.message.join('/')}`,
      ...config
    });
  }
  if (isOverSize) {
    message.error({
      content: () => invalidFileSize(file.name, constant.maxSize),
      ...config
    });
  }
  return (isTrueType && !isOverSize) || Upload.LIST_IGNORE;
};

export function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
  str = str.replace(/đ/g,"d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g," ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  return str;
}
