<template>
  <div class="h-full bg-white !px-6 !py-6 flex flex-col">
    <p class="font-bold text-2xl">Kho học liệu</p>
    <div class="filter-wrapper mt-[5px]">
      <a-form :model="formState" name="'filter'">
        <a-row :gutter="20" style="width: 100%">
          <a-col :span="8" class="name-input">
            <a-form-item label="" name="keyword">
              <a-input-search
                :placeholder="
                  t('courseManagement.list.titlePlaceholderCourseware')
                "
                @search="handleFilter"
                v-model:value="formState.keyword"
              >
                <template #prefix>
                  <IconSearch />
                </template>
                <template #enterButton>
                  <a-button type="text">{{
                    t("courseManagement.list.search")
                  }}</a-button>
                </template>
              </a-input-search>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="" name="position_id">
              <a-select
                :placeholder="
                  t('courseManagement.courseware.categoryCourseware')
                "
                allowClear
                showSearch
                optionFilterProp="filterProps"
                v-model:value="formState.type_file_id"
                @change="handleFilter"
              >
                <a-select-option
                  v-for="type in TYPE_FILE"
                  :value="type.id"
                  :key="type.id"
                  :filterProps="type.name"
                >
                  {{ type.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="" name="creator_id">
              <a-select
                :placeholder="t('courseManagement.list.creator')"
                allowClear
                showSearch
                optionFilterProp="filterProp"
                v-model:value="formState.creator_id"
                @change="handleFilter"
              >
                <a-select-option
                  v-for="author in authors"
                  :value="author.id"
                  :key="author.id"
                  :filterProp="author.name"
                >
                  {{ author.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <div class="btn-wrapper">
              <a-button
                class="flex items-center"
                type="primary"
                @click="handleOpenModalAdd"
              >
                <PlusOutlined />
                {{ t("courseManagement.courseware.add") }}
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="mt-4 flex-1">
      <a-table
        :columns="COLUMN"
        :pagination="false"
        :scroll="scrollHeightTable"
        :data-source="dataTable"
        :loading="loadingTable"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <div class="actions-wrapper flex gap-4 justify-end mr-3">
              <a-tooltip title="Chỉnh sửa học liêu">
                <span
                  @click="
                    (e) => handleActions(ACTION.Edit, record.id, record)
                  "
                >
                  <IconEdit />
                </span>
              </a-tooltip>

              <a-tooltip title="Xóa học liệu">
                <span
                  @click.stop="
                    (e) => handleActions(ACTION.Delete, record.id, e)
                  "
                >
                  <IconDelete />
                </span>
              </a-tooltip>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-wrapper">
      <span>Rows per page:</span>
      <a-select default-value="10">
        <i class="fas fa-sort-down dropdown-icon"></i>
        <a-select-option
          v-for="(pageSize, index) in pageSizeList"
          :value="pageSize"
          :key="`pageSize_${index}`"
          @click="handleActions(ACTION.ChangeSize, null, pageSize)"
        >
          {{ pageSize }}
        </a-select-option>
      </a-select>
      <span class="total-page"
        >{{ meta.from }}-{{ meta.to }} of {{ meta.total }}</span
      >
      <a-button
        class="pagination-btn"
        @click="handleActions(ACTION.Prev)"
        :disabled="disabledPrev"
      >
        <i class="fas fa-chevron-left icon-btn"></i>
      </a-button>
      <a-button
        class="pagination-btn"
        @click="handleActions(ACTION.Next)"
        :disabled="disabledNext"
      >
        <i class="fas fa-chevron-right icon-btn"></i>
      </a-button>
    </div>
    <ModalAdd
      :visible="visible"
      @cancel="closeModal"
      :currentId="currentEditId"
      :isUsed="isUsed"
      @ok="okModal"
      :modeCreate="modeCreate"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import IconDropdown from "../../../components/icon/icon-dowload.vue";
import IconSearch from "../../../components/icon/icon-search.vue";
import IconEdit from "../../../components/icon/icon-edit.vue";
import IconDelete from "../../../components/icon/icon-delete.vue";
import ModalAdd from "./components/ModalAdd.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ELearningManagementApi } from "./api/request";
import { COLUMN, ACTION, TYPE_FILE } from "./utils/constant";
import moment from "moment";
import { useShowConfirm } from "../../../utils/hook";
import { useNotify } from "../../../utils/hook";
import { PlusOutlined } from "@ant-design/icons-vue";
import { limitTextLength } from "../../../utils/helper";

const { t } = useI18n();
const store = useStore();
const { showConfirm } = useShowConfirm();
const { notifyError, notifySuccess } = useNotify();
const pageSizeList = [10, 20, 25, 50, 100];
const disabledNext = ref(false);
const disabledPrev = ref(false);
const loadingTable = ref(false);
const authors = ref([]);
const modeCreate = ref(false);
const currentEditId = ref("");
const isUsed = ref(false)
const formState = reactive({
  keyword: "",
  type_file_id: null,
  creator_id: null,
});

const meta = reactive({
  total: null,
  from: 1,
  to: null,
  currentPage: 1,
});
const paramFetchList = reactive({
  limit: 10,
  page: 1,
});
const scrollHeightTable = reactive({
  y: "calc(100vh - 425px)",
});
const dataTable = ref([]);
const visible = ref(false);
const handleOpenModalAdd = () => {
  isUsed.value = false
  currentEditId.value = null;
  modeCreate.value = true;
  visible.value = true;
};
const closeModal = () => {
  visible.value = false;
  modeCreate.value = false;
  currentEditId.value = null;
  isUsed.value = false
};
const okModal = () => {
  closeModal();
  getListStudyMaterial(paramFetchList);
};

const getListStudyMaterial = async (params) => {
  loadingTable.value = true;
  const res = await ELearningManagementApi.getListStudyMaterial(params);
  dataTable.value = res.data.map((item, index) => {
    return {
      id: item.id,
      stt: (res.current_page - 1) * res.per_page + index + 1,
      name: limitTextLength(item.name),
      type_file: item.type_file === "Document" ? "Bài giảng" : item.type_file,
      created_at: moment(item.created_at).format("DD/MM/YYYY"),
      creator: item.creator,
      being_used: item.being_used
    };
  });
  meta.total = res.total;
  meta.currentPage = res.current_page;
  meta.from = res.from;
  meta.to = res.to;
  disabledNext.value = !res.to || res.to === res.total;
  disabledPrev.value = !res.from || res.from === 1;
  loadingTable.value = false;
};

const handleActions = async (name, id, payload) => {
  switch (name) {
    case ACTION.Next:
      paramFetchList.page = paramFetchList.page + 1;
      getListStudyMaterial({ ...paramFetchList, ...formState });
      break;
    case ACTION.Prev:
      paramFetchList.page = paramFetchList.page - 1;
      getListStudyMaterial({ ...paramFetchList, ...formState });
      break;
    case ACTION.ChangeSize:
      paramFetchList.limit = payload;
      paramFetchList.page = 1;
      getListStudyMaterial({ ...paramFetchList, ...formState });
      break;
    case ACTION.Edit:
      currentEditId.value = id;
      modeCreate.value = false;
      visible.value = true;
      isUsed.value = payload.being_used
      break;
    case ACTION.Delete:
      showConfirm({
        onOk: () => {
          deleteCourseware(id);
        },
        suffix: t("courseManagement.courseware.default"),
      });
    default:
      break;
  }
};
const handleTableChange = (pagination, filters, sorter) => {
  paramFetchList.sort_by = sorter.field;
  paramFetchList.sort_type = sorter.order == "ascend" ? "asc" : "desc";
  getListStudyMaterial({ ...paramFetchList, ...formState });
};
const handleFilter = (props, fields) => {
  paramFetchList.page = 1;
  getListStudyMaterial({ ...paramFetchList, ...formState });
};

const getListUser = async (params) => {
  const res = await ELearningManagementApi.getListUser(params);
  authors.value = res.data;
};

const deleteCourseware = async (id) => {
  try {
    await ELearningManagementApi.deleteCourseware(id);
    notifySuccess(t("courseManagement.courseware.notiDelete"));
  } catch (error) {
    notifyError(t("courseManagement.courseware.notiDeleteError"));
  }
  getListStudyMaterial(paramFetchList);
};

onMounted(() => {
  getListStudyMaterial(paramFetchList);
  getListUser({ roles: [1, 14] });
});
</script>
<style lang="scss" scoped>
.btn-search {
  font-weight: 600;
  font-size: 14px;
  line-height: 193%;
  color: #929292;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    display: inline-block;
    margin-right: 6px;
  }
  svg {
    display: inline-block;
    margin-bottom: 20px;
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  .total-page {
    color: rgba(0, 0, 0, 0.87);
    margin: 0 30px;
  }
  .dropdown-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    top: -7px;
    left: 4px;
  }
  .pagination-btn {
    border: none;
    margin-bottom: 0;
    width: unset;
    .icon-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  :deep(.ant-select-selection-item) {
    display: flex !important;
    align-items: center;
  }
}
:deep(.filter-wrapper) {
  .name-input {
    .ant-input {
      border-radius: 36px !important;
      padding-left: 10px !important;
    }
  }
  .ant-input-suffix {
    i {
      display: none;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-right: 4px;
      color: black;
    }
  }

  .search-btn {
    &hover {
      cursor: pointer;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .btn-wrapper {
    height: 100%;
    display: flex;
    gap: 20px;
    font-weight: 700px;
    justify-content: flex-end;
    button {
      font-size: 16px;
    }
  }
}
:deep(.ant-input-affix-wrapper) {
  padding: 0 11px;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

:deep(.ant-input-group-addon) {
  border-top: 1px solid #d9d9d9 !important;
  border-right: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

:deep(.ant-input-affix-wrapper:focus) {
  border-color: #d9d9d9 !important;
}

:deep(.ant-input-affix-wrapper-focused) {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
:deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-color: #d9d9d9;
}

:deep(.ant-table-column-title) {
  color: black;
  font-size: 20px;
  @media only screen and (max-width: 1366px) {
    font-size: 16px;
  }
}
:deep(.ant-table-cell) {
  color: black;
  font-size: 16px;
}
</style>