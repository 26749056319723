import httpRequest from './http-request';
import apiConstant from './apiConstant';

export const categoryManagement = {
  getListCategory: async (params) => {
    const res = await httpRequest.get(apiConstant.CATEGORY.LIST_CATEGORY, {
      params
    });
    return res;
  },
  createCategory: async (payload) => {
    const res = await httpRequest.post(
      apiConstant.CATEGORY.CREATE_CATEGORY,
      payload
    );
    return res;
  },
  editCategory: async (id, payload) => {
    const res = await httpRequest.put(
      apiConstant.CATEGORY.EDIT_CATEGORY + id,
      payload
    );
    return res;
  },
  deleteCategory: async (id) => {
    const res = await httpRequest.delete(
      apiConstant.CATEGORY.DELETE_CATEGORY + id
    );
  }
};
