import { CREATE_COURSE_STATUS } from '../features/e-learning/course/utils/constant';

import i18n from '../i18n';
const { t } = i18n.global;

const state = () => ({
  action: {
    name: undefined,
    id: undefined,
    slug: undefined
  },
  course_id: null,
  edit_course: false,
  view_course: false,
  courseStatus: null,
  modeCreate: false,
  steps: [
    {
      name: 1,
      title: t('courseManagement.step1.headerTitle'),
      status: CREATE_COURSE_STATUS.Open,
      view: false,
      completed: false
    },
    {
      name: 2,
      title: t('courseManagement.step2.headerTitle'),
      status: CREATE_COURSE_STATUS.Open,
      view: false,
      completed: false
    },
    {
      name: 3,
      title: t('courseManagement.step3.headerTitle'),
      status: CREATE_COURSE_STATUS.Open,
      view: false,
      completed: false
    }
  ]
});

const mutations = {
  updateAction(state, action) {
    state.action = action;
  },

  updateStep(state, step) {
    state.steps = step;
  },

  doneStep(state, stepName) {
    state.steps = state.steps.map((item, index) => {
      if (item.name !== stepName) return item;
      return { ...item, status: CREATE_COURSE_STATUS.Done };
    });
  },

  doingStep(state, stepName) {
    state.steps = state.steps.map((item, index) => {
      if (item.name !== stepName) return item;
      return { ...item, status: CREATE_COURSE_STATUS.Doing };
    });
  },

  resetStepStatus(state, stepName) {
    state.steps = state.steps.map((item, index) => {
      return { ...item, status: CREATE_COURSE_STATUS.Open };
    });
  },

  viewStep(state, stepName) {
    state.steps = state.steps.map((item, index) => {
      if (item.name !== stepName) return { ...item, view: false };
      return { ...item, view: true };
    });
  },

  goEditStep(state, stepName) {
    state.steps = state.steps.map((item, index) => {
      if (item.name !== stepName) {
        return { ...item, status: CREATE_COURSE_STATUS.Open };
      }
      return { ...item, status: CREATE_COURSE_STATUS.Doing };
    });
  },

  backListCourse(state) {
    state.steps = state.steps.map((item, index) => {
      return {
        ...item,
        status: CREATE_COURSE_STATUS.Open,
        view: false,
        completed: false
      };
    });
    state.course_id = null;
    state.edit_course = false;
    state.view_course = false;
    state.courseStatus = null;
    state.modeCreate = false;
  },

  updateEditStatus(state, status) {
    state.edit_course = status;
    state.steps = state.steps.map((item, index) => ({ ...item, view: false }));
  },

  updateCourseStatus(state, status) {
    state.courseStatus = status;
    if (status?.course_info === 1) {
      state.steps[0].completed = status.course_info === 1;
    }
    if (status?.lesson_info === 1) {
      state.steps[1].completed = status.lesson_info === 1;
    }
    if (status?.test_info === 1) {
      state.steps[2].completed = status.test_info === 1;
    }
  },

  updateViewStatus(state, status) {
    state.view_course = status;
  },

  updateModeCreate(state, status) {
    state.modeCreate = status;
  },
  updateCourseId(state, id) {
    state.course_id = id;
  }
};

const getters = {
  steps(state) {
    return state.steps;
  }
};

const actions = {
  updateAction({ commit }, action) {
    commit('updateAction', action);
  },

  updateStep({ commit }, action) {
    commit('updateStep', action);
  },

  doingStep({ commit }, action) {
    commit('doingStep', action);
  },

  resetStepStatus({ commit }, action) {
    commit('resetStepStatus', action);
  },

  doneStep({ commit }, action) {
    commit('doneStep', action);
  },

  viewStep({ commit }, step) {
    commit('viewStep', step);
  },

  goEditStep({ commit }, step) {
    commit('goEditStep', step);
  },

  backListCourse({ commit }, action) {
    commit('backListCourse', action);
  },

  updateCourseId({ commit }, id) {
    commit('updateCourseId', id);
  },

  updateEditStatus({ commit }, status) {
    commit('updateEditStatus', status);
  },

  updateCourseStatus({ commit }, status) {
    commit('updateCourseStatus', status);
  },

  updateViewStatus({ commit }, status) {
    commit('updateViewStatus', status);
  },
  updateModeCreate({ commit }, status) {
    commit('updateModeCreate', status);
  }
};

const courseManagment = {
  state,
  actions,
  mutations,
  getters
};

export default courseManagment;
