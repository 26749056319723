const SERVICE_URL_WIKI_BASE = '/api/v2/';

const pathUrl = {
  //   post: 'post',
  category: 'elearning-category',
  user: 'user/list',
  studyMaterial: 'file/study_material',
  user: 'user',
  file: 'file'
};

export { pathUrl, SERVICE_URL_WIKI_BASE };
