import i18n from '../../../../i18n';
const { t } = i18n.global;
import { Table } from "ant-design-vue";

export const TOOL_BAR = [
  'undo',
  'redo',
  'heading',
  '|',
  'fontfamily',
  'fontsize',
  '|',
  'alignment',
  '|',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  '|',
  'link',
  '|',
  'outdent',
  'indent',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'insertTable',
  '|',
  'blockQuote',
  '|'
];

export const CREATE_COURSE_STATUS = {
  Doing: 'DOING',
  Open: 'OPEN',
  Done: 'Success',
  Show: false
};

export const FILE_TYPE = {
  Video: 'video',
  Document: 'document'
};

export const VIDEOS_FILE_STEP2 = {
  type: ['video/mp4', 'video/quicktime'],
  message: ['MP4', 'MOV'],
  maxSize: 500 * 1024 * 1024
};

export const DOCS_FILE_STEP2 = {
  type: [
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  message: ['WORD', 'MSWORD', 'POWERPOINT', 'PDF'],
  maxSize: 100 * 1024 * 1024
};

export const IMG_FILE_STEP1 = {
  type: ['image/png', 'image/jpeg', 'image/svg+xml'],
  message: ['PNG', 'JPEG', 'SVG'],
  maxSize: 100 * 1024 * 1024
};

export const DOC_FILE_STEP1 = {
  type: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  message: ['PDF', 'WORD', 'POWERPOINT'],
  maxSize: 100 * 1024 * 1024
};

export const COLUMNS = [
  {
    title: t('courseManagement.list.no'),
    dataIndex: 'no',
    sorter: false,
    width: '8%',
    key: 'no'
  },
  {
    title: t('courseManagement.list.course'),
    dataIndex: 'title',
    sorter: true,
    width: '17%',
    key: 'title'
  },
  {
    title: t('courseManagement.list.category'),
    dataIndex: 'learning_categories',
    sorter: false,
    width: '15%',
    key: 'learning_categories'
  },
  {
    title: t('courseManagement.list.position'),
    dataIndex: 'positions',
    sorter: false,
    width: '25%',
    key: 'positions'
  },
  {
    title: t('courseManagement.list.createdAt'),
    dataIndex: 'created_at',
    sorter: true,
    width: '15%',
    key: 'created_at'
  },
  {
    title: t('courseManagement.list.creator'),
    dataIndex: 'creator',
    sorter: true,
    width: '12%',
    key: 'creator'
  },
  {
    title: '',
    dataIndex: 'action',
    width: '8%',
    key: 'action'
  }
];

export const COLUMN_STATISTICS = [
  {
    title: t('courseManagement.statisticalCol.courseName'),
    dataIndex: 'name',
    width: '5%',
    key: 'name'
  },
  {
    title: t('courseManagement.statisticalCol.timeRequired'),
    dataIndex: 'time',
    width: '5%',
    key: 'time'
  },
  {
    title: t('courseManagement.statisticalCol.questionCount'),
    dataIndex: 'questionCount',
    width: '5%',
    key: 'questionCount'
  },
  {
    title: t('courseManagement.statisticalCol.evarageScore'),
    dataIndex: 'overage',
    width: '5%',
    key: 'overage'
  }
];

export const COLUMN_STATISTICS_DETAIL = [
  {
    title: t('courseManagement.statisticalCol.courseName'),
    dataIndex: 'test_name',
    key: 'test_name',
    sorter: true,
    width: '18%'
  },
  {
    title: t('courseManagement.statisticalCol.staffName'),
    dataIndex: 'user_name',
    key: 'user_name',
    width: '17%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.realTimeMakeCourse'),
    dataIndex: 'time_taken',
    key: 'time_taken',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.score'),
    dataIndex: 'score',
    key: 'score',
    width: '13%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.branch'),
    dataIndex: 'branch',
    key: 'branch',
    width: '13%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.homeworkDay'),
    dataIndex: 'started_at',
    key: 'started_at',
    width: '15%',
    sorter: true
  }
];

export const COLUMN_DETAIL_COURSE = [
  {
    title: t('courseManagement.statisticalCol.staffName'),
    dataIndex: 'user_name',
    key: 'user_name',
    width: '17%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.mandatoryStaff'),
    dataIndex: 'is_compulsory',
    key: 'is_compulsory',
    width: '20%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.startCourse'),
    dataIndex: 'started_at',
    key: 'started_at',
    width: '23%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.branch'),
    dataIndex: 'branch',
    key: 'branch',
    width: '13%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.studyTime'),
    dataIndex: 'study_time',
    key: 'study_time',
    width: '15%',
    sorter: true
  },
  {
    title: t('courseManagement.statisticalCol.progress'),
    dataIndex: 'percentage_progress',
    key: 'percentage_progress',
    width: '12%',
    sorter: true
  }
];

export const COLUMN_COURSE_HISTORY = [
  {
    title: t('courseManagement.statisticalCol.timeChange'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%'
  },
  {
    title: t('courseManagement.statisticalCol.peopleChange'),
    dataIndex: 'user',
    key: 'user',
    width: '10%'
  },
  {
    title: t('courseManagement.statisticalCol.activityHistory'),
    dataIndex: 'history',
    key: 'history',
    width: '20%'
  }
];
export const STUDY_TABLE = [
  {
    title: t('courseManagement.list.no'),
    dataIndex: 'no',
    key: 'no',
    width: '20%',
    fixed: "right",
  },
  {
    title: t('courseManagement.courseware.nameCourseware'),
    dataIndex: 'name',
    key: 'name',
    width: '57%'
  },
  Table.SELECTION_COLUMN,
];

export const ACTION = {
  Next: 'NEXT',
  Prev: 'PREV',
  ChangeSize: 'CHANGESIZE'
};
export const PAGESIZE = [5, 10, 20, 50, 100];
export const PAGESIZE1 = [10, 20, 50, 100];
export const STEP_NAME = ['course_info', 'lesson_info', 'test_info', 'created'];

export const YEAR = [
  {
    id: 0,
    name: 'Năm',
    type: ''
  },
  {
    id: 2020,
    name: '2020',
    type: '2020'
  },
  {
    id: 2021,
    name: '2021',
    type: '2021'
  },
  {
    id: 2022,
    name: '2022',
    type: '2022'
  },
  {
    id: 2023,
    name: '2023',
    type: '2023'
  }
];
export const listCompulsoryUser = [
  {
    id: 0,
    name: 'Nhân viên không bắt buộc',
    type: '0'
  },
  {
    id: 1,
    name: 'Nhân viên bắt buộc',
    type: '1'
  }
];
export const MONTH = [
  {
    id: 0,
    name: 'Tháng',
    type: ''
  },
  {
    id: 1,
    name: 'Tháng 1',
    type: '1'
  },
  {
    id: 2,
    name: 'Tháng 2',
    type: '2'
  },
  {
    id: 3,
    name: 'Tháng 3',
    type: '3'
  },
  {
    id: 4,
    name: 'Tháng 4',
    type: '4'
  },
  {
    id: 5,
    name: 'Tháng 5',
    type: '5'
  },
  {
    id: 6,
    name: 'Tháng 6',
    type: '6'
  },
  {
    id: 7,
    name: 'Tháng 7',
    type: '7'
  },
  {
    id: 8,
    name: 'Tháng 8',
    type: '8'
  },
  {
    id: 9,
    name: 'Tháng 9',
    type: '9'
  },
  {
    id: 10,
    name: 'Tháng 10',
    type: '10'
  },
  {
    id: 11,
    name: 'Tháng 11',
    type: '11'
  },
  {
    id: 12,
    name: 'Tháng 12',
    type: '12'
  }
];
export const LIST_BRANCH = [
  {
    id: 1,
    name: "MHN"
  },
  {
    id: 2,
    name: "MSG"
  },
  {
    id: 3,
    name: "MDN"
  },
  {
    id: 4,
    name: "MA"
  },
] 
export const UPLOAD_METHOD = [
  {
    id: 1,
    name: t('courseManagement.step2.localUpload')
  },
  {
    id: 2,
    name: t('courseManagement.step2.upLoadCourseware')
  }
];
export const DATE_FORMAT = "DD/MM/YYYY"

export const STATUS_UPLOAD = {
  Loading: "loading",
  Success: "success"
}