<template>
  <section class="home-detail" ref="contentElement">
    <div
      class="search-form flex justify-between items-center rounded-[10px]"
      v-if="isShowSearchBar"
    >
      <input
        class="w-full outline-none border-none bg-inherit font-bold text-[13px] text-white"
        type="search"
        placeholder="Nhập từ khóa"
        @input="search"
        v-model="searchQuery"
      />
      <div class="text-[13px] font-bold text-white mr-1">{{ (lengthMark ? (currentIndexSearch + 1) : 0) + '/' + lengthMark }}</div>
      <div class="self-stretch w-[1px] bg-white py-1"></div>
      <div class="min-w-[70px] flex items-center justify-end">
        <button data-search="prev" class="arrow-icon down">
          <span class="left-bar"></span>
          <span class="right-bar"></span>
        </button>
        <button data-search="next" class="arrow-icon">
          <span class="left-bar"></span>
          <span class="right-bar"></span>
        </button>
        <button data-search="clear" class="clear text-lg text-white" @click="closeAndUnMark">✖</button>
      </div>
    </div>
    <a-row>
      <a-col :span='5' class="pl-[30px]" >
      <div class="sticky top-4">
        <div class="go-back flex items-center gap-1 mt-3 cursor-pointer">
          <a @click="$router.go(-1)">
            <IconBack class="text-mainColor"/>
          </a>
          <span @click="$router.go(-1)" class="!leading-none text-mainColor">Back</span>
        </div>
        <div class="chapter mt-[40px] ml-[20px]" v-if="post.chapters?.length > 1">
          <p class="font-bold text-lg text-mainColor">
            Mục lục
          </p>
          <div
            class="title mb-[10px] cursor-pointer mt-2"
            v-for="(chapter, index) in post.chapters"
            @click="goToScrollChapter(index)"
            :key="index"
          >
            <span 
              v-if="chapter.title?.length" 
              :class="{ 'font-bold': activeIndex === index }"
              class="text-mainColor text-base" 
            >
              {{ labelChapter(index + 1) }} {{ chapter.title }}
            </span>
          </div>
          <p class="text-mainColor font-bold">Tài liệu đính kèm</p>
        </div>
      </div>
      </a-col>
      <a-col :span="16" class="detail">
        <div>
          <div class="chapter" ref='content'>
            <div class="font-bold text-mainColor text-2xl">{{ post.title }}</div>
            <div class="created-date mt-2"><span class="font-bold">{{t("courseManagement.common.updateDate")}}:</span> {{ post.updated_at }}</div>
            <div class="created-date"><span class="font-bold">{{t("courseManagement.list.createdAt")}}:</span> {{ post.created_at }}</div>
            <div class="creator"><span class="font-bold">{{t("courseManagement.common.poster")}}:</span> {{ post.user.name }}</div>
            <div class="categories"><span class="font-bold">{{t("courseManagement.post.category")}}:</span> {{ categories.toString() }}</div>
            <div class="categories"><span class="font-bold">{{t("courseManagement.post.postVisits")}}:</span> {{ post.view }}</div>
            <div class="description">
              <span class="font-bold">{{t("courseManagement.post.description")}}:</span> <span v-html="post.description"></span>
            </div>
            <div
              class="info-chapter mb-[10px]"
              v-for="(chapter, index) in post.chapters"
              :key="index"
              :id="`chapter-${index}`"
              :ref="'section-'+ index"
            >
              <span v-if="chapter.title?.length" class="font-bold text-[20px] mt-[30px]">{{ labelChapter(index + 1) + ' '}} </span>
              <span v-if="chapter.title?.length" class="font-bold text-[20px]" v-html="chapter.title "></span>
              <div class="ck-content text-[16px]" v-html="chapter.description"></div>
            </div>
            <div class="attachments-title mt-11">{{t("courseManagement.post.attachments")}}</div>
            <div class="attachements" v-if="post?.files">
              <div
                class="attachements-item flex items-center gap-2 mt-1"
                v-for="(file, key) in post.files"
                :key="key"
              >
                <div class="m-0 flex gap-1 items-center cursor-pointer" @click="openPreviewDocument(file?.full_path)">
                  <img src="../../../../public/images/pdf.svg" alt="">
                  <div>
                    <p class="text-sm">{{file?.name}}</p>
                    <p class="text-xs text-[#666666]">{{convertByteToOther(file?.file_size)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </section>
  <ModalPreviewDocument :isShow="isShowModal" :originPath="originPath" :path="pathDocument" @onCloseModal="handleCloseModal"></ModalPreviewDocument>
</template>

<script>
import IconBack from '../../components/icon/icon-back.vue';
import ModalPreviewDocument from '../../components/modal/ModalPreviewDocument.vue';
import IconPdf from '../../components/icon/icon-pdf.vue';
import { getDetail } from '../../api/homeDetail.js';
import Loading from '../../components/loading/Loading.vue';
import { romanize } from '../../features/post/utils/helpers';
import { formatString } from '../../utils/helper'
import { DownloadOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import { LINK_EMBEDDED_DOC } from "../../constant/common";
import { useLoading } from '../../utils/hook';
import Mark from "mark.js";

export default {
  setup(){
    const { t } = useI18n();
    const { hideLoading, showLoading } = useLoading();
    return {
      t,
      hideLoading,
      showLoading
    }
  },

  components: { IconBack, IconPdf, Loading, DownloadOutlined, ModalPreviewDocument },

  data() {
    return {
      post: {
        description: '',
        categories: [],
        user: {},
        files: []
      },
      categories: [],
      topViews: [],
      total: 0,
      pageSize: 5,
      textSearch: '',
      isShowModal: false,
      pathDocument: "",
      originPath: "",
      searchQuery: "",
      resultSearch: null,
      currentIndexSearch: 0,
      position: null,
      isShowSearchBar: false,
      markInstance: null,
      lengthMark: null,
      activeIndex: 0,
      LINK_EMBEDDED_DOC: LINK_EMBEDDED_DOC,
    };
  },

  created() {
    this.textSearch = this.$route?.query?.keyword || '';
    if (this.$route.params.slug) {
      this.getDetail(this.$route.params.slug);
    }
  },

  watch: {
    $route() {
      this.textSearch = this.$route?.query?.keyword || '';
      // this.getDetail(this.$route.params.slug);
    },
  },
  
  methods: {
    openPreviewDocument(url){
      const link =  url
      this.isShowModal = true
      this.pathDocument = link
      this.originPath = url
    },
    convertByteToOther(byte){
      let data
      if(byte < 1000){
        data = byte / 1024
        return `${data.toFixed(1)}Kb`
      }else{
        data = byte / (1024 * 1024)
        return `${data.toFixed(2)}Mb`
      }
    },

    async getDetail(slug) {
      this.showLoading()
      try {
        this.loading = true;
        const response = await getDetail(slug);
        this.post = response.data;
        this.categories = [];
        for (let i = 0; i < this.post.categories.length; i++) {
          this.categories.push(this.post.categories[i].name);
        }
        this.initSearch();
        document.title = this.post.title + '-Wiki Mor';
        document
          .querySelector("meta[property='og:description']")
          .setAttribute(
            'content',
            (this.post.chapters.length && this.post.chapters[0].description ? formatString(this.post.chapters[0].description.slice(1, 100)) : '' +
              '-Wiki Mor'
          ));
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
        this.hideLoading()
      }
    },

    goToScrollChapter(index) {
      this.activeIndex = index
      document.getElementById(`chapter-${index}`).scrollIntoView({ behavior: "smooth" });
    },

    handleScroll(){
      const sections = this.$refs
      const sectionKeys = Object.keys(sections).filter(key => key.startsWith('section-'));
      sectionKeys.forEach((key) =>{
        const elementSection = this.$refs[key][0]
        const rect = elementSection.getBoundingClientRect();
        if (rect.top < 85 && rect.top >= 0) {
          const index = Number(key.split('-')[1]);
          if(!this.activeIndex == index){
            this.activeIndex = index
          }
        }
      })
    },
    
    convertRomanize(number){
      return romanize(number)
    },

    labelChapter(number){
      return `${this.t("courseManagement.post.chapter")} ${this.convertRomanize(number)}:`
    },

    handleCloseModal() {
      this.isShowModal = false;
    },

    closeAndUnMark(){
      this.markInstance.unmark()
      this.isShowSearchBar = false;
    },

    search() {
      this.markInstance.unmark();
      if (this.searchQuery) {
        this.markInstance.mark(this.searchQuery, {
          separateWordSearch: false,
          diacritics: false,
          done: () => {
            this.resultSearch = $(this.$refs.content).find("mark");
            this.lengthMark = this.resultSearch?.length
            this.currentIndexSearch = 0;
            this.jumpTo();
          },
        });
      }else{
        this.lengthMark = 0
      }
    },

    jumpTo() {
      if (this.resultSearch.length) {
        const offsetTop = 50;
        const current = this.resultSearch.eq(this.currentIndexSearch);
        this.resultSearch.removeClass("current");
        if (current.length) {
          current.addClass("current");
          this.position = current.offset().top - $(".home-detail").offset().top - offsetTop;
          $(".layout-content").scrollTop(this.position);
        }
      }
    },

    initSearch() {
      this.searchQuery = this.$router.currentRoute.value?.query?.keyword;
      if (this.searchQuery) {
        this.isShowSearchBar = true;
      }
      setTimeout(() => {
        this.markInstance = new Mark(this.$refs.content)
        this.search();
        const self = this;
        $("button[data-search='next']")
          .add($("button[data-search='prev']"))
          .on("click", function () {
            if (self.resultSearch.length) {
              self.currentIndexSearch += $(this).is($("button[data-search='prev']")) ? -1 : 1;
              if (self.currentIndexSearch < 0) {
                self.currentIndexSearch = self.resultSearch.length - 1;
              }
              if (self.currentIndexSearch > self.resultSearch.length - 1) {
                self.currentIndexSearch = 0;
              }
              self.jumpTo();
            }
          });
      }, 100);
    },
  },
  mounted(){
    $('.layout-content').scroll(()=>{
      this.handleScroll()
    })
  },
  beforeUnmount(){
    $('.layout-content').off('scroll')
  }
};
</script>

<style scoped lang="scss">
:deep(.chapter) {
  ul,
  ol {
    list-style-position: inside;
  }
}

.search-form {
  padding: 10px;
  background: #17469e;
  position: fixed;
  top: 100;
  right: 30px;
  width: 360px;
  z-index: 1000;
}
.arrow-icon {
  position: relative;
  width: 22px;
  height: 30px;
  .left-bar {
    position: absolute;
    top: 15px;
    left: 0;
    width: 10px;
    height: 3px;
    display: block;
    transform: rotate(35deg);
    float: right;
    border-radius: 2px;
    background-color: white;
  }

  .right-bar {
    position: absolute;
    top: 15px;
    left: 7px;
    width: 10px;
    height: 3px;
    display: block;
    transform: rotate(-35deg);
    float: right;
    border-radius: 2px;
    background-color: white;
  }
}
.arrow-icon.down {
  .left-bar {
    transform: rotate(-35deg);
  }
}
.arrow-icon.down {
  .right-bar {
    transform: rotate(35deg);
  }
}
:deep(.ck-content){
  p, ul, li{
    span{
      font-family: Arial, sans-serif !important;
    }
  }
}
</style>