export const socialNetwork ={
    facebook: 'https://www.facebook.com/morjsc',
    mms: 'https://mms.mor-software.com/',
    morHome: 'https://morsoftware.com/'
}

export const LINK_EMBEDDED_DOC = 'https://drive.google.com/viewerng/viewer?embedded=true&url='

export const LIST_CATEGORY = [
    'Quy trình và biểu mẫu',
    'Bảo hiểm VBI',
    'Nội quy, chế độ phúc lợi',
    'Hướng dẫn sử dụng',
    'Hệ thống Wiki'
]